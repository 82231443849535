import PropTypes from 'prop-types';

const TextValue = ({ value }) => {
  if (!value) {
    return null;
  }

  return (value || '').toString().replace(/(<([^>]+)>)/gi, '').substr(0, 200);
};

TextValue.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

TextValue.defaultProps = {
  value: null,
};

export default TextValue;
