import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../internationalization/i18n';

const propTypes = {
  text: PropTypes.string,
};

const defaultProps = {
  text: null,
};

const ContentLoader = props => (
  <div className="c-bb-loader">
    {/* TODO Ronald: deze werkt nog niet, is gewoon KOPIE van NSI loader wat je gemaakt hebt */}
    <svg className="c-bb-loader__spinner o-bb-spinner" width="24px" height="24px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
    </svg>
    {props.text || i18n.t('CONTENT.loading')}
  </div>
);

ContentLoader.propTypes = propTypes;
ContentLoader.defaultProps = defaultProps;

export default ContentLoader;
