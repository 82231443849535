import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Switch = ({ checked, onChange }) => {
  const handleChange = () => {
    if (onChange) {
      onChange(!checked);
    }
  };

  return (
    <button
      type="button"
      onClick={handleChange}
      className={classNames(
        'c-bb-switch',
        { 'c-bb-switch--checked': checked },
      )}
    >
      <div className="c-bb-switch__circle" />
    </button>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

Switch.defaultProps = {
  onChange: () => {},
};

export default Switch;
