import {
  SET_CURRENT_MODULE_ITEM,
  SET_MODULE_ITEMS_FILTER_BY_NAME,
  SET_MODULE_ITEMS_FILTER_BY_CATEGORY,
  BASE_MODULE_ITEMS_LIST_REQUEST,
  BASE_MODULE_ITEMS_LIST_SUCCESS,
  BASE_MODULE_ITEMS_LIST_FAILURE,
  CLEAR_FORM_MEDIA_FILED,
  BASE_MODULE_ITEM_SAVE_REQUEST,
  BASE_MODULE_ITEM_SAVE_SUCCESS,
  BASE_MODULE_ITEM_SAVE_FAILURE,
  BASE_MODULE_ITEM_NSI_TYPES_SUCCESS,
  NSI_DETAIL_FILTERS_SUCCESS,
  BASE_MODULE_ITEM_DELETE_REQUEST,
  BASE_MODULE_ITEM_DELETE_SUCCESS,
  BASE_MODULE_ITEM_DELETE_FAILURE,
  BASE_MODULE_ITEMS_REORDER,
  readBaseModuleItem,
  SET_BASE_MODULE_LIST_FILTERS,
  BASE_MODULE_ITEMS_SET_SORT_BY,
  BASE_MODULE_ITEMS_RESET,
} from '../actions/baseModuleItems';
import { CLOSE_MEDIA_MANAGER } from '../actions/mediaManager';
import transform from '../tools/transform-entities';
import { arrayMove } from '../tools/array';

const initialState = {
  currentItemId: null,
  filters: {},
  entities: {},
  ids: [],
  itemsList: [],
  isLoading: false,
  fetchingItemsList: true,
  fetchItemSave: false,
  fetchItemDelete: false,
  currentFormMediaField: null,
  customProductTypes: [],
  customDetailFilters: [],
  sortableField: null,
  sortableDirection: null,
};

const baseModuleItems = (state = initialState, action) => {
  switch (action.type) {
    case BASE_MODULE_ITEMS_RESET: {
      return {
        ...initialState,
        customProductTypes: state.customProductTypes,
        customDetailFilters: state.customDetailFilters,
      };
    }
    case SET_BASE_MODULE_LIST_FILTERS: {
      const { filters } = action.payload;

      return {
        ...state,
        filters,
      };
    }
    case BASE_MODULE_ITEMS_REORDER: {
      const { oldIndex, newIndex } = action.payload;
      const nextIds = arrayMove(state.ids, oldIndex, newIndex);

      return {
        ...state,
        ids: nextIds,
      };
    }
    case BASE_MODULE_ITEMS_SET_SORT_BY: {
      const { field, direction } = action.payload;

      return {
        ...state,
        sortableField: field,
        sortableDirection: direction,
      };
    }
    case readBaseModuleItem.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case readBaseModuleItem.FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case BASE_MODULE_ITEM_SAVE_REQUEST: {
      return {
        ...state,
        fetchItemSave: true,
      };
    }
    case BASE_MODULE_ITEM_SAVE_SUCCESS: {
      return {
        ...state,
        fetchItemSave: false,
      };
    }
    case BASE_MODULE_ITEM_SAVE_FAILURE: {
      return {
        ...state,
        fetchItemSave: false,
      };
    }
    case BASE_MODULE_ITEM_DELETE_REQUEST: {
      return {
        ...state,
        fetchItemDelete: true,
      };
    }
    case BASE_MODULE_ITEM_DELETE_SUCCESS: {
      const { id } = action.meta;
      const nextEntities = {
        ...state.entities,
      };
      delete nextEntities[id];

      return {
        ...state,
        ids: state.ids.filter(item => +item !== +id),
        entities: nextEntities,
        fetchItemDelete: false,
      };
    }
    case BASE_MODULE_ITEM_DELETE_FAILURE: {
      return {
        ...state,
        fetchItemDelete: false,
      };
    }
    case readBaseModuleItem.SUCCESS: {
      const { entities } = state;
      const { response } = action;
      const entity = entities[response.id] || {};

      return {
        ...state,
        isLoading: false,
        entities: {
          ...entities,
          [response.id]: {
            ...entity,
            ...response,
          },
        },
      };
    }
    case SET_CURRENT_MODULE_ITEM: {
      const { id = null } = action.payload;

      return {
        ...state,
        currentItemId: id,
      };
    }
    case SET_MODULE_ITEMS_FILTER_BY_NAME:
      return {
        ...state,
        filterByName: action.payload,
      };
    case SET_MODULE_ITEMS_FILTER_BY_CATEGORY:
      return {
        ...state,
        filterByCategory: action.payload,
      };
    case BASE_MODULE_ITEM_NSI_TYPES_SUCCESS:
      return {
        ...state,
        customProductTypes: action.response,
      };
    case NSI_DETAIL_FILTERS_SUCCESS:
      return {
        ...state,
        customDetailFilters: action.response,
      };
    case BASE_MODULE_ITEMS_LIST_REQUEST:
      return {
        ...state,
        fetchingItemsList: true,
      };
    case BASE_MODULE_ITEMS_LIST_SUCCESS: {
      const { ids, entities } = transform(action.response);

      return {
        ...state,
        ids,
        entities,
        fetchingItemsList: false,
        itemsList: action.response,
      };
    }
    case BASE_MODULE_ITEMS_LIST_FAILURE:
      return {
        ...state,
        fetchingItemsList: false,
        itemsList: initialState.itemsList,
      };
    case CLOSE_MEDIA_MANAGER:
      if (state.currentFormMediaField && action.value) {
        return {
          ...state,
          currentFormMediaField: {
            ...state.currentFormMediaField,
            media: action.value,
          },
        };
      }
      return state;
    case CLEAR_FORM_MEDIA_FILED:
      return {
        ...state,
        currentFormMediaField: null,
      };
    default:
      return state;
  }
};

export default baseModuleItems;
