import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MessageModal from '../Modals/MessageModal';
import { clearMessage } from '../../actions/messages';
import i18n from '../../internationalization/i18n';

const propTypes = {
  status: PropTypes.number.isRequired, // Store value
  clearMessage: PropTypes.func.isRequired, // Redux action creator
  title: PropTypes.string, // Store value
  message: PropTypes.string, // Store value
};
const defaultProps = {
  title: '',
  message: '',
};

class MessageContainer extends Component {
  static renderGeneralErrorMessage() {
    return (
      <div>
        <div>
          Het spijt ons, maar er is iets foutgelopen.
        </div>
        <div>
          Gelieve onze <a href="mailto:helpdesk@browsbox.com">helpdesk te
            contacteren</a> indien dit een terugkerend probleem is.
        </div>
      </div>
    );
  }

  static renderMaintenanceMessage() {
    return (
      <p>
        We are temporarily down for maintenance, we are updating the website for an even better experience.<br />
        The service needed to be locked to ensure data integrity during the update procedure.<br />
        We apologise for the inconvenience and <b>we will be back in 5 minutes</b>.
      </p>
    );
  }

  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  onClose(evt) {
    if (evt) {
      evt.preventDefault();
    }
    this.props.clearMessage();
  }

  redirectToLogin = () => {
    window.location.href = '/admin';
  };

  renderErrorMessage() {
    const { message } = this.props;
    return <div>{ message }</div>;
  }

  renderUnauthorizedMessage() {
    return (
      <MessageModal
        onClose={this.redirectToLogin}
        onOk={this.redirectToLogin}
        title={i18n.t('CONTENT.authorizationRequired')}
      >
        {i18n.t('CONTENT.authorizationMessage')}
      </MessageModal>
    );
  }

  render() {
    const { status, message: errorMessage, title: errorTitle } = this.props;
    let message = null;
    let title;

    if (errorMessage) {
      message = this.renderErrorMessage();
      title = errorTitle || i18n.t('CONTENT.genericError');
    } else if (status === 500) {
      message = MessageContainer.renderGeneralErrorMessage();
      title = i18n.t('CONTENT.genericError');
    } else if (status === 503) {
      message = MessageContainer.renderMaintenanceMessage();
      title = i18n.t('CONTENT.maintenance');
    }

    if ([401, 403].includes(status)) {
      return this.renderUnauthorizedMessage();
    }

    if (message) {
      return (
        <MessageModal
          onClose={this.onClose}
          onOk={this.onClose}
          title={title}
        >
          {message}
        </MessageModal>
      );
    }
    return null;
  }
}

MessageContainer.propTypes = propTypes;
MessageContainer.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const {
    messages,
  } = state;

  return {
    ...messages,
  };
};
const mapDispatchToProps = {
  clearMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageContainer);
