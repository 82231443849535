import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GooglePreview from '../../../FormElements/GooglePreview';

const BaseModuleItemGooglePreview = ({ title, description, url }) => (
  <GooglePreview title={title} description={description} url={url} />
);

BaseModuleItemGooglePreview.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { routePrefix }) => {
  const formValues = state.form.baseModuleItemForm?.values || {};
  const autoSeoValues = formValues?.autoSeoValues || {};

  const title = formValues?.autoSeoTitle ? autoSeoValues.title : formValues.seoTitle;
  const description = formValues?.autoSeoDescription ? autoSeoValues.description : formValues.seoDescription;
  const url = formValues?.autoSeoUrl ? autoSeoValues.url : `${routePrefix}/${formValues.seoUrl}`;

  return {
    title: title || '',
    description: description || '',
    url: url || '',
  };
};

export default connect(mapStateToProps)(BaseModuleItemGooglePreview);
