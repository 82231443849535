import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Icon from '../Icon/Icon';
import { setSearchQuery } from '../../actions/users';

const UserSearchBar = ({ t, value, onChange }) => {
  const handleChange = (event) => {
    onChange(event.currentTarget.value);
  };

  return (
    <div className="c-search-bar">
      <input
        placeholder={t('CONTENT.search')}
        value={value}
        onChange={handleChange}
        className="c-search-bar__input"
        type="search"
      />
      <Icon name="search" />
    </div>
  );
};

UserSearchBar.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  value: state.users.searchQuery,
});

const mapDispatchToProps = {
  onChange: setSearchQuery,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(UserSearchBar));
