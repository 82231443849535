import React from 'react';
import PropTypes from 'prop-types';
import ReactSkeleton from 'react-skeleton-loader';

const Skeleton = props => (
  <ReactSkeleton
    {...props}
  />
);

Skeleton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  widthRandomness: PropTypes.number,
  heightRandomness: PropTypes.number,
  borderRadius: PropTypes.string,
  color: PropTypes.string,
  count: PropTypes.number,
  animated: PropTypes.bool,
};

Skeleton.defaultProps = {
  width: '100%',
  height: '20px',
  widthRandomness: 0,
  heightRandomness: 0,
  borderRadius: '4px',
  color: '#eeeeee',
  count: 1,
  animated: true,
};

export default Skeleton;
