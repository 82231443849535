import { connect } from 'react-redux';
import BrowsboxMenuList from './BrowsboxMenuList';
import { setActiveMenuItem, loadConfigurationMenuItems } from '../../actions/menu';

const mapStateToProps = (state) => {
  const {
    menuItems,
    activeMenuItem,
  } = state;
  return {
    extraConfigItems: menuItems.configItems,
    activeMenuItem,
  };
};

const mapDispatchToProps = ({
  setActiveMenuItem,
  loadConfigurationMenuItems,
});

const BrowsboxMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrowsboxMenuList);

export default BrowsboxMenuContainer;
