import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaListViewItem from './MediaListViewItem';
import UploadedTableItem from '../UploadQueue/UploadedTableItem';
import AbstractView from './AbstractView';
import MediaListViewToolbar from './MediaListViewToolbar';

const propTypes = {
  items: PropTypes.array.isRequired,
  listRef: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  openMediaUpload: PropTypes.func,
  isSelected: PropTypes.func.isRequired,
};

const defaultProps = {
  multiple: true,
  selected: false,
  openMediaUpload: undefined,
  listRef: undefined,
};

class MediaListView extends Component {
  renderItem = image => (image.isUploading
    ? this.renderUploadingPlaceholder(image)
    : this.renderImage(image));

  renderUploadingPlaceholder = ({ uniqueId, file, progress }) => (
    <UploadedTableItem
      key={uniqueId}
      filename={file.name}
      progress={progress}
    />
  );

  renderImage = (image) => {
    const {
      onSelect,
      onUnselect,
      onDoubleClick,
      isSelected,
    } = this.props;

    return (
      <MediaListViewItem
        key={image.id}
        onSelect={onSelect}
        onUnSelect={onUnselect}
        onDoubleClick={onDoubleClick}
        isSelected={isSelected(image.id)}
        {...image}
      />
    );
  };

  render() {
    const {
      items,
      listRef,
      openMediaUpload,
    } = this.props;

    return (
      <div ref={listRef} className="c-bb-media__table-view">
        {openMediaUpload && (
          <MediaListViewToolbar onCreateClick={openMediaUpload} />
        )}
        {items.length > 0 && (
          <table className="c-bb-media__table">
            <tbody>
              { items.map(this.renderItem) }
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

MediaListView.propTypes = propTypes;
MediaListView.defaultProps = defaultProps;

export default props => (
  <AbstractView
    {...props}
    component={MediaListView}
  />
);
