import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

export const FolderContext = React.createContext({
  id: null,
  setId: () => {},
});

export const useFolderContext = () => useContext(FolderContext);

export const FolderContextProvider = ({ children, onClick }) => {
  const [id, setId] = useState(null);

  const value = useMemo(() => ({
    id, setId, onClick,
  }), [id, setId, onClick]);

  return (
    <FolderContext.Provider value={value}>
      {children}
    </FolderContext.Provider>
  );
};

FolderContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};
