import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { withNamespaces } from 'react-i18next';
import { renderError } from '../../tools/validation';

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  defaultCountry: PropTypes.string,
  availableCountries: PropTypes.arrayOf(PropTypes.string),
  favoriteCountries: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  label: null,
  defaultCountry: '',
  availableCountries: null,
  favoriteCountries: null,
};

const PhoneField = ({
  input: { onChange, value },
  meta: { touched, error },
  label,
  defaultCountry,
  availableCountries,
  favoriteCountries,
}) => (
  <div className="c-bb-form__input c-bb-form__phone">
    {label && <label htmlFor="title">{label}</label>}
    <PhoneInput
      value={value}
      onChange={newValue => onChange(newValue)}
      country={defaultCountry}
      countries={availableCountries}
      countryOptionsOrder={favoriteCountries}
    />
    {touched && error
    && <span className="c-bb-form__input-error">{renderError(error)}</span>}
  </div>
);

PhoneField.propTypes = propTypes;
PhoneField.defaultProps = defaultProps;

export default withNamespaces()(PhoneField);
