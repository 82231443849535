/* eslint-disable */
// Disable eslint because each 'require' would need a 'eslint-disable-next-line global-require'
if (process.env.NODE_ENV === 'production') {
  require('./style/overrule-browsbox.css');
}
require('./style/_browsbox.scss');
require('./style/_base.scss');
require('@browsbox-ui/bb-pane-group/pane-group.scss');
require('@browsbox-ui/bb-nav/nav.scss');
require('@browsbox-ui/bb-nav-item/nav-item.scss');
require('@browsbox-ui/bb-popup/popup.scss');
require('@browsbox-ui/bb-button/button.scss');
require('@browsbox-ui/bb-icon-button/button.scss');
require('@browsbox-ui/bb-menu/style.scss');
require('@browsbox-ui/bb-react-sortable-tree-theme/react-sortable-tree-theme.scss');
require('@browsbox-ui/bb-table/table.scss');
require('@browsbox-ui/bb-pagination/pagination.scss');
require('@browsbox-ui/bb-icon-button/button.scss');
