import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onFocus: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
};

const defaultProp = {
  children: null,
  className: '',
};

class SelectTypeOptions extends Component {
  constructor(props) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.props.option.disabled) {
      this.props.onSelect(this.props.option, event);
    }
  }

  handleMouseEnter(event) {
    this.props.onFocus(this.props.option, event);
  }

  handleMouseMove(event) {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  }

  render() {
    return (
      <div
        className={this.props.className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={this.props.option.title}
      >
        <i className={this.props.isSelected ? 'fa fa-dot-circle-o' : 'fa fa-circle-o'} style={{ marginRight: '10px' }} />
        {this.props.children}
      </div>
    );
  }
}

SelectTypeOptions.propTypes = propTypes;
SelectTypeOptions.defaultProps = defaultProp;

export default withNamespaces()(SelectTypeOptions);
