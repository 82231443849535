/* eslint-disable */

if (window.tinymce) {
  window.tinyMCE.PluginManager.add('preventEnterKey', function(editor) {
    editor.on('keydown', function(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    });
  });
}
