import { CALL_BB_API } from '../api';
import { ROUTE_LANGUAGES, ROUTE_BROWSBOX_LANGUAGES } from '../api/routes';

export const LANGUAGES_FETCH_REQUEST = 'LANGUAGES_FETCH_REQUEST';
export const LANGUAGES_FETCH_SUCCESS = 'LANGUAGES_FETCH_SUCCESS';
export const LANGUAGES_FETCH_FAILURE = 'LANGUAGES_FETCH_FAILURE';

const fetchLanguages = () => ({
  [CALL_BB_API]: {
    types: [LANGUAGES_FETCH_REQUEST, LANGUAGES_FETCH_SUCCESS, LANGUAGES_FETCH_FAILURE],
    endpoint: ROUTE_LANGUAGES,
    method: 'GET',
  },
});

export const loadLanguages = () => dispatch => dispatch(fetchLanguages());

export const BROWSBOX_LANGUAGES_FETCH_REQUEST = 'BROWSBOX_LANGUAGES_FETCH_REQUEST';
export const BROWSBOX_LANGUAGES_FETCH_SUCCESS = 'BROWSBOX_LANGUAGES_FETCH_SUCCESS';
export const BROWSBOX_LANGUAGES_FETCH_FAILURE = 'BROWSBOX_LANGUAGES_FETCH_FAILURE';

const fetchBrowsboxLanguages = () => ({
  [CALL_BB_API]: {
    types: [BROWSBOX_LANGUAGES_FETCH_REQUEST, BROWSBOX_LANGUAGES_FETCH_SUCCESS, BROWSBOX_LANGUAGES_FETCH_FAILURE],
    endpoint: ROUTE_BROWSBOX_LANGUAGES,
    method: 'GET',
  },
});

export const loadBrowsboxLanguages = () => dispatch => dispatch(fetchBrowsboxLanguages());
