export const isButtonEditor = (editor) => {
  const el = editor.bodyElement;

  return el.classList.contains('c-button');
};

export const getBookMarkOfEditor = (editor) => {
  // bookmark is object like: { id: 'mce_X' }
  const bookMark = editor.selection.getBookmark();
  const startNode = editor.dom.get(`${bookMark.id}_start`);
  const endNode = editor.dom.get(`${bookMark.id}_end`);
  const editorId = editor.bodyElement.id;

  // we need to prefix id of bookmark with ID of this editor instance
  // to prevent focus lost and invalid bookmark restoration when there is more
  // than one editor on the page, now it becomes: react-timymce-X_mce_X
  [startNode, endNode].forEach((node) => {
    // eslint-disable-next-line no-param-reassign
    node.id = `${editorId}_${node.id}`;
  });
  bookMark.id = `${editorId}_${bookMark.id}`;

  return bookMark;
};

export const getAnchorElement = (editor) => {
  const el = editor.bodyElement;

  if (isButtonEditor(editor)) {
    return editor.dom.select('a', el)[0];
  }

  const node = editor.selection.getNode();
  if (node.tagName === 'A') {
    return node;
  }

  return editor.dom.getParent(node, 'a[href]');
};

export const getHref = (elm) => {
  const href = elm.getAttribute('data-mce-href');
  return href || elm.getAttribute('href');
};

export const isOnlyTextSelected = html => !(/</.test(html) && (!/^<a [^>]+>[^<]+<\/a>$/.test(html) || html.indexOf('href=') === -1));

export default getAnchorElement;
