import React from 'react';
import PropTypes from 'prop-types';
import { contentPropType } from './ContentPropTypes';
import BrowsboxContentItem from './ContentItem';

function renderItem(content) {
  return (
    <BrowsboxContentItem
      id={content.id}
      key={content.id}
      content={content}
    />
  );
}

function BrowsboxContentList(props) {
  const { content } = props;
  if (content.length < 2) {
    return renderItem(content[0]);
  }

  return (
    <div>
      { content.map(renderItem) }
    </div>
  );
}

BrowsboxContentList.propTypes = {
  content: PropTypes.arrayOf(contentPropType).isRequired,
};

export default BrowsboxContentList;
