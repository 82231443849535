/* eslint-disable react/prop-types,react/static-property-placement,react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { decodeCropperData, encodeCropperData } from './utils';
import { cropMedia, getMedia } from '../../../../actions/media';
import { setComponentOption } from '../../../../actions/contentConfig';
import { getComponentById } from '../../../../utils/entities';
import { setUiOption } from '../../../../actions/ui';

export const withCropperPropTypes = {
  isCroppingSupported: PropTypes.bool.isRequired,
  isCropping: PropTypes.bool.isRequired,
  isCropPending: PropTypes.bool.isRequired,

  enableImageCropping: PropTypes.func.isRequired,
  disableImageCropping: PropTypes.func.isRequired,
  handleCropChange: PropTypes.func.isRequired,
  handleCropImage: PropTypes.func.isRequired,
  handleRemoveImage: PropTypes.func.isRequired,
  enableCustomSizing: PropTypes.func.isRequired,
  disableCustomSizing: PropTypes.func.isRequired,

  cropperData: PropTypes.object,
  component: PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string,
    cropperDataRaw: PropTypes.string,
    mediaId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const component = getComponentById(state.entities.content, id);

  return {
    component,
  };
};

const mapDispatchToProps = {
  cropMedia,
  getMedia,
  setComponentOption,
  setUiOption,
};

const getMediaIdFromUrl = (url) => {
  if (!url) {
    return null;
  }

  const urlArray = url.split('/');

  if (typeof urlArray[4] !== 'undefined' && !Number.isNaN(urlArray[4])) {
    return Number(urlArray[4]);
  }

  return null;
};

const withCropper = WrappedComponent => connect(
  mapStateToProps,
  mapDispatchToProps,
)(class WithCropper extends React.Component {
  static displayName = `WithCropper(${WrappedComponent.displayName || WrappedComponent.name})`;

  static propTypes = {
    url: PropTypes.string,
    component: PropTypes.shape({
      cropperDataRaw: PropTypes.string,
    }),
    cropMedia: PropTypes.func.isRequired,
    setUiOption: PropTypes.func.isRequired,
    setComponentOption: PropTypes.func.isRequired,
  };

  static defaultProps = {
    url: null,
    component: {},
  };

  constructor(props) {
    super(props);
    const { cropperDataRaw = null } = props.component;
    const cropperData = decodeCropperData(cropperDataRaw);

    this.state = {
      cropperData,
      thumbCropperData: cropperData,
      isCropping: false,
      isCropPending: false,
      isCroppingSupported: true,
    };
  }

  componentDidMount() {
    const { url, component } = this.props;

    if (url) {
      this.checkFileType(url);
    }

    if (component && component.mediaId) {
      this.props.getMedia({ id: component.mediaId });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.url && prevProps.url !== this.props.url) {
      this.checkFileType(this.props.url);
    }

    if (this.props.component && (
      this.props.component.mediaId !== prevProps.component.mediaId
      || (this.props.url !== prevProps.url && (this.props.url && this.props.url.includes('/original/')))
    )) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        cropperData: null,
        thumbCropperData: null,
      });

      this.disableCustomSizing();
      this.clearCropperRawData();
    }

    if (this.props.component && !this.props.component.mediaId) {
      const mediaId = getMediaIdFromUrl(this.props.component.url);

      if (mediaId) {
        this.props.setComponentOption({
          option: 'mediaId',
          contentId: this.props.id,
          value: mediaId,
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.fetchController !== undefined) {
      this.fetchController.abort();
    }
  }

  enableImageCropping = () => {
    this.setState({
      isCropping: true,
    });

    this.props.setUiOption({
      isCropping: true,
    });
  };

  disableImageCropping = () => {
    this.setState(state => ({
      isCropping: false,
      isCropPending: false,
      cropperData: state.thumbCropperData,
    }));

    this.props.setUiOption({
      isCropping: false,
    });
  };

  handleCropChange = (cropperData) => {
    this.setState({ cropperData });
  };

  handleCropImage = async ({ cropperData, options }) => {
    this.setState({
      cropperData,
      thumbCropperData: cropperData,
      isCropPending: true,
    });

    const { width, height, hasTransparentArea } = options;
    const { response } = await this.props.cropMedia({
      id: this.props.component.mediaId,
      options: {
        width,
        height,
        hasTransparentArea,
      },
      settings: {
        ...cropperData.data,
      },
    });

    if (response && response.cropperImage) {
      this.props.setComponentOption({
        option: 'url',
        contentId: this.props.id,
        value: response.cropperImage,
      });
      this.props.setComponentOption({
        option: 'cropperDataRaw',
        contentId: this.props.id,
        value: encodeCropperData(cropperData),
      });
      this.disableImageCropping();
      this.enableCustomSizing();
    }
  };

  handleRemoveImage = () => {
    this.setState({
      cropperData: null,
      thumbCropperData: null,
    });
  };

  enableCustomSizing = () => {
    this.props.setComponentOption({
      option: 'hasCustomSize',
      contentId: this.props.id,
      value: true,
    });
  };

  disableCustomSizing = () => {
    this.props.setComponentOption({
      option: 'hasCustomSize',
      contentId: this.props.id,
      value: false,
    });
  };

  clearCropperRawData = () => {
    this.props.setComponentOption({
      option: 'cropperDataRaw',
      contentId: this.props.id,
      value: null,
    });
  };

  checkFileType(url) {
    if (this.fetchController !== undefined) {
      this.fetchController.abort();
    }

    if ('AbortController' in window) {
      this.fetchController = new window.AbortController();
      this.fetchSignal = this.fetchController.signal;
    }

    fetch(url, { signal: this.fetchSignal })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }

        return Promise.reject(response);
      })
      .then((blob) => {
        const { type } = blob;
        const disabledTypes = ['svg', 'gif'];
        const supported = !disabledTypes.some(imageType => type.includes(imageType));

        this.setState({
          isCroppingSupported: supported,
        });
      })
      .catch(() => {
        this.setState({
          isCroppingSupported: true,
        });
      });
  }

  render() {
    const {
      isCroppingSupported,
      isCropping,
      isCropPending,
      cropperData,
    } = this.state;

    return (
      <WrappedComponent
        {...this.props}
        isCroppingSupported={isCroppingSupported}
        isCropping={isCropping}
        isCropPending={isCropPending}
        cropperData={cropperData}
        enableImageCropping={this.enableImageCropping}
        disableImageCropping={this.disableImageCropping}
        enableCustomSizing={this.enableCustomSizing}
        disableCustomSizing={this.disableCustomSizing}
        handleCropChange={this.handleCropChange}
        handleCropImage={this.handleCropImage}
        handleRemoveImage={this.handleRemoveImage}
      />
    );
  }
});

export default withCropper;
