import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openDeleteMedia } from '../../../actions/mediaDelete';
import { openRenameMedia } from '../../../actions/mediaConfig';
import { moveMedia } from '../../../actions/media';
import download from '../../../tools/download';
import FolderPickerModal from '../FolderPicker/FolderPickerModal';

const propTypes = {
  id: PropTypes.number.isRequired,
  thumbnail: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  fileExtension: PropTypes.string.isRequired,
  updatedOn: PropTypes.string,
  resolution: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onUnSelect: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func,
  isSelected: PropTypes.bool,
  original: PropTypes.string,
  openDeleteMedia: PropTypes.func.isRequired,
  openRenameMedia: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  move: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
};

const defaultProps = {
  resolution: '',
  updatedOn: '',
  isSelected: false,
  original: '',
  onDoubleClick: () => {},
};

let clickTimeout = null;

const AbstractItemView = (props) => {
  const [moving, setMoving] = useState(false);
  const {
    fileExtension,
    filename,
    original,
    id,
    disabled,
  } = props;

  const onSelect = () => {
    if (disabled) {
      return null;
    }

    return props.isSelected ? props.onUnSelect(id) : props.onSelect(id);
  };

  const onRename = (evt) => {
    evt.preventDefault();
    props.openRenameMedia({ id, filename, fileExtension });
  };

  const onDelete = (evt) => {
    evt.preventDefault();
    props.openDeleteMedia({ id, filename, fileExtension });
  };

  const onDoubleClick = () => {
    if (!disabled) {
      props.onDoubleClick(id);
    }
  };

  const onView = () => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = original;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const onDownload = async () => {
    const response = await fetch(original);
    const blob = await response.blob();

    const name = filename + fileExtension;
    download(blob, name);
  };

  const onClick = () => {
    if (clickTimeout !== null) {
      clearTimeout(clickTimeout);
      clickTimeout = null;
      onDoubleClick();
    } else {
      onSelect();
      clickTimeout = setTimeout(() => {
        clearTimeout(clickTimeout);
        clickTimeout = null;
      }, 200);
    }
  };

  const onMove = () => {
    setMoving(true);
  };

  const handleMediaMove = (dist) => {
    if (dist === 'root') {
      props.move({ id, parent: null });
      return;
    }

    props.move({ id, parent: dist.id });
  };

  const {
    component: Component,
    ...rest
  } = props;

  const extraProps = {
    onRename,
    onDelete,
    onClick,
    onDownload,
    onView,
    onMove,
  };

  return (
    <>
      <Component {...rest} {...extraProps} disabled={disabled} />
      {moving && (
        <FolderPickerModal
          onSelect={handleMediaMove}
          onClose={() => setMoving(false)}
        />
      )}
    </>
  );
};

AbstractItemView.propTypes = propTypes;
AbstractItemView.defaultProps = defaultProps;

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  openDeleteMedia,
  openRenameMedia,
  move: moveMedia,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AbstractItemView);
