import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FolderBreadcrumbs from './Folders/FolderBreadrumbs';
import ViewModeToggle from './ViewModeToggle';
import { mediaLibrarySetViewMode } from '../../actions/media';
import MediaTypeFilter from './MediaTypeFilter';
import Icon from '../Icon/Icon';

const MediaManagerToolbar = (props) => {
  const {
    viewMode,
    setViewMode,
    enableTypeFilter,
    onSearchClick,
  } = props;

  return (
    <div className="c-bb-media-manager__toolbar">
      <div className="c-bb-media-manager__toolbar__breadcrumbs">
        <FolderBreadcrumbs />
      </div>
      <div style={{ flexGrow: 1 }} />
      <button type="button" className="c-bb-search-form__toggle" onClick={onSearchClick}>
        <Icon name="search" />
      </button>
      <ViewModeToggle value={viewMode} onChange={setViewMode} />
      {enableTypeFilter && (
        <MediaTypeFilter />
      )}
    </div>
  );
};

MediaManagerToolbar.propTypes = {
  viewMode: PropTypes.string.isRequired,
  setViewMode: PropTypes.func.isRequired,
  enableTypeFilter: PropTypes.bool,
  onSearchClick: PropTypes.func.isRequired,
};

MediaManagerToolbar.defaultProps = {
  enableTypeFilter: false,
};

const mapStateToProps = state => ({
  viewMode: state.media.viewMode,
});

const mapDispatchToProps = {
  setViewMode: mediaLibrarySetViewMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaManagerToolbar);
