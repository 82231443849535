import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContentComponentImage from './ContentComponentImage';
import CONTENT_COMPONENT_TYPE from './types';

const containerStyles = {
  float: 'left',
  overflow: 'hidden',
  position: 'relative',
  display: 'block',
  height: 'auto',
};

class ContentComponentGalleryImage extends Component {
  static propTypes = {
    id: PropTypes.string,
    alt: PropTypes.string,
    classes: PropTypes.string,
    containerClassName: PropTypes.string,
    tooltip: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    hyperlink: PropTypes.string,
  };

  static defaultProps = {
    id: '',
    alt: '',
    classes: '',
    containerClassName: '',
    tooltip: '',
    type: CONTENT_COMPONENT_TYPE.galleryImage,
    url: '',
    hyperlink: '',
  };

  renderImage() {
    const {
      id,
      alt,
      containerClassName,
      tooltip,
      type,
      url,
      hyperlink,
    } = this.props;
    const key = `${id}-image`;
    const component = {
      url, type, tooltip, alt, hyperlink,
    };

    return (
      <ContentComponentImage
        key={key}
        containerClassName={containerClassName}
        {...component}
      />
    );
  }

  render() {
    const {
      classes,
    } = this.props;
    const type = CONTENT_COMPONENT_TYPE.galleryImage;
    const componentClasses = classNames(
      classes,
      `c-${type}`,
    );

    return (
      <div
        className={componentClasses}
        style={{ ...containerStyles }}
      >
        {this.renderImage()}
      </div>
    );
  }
}

export default ContentComponentGalleryImage;
