/* eslint-disable global-require */
import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/nl';
import 'moment/locale/de';
import 'moment/locale/pl';
import { reactI18nextModule } from 'react-i18next';
import translations from './translations/index';
import { loadBrowsboxLanguages } from '../actions/languages';
import { currentBrowsboxLanguageKey } from './constants';

const fallBackLanguage = 'nl';
const currentBrowsboxLanguage = typeof localStorage !== 'undefined' && localStorage.getItem(currentBrowsboxLanguageKey);

// retrieve the userLanguage from global set variable from the backend
const userLanguage = (typeof window !== 'undefined' && typeof window.userLanguage !== 'undefined' && window.userLanguage
  ? window.userLanguage
  : fallBackLanguage
);

i18n.on('languageChanged', (language) => {
  moment.locale(language);
});

/**
 * This is pure for initialising the i18n so everything within React can be rendered with the `i18n.t()` function
 * because the Browsbox languages are retrieved via an API call (-> delay).
 */
const defaultInternationalization = () => {
  // check if a language was already been set -> use this as the default init language
  // otherwise use the fallback language
  const defaultInitLanguage = currentBrowsboxLanguage || fallBackLanguage;
  const resources = {
    [defaultInitLanguage]: {
      translation: translations[defaultInitLanguage],
    },
  };

  i18n
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
      resources,
      lng: defaultInitLanguage,
      fallbackLng: defaultInitLanguage,
      interpolation: {
        escapeValue: false,
      },
    });
};

defaultInternationalization();

/**
 * This is the initialisation when all possible languages are retrieved via the API call.
 *
 * @param {array} languages
 */
const initInternationalizationLanguages = (languages) => {
  const resources = {};
  let languageCode;
  let defaultLanguage = userLanguage;

  languages.map((language) => {
    languageCode = language.code;

    if (typeof translations[languageCode] !== 'undefined') {
      resources[languageCode] = {
        translation: translations[languageCode],
      };
    }

    if (language.default) {
      defaultLanguage = languageCode;
    }

    return false;
  });

  if (Object.keys(resources).length !== 0) {
    i18n
      .use(reactI18nextModule) // passes i18n down to react-i18next
      .init({
        resources,
        lng: defaultLanguage,
        fallbackLng: fallBackLanguage,
        interpolation: {
          escapeValue: false,
        },
      });

    // check if the Browsbox language was previously set (on previous page or on the login screen) via localStorage
    if (currentBrowsboxLanguage && fallBackLanguage !== currentBrowsboxLanguage) {
      i18n.changeLanguage(currentBrowsboxLanguage);
    } else
    // check if the default language is different from the fallback language, if so, reset the current language
    if (fallBackLanguage !== defaultLanguage) {
      i18n.changeLanguage(defaultLanguage);
    }
  }
};

export const initInternationalization = (store) => {
  let browsboxLanguages = [];

  store.dispatch(loadBrowsboxLanguages());

  store.subscribe(() => {
    const state = store.getState();
    const stateBrowsboxLanguages = state.global.browsboxLanguages;

    if (browsboxLanguages !== stateBrowsboxLanguages) {
      browsboxLanguages = stateBrowsboxLanguages;
      initInternationalizationLanguages(browsboxLanguages);
    }
  });
};

export { currentBrowsboxLanguage };

export default i18n;
