import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CONTENT_COMPONENT_TYPE from './types';
import LinkedImage from './LinkedImage';
import { IMAGE_PLACEHOLDER_URL, LIGHTBOX_CLASSNAME } from '../utils/mediaDefaults';
import { getLargeImageUrl } from '../utils/media';
import ContentComponentImageContainer from './ContentComponentImageContainer';

const propTypes = {
  alt: PropTypes.string,
  classes: PropTypes.string,
  containerClassName: PropTypes.string,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  hyperlink: PropTypes.string,
  imageType: PropTypes.string,
  hasCustomSize: PropTypes.bool,
  lightbox: PropTypes.bool, // add lightbox url if hyperlink is empty
};

const defaultProps = {
  alt: '',
  classes: '',
  containerClassName: '',
  tooltip: '',
  type: CONTENT_COMPONENT_TYPE.image,
  url: '',
  hyperlink: '',
  lightbox: true,
  imageWidth: null,
  imageHeight: null,
  imageType: null,
  hasCustomSize: false,
};

const isVectorImage = (url) => {
  if (!url) {
    return false;
  }

  const vectors = ['svg'];

  return vectors.some(extension => url.endsWith(extension));
};

const ContentComponentImage = (props) => {
  const {
    alt,
    classes,
    containerClassName,
    tooltip,
    type,
    imageType,
    url,
    hyperlink,
    lightbox,
    imageWidth,
    imageHeight,
    hasCustomSize,
  } = props;

  const componentClasses = classNames(
    classes,
    containerClassName,
    'c-image',
    `c-image-${type}`,
  );
  const lightboxHyperlink = !hyperlink && lightbox ? getLargeImageUrl(url) : '';
  const usePlaceholderUrl = !lightboxHyperlink && lightbox ? IMAGE_PLACEHOLDER_URL : null;

  return (
    <div className={componentClasses}>
      <ContentComponentImageContainer
        type={type}
        imageType={imageType}
        width={imageWidth}
        height={imageHeight}
      >
        <LinkedImage
          hyperlink={hyperlink || lightboxHyperlink || usePlaceholderUrl}
          className={classNames({
            [LIGHTBOX_CLASSNAME]: lightboxHyperlink || usePlaceholderUrl,
            'c-image__link--has-custom-size': hasCustomSize,
          })}
        >
          <img
            className={classNames(
              `c-${type}__element`,
              { [`c-${type}__element--has-custom-size`]: hasCustomSize },
              { 'c-image__element--vector': isVectorImage(url) },
            )}
            src={url || IMAGE_PLACEHOLDER_URL}
            alt={alt}
            title={tooltip}
          />
        </LinkedImage>
      </ContentComponentImageContainer>
    </div>
  );
};

ContentComponentImage.propTypes = propTypes;
ContentComponentImage.defaultProps = defaultProps;

export default ContentComponentImage;
