import { actionTypes, change } from 'redux-form';
import {
  put, select, takeLatest, takeEvery,
} from 'redux-saga/effects';
import { changeBaseModuleLang } from '../../actions/baseModule';
import { normalizeSlug } from '../../tools/url';

/**
 * Watches Base Module form submission and changes the lang if errors
 * appear in the different one
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* handleBaseModuleFormSubmissionError(action) {
  const formName = action.meta.form;
  if (formName !== 'baseModuleForm') {
    return;
  }

  const errors = yield select(state => state.form[formName].syncErrors || {});
  const currentLang = yield select(state => state.baseModule.lang);
  const invalidLanguages = errors.languages;

  if (invalidLanguages.length && !invalidLanguages.includes(currentLang)) {
    yield put(changeBaseModuleLang(invalidLanguages[0]));
  }
}

function* handleBaseModuleFormAutoUrlField(action) {
  const { form, field } = action.meta;
  if (form !== 'baseModuleItemForm') {
    return;
  }

  if (field !== 'title') {
    return;
  }

  const autoUrlEnabled = yield select(state => state.form.baseModuleItemForm.values.autoUrl);

  if (autoUrlEnabled) {
    const currentItem = yield select(state => state.baseModule.currentModule);
    const prefix = currentItem.route || '';
    const slug = normalizeSlug(action.payload);
    const url = [prefix, slug].join('/');
    yield put(change(form, 'url', url));
  }
}

export default function* () {
  yield takeLatest(actionTypes.SET_SUBMIT_FAILED, handleBaseModuleFormSubmissionError);
  yield takeEvery(actionTypes.CHANGE, handleBaseModuleFormAutoUrlField);
}
