import { CALL_BB_API } from '../api';
import { ROUTE_PAGE_NEW } from '../api/routes';

export const PAGE_NEW_REQUEST = 'PAGE_NEW_REQUEST';
export const PAGE_NEW_SUCCESS = 'PAGE_NEW_SUCCESS';
export const PAGE_NEW_FAILURE = 'PAGE_NEW_FAILURE';

// BrowsBox API middleware.
const postNewPage = value => ({
  [CALL_BB_API]: {
    types: [PAGE_NEW_REQUEST, PAGE_NEW_SUCCESS, PAGE_NEW_FAILURE],
    endpoint: ROUTE_PAGE_NEW,
    method: 'POST',
    json: value,
  },
});

// Redux Thunk middleware.
export const newPage = value => dispatch => dispatch(
  postNewPage(value),
);

export const OPEN_NEW_PAGE = 'OPEN_NEW_PAGE';
export const CLOSE_NEW_PAGE = 'CLOSE_NEW_PAGE';

// Redux Thunk middleware.
export const openNewPage = id => ({ type: OPEN_NEW_PAGE, id });
export const closeNewPage = () => ({ type: CLOSE_NEW_PAGE });
