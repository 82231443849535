import { CALL_BB_API, buildRoute } from '../api';
import { ROUTE_MEDIA_RENAME } from '../api/routes';

export const SET_MEDIA_OPTION = 'SET_MEDIA_OPTION';
export const setMediaOption = value => ({ type: SET_MEDIA_OPTION, value });
export const CLEAR_MEDIA_OPTION = 'CLEAR_MEDIA_OPTION';
export const clearMediaOption = value => ({ type: CLEAR_MEDIA_OPTION, value });

export const OPEN_RENAME_MEDIA = 'OPEN_RENAME_MEDIA';
export const CLOSE_RENAME_MEDIA = 'CLOSE_RENAME_MEDIA';
export const openRenameMedia = value => ({ type: OPEN_RENAME_MEDIA, value });
export const closeRenameMedia = () => ({ type: CLOSE_RENAME_MEDIA });

export const RENAME_MEDIA_REQUEST = 'RENAME_MEDIA_REQUEST';
export const RENAME_MEDIA_SUCCESS = 'RENAME_MEDIA_SUCCESS';
export const RENAME_MEDIA_FAILURE = 'RENAME_MEDIA_FAILURE';

// BrowsBox API middleware.
const renameMediaCall = (value) => {
  const { id, value: newFilename } = value;
  const endpoint = buildRoute(ROUTE_MEDIA_RENAME, { id });
  return {
    [CALL_BB_API]: {
      types: [RENAME_MEDIA_REQUEST, RENAME_MEDIA_SUCCESS, RENAME_MEDIA_FAILURE],
      endpoint,
      method: 'PATCH',
      json: {
        newFilename,
        // folderId: 0,
      },
    },
  };
};

// Redux Thunk middleware.
export const renameMedia = value => dispatch => dispatch(renameMediaCall(value));
