/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormInput, FormCheckbox, FormTextArea } from '../FormElements';
import ModalFooter from '../Modals/ModalFooter';
import { BbButton } from '@browsbox-ui';
import i18n from '../../internationalization/i18n';
import Modal from "../Modals/Modal";
import GooglePreview from "../FormElements/GooglePreview";

const propTypes = {
  value: PropTypes.shape({
    description: PropTypes.string.isRequired,
    keywords: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pagetitle: PropTypes.string.isRequired,
    published: PropTypes.bool.isRequired,
    publishable: PropTypes.bool,
    title: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    valueLength: PropTypes.string,
    maxLength: PropTypes.string,
    icon: PropTypes.string,
    labelInfo: PropTypes.object,
  }).isRequired,
  onChange: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  messages: PropTypes.array,
  submitButtonText: PropTypes.string,
};

const defaultProps = {
  onChange: () => {},
  messages: [],
  submitButtonText: null,
};

const fields = [
  {
    tag: FormInput,
    id: 'page-edit-form-route',
    name: 'name',
    type: 'text',
    labelTranslationKey: 'CONTENT.routeName',
    required: true,
    maxLength: 35,
  },
  {
    tag: FormInput,
    id: 'page-edit-form-title',
    name: 'pagetitle',
    type: 'text',
    labelTranslationKey: 'CONTENT.pageTitle',
    required: true,
    maxLength: 70
  },
  {
    tag: FormTextArea,
    id: 'page-edit-form-description',
    name: 'description',
    type: 'textarea',
    labelTranslationKey: 'CONTENT.pageDescription',
    maxLength: 155,
    rows: 3,
  },
  {
    tag: FormCheckbox,
    id: 'page-edit-form-published',
    name: 'published',
    type: 'checkbox',
    labelTranslationKey: 'CONTENT.publish',
    disabled: (value = {}) => !value.publishable
  },
  {
    tag: FormCheckbox,
    id: 'page-edit-form-visible',
    name: 'visible',
    type: 'checkbox',
    labelTranslationKey: 'CONTENT.visible',
  },
];

class BrowsboxPageEditForm extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      value: props.value,
      fields: fields.map( f => Object.assign({}, f))
    };
  }

  componentDidUpdate() {
    this.props.onChange(this.state.value);
  }

  onChange(evt) {
    const target = evt.target;
    const targetId = target.id;

    let value = target.type === 'checkbox' ? target.checked : target.value.trimLeft();
    const name = target.name;
    const fields = this.state.fields.map( f => Object.assign({}, f));

    const field = fields.filter( f => f.id === targetId )[0];

    if (typeof value === 'string' && field.maxLength && value.length > field.maxLength) {
      value = value.slice(0, field.maxLength);
    }

    if (field.required) {
      field.classes = value.length ? '' : 'o-bb-form-field--error';
    }

    this.setState({
      value: {
        ...this.state.value,
        [name]: value,
      },
      fields: fields,
    });
  }

  iconClickHandler = (valueName, affectedField) => () => {
    const fields = this.state.fields.map( f => Object.assign({}, f));
    const value = Object.assign({}, this.state.value, {[affectedField]: !this.state.value[affectedField]});
    const field = fields.filter( f => f.name === valueName )[0];

    fields.forEach(f => {
      if(f.id === field.id) {
        if (value[affectedField]) {
          f.icon = 'icon-basic_eye';
          f.labelInfo.message = 'WORDT GETOOND IN NAVIGATIE';
          f.labelInfo.color = 'green';
        } else {
          f.icon = 'icon-basic_eye_closed';
          f.labelInfo.message = 'WORDT NIET GETOOND IN NAVIGATIE';
          f.labelInfo.color = 'red';
        }
      }
    });

    this.setState({ value, fields });
  };

  validateForm() {
    const fields = this.state.fields.map( f => Object.assign({}, f));

    // Only required field values are checked
    const requiredFields = fields
      .filter( field => field.required === true )
      .map( field => {
        const isValid = this.state.value[field.name].trim().length !== 0;
        field.classes = isValid ? '' : 'o-bb-form-field--error';
        return !isValid;
      });

    // update state to use updated validation class
    if (requiredFields.length > 0) {
      this.setState({ fields });
    }
    return requiredFields.filter(isInvalid => isInvalid);
  }

  onSubmit(evt) {
    evt.preventDefault();
    const invalidFields = this.validateForm();
    if (invalidFields.length > 0) {
      return false;
    }
    this.props.onSubmit(this.state.value);
  }

  onCancel(evt) {
    evt.preventDefault();
    this.props.onCancel();
  }

  onKeyDown(event) {
    if ('textarea' === event.target.tagName.toLowerCase() && 'Enter' === event.key) {
      event.preventDefault();
      return false;
    }
  }

  renderMessages() {
    const {
      messages,
    } = this.props;

    if (!messages || !messages.length) { return null; }

    const messageList = messages.map((message, idx) => {
      const key = `msg-${idx}`;
      return <div key={key}>{message.message}</div>;
    });

    return (
      <div className="o-validation">{ messageList }</div>
    );
  }

  render() {
    const {
      name,
      pagetitle,
      description,
    } = this.state.value;

    const submitButtonText = this.props.submitButtonText || i18n.t('CONTENT.save');

    return (
      <form onSubmit={this.onSubmit}>
        <div className="o-bb-modal__content">
          <div className="o-bb-form__modal-wrapper">
          {
            this.state.fields.map( field => {
              const {
                classes,
                labelTranslationKey,
                ...attributes
              } = field;

              const Tag = field.tag;
              delete attributes.tag;

              attributes.label = i18n.t(labelTranslationKey);

              attributes.onChange = this.onChange;
              attributes.onKeyDown = this.onKeyDown;
              if(attributes.icon && attributes.name === 'name') {
                attributes.iconClickHandler = this.iconClickHandler(attributes.name, 'visible');
              }

              if (field.type === 'checkbox') {
                attributes.checked= this.state.value[field.name];
              } else {
                attributes.value= this.state.value[field.name];
                attributes.valueLength= this.state.value[field.name].length;
              }

              if (typeof field.disabled === 'function') {
                attributes.disabled = field.disabled(this.props.value);
              }

              return (
                <div className={classNames(classes, {'o-bb-form-field': field.type !== 'checkbox', 'o-bb-form-field--group': field.type === 'checkbox'})} key={field.id}>
                  <Tag {...attributes} />
                </div>
              )
            })
          }
          { this.renderMessages() }
          </div>
        </div>
        <ModalFooter>
          <div className="c-modal-page-edit__google-preview">
            <GooglePreview
              route={name}
              title={pagetitle}
              description={description}
              origin={`https://www.${i18n.t('CONTENT.yourDomainName')}.be`}
            />
          </div>
          <Modal.Context>
            {({ closeModal }) => (
              <div style={{ display: 'flex', height: '60px' }}>
                <BbButton className="c-bb-button--primary" onClick={this.onSubmit} text={submitButtonText} type="submit"/>
                <BbButton className="c-bb-button--secondary" onClick={closeModal} text={i18n.t('CONTENT.cancel')} type="button"/>
              </div>
            )}
          </Modal.Context>
        </ModalFooter>
      </form>
    );
  }
}

BrowsboxPageEditForm.propTypes = propTypes;
BrowsboxPageEditForm.defaultProps = defaultProps;

export default BrowsboxPageEditForm;
