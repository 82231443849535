import React, { useState, useCallback } from 'react';
import ConfirmationModal from '../Modals/ConfirmationModal';
import ConfirmationContext from './ConfirmationContext';
import i18n from '../../internationalization/i18n';

const ConfirmationProvider = ({ children }) => {
  const [confirmation, setConfirmation] = useState(null);

  const confirm = useCallback((item) => {
    if (typeof item.action !== 'function') {
      throw new Error('Confirmation action should be a function');
    }

    if (typeof item.message !== 'string') {
      throw new Error('Confirmation message should be a string');
    }

    setConfirmation(item);
  }, [setConfirmation]);

  const handleConfirm = useCallback(() => {
    confirmation.action();
    setConfirmation(null);
  }, [confirmation, setConfirmation]);

  return (
    <ConfirmationContext.Provider value={confirm}>
      {children}
      {!!confirmation && (
        <ConfirmationModal
          icon="fal fa-exclamation-square"
          onCancel={() => setConfirmation(null)}
          onClose={() => setConfirmation(null)}
          onOk={handleConfirm}
          submitButtonText={confirmation?.buttonText || i18n.t('CONTENT.continue')}
          title={confirmation.title}
        >
          <p className="u-mb-l u-mt-l">{confirmation.message}</p>
        </ConfirmationModal>
      )}
    </ConfirmationContext.Provider>
  );
};

export const withConfirmation = (Component) => props => (
  <ConfirmationProvider>
    <Component {...props} />
  </ConfirmationProvider>
);

export default ConfirmationProvider;
