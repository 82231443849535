import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ToolbarItem = ({ children, tooltip, className }) => (
  <li className={classNames(
    'c-bb-inline-image-toolbar__list__item',
    className,
  )}
  >
    {children}
    {tooltip && (
      <span className="c-bb-inline-image-toolbar__list__item__tooltip">
        {tooltip}
      </span>
    )}
  </li>
);

ToolbarItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  tooltip: PropTypes.string,
  className: PropTypes.string,
};

ToolbarItem.defaultProps = {
  tooltip: '',
  className: '',
};

export default ToolbarItem;
