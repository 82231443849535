import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { BbPopup } from '@browsbox-ui';
import ReactModal2 from 'react-modal2';
import { QueryClient, QueryClientProvider } from 'react-query';
import { withNamespaces } from 'react-i18next';
import { GatewayDest, GatewayProvider } from '../Gateway';
import DragLayer from '../DragAndDrop/DragLayer';
import BrowsboxDndContainer from '../DragAndDrop/DndContainer';
import BrowsboxMenuContainer from '../Browsbox-menu/BrowsboxMenuContainer';
import BrowsboxContentContainer from '../Content/ContentContainer';
import PreviewContainer from '../Preview/PreviewContainer';
import MessageContainer from '../Messages/MessagesContainer';
import SessionExpiredModal from '../Modals/SessionExpiredModal';
import initSessionTimer from '../../store/sessionActivity';
import BaseModuleContainer from '../BaseModule/BaseModuleContainer';
import UsersContainer from '../Users/UsersContainer';
import DragPreview from '../MediaManager/DragPreview';
import RedirectWithWarningProvider from '../Page/RedirectWithWarningProvider';

const propTypes = {
  store: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const queryClient = new QueryClient();

const isProduction = process.env.NODE_ENV === 'production';

const RootContainer = ({ store }) => {
  React.useEffect(() => {
    if (isProduction) {
      initSessionTimer();
    }
  }, []);

  return (
    <Provider store={store}>
      <Router basename="/base-module">
        <QueryClientProvider client={queryClient}>
          <GatewayProvider>
            <RedirectWithWarningProvider>
              <div id="rootContainer">
                <BrowsboxDndContainer>
                  <BrowsboxMenuContainer />
                  <BrowsboxContentContainer />
                  <DragLayer />
                </BrowsboxDndContainer>
                <PreviewContainer />
                <MessageContainer />
                <BaseModuleContainer />
                <UsersContainer />
                {isProduction && (
                  <SessionExpiredModal />
                )}
                <BrowsboxDndContainer>
                  <GatewayDest name="source-editor" className="o-editor-container" />
                  <GatewayDest name="modal" className="o-bb-modal-container" />
                  <GatewayDest name="popup" className="o-bb-popup-container" />
                  <GatewayDest name="messages" className="o-bb-modal-messages-container" />
                  <DragPreview />
                </BrowsboxDndContainer>
              </div>
            </RedirectWithWarningProvider>
          </GatewayProvider>
        </QueryClientProvider>
      </Router>
    </Provider>
  );
};

RootContainer.propTypes = propTypes;

// for ReactModal2 accessibility
ReactModal2.getApplicationElement = () => document.getElementById('rootContainer');
// for BbPopup accessibility
BbPopup.getApplicationElement = () => document.getElementById('rootContainer');

export default withNamespaces()(RootContainer);
