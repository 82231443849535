import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const BbNav = (props) => {
  const {
    className,
    ...attributes
  } = props;

  const classes = classNames(
    className,
    'o-bb-nav',
  );

  return (
    <ul className={classes} {...attributes} />
  );
};

BbNav.propTypes = propTypes;
BbNav.defaultProps = defaultProps;

export default BbNav;
