import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BbNav, BbNavItem, BbPane, BbPaneGroup,
} from '@browsbox-ui';
import { withNamespaces } from 'react-i18next';
import ResponsivePopup from './ResponsivePopup';
import { getWebsiteSettings } from '../../actions/website';
import Spinner from '../Spinner';
import { loadModules } from '../../actions/modules';
import BrowsboxPageContainer from '../Page/PageContainer';
import BrowsboxModuleContainer from '../Browsbox-module/BrowsboxModuleContainer';
import ConfigurationContainer from '../Configuration/ConfigurationContainer';
import BrowsboxContentUpdate from '../Content/ContentUpdate';
import {
  showResponsiveView,
  DESKTOP_RESPONSIVE_VIEW,
  DEFAULT_VIEW,
} from '../../actions/responsive';
import i18n from '../../internationalization/i18n';
import { currentBrowsboxLanguageKey } from '../../internationalization';
import Icon from '../Icon/Icon';
import BaseModuleNavigation from '../BaseModule/BaseModuleNavigation';

class BrowsboxMenuList extends React.Component {
  static propTypes = {
    activeMenuItem: PropTypes.string,
    setActiveMenuItem: PropTypes.func.isRequired,
    responsiveView: PropTypes.string.isRequired,
    showResponsiveView: PropTypes.func.isRequired,
    isModified: PropTypes.bool.isRequired,
    getWebsiteSettings: PropTypes.func.isRequired,
    isSettingsPending: PropTypes.bool.isRequired,
    browsboxLanguages: PropTypes.array.isRequired,
    enabledModules: PropTypes.array.isRequired,
    browsboxVersion: PropTypes.string,
    loadModules: PropTypes.func.isRequired,
    loadConfigurationMenuItems: PropTypes.func.isRequired,
    extraConfigItems: PropTypes.array.isRequired,
  };

  static defaultProps = {
    activeMenuItem: '',
    browsboxVersion: null,
  };

  static getMenuItems() {
    return [
      { id: 'settings', name: i18n.t('SETTINGS.settings'), icon: 'preferences' },
      { id: 'base-module', name: i18n.t('SETTINGS.modules'), icon: 'fa-module' },
      {
        id: 'navigation', name: i18n.t('SETTINGS.navigation'), icon: 'navigation', modifiedWarning: true,
      },
      {
        id: 'content', name: i18n.t('SETTINGS.content'), icon: 'content', modifiedWarning: true,
      },
    ];
  }

  constructor(props) {
    super(props);
    this.setActiveMenuItem = this.setActiveMenuItem.bind(this);
    this.onResponsivePopupClose = this.onResponsivePopupClose.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.state = {
      showResponsivePopup: false,
    };
  }

  componentDidMount() {
    this.props.getWebsiteSettings();
    this.props.loadConfigurationMenuItems();
    this.props.loadModules();

    if (window && window.location.hash.startsWith('#/base-module')) {
      this.props.setActiveMenuItem('base-module');
    }
  }

  onResponsivePopupClose() {
    this.setState({ showResponsivePopup: false });
  }

  setActiveMenuItem(menuItem) {
    const { id } = menuItem;
    if (id === 'display') {
      this.setState({ showResponsivePopup: true });
    } else {
      this.setState({ showResponsivePopup: false });
      this.props.setActiveMenuItem(id);
    }
  }

  getActiveMenuItem(menuItems) {
    let { activeMenuItem } = this.props;

    if (!activeMenuItem) {
      const defaultTab = 'content';
      const [, linkItemHref = null] = window.location.hash.split('/');
      const activeTab = linkItemHref || defaultTab;
      const menuItem = menuItems.filter(m => m.id === activeTab)[0];
      activeMenuItem = menuItem ? menuItem.id : defaultTab;
    }

    return activeMenuItem;
  }

  filterMenuItem = (item) => {
    if (item.id === 'base-module') {
      return this.props.enabledModules.includes('base_module');
    }

    return true;
  };

  toggleMenu(evt) {
    evt.preventDefault();
    const { responsiveView } = this.props;
    const view = responsiveView === DEFAULT_VIEW ? DESKTOP_RESPONSIVE_VIEW : DEFAULT_VIEW;
    this.props.showResponsiveView(view);
  }

  switchLanguage(languageCode) {
    // set the language and reload current page
    localStorage.setItem(currentBrowsboxLanguageKey, languageCode);
    i18n.changeLanguage(languageCode);
    this.props.loadModules();
  }

  renderActivePanel(menuItem) {
    if (this.props.isSettingsPending) {
      return <Spinner />;
    }

    switch (menuItem.id) {
      case 'settings':
        return <ConfigurationContainer extraMenuItems={this.props.extraConfigItems} />;
      case 'base-module':
        return <BaseModuleNavigation />;
      case 'content':
        return <BrowsboxModuleContainer />;
      case 'navigation':
        return <BrowsboxPageContainer />;
      default:
        return null;
    }
  }

  render() {
    const {
      responsiveView,
      isModified,
      browsboxLanguages,
      browsboxVersion,
    } = this.props;
    const {
      showResponsivePopup,
    } = this.state;

    const paneClasses = classNames(
      'bb-panel',
      { 'bb-panel--isModified': isModified },
    );
    const showToggleButton = responsiveView === DEFAULT_VIEW
      || responsiveView === DESKTOP_RESPONSIVE_VIEW;
    const icon = responsiveView === DEFAULT_VIEW ? 'toggle' : 'retoggle';
    const menuItems = BrowsboxMenuList.getMenuItems().filter(this.filterMenuItem);
    const activeMenuItem = this.getActiveMenuItem(menuItems);

    return (
      <div className={paneClasses}>
        <BbNav className="bb-panel__header">
          {menuItems.map(menuItem => (
            <BbNavItem
              key={menuItem.id}
              href={`#/${menuItem.id}`}
              id={`sidebar-${menuItem.id}`}
              active={menuItem.id === activeMenuItem}
              onClick={() => this.setActiveMenuItem(menuItem)}
              icon={menuItem.icon}
            >{menuItem.name}
            </BbNavItem>
          ))}
        </BbNav>
        <BbPaneGroup className="bb-panel__content">
          {menuItems
            .map(menuItem => (
              <BbPane
                className={menuItem.id}
                key={menuItem.id}
                active={menuItem.id === activeMenuItem}
              >
                {menuItem.modifiedWarning
                  && <BrowsboxContentUpdate />}
                <div className="bb-panel__container">
                  { this.renderActivePanel(menuItem) }
                </div>
              </BbPane>
            ))}
        </BbPaneGroup>
        <div className="bb-panel__footer">
          <div className="bb-panel__language-switch bb-language-switch">
            <div className="bb-language-switch__active-language">
              {i18n.language}
            </div>
            <ul className="bb-language-switch__list">
              {browsboxLanguages.map(language => (
                <li className={classNames('bb-language-switch__item', { 'bb-language-switch__item--active': i18n.language === language.code })} key={language.code}>
                  <a className="bb-language-switch__link" onClick={() => { this.switchLanguage(language.code); }}>
                    {language.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {browsboxVersion && (
            <div className="bb-version">
              {browsboxVersion}
            </div>
          )}
        </div>
        { showResponsivePopup && (
          <ResponsivePopup
            target="sidebar-display"
            onClose={this.onResponsivePopupClose}
          />
        )}
        {showToggleButton && (
          <div className="bb-view-toggle-button">
            <a onClick={this.toggleMenu}>
              <Icon name={icon} />
            </a>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    content: {
      isModified,
    },
    options: {
      view: {
        responsiveView,
      },
    },
    global: {
      browsboxLanguages,
      isSettingsPending,
      websiteSettings: {
        enabledModules = [],
        browsboxVersion,
      },
    },
  } = state;

  return {
    isModified,
    responsiveView,
    isSettingsPending,
    browsboxLanguages,
    browsboxVersion,
    enabledModules,
  };
};

const mapDispatchToProps = {
  showResponsiveView,
  getWebsiteSettings,
  loadModules,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(BrowsboxMenuList));
