import { normalize } from 'normalizr';
import axios from 'axios';
import { resetComponentIds } from '../utils/entities';
import { errorMessage } from '../actions/messages';
// noinspection ES6PreferShortImport
import { currentBrowsboxLanguageKey } from '../internationalization/constants';
import { CALL_BB_API } from './contants';

axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Add the given name & value as query parameter to the GET URL.
 *
 * @param {string} endpoint
 * @param {string} name
 * @param {string} value
 *
 * @returns {string}
 */
export const addToUrlQuery = (endpoint, name, value) => {
  const separator = endpoint && endpoint.indexOf('?') !== -1 ? '&' : '?';
  return `${endpoint}${separator}${name}=${value}`;
};

/**
 * Add some extra data to the data parameters object.
 *
 * @param {object} data
 *
 * @returns {object}
 */
const addExtraData = data => ({
  ...data,
  currentUrl: encodeURIComponent(window.location.pathname + window.location.search),
  browsboxLanguage: localStorage.getItem(currentBrowsboxLanguageKey),
});

const jsonRequest = (url, method, data, params = {}) => new Promise((resolve, reject) => {
  axios({
    url,
    method,
    data,
    params,
  })
    .then((response) => {
      if (response.headers.location) {
        // There is no data in POST response, make a round trip to get data.
        axios({
          url: response.headers.location,
          method: 'GET',
        })
          .then((locationResponse) => {
            resolve({ ok: true, json: locationResponse.data });
          });
      } else {
        resolve({ ok: true, json: response.data });
      }
    })
    .catch((error) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        ok: false, json: {}, error, response: error.response,
      });
    });
});

const formRequest = (url, method, data, options = {}) => new Promise((resolve, reject) => {
  const formData = new FormData();
  Object.keys(data).forEach(k => formData.append(k, data[k]));
  axios({
    ...options,
    url,
    data: formData,
    method: 'POST',
  })
    .then((response) => {
      resolve({ ok: true, json: response.data || {} });
    })
    .catch((error) => {
      const { response } = error;
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        ok: false, json: {}, error, response,
      });
    });
});

// XHR API based 'callEndpoint'
const callEndpoint = (endpoint, schema, method, json, params, options) => {
  if (method === 'GET') {
    const defaultParams = {
      currentUrl: window.location.pathname + window.location.search,
      browsboxLanguage: localStorage.getItem(currentBrowsboxLanguageKey),
    };

    return jsonRequest(endpoint, method, json, { ...defaultParams, ...params })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response.json);
        }
        if (schema) {
          const normalized = normalize(response.json, schema);
          // normalized.result has original order by id
          // normalized.entities are sorted by id
          if (normalized.entities.pages) {
            normalized.entities.pageOrder = normalized.result;
          } else if (normalized.entities.content) {
            normalized.entities.content = resetComponentIds(normalized.entities.content[normalized.result].sections);
          }

          return {

            ...normalized,
          };
        }
        return Promise.resolve(response.json);
      });
  } if (method === 'POST:FORM') {
    const data = addExtraData(json);
    return formRequest(endpoint, 'POST', data, options)
      .then((response) => {
        if (response.ok) {
          return Promise.resolve(response.json);
        }
        return Promise.reject(response.json);
      });
  } if (method === 'PUT' || method === 'PATCH' || method === 'POST' || method === 'DELETE') {
    const data = addExtraData(json);
    return jsonRequest(endpoint, method, data, params)
      .then((response) => {
        if (response.ok) {
          return Promise.resolve(response.json);
        }
        return Promise.reject(response.json);
      });
  }

  return false;
};

const bbApi = store => next => (action) => {
  const callAPI = action[CALL_BB_API];

  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint } = callAPI;
  const {
    schema, types, method, json, params, options, meta = {},
  } = callAPI;

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify endpoint URL as string');
  }

  if (!method) {
    throw new Error('Specify a method.');
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected 3 action types.');
  }

  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  const actionData = (data) => {
    const finalAction = { ...action, ...data };
    delete finalAction[CALL_BB_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionData({ type: requestType, meta }));

  return callEndpoint(endpoint, schema, method, json, params, options)
    .then(
      response => next(actionData(
        {
          response,
          type: successType,
          endpoint,
          meta,
        },
      )),
      (error) => {
        if (options && typeof options.onError === 'function') {
          options.onError(error);
        }

        const apiErrorMessage = error.message || (error.error && error.error.message) || 'Error';
        const response = error.response || {};
        store.dispatch(errorMessage({ error }));
        return next(actionData(
          {
            type: failureType,
            error: apiErrorMessage,
            response,
          },
        ));
      },
    );
};

export default bbApi;
