/* eslint-disable object-shorthand, func-names */
import i18n from '../../../../internationalization/i18n';
import { getLinkType, LINK_TYPE_EXTERNAL } from '../../../../tools/editor';
import {
  getAnchorElement, getHref, isOnlyTextSelected, isButtonEditor, getBookMarkOfEditor,
} from '../utils';

const setHyperlinkToElement = (element, hyperlink) => {
  const linkType = getLinkType(hyperlink);

  element.setAttribute('href', hyperlink);
  element.setAttribute('data-mce-href', hyperlink);

  if (linkType === LINK_TYPE_EXTERNAL) {
    element.setAttribute('target', '_blank');
  } else {
    element.removeAttribute('target');
  }
};

// based on "webcreatorfunctions.editor.js"
const linkButton = function (editor, adminAssetPath, showLinkPickerModal, commitChange) {
  return {
    icon: 'link',
    tooltip: i18n.t('CONTENT.insertLink'),
    onclick: function () {
      const selectedContent = editor.selection.getContent();
      const anchorElm = getAnchorElement(editor);
      let bookMark = null;

      if (!anchorElm && !isOnlyTextSelected(selectedContent)) {
        return;
      }

      // keep correct selection
      if (!isButtonEditor(editor) && editor.selection.getContent()) {
        bookMark = getBookMarkOfEditor(editor);
      }

      // Opening the modal could unfocus tinymce and loose the selection. Store the selection in bookmark.
      // Called if a selection is made in the LinkPickerModal
      const cb = (link) => {
        if (link === false) {
          return;
        }

        // eslint-disable-next-line no-param-reassign
        editor.bbHiperlink = link;

        if (anchorElm) {
          setHyperlinkToElement(anchorElm, link);
          commitChange(editor);
          return;
        }

        if (bookMark) {
          editor.selection.moveToBookmark(bookMark);
        }

        // make sure SOMETHING is selected, otherwise no use in adding a hyperlink
        const content = editor.selection.getContent();
        if (content && isOnlyTextSelected(content)) {
          const elm = editor.dom.create('a');
          setHyperlinkToElement(elm, link);
          elm.innerHTML = content;
          const hyperlink = elm.outerHTML;
          // Set the hyperlink instead of the text
          editor.selection.setContent(hyperlink);
        }

        commitChange(editor);
      };

      let link = '';
      if (anchorElm) {
        link = getHref(anchorElm) || '';
      }

      showLinkPickerModal(cb, link);
    },
  };
};

export default linkButton;
