import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FoldersListView from './FoldersList';
import FolderGrid from './FolderGrid';
import { FolderContextProvider } from './FolderContext';
import { mediaLibrarySetFolderId } from '../../../actions/media';
import { selectMediaFolders } from '../../../selectors/media';

const ViewMap = {
  grid: FolderGrid,
  table: FoldersListView,
};

const FoldersContainer = (props) => {
  const {
    viewMode,
    onClick,
    folders,
  } = props;

  const Component = ViewMap[viewMode] || FolderGrid;

  return (
    <FolderContextProvider onClick={onClick}>
      <Component folders={folders} />
    </FolderContextProvider>
  );
};

FoldersContainer.propTypes = {
  viewMode: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  folders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
  viewMode: state.media.viewMode,
  folders: selectMediaFolders(state),
});

const mapDispatchToProps = {
  onClick: mediaLibrarySetFolderId,
};

export default connect(mapStateToProps, mapDispatchToProps)(FoldersContainer);
