import { all } from 'redux-saga/effects';
import baseModules from './baseModules';
import media from './media';
import pages from './pages';
import content from './content';

export default function* rootSaga() {
  yield all([
    baseModules,
    media,
    pages,
    content,
  ]);
}
