import isUrl from 'validator/lib/isURL';
import URL from 'url-parse';

const ROUTE_MEDIA_GET = '/media/get/web';

export const getYoutubeEmbedUrl = (url = '') => {
  if (!isUrl(url)) { return ''; }
  const urlSplit = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  const embedUrl = undefined !== urlSplit[2] ? urlSplit[2].split(/[^0-9a-z_-]/i)[0] : urlSplit[0];
  return embedUrl ? `https://www.youtube.com/embed/${embedUrl}` : '';
};

export const isVideoUrl = (url) => {
  if (typeof url !== 'string' || !isUrl(url)) { return false; }
  return URL(url).hostname.indexOf('youtu') !== -1;
};

export const getLargeImageUrl = (hyperlink) => {
  if (!hyperlink) {
    return false;
  }

  const url = URL(hyperlink);
  if (!url.pathname.indexOf('/media/get/') === 0) {
    return hyperlink;
  }
  const path = url.pathname.split('/');
  path[3] = 'large';
  const largeImageUrl = path.join('/');
  return URL(largeImageUrl).toString();
};

export const getWebMediaUrl = (media) => {
  const { id, filename, fileExtension } = media;

  if (!id || !filename || !fileExtension) {
    return null;
  }

  const path = `${id}/${encodeURIComponent(filename)}${fileExtension}`;

  return `${ROUTE_MEDIA_GET}/${path}`;
};
