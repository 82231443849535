import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  showResponsiveView,
  DEFAULT_VIEW,
  SMARTPHONE_RESPONSIVE_VIEW,
  TABLET_RESPONSIVE_VIEW,
  DESKTOP_RESPONSIVE_VIEW,
} from '../../actions/responsive';
import { updateQueryStringParameter } from '../../api/buildRoute';
import Modal from '../Modals/Modal';

const propTypes = {
  responsiveView: PropTypes.string.isRequired,
  showResponsiveView: PropTypes.func.isRequired,
};
const defaultProps = {};
const panelIsOpenClassname = 'bb-panel--is-open';

class ResponsivePopup extends Component {
  static setBodyClass(responsiveView) {
    if (responsiveView === DESKTOP_RESPONSIVE_VIEW) {
      window.document.body.classList.remove(panelIsOpenClassname);
    } else {
      window.document.body.classList.add(panelIsOpenClassname);
    }
  }

  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    ResponsivePopup.setBodyClass(this.props.responsiveView);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.responsiveView !== this.props.responsiveView) {
      ResponsivePopup.setBodyClass(this.props.responsiveView);
    }
  }

  onClose(evt) {
    if (evt) {
      evt.preventDefault();
    }
    this.props.showResponsiveView(DEFAULT_VIEW);
  }

  render() {
    const { responsiveView } = this.props;
    if (
      responsiveView === SMARTPHONE_RESPONSIVE_VIEW
      || responsiveView === TABLET_RESPONSIVE_VIEW
    ) {
      const containerClasses = `o-bb-preview o-bb-preview--${responsiveView}`;
      const modalClasses = `o-bb-modal-preview o-bb-modal-preview--${responsiveView}`;
      const location = window.location.href;
      const iframeUrl = updateQueryStringParameter(location, 'preview', responsiveView);
      return (
        <Modal
          onClose={this.onClose}
          modalClassName={modalClasses}
        >
          <i onClick={this.onClose} className="o-bb-modal__close icon-close" />
          <div className={containerClasses}>
            <iframe src={iframeUrl} title={responsiveView} />
          </div>
        </Modal>
      );
    }
    return null;
  }
}

ResponsivePopup.propTypes = propTypes;
ResponsivePopup.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const {
    options: {
      view: {
        responsiveView,
      },
    },
  } = state;

  return {
    responsiveView,
  };
};
const mapDispatchToProps = {
  showResponsiveView,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsivePopup);
