import { compareDates, compareValues } from '../tools/sortable';

/**
 * Returns single media item when ID is valid and item exists in state
 *
 * @param state
 * @param id
 * @returns {null|*}
 */
export const findMediaItem = (state, id) => {
  if (!id) {
    return null;
  }

  return state.entities.media[id];
};

export const selectMediaFolder = (state, id) => {
  if (!id) {
    return null;
  }

  return state.entities.mediaFolder[id];
};

/**
 * Returns array of items in upload queue, sorted by created time
 *
 * @param state
 * @returns []
 */
export const selectUploadedItems = (state) => {
  const {
    media: {
      uploadQueue,
    },
  } = state;

  return Object.values(uploadQueue).sort((a, b) => b.startAt - a.startAt);
};

/**
 * Returns single item from uploaded queue by unique ID
 *
 * @param state
 * @param uploadId
 * @returns {*}
 */
export const selectUploadedItemByUniqId = (state, uploadId) => (
  state.media.uploadQueue[uploadId]
);

/**
 * Performs a filter to media items to select only specific type if it's set.
 *
 * @param type
 * @param type
 * @returns {*}
 */
const createTypeMapper = (type = '') => {
  const additionalImageTypes = ['gallery', 'galleryImage', 'sliderImage', 'section'];
  const disabledTypeFilters = ['editor'];

  return (media) => {
    if (
      media.isUploading
      || (!type || type.length === 0)
      || disabledTypeFilters.includes(type)
      || (additionalImageTypes.includes(type) && media.type === 'image')
      || media.type === type
    ) {
      return media;
    }

    return {
      ...media,
      disabled: true,
    };
  };
};

/**
 * Returns an array of media from state
 *
 * @param state
 */
export const selectAllMediaItems = (state) => {
  const {
    entities: {
      media,
    },
  } = state;

  return Object.values(media)
    .sort((a, b) => b.id - a.id);
};

const sortMap = new Map([
  ['name', (a, b) => compareValues(a, b, 'filename')],
  ['size', (a, b) => compareValues(a, b, 'size')],
  ['type', (a, b) => compareValues(a, b, 'type')],
  ['date', (a, b) => compareDates(a, b, 'createdOn')],
]);

/**
 * Selects available media items from search
 *
 * @param state
 * @returns {*[]}
 */
export const selectSearchResultsMedia = (state) => {
  const {
    media: {
      searchResult: {
        media = [],
      },
    },
    content: {
      mediaManagerContentType: type,
    },
  } = state;

  return media.map(createTypeMapper(type));
};

/**
 * Returns an array of media from state
 *
 * @param state
 */
export const selectMediaItems = (state) => {
  const {
    entities: {
      media,
    },
    media: {
      library: {
        page = 1,
        searchPhrase = '',
        ids = [],
      },
      sortType,
      sortDirection,
    },
    content: {
      mediaManagerContentType: type,
    },
  } = state;

  let items = ids
    .map(id => media[id])
    .filter(Boolean)
    .map(createTypeMapper(type));
  if (searchPhrase && searchPhrase.length) {
    const phrases = searchPhrase.toUpperCase().trim().split(' ');
    items = items.filter((item) => {
      const fullName = [item.filename, item.fileExtension, item.resolution].join('');

      return phrases.every(phrase => fullName.toUpperCase().includes(phrase));
    });
  }

  items = items.sort((a, b) => {
    const sortFunc = sortMap.get(sortType);
    return sortFunc ? sortFunc(a, b) : 0;
  });
  items = sortDirection === 'desc' ? items.reverse() : items;

  // reorder recently uploaded items always on the top
  const recentlyCreated = items.filter(i => i.wasRecentlyCreated);
  const existing = items.filter(i => !i.wasRecentlyCreated);
  items = [...recentlyCreated, ...existing];

  const perPage = 15;
  const total = items.length;
  const pages = Math.ceil(total / perPage);
  const mediaItems = items.slice(0, page * perPage);

  return {
    items: mediaItems,
    pagination: {
      page,
      pages,
      perPage,
      total,
      hasMorePages: page < pages,
      isFirstPage: page === 1,
      isLastPage: page === pages,
    },
  };
};

export const selectMediaFolders = (state) => {
  const {
    media: {
      library: {
        searchPhrase = '',
      },
    },
    entities: {
      mediaFolder,
    },
  } = state;

  let folders = Object.values(mediaFolder);

  if (searchPhrase && searchPhrase.length) {
    const phrases = searchPhrase.toUpperCase().trim().split(' ');
    folders = folders.filter(item => phrases.every(phrase => item.name.toUpperCase().includes(phrase)));
  }

  return folders;
};

export default {
  selectUploadedItems,
  selectUploadedItemByUniqId,
  findMediaItem,
  selectMediaItems,
  selectAllMediaItems,
  selectMediaFolders,
};
