import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaManagerContainer from './MediaManagerContainer';
import ModalHeader from '../Modals/ModalHeader';
import i18n from '../../internationalization/i18n';
import Modal from '../Modals/Modal';
import ModalContext from '../Modals/ModalContext';

const propTypes = {
  contentId: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  mediaId: PropTypes.number,
  icon: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  title: PropTypes.string,
};

const defaultProps = {
  contentId: null,
  mediaId: null,
  icon: 'fas fa-images',
  submitButtonText: null,
  title: null,
};

class MediaManagerModal extends Component {
  static mediaIdFromMediaUrl(url) {
    if (!url) { return false; }
    const value = url.split('/');
    if (value.length > 2) {
      const idNumber = parseInt(value.slice(-2, -1), 10);
      return Number.isNaN(idNumber) ? false : idNumber;
    }
    return false;
  }

  static onItemClick(evt) {
    evt.preventDefault();
  }

  constructor(props) {
    super(props);
    this.onOk = this.onOk.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onOk(mediaItem, contentType) {
    const {
      contentId,
    } = this.props;

    this.props.onOk(contentId, contentType, mediaItem);
  }

  onCancel(evt) {
    if (evt) {
      evt.preventDefault();
    }
    this.props.onCancel();
  }

  onClose(evt) {
    if (evt) {
      evt.preventDefault();
    }
    this.props.onClose();
  }

  render() {
    const {
      icon,
      mediaId,
      ...props
    } = this.props;

    const submitButtonText = this.props.submitButtonText || i18n.t('CONTENT.insert');
    const title = this.props.title || i18n.t('CONTENT.manageFiles');

    return (
      <Modal
        onClose={this.onClose}
        modalClassName="o-bb-modal o-bb-modal--large o-bb-modal--mediamanager"
      >
        <ModalHeader icon={icon} title={title} />
        <ModalContext>
          {({ closeModal }) => (
            <MediaManagerContainer
              {...props}
              onCancel={closeModal}
              onOk={this.onOk}
              submitButtonText={submitButtonText}
              mediaId={mediaId}
            />
          )}
        </ModalContext>
      </Modal>
    );
  }
}

MediaManagerModal.propTypes = propTypes;
MediaManagerModal.defaultProps = defaultProps;

export default MediaManagerModal;
