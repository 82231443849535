import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MediaListView from './MediaListView';
import MediaGridView from './MediaGridView';

const ViewMap = {
  grid: MediaGridView,
  table: MediaListView,
};

const MediaView = (props) => {
  const {
    viewMode,
    ...rest
  } = props;

  const Component = ViewMap[viewMode] || MediaGridView;

  return (
    <Component {...rest} />
  );
};

MediaView.propTypes = {
  viewMode: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  viewMode: state.media.viewMode,
});

export default connect(mapStateToProps)(MediaView);
