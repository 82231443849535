import { CALL_BB_API } from '../api';
import { ROUTE_CSS_EDITOR, ROUTE_JS_EDITOR } from '../api/routes';

export const CODE_EDITOR_STORAGE_KEY = '@browsbox/CODE_EDITOR_IS_OPEN';

export const OPEN_CODE_EDITOR = 'OPEN_CODE_EDITOR';
export const CLOSE_CODE_EDITOR = 'CLOSE_CODE_EDITOR';
export const UPDATE_CODE_EDITOR = 'UPDATE_CODE_EDITOR';
export const OPEN_CODE_EDITOR_SAVE_CONFIRMATION = 'OPEN_EDITOR_SAVE_CONFIRMATION';
export const CLOSE_CODE_EDITOR_SAVE_CONFIRMATION = 'CLOSE_CODE_EDITOR_SAVE_CONFIRMATION';

export const openCodeEditor = codeLanguage => ({ type: OPEN_CODE_EDITOR, codeLanguage });
export const openCodeEditorSaveConfirmation = value => ({ type: OPEN_CODE_EDITOR_SAVE_CONFIRMATION, value });
export const closeCodeEditorSaveConfirmation = value => ({ type: CLOSE_CODE_EDITOR_SAVE_CONFIRMATION, value });

export const closeCodeEditor = () => ({ type: CLOSE_CODE_EDITOR });
export const updateCodeEditor = () => ({ type: UPDATE_CODE_EDITOR });

export const CLEAR_CODE_EDITOR_CONTENT = 'CLEAR_CODE_EDITOR_CONTENT';
export const clearCodeEditorContent = () => ({ type: CLEAR_CODE_EDITOR_CONTENT });

/*
  CSS Editor API Requests
 */

export const CSS_EDITOR_FETCH_REQUEST = 'CSS_EDITOR_FETCH_REQUEST';
export const CSS_EDITOR_FETCH_SUCCESS = 'CSS_EDITOR_FETCH_SUCCESS';
export const CSS_EDITOR_FETCH_FAILURE = 'CSS_EDITOR_FETCH_FAILURE';

const fetchCssEditorContent = () => ({
  [CALL_BB_API]: {
    types: [CSS_EDITOR_FETCH_REQUEST, CSS_EDITOR_FETCH_SUCCESS, CSS_EDITOR_FETCH_FAILURE],
    endpoint: ROUTE_CSS_EDITOR,
    method: 'GET',
  },
});

export const loadCssEditorContent = () => dispatch => dispatch(fetchCssEditorContent());

export const CSS_EDITOR_POST_REQUEST = 'CSS_EDITOR_POST_REQUEST';
export const CSS_EDITOR_POST_SUCCESS = 'CSS_EDITOR_POST_SUCCESS';
export const CSS_EDITOR_POST_FAILURE = 'CSS_EDITOR_POST_FAILURE';

const postCssEditorContent = value => ({
  [CALL_BB_API]: {
    types: [CSS_EDITOR_POST_REQUEST, CSS_EDITOR_POST_SUCCESS, CSS_EDITOR_POST_FAILURE],
    endpoint: ROUTE_CSS_EDITOR,
    method: 'POST',
    json: value,
  },
});

// Redux Thunk middleware.
export const saveCssEditorContent = value => dispatch => dispatch(postCssEditorContent(value));

/*
  JS Editor API Requests
 */

export const JS_EDITOR_FETCH_REQUEST = 'JS_EDITOR_FETCH_REQUEST';
export const JS_EDITOR_FETCH_SUCCESS = 'JS_EDITOR_FETCH_SUCCESS';
export const JS_EDITOR_FETCH_FAILURE = 'JS_EDITOR_FETCH_FAILURE';

const fetchJsEditorContent = () => ({
  [CALL_BB_API]: {
    types: [JS_EDITOR_FETCH_REQUEST, JS_EDITOR_FETCH_SUCCESS, JS_EDITOR_FETCH_FAILURE],
    endpoint: ROUTE_JS_EDITOR,
    method: 'GET',
  },
});

export const loadJsEditorContent = () => dispatch => dispatch(fetchJsEditorContent());

export const JS_EDITOR_POST_REQUEST = 'JS_EDITOR_POST_REQUEST';
export const JS_EDITOR_POST_SUCCESS = 'JS_EDITOR_POST_SUCCESS';
export const JS_EDITOR_POST_FAILURE = 'JS_EDITOR_POST_FAILURE';

const postJsEditorContent = (value) => ({
  [CALL_BB_API]: {
    types: [JS_EDITOR_POST_REQUEST, JS_EDITOR_POST_SUCCESS, JS_EDITOR_POST_FAILURE],
    endpoint: ROUTE_JS_EDITOR,
    method: 'POST',
    json: value,
  },
});

// Redux Thunk middleware.
export const saveJsEditorContent = value => dispatch => dispatch(postJsEditorContent(value));
