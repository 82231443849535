import {
  takeEvery, put, takeLatest, select, delay,
} from 'redux-saga/effects';
import { actionTypes, change } from 'redux-form';
import {
  BASE_MODULE_ITEM_PUBLISH_SUCCESS,
  BASE_MODULE_ITEM_UNPUBLISH_SUCCESS,
  SET_BASE_MODULE_LIST_FILTERS,
  BASE_MODULE_LIST_REFRESH,
  readBaseModuleItem,
  getBaseModuleItemsList,
  orderBaseModuleItems,
  getSeoPreview,
  BASE_MODULE_ITEMS_REORDER,
  BASE_MODULE_ITEMS_SET_SORT_BY,
} from '../../actions/baseModuleItems';
import { prepareBaseModuleItemFieldsToSubmit } from '../../common';

function* handleBaseModulePublishChange(action) {
  const { id } = action.meta;

  yield put(readBaseModuleItem(id));
}

function* handleBaseModuleListRefresh() {
  yield delay(200);
  const filters = yield select(state => state.baseModuleItems.filters);
  const sortableField = yield select(state => state.baseModuleItems.sortableField);
  const sortableDirection = yield select(state => state.baseModuleItems.sortableDirection);
  const params = {
    search: filters.search,
    categories: (filters.categories || []).map(item => item.id),
    language: filters.language ? filters.language.code : undefined,
    sortBy: sortableField,
    sortDirection: sortableDirection,
  };

  yield put(getBaseModuleItemsList(params));
}

function* handleBaseModuleItemFormChange(action) {
  const { form, field } = action.meta;

  if (form !== 'baseModuleItemForm') {
    return;
  }

  if (field === 'autoSeoValues') {
    return;
  }

  yield delay(250);
  const entity = yield select(state => state.baseModule.currentModule);

  if (!entity) {
    return;
  }

  const itemForm = yield select(state => state.form.baseModuleItemForm);

  if (!itemForm) {
    return;
  }

  const {
    title, routeId, seoTitle, seoDescription, seoUrl, fields,
    autoSeoUrl, autoSeoTitle, autoSeoDescription,
  } = itemForm.values;

  if (!title) {
    return;
  }

  yield put(getSeoPreview({
    entityId: entity.id,
    values: {
      title,
      fields: prepareBaseModuleItemFieldsToSubmit(fields),
      routeId,
      seoTitle,
      seoDescription,
      seoUrl,
      autoSeoUrl,
      autoSeoTitle,
      autoSeoDescription,
    },
  }));
}

function* handleSeoPreviewSuccess(action) {
  const { response } = action;

  yield put(change('baseModuleItemForm', 'autoSeoValues', response));
}

function* handleBaseModuleItemsReorder() {
  const orderIds = yield select(state => state.baseModuleItems.ids);

  yield put(orderBaseModuleItems({ orderIds }));
}

export default function* () {
  yield takeEvery([
    BASE_MODULE_ITEM_PUBLISH_SUCCESS,
    BASE_MODULE_ITEM_UNPUBLISH_SUCCESS,
  ], handleBaseModulePublishChange);

  yield takeLatest([
    SET_BASE_MODULE_LIST_FILTERS,
    BASE_MODULE_LIST_REFRESH,
    BASE_MODULE_ITEMS_SET_SORT_BY,
  ], handleBaseModuleListRefresh);

  yield takeLatest([
    actionTypes.INITIALIZE,
    actionTypes.CHANGE,
  ], handleBaseModuleItemFormChange);

  yield takeLatest(getSeoPreview.SUCCESS, handleSeoPreviewSuccess);
  yield takeLatest(BASE_MODULE_ITEMS_REORDER, handleBaseModuleItemsReorder);
}
