import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { BbIconButton, BbMenu, BbMenuItem } from '../@browsbox-ui';
import { setMediaPickerType } from '../../actions/mediaManager';
import Icon from '../Icon/Icon';
import MEDIA_TYPES from './MediaTypes';

const FilterTypes = [
  { key: 'all', label: 'CONTENT.showAll', value: '' },
  { key: MEDIA_TYPES.document, label: 'CONTENT.documents', value: MEDIA_TYPES.document },
  { key: MEDIA_TYPES.image, label: 'CONTENT.images', value: MEDIA_TYPES.image },
];

const MediaTypeFilter = ({ value, onChange, t }) => {
  const [open, setOpen] = useState(false);

  const handleChange = (nextValue) => {
    onChange(nextValue);
    setOpen(false);
  };

  return (
    <div
      className="c-bb-media__type-filter"
      onMouseLeave={() => setOpen(false)}
    >
      <BbIconButton
        onClick={() => setOpen(true)}
        className="o-action-link"
      >
        <Icon medium name="filter" />
      </BbIconButton>
      {open && (
        <BbMenu>
          {FilterTypes.map(type => (
            <BbMenuItem
              key={type.key}
              onClick={() => handleChange(type.value)}
              selected={type.value === value}
            >
              {t(type.label)}
            </BbMenuItem>
          ))}
        </BbMenu>
      )}
    </div>
  );
};

MediaTypeFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  value: state.content.mediaManagerContentType,
});

const mapDispatchToProps = {
  onChange: setMediaPickerType,
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(MediaTypeFilter));
