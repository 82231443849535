import translationNL from './nl.json';
import translationPL from './pl.json';
import translationFR from './fr.json';
import translationEN from './en.json';

const translations = {
  nl: translationNL,
  pl: translationPL,
  fr: translationFR,
  en: translationEN,
};

export default translations;
