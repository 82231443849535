/* eslint-disable object-shorthand, func-names */
import i18n from '../../../../internationalization/i18n';
import {
  getAnchorElement, isOnlyTextSelected, isButtonEditor, getBookMarkOfEditor,
} from '../utils';

const setHyperlinkToElement = (element, hyperlink) => {
  element.setAttribute('href', hyperlink);
  element.setAttribute('data-mce-href', hyperlink);
  element.setAttribute('target', '_blank');
};

// based on "webcreatorfunctions.editor.js"
const mediaLinkButton = function (editor, adminAssetPath, showMediaLinkModal, commitChange) {
  return {
    icon: ' far fa-download',
    tooltip: i18n.t('CONTENT.insertMediaLink'),
    onclick: function () {
      const anchorElm = getAnchorElement(editor);
      let bookMark = null;

      if (!isButtonEditor(editor) && editor.selection.getContent()) {
        bookMark = getBookMarkOfEditor(editor);
      }

      // Called if a selection is made in the MediaManager
      const cb = (mediaItem) => {
        if (mediaItem === null) {
          return;
        }

        const { original } = mediaItem;
        // need original media url
        if (!original) {
          return;
        }

        // eslint-disable-next-line no-param-reassign
        editor.bbHiperlink = original;

        if (anchorElm) {
          setHyperlinkToElement(anchorElm, original);
          commitChange(editor);
          return;
        }

        if (bookMark) {
          // Restore bookmarked selection
          editor.selection.moveToBookmark(bookMark);
        }

        // make sure SOMETHING is selected, otherwise no use in adding a hyperlink
        const content = editor.selection.getContent();
        if (content && isOnlyTextSelected(content)) {
          const elm = editor.dom.create('a');
          setHyperlinkToElement(elm, original);
          elm.innerHTML = content;
          const hyperlink = elm.outerHTML;
          // Set the hyperlink instead of the text
          editor.selection.setContent(hyperlink);
        }

        commitChange(editor);
      };

      showMediaLinkModal(cb);
    },
  };
};

export default mediaLinkButton;
