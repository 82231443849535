import { ROUTE_MEDIA_FILE } from '../api/routes';
import { buildRoute, CALL_BB_API } from '../api';
import { addToUrlQuery } from '../api/browsboxApi';

export const DELETE_MEDIA_REQUEST = 'DELETE_MEDIA_REQUEST';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAILURE = 'DELETE_MEDIA_FAILURE';

export const DELETE_MEDIA = 'DELETE_MEDIA';
export const OPEN_DELETE_MEDIA = 'OPEN_DELETE_MEDIA';
export const CLOSE_DELETE_MEDIA = 'CLOSE_DELETE_MEDIA';

export const openDeleteMedia = value => ({ type: OPEN_DELETE_MEDIA, value });
export const closeDeleteMedia = () => ({ type: CLOSE_DELETE_MEDIA });

// BrowsBox API middleware.
const deleteMediaCall = ({ id, force }) => {
  let endpoint = buildRoute(ROUTE_MEDIA_FILE, { id });

  if (force) {
    endpoint = addToUrlQuery(endpoint, 'force', 'true');
  }

  return {
    [CALL_BB_API]: {
      types: [DELETE_MEDIA_REQUEST, DELETE_MEDIA_SUCCESS, DELETE_MEDIA_FAILURE],
      endpoint,
      method: 'DELETE',
    },
  };
};

// Redux Thunk middleware.
export const deleteMedia = value => dispatch => dispatch(deleteMediaCall(value));
