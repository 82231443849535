import React from 'react';
import PropTypes from 'prop-types';
import { BbIconButton } from '../../@browsbox-ui';
import Icon from '../../Icon/Icon';
import MediaSortDirection from './MediaSortDirection';

const MediaListViewToolbar = ({ onCreateClick }) => (
  <div className="c-bb-media__table-view__toolbar">
    <BbIconButton onClick={onCreateClick} className="c-bb-media__table-view__create-new">
      <Icon name="add" />
    </BbIconButton>
    <MediaSortDirection />
  </div>
);

MediaListViewToolbar.propTypes = {
  onCreateClick: PropTypes.func.isRequired,
};

export default MediaListViewToolbar;
