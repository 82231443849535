import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BbButton from '@browsbox-ui/bb-button';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import i18n from '../../internationalization/i18n';
import Modal from './Modal';

const propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  title: PropTypes.string,
};

const defaultProps = {
  children: null,
  icon: '',
  submitButtonText: null,
  title: null,
};

class MessageModal extends Component {
  constructor(props) {
    super(props);
    this.onOk = this.onOk.bind(this);
  }

  onOk() {
    this.props.onOk();
  }

  render() {
    const {
      children,
      icon,
      onClose,
    } = this.props;

    const submitButtonText = this.props.submitButtonText || i18n.t('CONTENT.close');
    const title = this.props.title || i18n.t('CONTENT.genericError');

    return (
      <Modal
        onClose={onClose}
        backdropClassName="o-bb-backdrop--high"
        modalClassName="o-bb-modal--confirmation"
      >
        <ModalHeader icon={icon} title={title} />
        <div className="o-bb-modal__content">
          {children}
        </div>
        <ModalFooter>
          <BbButton className="c-bb-button--primary" onClick={this.onOk} text={submitButtonText} />
        </ModalFooter>
      </Modal>
    );
  }
}

MessageModal.propTypes = propTypes;
MessageModal.defaultProps = defaultProps;

export default MessageModal;
