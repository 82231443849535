import { ROUTE_PAGE_CONTENT } from '../api/routes';
import { buildRoute, CALL_BB_API } from '../api';

export const CONTENT_SAVE_REQUEST = 'CONTENT_SAVE_REQUEST';
export const CONTENT_SAVE_SUCCESS = 'CONTENT_SAVE_SUCCESS';
export const CONTENT_SAVE_FAILURE = 'CONTENT_SAVE_FAILURE';

// BrowsBox API middleware.
const postContent = (id, value) => {
  const endpoint = buildRoute(ROUTE_PAGE_CONTENT, { id });
  return {
    [CALL_BB_API]: {
      types: [CONTENT_SAVE_REQUEST, CONTENT_SAVE_SUCCESS, CONTENT_SAVE_FAILURE],
      endpoint,
      method: 'PUT',
      json: value,
    },
  };
};

export const saveContent = value => dispatch => dispatch(
  postContent(value.id, value.content),
);
