/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import * as i18n from 'i18next';
import { Gateway } from '../../../Gateway';
import { BbPopup, BbTags } from '../../../@browsbox-ui';
import FontAwesomeIcon from '../../../Icon/FontAwesomeIcon';

const ItemInfoPopup = ({ item, onClose }) => (
  <Gateway into="popup">
    <BbPopup
      target={`base-module-item-info-${item.id}`}
      onClose={onClose}
      onMouseLeave={onClose}
      placement="left top"
      className="o-bb-base-module__item__info"
    >
      <div className="o-bb-base-module__info__published">
        {item.route.publishable && item.route.published ? (
          <span>
            <FontAwesomeIcon name="far fa-eye" />
            {i18n.t('CONTENT.published').toUpperCase()}
          </span>
        ) : (
          <span>
            <FontAwesomeIcon name="far fa-eye-slash" />
            {i18n.t('CONTENT.notPublished').toUpperCase()}
          </span>
        )}
      </div>
      <div className="o-bb-base-module__info__categories">
        {item.categories && item.categories.length > 0 && (
          <BbTags
            tags={item.categories.map(category => ({
              id: category.id,
              name: category.title,
            }))}
          />
        )}
      </div>
      <div className="o-bb-base-module__info__last-update">
        <span>{i18n.t('CONTENT.updatedAt').toUpperCase()} <strong>{moment(item.updated_on).format('L (LT)')}</strong></span>
      </div>
    </BbPopup>
  </Gateway>
);

export default ItemInfoPopup;
