/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { renderError } from '../../tools/validation';

const defaultIsEqual = (a, b) => a === b;

// eslint-disable-next-line react/prop-types
const RadioFields = ({
  input: { value, onChange }, meta: { touched, error }, options, isEqual = defaultIsEqual,
}) => (
  <div className="c-bb-form__radio">
    <ul className="c-bb-form__radio__list">
      {options.map(option => (
        <li key={option.value.toString()}>
          <label>
            <input
              value={option.value}
              type="radio"
              onChange={onChange}
              checked={isEqual(value, option.value)}
            />
            <span>
              {option.label}
            </span>
          </label>
        </li>
      ))}
    </ul>
    {touched && error && <span className="c-bb-form__input-error">{renderError(error)}</span>}
  </div>
);

export default RadioFields;
