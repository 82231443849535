/* global $settings */
import get from 'lodash/get';

const SuperAdminOnly = ({ children }) => {
  const isSuperAdmin = get($settings || {}, 'isSuperAdmin');

  if (isSuperAdmin) {
    return children;
  }

  return null;
};

export default SuperAdminOnly;
