import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { withNamespaces } from 'react-i18next';
import { FoldersList } from './Folders/FoldersList';
import { FolderContextProvider } from './Folders/FolderContext';
import { mediaLibrarySetFolderId, searchMedia as searchMediaAction, resetSearchView } from '../../actions/media';
import MediaListView from './Media/MediaListView';
import CircleLoader from '../Loader/CircleLoader';
import Icon from '../Icon/Icon';
import { selectSearchResultsMedia } from '../../selectors/media';

const SearchView = (props) => {
  const {
    t,
    active,
    onBackClick,
    onFolderClick,
    media,
    folders,
    searchMedia,
    isLoading,
    reset,
  } = props;
  const [value, setValue] = React.useState('');
  const [typing, setTyping] = React.useState(false);
  const inputRef = React.createRef();

  React.useEffect(() => {
    reset();
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (active && inputRef.current) {
        inputRef.current.focus();
      }
    }, 500);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [active, inputRef]);

  const handleFolderClick = (id) => {
    onFolderClick(id);
    onBackClick();
  };

  const handleSearch = React.useCallback((query) => {
    searchMedia({ query });
    setTyping(false);
  }, [searchMedia, setTyping]);

  const debouncedSearchHandler = React.useCallback(debounce(handleSearch, 400), [handleSearch]);

  const onSearchChange = (event) => {
    event.persist();
    const { value: inputValue = '' } = event.currentTarget;
    const nextValue = inputValue.trim();

    if (nextValue !== value) {
      setValue(nextValue);
      setTyping(true);
      debouncedSearchHandler(nextValue);
    }
  };

  const onClearClick = () => {
    setValue('');
    searchMedia({ query: '' });
    reset();
  };

  const isSearching = value.length > 0;
  const hasResults = [...folders, ...media].length > 0;

  return (
    <div className="o-bb-media-search-view">
      <div className="o-bb-media-search-view__header">
        <button type="button" className="o-bb-media-search-view__header__back" onClick={onBackClick}>
          <Icon name="chevron-left" small />
          {t('CONTENT.back')}
        </button>
        <div className="o-bb-media-search-view__form">
          <input
            value={value}
            placeholder={t('CONTENT.search')}
            aria-label={t('CONTENT.search')}
            className="o-bb-media-search-view__form__input"
            onChange={onSearchChange}
            ref={inputRef}
            type="text"
          />
          <button type="button" className="o-bb-media-search-view__form__clear" onClick={onClearClick}>
            <Icon name="close" small />
          </button>
        </div>
      </div>
      <div className="o-bb-media-search-view__main">
        {isLoading && (
          <CircleLoader />
        )}
        {!isLoading && !hasResults && isSearching && !typing && (
          <div>
            {t('MEDIA.noSearchResults')}
            {' '}
            <strong>{`'${value}'`}</strong>
          </div>
        )}
        {!isLoading && hasResults && (
          <>
            <FolderContextProvider onClick={handleFolderClick}>
              <FoldersList
                folders={folders}
              />
            </FolderContextProvider>
            <MediaListView
              items={media}
            />
          </>
        )}
      </div>
    </div>
  );
};

SearchView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  media: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  folders: PropTypes.array,
  onBackClick: PropTypes.func.isRequired,
  onFolderClick: PropTypes.func.isRequired,
  searchMedia: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

SearchView.defaultProps = {
  media: [],
  folders: [],
};

const mapDispatchToProps = {
  onFolderClick: mediaLibrarySetFolderId,
  searchMedia: searchMediaAction,
  reset: resetSearchView,
};

const mapState = state => ({
  media: selectSearchResultsMedia(state),
  folders: state.media.searchResult.folders,
  isLoading: state.media.searchResult.isLoading,
});

const ConnectedSearchView = connect(mapState, mapDispatchToProps)(SearchView);

export default withNamespaces()(ConnectedSearchView);
