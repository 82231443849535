import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { contentLayoutProps } from '@liswood-tache/browsbox-static';
import ModalHeader from '../Modals/ModalHeader';
import i18n from '../../internationalization/i18n';
import Modal from '../Modals/Modal';

const propTypes = {
  icon: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  title: PropTypes.string,
  contentId: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
  layouts: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(contentLayoutProps)]).isRequired,
};

const defaultProps = {
  icon: 'fas fa-exchange-alt',
  title: null,
};

const LayoutItem = withNamespaces()(({ option, onClick, lng }) => {
  const handleClick = (event) => {
    event.preventDefault();
    onClick(option);
  };

  const resolveImage = () => {
    const localeImg = get(option, `locale_img.${lng}`);

    if (localeImg) {
      return localeImg;
    }

    return option.img;
  };

  return (
    <li className="c-bb-layouts__item">
      <a onClick={handleClick} className="c-bb-layouts__link">
        <img src={resolveImage()} alt={option.classes} className="c-bb-layouts__img" />
      </a>
    </li>
  );
});

LayoutItem.propTypes = {
  option: contentLayoutProps.isRequired,
  onClick: PropTypes.func.isRequired,
};

class ContentLayoutModal extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSelectOption = this.onSelectOption.bind(this);
  }

  onSelectOption(option) {
    const { contentId } = this.props;
    const { classes } = option;
    this.props.onOk(contentId, classes);
  }

  onCancel(evt) {
    evt.preventDefault();
    this.props.onCancel();
  }

  onClose(evt) {
    evt.preventDefault();
    this.props.onClose();
  }

  render() {
    const {
      icon,
      onClose,
      layouts,
    } = this.props;

    const title = this.props.title || i18n.t('CONTENT.chooseLayout');

    if (!layouts) {
      return null;
    }

    return (
      <Modal
        onClose={onClose}
        modalClassName="o-bb-modal--layouts"
      >
        <ModalHeader onClose={onClose} icon={icon} title={title} />
        <div className="o-bb-modal__content">
          <ul className="c-bb-layouts">
            {
              layouts.map(option => (
                <LayoutItem
                  option={option}
                  onClick={this.onSelectOption}
                  key={option.classes}
                />
              ))
            }
          </ul>
        </div>
      </Modal>
    );
  }
}

ContentLayoutModal.propTypes = propTypes;
ContentLayoutModal.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  const { modules, content } = state.entities;
  const contentItem = content.filter(c => c.id === ownProps.contentId).pop();
  const moduleDef = (contentItem && modules[contentItem.type]) || {};
  const layoutClasses = (contentItem && contentItem.options && contentItem.options.layoutClasses) || '';
  const layouts = moduleDef.layouts || false;
  return {
    layouts,
    layoutClasses,
  };
};
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentLayoutModal);
