import { SET_ACTIVE_MODULE_ITEM } from '../actions/setActiveModuleItem';

const activeModuleItem = (state = [], action) => {
  switch (action.type) {
    case SET_ACTIVE_MODULE_ITEM:
      return action.id;
    default:
      return state;
  }
};

export default activeModuleItem;
