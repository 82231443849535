import React from 'react';
import { connect } from 'react-redux';
import Multiselect from 'react-widgets/lib/Multiselect';
import PropTypes from 'prop-types';

const propTypes = {
  input: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const ListItem = ({ item }) => (
  <span title={`{item.title} - ${item.entity_name} - #${item.id}`}>
    {item.title} [{item.entity_name}]
  </span>
);

ListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    entity_name: PropTypes.string.isRequired,
  }).isRequired,
};

const TagItem = ({ item }) => `${item.title} [${item.entity_name}]`;

const RelatedItemsField = ({
  label, input, data, isLoading,
}) => {
  const handleChange = (items) => {
    const nextValue = (items || []).map(item => item.id);
    input.onChange(nextValue);
  };

  const mapIdsToValues = () => {
    const selected = input.value || [];
    return (data || []).filter(item => selected.includes(item.id));
  };

  const filterOptions = React.useCallback((item, value) => {
    const searchable = item.title.toLowerCase();
    const search = value.toLowerCase();
    const possibleId = parseInt(value, 10);

    return searchable.indexOf(search) === 0 || (possibleId === item.id);
  }, [input, data]);

  return (
    <div className="c-bb-form__input-wrap">
      <div className="c-bb-form__input c-bb-form__input-item-group c-bb-form__input__related-items">
        {label && <label htmlFor={input.name}>{label}</label>}
        <Multiselect
          {...input}
          busy={isLoading}
          allowCreate={false}
          onChange={handleChange}
          onBlur={() => input.onBlur()}
          className="input-item-group"
          value={mapIdsToValues()}
          data={data}
          itemComponent={ListItem}
          tagComponent={TagItem}
          filter={filterOptions}
        />
      </div>
    </div>
  );
};

RelatedItemsField.propTypes = propTypes;

const mapStateToProps = state => ({
  data: state.baseModuleRelatedItems.items,
  isLoading: state.baseModuleRelatedItems.isLoading,
});

export default connect(mapStateToProps)(RelatedItemsField);
