import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toString } from 'lodash';
import { IMAGE_PLACEHOLDER_URL, getLinkType } from '@liswood-tache/browsbox-static';
import { FormInput } from '../FormElements';
import Links, { LINK_TYPE_EMAIL, LINK_TYPE_NONE } from '../Modals/Links';
import i18n from '../../internationalization/i18n';
import Icon from '../Icon/Icon';

const propTypes = {
  onChange: PropTypes.func.isRequired, // on image data change
  onDelete: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  url: PropTypes.string, // Media data
  alt: PropTypes.string, // Media data
  tooltip: PropTypes.string, // Media data
  showMetaData: PropTypes.bool.isRequired, // Media data ( show image alt and tooltip )
  showMetaLinks: PropTypes.bool.isRequired, // Media data ( show image alt and tooltip )
  canDelete: PropTypes.bool, // Media data ( show remove button )
  hyperlink: PropTypes.string, // Media data
  hyperlinkValidationMessage: PropTypes.string.isRequired,
};

const defaultProps = {
  canDelete: false,
  url: '',
  alt: '',
  tooltip: '',
  hyperlink: '',
};

const actionItemClasses = 'o-action-link u-mr-m';

class MediaPickerImage extends Component {
  constructor(props) {
    super(props);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onUploadClick = this.onUploadClick.bind(this);
    this.onLinkChange = this.onLinkChange.bind(this);
    this.onLinkTypeChange = this.onLinkTypeChange.bind(this);
    const hyperlinkType = getLinkType(props.hyperlink);
    this.state = {
      hyperlinkType,
    };
  }

  // Handle input element value change
  onInputChange(evt) {
    const { target: { name, value } } = evt;
    this.props.onChange(name, value);
  }

  // Handle input element value change
  onLinkChange(link) {
    this.props.onChange('hyperlink', link.value);
  }

  // Handle link type change
  onLinkTypeChange(value) {
    if (value === LINK_TYPE_NONE) {
      this.props.onChange('hyperlink', '');
    }
  }

  onDeleteClick(evt) {
    evt.preventDefault();
    this.props.onDelete();
  }

  onUploadClick(evt) {
    evt.preventDefault();
    this.props.onUpload();
  }

  renderImageDescription() {
    const {
      showMetaData,
      tooltip,
      alt,
    } = this.props;
    if (showMetaData) {
      const attrib = {
        type: 'text',
      };
      return (
        <div>
          <FormInput
            id="mediapicker-alt"
            label={i18n.t('CONTENT.alternativeText')}
            maxLength="125"
            name="alt"
            value={alt}
            valueLength={toString(alt).length}
            onChange={this.onInputChange}
            info={i18n.t('CONTENT.imageAltDescription')}
            {...attrib}
          />
          <FormInput
            id="mediapicker-tooltip"
            label={i18n.t('CONTENT.tooltip')}
            maxLength="70"
            name="tooltip"
            value={tooltip}
            valueLength={toString(tooltip).length}
            onChange={this.onInputChange}
            info={i18n.t('CONTENT.imageTitleDescription')}
            {...attrib}
          />
        </div>
      );
    }
    return null;
  }

  renderImageActions() {
    const {
      url,
      canDelete,
    } = this.props;

    if (url) {
      return (
        <div className="c-bb-media-picker__actions">
          <a onClick={this.onUploadClick} className={actionItemClasses}>
            <Icon
              name="upload"
              className="icon icon--light icon--normal icon-"
            />
          </a>
          {canDelete && (
            <a onClick={this.onDeleteClick} className={actionItemClasses}>
              <Icon
                name="trash"
                className="icon icon--light icon--normal icon-"
              />
            </a>
          )}
        </div>
      );
    }

    return (
      <div className="c-bb-media-picker__actions">
        <a onClick={this.onUploadClick} className={actionItemClasses}>
          <Icon name="upload" className="icon--light icon--normal" />
        </a>
      </div>
    );
  }

  renderImagePreview() {
    const {
      url,
    } = this.props;
    const previewUrl = url || IMAGE_PLACEHOLDER_URL;

    return (
      <a onClick={this.onUploadClick}>
        <img className="o-bb-media-preview" src={previewUrl} alt="" />
      </a>
    );
  }

  renderLinks() {
    const { hyperlink, hyperlinkValidationMessage } = this.props;
    const { hyperlinkType } = this.state;
    return (
      <div className="c-bb-media-picker__links">
        <label className="o-bb-label" htmlFor="es-lint">{i18n.t('CONTENT.insertLinkLabel')}</label>
        <Links
          onChange={this.onLinkChange}
          onTypeChange={this.onLinkTypeChange}
          ignoreLinkType={[LINK_TYPE_EMAIL]}
          hyperlink={hyperlink}
          type={hyperlinkType}
          validationMessage={hyperlinkValidationMessage}
        />
      </div>
    );
  }

  render() {
    const {
      showMetaData,
      showMetaLinks,
    } = this.props;
    return (
      <div className="c-bb-media-picker">
        <div className="c-bb-media-picker__panel c-bb-media-picker__panel--image">
          {this.renderImagePreview()}
          {this.renderImageActions()}
        </div>
        {showMetaData
        && (
        <div className="c-bb-media-picker__panel">
          {this.renderImageDescription()}
          {showMetaLinks ? this.renderLinks() : null}
        </div>
        )}
      </div>
    );
  }
}

MediaPickerImage.propTypes = propTypes;
MediaPickerImage.defaultProps = defaultProps;

export default MediaPickerImage;
