import { openMediaManager } from '../../actions/mediaManager';

class Media {
  store = null;

  listeners = [];

  setStore(store) {
    this.store = store;
  }

  /**
   * Returns a promise which resolves when:
   *  - an item from library is selected:
   *   { canceled: false, media: { id, original, ...rest }}
   *  - an library is closed without selection:
   *   { canceled: true, media: null }
   *
   * @param options
   * @returns {*}
   */
  launchMediaLibraryAsync(options = {}) {
    if (!this.store) {
      throw new Error('Unable to lunch Media Library. Store is not set.');
    }

    const defaults = {
      type: '',
    };

    this.store.dispatch(openMediaManager({
      ...defaults,
      ...options,
    }));

    return new Promise((resolve) => {
      this.listeners.push(resolve);
    });
  }

  flushListeners({ canceled, media }) {
    this.listeners = this.listeners.filter((listener) => {
      listener({ canceled, media });
      return false;
    });
  }
}

export default new Media();
