import {
  ROUTE_BASEMODULES_ENTITIES,
  ROUTE_BASEMODULE_ENTITIES,
  ROUTE_BASEMODULE_FIELD_TYPES,
  ROUTE_LANGUAGES,
  ROUTE_BASEMODULES_ENTITY,
} from '../api/routes';
import { buildRoute, CALL_BB_API } from '../api';

export const OPEN_BASE_MODULE_VIEW = 'OPEN_BASE_MODULE_VIEW';
export const CLOSE_BASE_MODULE_VIEW = 'CLOSE_BASE_MODULE_VIEW';
export const CHANGE_BASE_MODULE_LANG = 'CHANGE_BASE_MODULE_LANG';
export const CHANGE_BASE_MODULE_SCREEN = 'CHANGE_BASE_MODULE_SCREEN';
export const CLEAR_BASE_MODULE_CURRENT_CATEGORY = 'CLEAR_BASE_MODULE_CURRENT_CATEGORY';
export const POPULATE_BASE_MODULE_CATEGORY = 'POPULATE_BASE_MODULE_CATEGORY';

export const openBaseModuleView = () => ({ type: OPEN_BASE_MODULE_VIEW });
export const closeBaseModuleView = () => ({ type: CLOSE_BASE_MODULE_VIEW });
export const clearCategory = () => ({ type: CLEAR_BASE_MODULE_CURRENT_CATEGORY });
export const populateBaseModuleCategory = value => ({ type: POPULATE_BASE_MODULE_CATEGORY, value });

/**
 * @deprecated Use router navigation instead
 */
export function changeBaseModuleScreen(screen) {
  // eslint-disable-next-line no-console
  console.warn('changeBaseModuleScreen is deprecated, use router navigation instead');
  return { type: CHANGE_BASE_MODULE_SCREEN, payload: screen };
}

export function changeBaseModuleLang(lang) {
  return { type: CHANGE_BASE_MODULE_LANG, payload: lang };
}

export const BASE_MODULE_SAVE_REQUEST = 'BASE_MODULE_SAVE_REQUEST';
export const BASE_MODULE_SAVE_SUCCESS = 'BASE_MODULE_SAVE_SUCCESS';
export const BASE_MODULE_SAVE_FAILURE = 'BASE_MODULE_SAVE_FAILURE';

const postBaseModule = (value) => {
  const endpoint = ROUTE_BASEMODULES_ENTITIES;
  return {
    [CALL_BB_API]: {
      types: [BASE_MODULE_SAVE_REQUEST, BASE_MODULE_SAVE_SUCCESS, BASE_MODULE_SAVE_FAILURE],
      endpoint,
      method: 'POST',
      json: value,
    },
  };
};

export const saveBaseModule = value => dispatch => dispatch(
  postBaseModule(value),
);

export const BASE_MODULE_DELETE_REQUEST = 'BASE_MODULE_DELETE_REQUEST';
export const BASE_MODULE_DELETE_SUCCESS = 'BASE_MODULE_DELETE_SUCCESS';
export const BASE_MODULE_DELETE_FAILURE = 'BASE_MODULE_DELETE_FAILURE';

const deleteBaseModule = (id) => {
  const endpoint = buildRoute(ROUTE_BASEMODULES_ENTITY, { id });
  return {
    [CALL_BB_API]: {
      types: [BASE_MODULE_DELETE_REQUEST, BASE_MODULE_DELETE_SUCCESS, BASE_MODULE_DELETE_FAILURE],
      endpoint,
      method: 'DELETE',
    },
  };
};

export const removeBaseModule = id => dispatch => dispatch(
  deleteBaseModule(id),
);

export const BASE_MODULE_UPDATE_REQUEST = 'BASE_MODULE_UPDATE_REQUEST';
export const BASE_MODULE_UPDATE_SUCCESS = 'BASE_MODULE_UPDATE_SUCCESS';
export const BASE_MODULE_UPDATE_FAILURE = 'BASE_MODULE_UPDATE_FAILURE';

const putBaseModule = (value, id) => {
  const endpoint = buildRoute(ROUTE_BASEMODULES_ENTITY, { id });
  return {
    [CALL_BB_API]: {
      types: [BASE_MODULE_UPDATE_REQUEST, BASE_MODULE_UPDATE_SUCCESS, BASE_MODULE_UPDATE_FAILURE],
      endpoint,
      method: 'PUT',
      json: value,
    },
  };
};

export const updateBaseModule = value => dispatch => dispatch(
  putBaseModule(value, value.id),
);

export const FIELD_TYPES_FETCH_REQUEST = 'FIELD_TYPES_FETCH_REQUEST';
export const FIELD_TYPES_FETCH_SUCCESS = 'FIELD_TYPES_FETCH_SUCCESS';
export const FIELD_TYPES_FETCH_FAILURE = 'FIELD_TYPES_FETCH_FAILURE';

const fetchFieldTypes = () => ({
  [CALL_BB_API]: {
    types: [FIELD_TYPES_FETCH_REQUEST, FIELD_TYPES_FETCH_SUCCESS, FIELD_TYPES_FETCH_FAILURE],
    endpoint: ROUTE_BASEMODULE_FIELD_TYPES,
    method: 'GET',
  },
});

export const getFieldTypes = () => dispatch => dispatch(
  fetchFieldTypes(),
);

export const LANGUAGES_FETCH_REQUEST = 'LANGUAGES_FETCH_REQUEST';
export const LANGUAGES_FETCH_SUCCESS = 'LANGUAGES_FETCH_SUCCESS';
export const LANGUAGES_FETCH_FAILURE = 'LANGUAGES_FETCH_FAILURE';

const fetchLangs = () => ({
  [CALL_BB_API]: {
    types: [LANGUAGES_FETCH_REQUEST, LANGUAGES_FETCH_SUCCESS, LANGUAGES_FETCH_FAILURE],
    endpoint: ROUTE_LANGUAGES,
    method: 'GET',
  },
});

export const getLangs = () => dispatch => dispatch(
  fetchLangs(),
);

export const DOMAIN_FETCH_REQUEST = 'DOMAIN_FETCH_REQUEST';
export const DOMAIN_FETCH_SUCCESS = 'DOMAIN_FETCH_SUCCESS';
export const DOMAIN_FETCH_FAILURE = 'DOMAIN_FETCH_FAILURE';

export const BASEMODULE_LIST_FETCH_REQUEST = 'BASEMODULE_LIST_FETCH_REQUEST';
export const BASEMODULE_LIST_FETCH_SUCCESS = 'BASEMODULE_LIST_FETCH_SUCCESS';
export const BASEMODULE_LIST_FETCH_FAILURE = 'BASEMODULE_LIST_FETCH_FAILURE';

const fetchBaseModuleList = () => ({
  [CALL_BB_API]: {
    types: [BASEMODULE_LIST_FETCH_REQUEST, BASEMODULE_LIST_FETCH_SUCCESS, BASEMODULE_LIST_FETCH_FAILURE],
    endpoint: ROUTE_BASEMODULE_ENTITIES,
    method: 'GET',
  },
});

export const getBaseModuleList = () => dispatch => dispatch(
  fetchBaseModuleList(),
);

export const SET_CURRENT_MODULE = 'SET_CURRENT_MODULE';

/**
 * @deprecated Use router navigation instead
 */
export const setCurrentModule = function setCurrentModule(module) {
  return { type: SET_CURRENT_MODULE, payload: module };
};
