/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { htmlDecode, htmlEncode } from 'htmlencode';
import { CONTENT_COMPONENT_TYPE } from '@liswood-tache/browsbox-static';
import Editor from '../../Editor/Editor';
import { DEFAULT_VIEW } from '../../../actions/responsive';

const propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  content: PropTypes.string,
  fromSectionOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  renderTarget: PropTypes.string.isRequired, // Render default or preview
  type: PropTypes.string,
};

const defaultProps = {
  className: '',
  containerClassName: '',
  content: '',
  fromSectionOption: false,
  type: CONTENT_COMPONENT_TYPE.text,
};

const ContentComponentText = (props) => {
  const {
    className,
    containerClassName,
    content: contentEncoded,
    fromSectionOption,
    id,
    renderTarget,
    type,
  } = props;
  const content = htmlDecode(contentEncoded);

  const componentClasses = classNames(
    className,
    containerClassName,
    `c-${type}`,
  );

  const onChange = (newContent) => {
    const newContentEncode = htmlEncode(newContent);
    props.onChange({ id, type, fromSectionOption }, newContentEncode);
  };

  // render edit behavior
  if (renderTarget === DEFAULT_VIEW) {
    return (
      <div className={componentClasses}>
        <Editor
          content={content}
          type={type}
          onChange={onChange}
        />
      </div>
    );
  }
  // render target is preview or html
  return (
    <div
      className={componentClasses}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

ContentComponentText.propTypes = propTypes;
ContentComponentText.defaultProps = defaultProps;

export default ContentComponentText;
