/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Field, Fields, FieldArray, FormSection, change } from 'redux-form';  // eslint-disable-line
import MultiLangFields from './MultiLangFields';
import MediaField from './MediaField';
import { openMediaManager } from '../../actions/mediaManager';
import { clearCategory } from '../../actions/baseModule';
import validation, { renderError } from '../../tools/validation';
import i18n from '../../internationalization/i18n';
import Icon from '../Icon/Icon';
import { selectAllMediaItems } from '../../selectors/media';
import IconButton from '../@browsbox-ui/bb-icon-button';

const propTypes = {
  meta: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  openMediaManager: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  defaultLang: PropTypes.object.isRequired,
  langs: PropTypes.array.isRequired,
  label: PropTypes.string,
  offset: PropTypes.string,
  media: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  clearCategory: PropTypes.func.isRequired,
  activeCategory: PropTypes.object,
};

const defaultProps = {
  label: null,
  offset: '0',
  activeCategory: null,
};

const CategoryFieldSortableHandle = SortableHandle(() => (
  <div className="c-bb-form__group__sortable-handle">
    <Icon name="base-module-drag" />
  </div>
));

const CategoryField = ({
  langs, lang, input, fields, fieldIndex, media, defaultLang, draggedNodeId,
  handleMedia, activeCategory,
  ...props
}) => {
  const field = fields.get(fieldIndex);
  const fieldId = get(field, `id.${lang}`);
  const classes = classNames(
    'c-bb-form__group-wrapper',
    { 'c-bb-form__group-wrapper--dragging': fieldId && draggedNodeId && (draggedNodeId === fieldId) },
  );

  return (
    <div className={classes}>
      <div className="c-bb-form__group c-bb-form__group__base-module-categories">
        <CategoryFieldSortableHandle />
        <Fields
          names={langs.map(lng => `${input}.title.${lng.code}`)}
          lang={lang}
          langs={langs}
          addClassName="c-bb-form__input--double"
          index={String(fieldIndex)}
          inputName="categories"
          fieldName="title"
          label={i18n.t('CONTENT.category')}
          component={MultiLangFields}
          validate={validation.required}
        />
        <Field name={`${input}.image`} component={MediaField} type="image" media={media} handleMedia={handleMedia} />
        <IconButton medium className="c-bb-form__remove-field" onClick={() => fields.remove(fieldIndex)}>
          <Icon name="trash" />
        </IconButton>
      </div>
      <div className="c-bb-form__group__children">
        <FormSection name={`${input}.children`}>
          <FieldArray
            name="categories"
            lang={lang}
            defaultLang={defaultLang}
            label={i18n.t('BASE_MODULE.addSubcategory').toUpperCase()}
            offset="40px"
            langs={langs}
            media={media}
            openMediaManager={props.openMediaManager}
            activeCategory={activeCategory}
            component={
              // eslint-disable-next-line no-use-before-define
              ConnectedRenderCategoriesFields
            }
          />
        </FormSection>
      </div>
    </div>
  );
};

CategoryField.propTypes = {
  draggedNodeId: PropTypes.number,
  fieldIndex: PropTypes.number.isRequired,
  input: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  defaultLang: PropTypes.object.isRequired,
  langs: PropTypes.array.isRequired,
  media: PropTypes.array.isRequired,
  handleMedia: PropTypes.func.isRequired,
  openMediaManager: PropTypes.func.isRequired,
  activeCategory: PropTypes.object,
};

CategoryField.defaultProps = {
  activeCategory: null,
  draggedNodeId: null,
};

const SortableCategoryField = SortableElement(props => <CategoryField {...props} />);
SortableCategoryField.propTypes = CategoryField.propTypes;

const SortableCategoriesContainer = SortableContainer(({ fields, ...props }) => (
  <div>
    {fields.map((input, index) => (
      <SortableCategoryField
        {...props}
        key={`sortable-category-${input}`}
        fields={fields}
        index={index}
        fieldIndex={index}
        input={input}
      />
    ))}
  </div>
));

class RenderCategoriesFields extends Component {
  constructor(props) {
    super(props);
    this.handleMedia = this.handleMedia.bind(this);

    this.state = {
      draggedNodeId: null,
    };
  }

  componentDidUpdate() {
    if (this.props.activeCategory && this.props.activeCategory.media) {
      this.props.change('baseModuleForm', this.props.activeCategory.name, this.props.activeCategory.media.id);
      this.props.clearCategory();
    }
  }

  handleMedia(name) {
    this.props.openMediaManager({ from: 'basemodule', name, type: 'image' });
  }

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const { fields } = this.props;
    fields.move(oldIndex, newIndex);

    this.setState({
      draggedNodeId: null,
    });
  };

  handleSortStart = ({ index }) => {
    const { fields, lang } = this.props;
    const node = fields.get(index);
    const id = get(node, `id.${lang}`);

    this.setState({
      draggedNodeId: id,
    });
  };

  render() {
    const {
      fields, meta: { error }, lang, defaultLang, langs, media, offset,
    } = this.props;
    const label = this.props.label || i18n.t('BASE_MODULE.addCategory').toUpperCase();

    return (
      <div style={{ marginLeft: offset }} className="c-bb-form__block">
        <div className="c-bb-form__add-field" onClick={() => fields.push()}>
          <Icon name="add" small /> {label}
        </div>
        <div>
          <SortableCategoriesContainer
            useDragHandle
            helperClass="c-bb-form__group__sortable-helper"
            lockAxis="y"
            fields={fields}
            lang={lang}
            langs={langs}
            media={media}
            defaultLang={defaultLang}
            draggedNodeId={this.state.draggedNodeId}
            onSortEnd={this.handleSortEnd}
            onSortStart={this.handleSortStart}
            handleMedia={this.handleMedia}
            openMediaManager={this.props.openMediaManager}
            activeCategory={this.props.activeCategory}
          />
        </div>
        {error && <div className="error">{renderError(error)}</div>}
      </div>
    );
  }
}

RenderCategoriesFields.propTypes = propTypes;
RenderCategoriesFields.defaultProps = defaultProps;

const mapStateToProps = state => ({
  activeCategory: state.baseModule.currentCategory,
  media: selectAllMediaItems(state),
});

const mapDispatchToProps = {
  change,
  openMediaManager,
  clearCategory,
};

const ConnectedRenderCategoriesFields = withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(RenderCategoriesFields),
);

export default ConnectedRenderCategoriesFields;
