import { CONTENT_SCHEMA } from '../api/schema';
import {
  ROUTE_PAGE_HTML_CONTENT,
  ROUTE_PAGE_CONTENT,
} from '../api/routes';
import { buildRoute, CALL_BB_API } from '../api';

export const CONTENT_REQUEST = 'CONTENT_REQUEST';
export const CONTENT_SUCCESS = 'CONTENT_SUCCESS';
export const CONTENT_FAILURE = 'CONTENT_FAILURE';

// BrowsBox API middleware.
const fetchContent = (id) => {
  const endpoint = buildRoute(ROUTE_PAGE_CONTENT, { id });
  return {
    [CALL_BB_API]: {
      types: [CONTENT_REQUEST, CONTENT_SUCCESS, CONTENT_FAILURE],
      endpoint,
      method: 'GET',
      schema: CONTENT_SCHEMA,
    },
  };
};

// Redux Thunk middleware.
export const loadContent = pageId => dispatch => dispatch(fetchContent(pageId));

export default loadContent;

// Close all content related modals ( mediaPicker, mediaManger, layout, ... )
export const CLOSE_CONTENT_MODALS = 'CLOSE_CONTENT_MODALS';
export const closeContentModals = () => ({ type: CLOSE_CONTENT_MODALS });

export const PAGE_HTML_CONTENT_REQUEST = 'PAGE_HTML_CONTENT_REQUEST';
export const PAGE_HTML_CONTENT_SUCCESS = 'PAGE_HTML_CONTENT_SUCCESS';
export const PAGE_HTML_CONTENT_FAILURE = 'PAGE_HTML_CONTENT_FAILURE';

// BrowsBox API middleware.
const fetchPageHtmlContent = (id) => {
  const endpoint = buildRoute(ROUTE_PAGE_HTML_CONTENT, { id });
  return {
    [CALL_BB_API]: {
      types: [PAGE_HTML_CONTENT_REQUEST, PAGE_HTML_CONTENT_SUCCESS, PAGE_HTML_CONTENT_FAILURE],
      endpoint,
      method: 'GET',
    },
  };
};

// Redux Thunk middleware.
export const loadPageHtmlContent = pageId => dispatch => dispatch(fetchPageHtmlContent(pageId));

export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';
export function setActiveSection(sectionId) {
  return {
    type: SET_ACTIVE_SECTION,
    value: sectionId,
  };
}
