/* eslint-disable react/prop-types,react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import ToggleableField from './ToggleableField';
import { renderError } from '../../tools/validation';
import Icon from '../Icon/Icon';

const CopiedFeedback = withNamespaces()(({ active, t }) => (
  <span className={classNames(
    'c-copied-feedback',
    { 'c-copied-feedback--active': active },
  )}
  >
    <Icon name="check" medium />
    {t('BASE_MODULE.copiedToClipboard')}
  </span>
));

const TextField = ({
  className,
  input,
  meta: { touched, error },
  disabled,
  disabledValue,
  component: Component = 'input',
  extraProps = {},
  maxLength,
}) => {
  const inputRef = React.useRef();
  const [copied, setCopied] = React.useState(false);
  const currentLength = input.value ? input.value.length : 0;

  React.useEffect(() => {
    let timer = null;

    if (copied) {
      timer = setTimeout(() => setCopied(false), 3000);
    }

    return () => timer && clearTimeout(timer);
  }, [copied, setCopied]);

  const handleCopy = () => {
    if (!inputRef.current) {
      return;
    }

    const { value } = inputRef.current;
    const tmp = document.createElement('input');
    tmp.value = value;
    document.body.appendChild(tmp);
    tmp.select();
    document.execCommand('copy');
    tmp.remove();
    setCopied(true);
  };

  return (
    <div
      className={classNames([
        'c-bb-form__input',
        className,
        { 'c-bb-form__input--has-error': touched && error },
        { 'c-bb-form__input--has-max-length': !disabled && !!maxLength },
      ])}
    >
      <Component
        ref={inputRef}
        {...input}
        value={(disabled && disabledValue) ? disabledValue : (input.value || '')}
        {...extraProps}
        disabled={disabled}
      />
      {(!disabled && !!maxLength) && (
        <span
          className={classNames(
            'o-bb-form__input--info',
            { 'o-bb-form__input--info--warning': currentLength > maxLength },
          )}
        >
          {currentLength}/{maxLength}
        </span>
      )}
      {disabled && (
        <button className="c-bb-form__input-copy" type="button" title="Copy" onClick={handleCopy}>
          <Icon name="clone" medium />
          <CopiedFeedback active={copied} />
        </button>
      )}
      {touched && error
      && <span className="c-bb-form__input-error">{renderError(error)}</span>}
    </div>
  );
};

export const SeoTitle = props => (
  <ToggleableField
    component={TextField}
    {...props}
  />
);

const SeoTextArea = React.forwardRef((props, ref) => (
  <textarea ref={ref} {...props} className="c-bb-form__textarea" rows={3} />
));

const TextAreaField = properties => (
  <TextField
    className="c-bb-form__seo-description"
    {...properties}
    extraProps={{
      value: (properties.disabled && properties.disabledValue)
        ? properties.disabledValue
        : (properties.input.value || ''),
    }}
    component={SeoTextArea}
  />
);

export const SeoDescription = props => (
  <ToggleableField
    component={TextAreaField}
    {...props}
  />
);

const UrlFieldInput = React.forwardRef(({ urlPrefix, disabledValue, ...props }, ref) => {
  const handlePrefixClick = () => {
    if (ref.current && !props.disabled) {
      ref.current.focus();
    }
  };

  return (
    <div className={classNames(
      'c-bb-input-url__wrapper',
      { 'c-bb-input-url__wrapper--disabled': props.disabled },
    )}
    >
      <div className="c-bb-input-url__prefix" onClick={handlePrefixClick}>
        {window?.location?.origin}{urlPrefix}{!props.disabled && '/'}
      </div>
      <input
        ref={ref}
        className="c-bb-input-url__input"
        type="text"
        {...props}
        value={props.disabled ? disabledValue : props.value}
      />
    </div>
  );
});

const SeoUrlField = properties => (
  <TextField
    {...properties}
    extraProps={{
      urlPrefix: properties.urlPrefix,
      disabledValue: properties.disabledValue,
    }}
    component={UrlFieldInput}
    className="c-bb-form__seo-description"
  />
);

export const SeoUrl = props => (
  <ToggleableField
    component={SeoUrlField}
    {...props}
  />
);
