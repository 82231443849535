import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import ReactModal2 from 'react-modal2';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import ModalContext from './ModalContext';
import { Gateway } from '../Gateway';

const propTypes = {
  backdropClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeOnEsc: PropTypes.bool,
  closeOnBackdropClick: PropTypes.bool,
  modalClassName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  backdropClassName: '',
  closeOnEsc: true,
  closeOnBackdropClick: true,
  modalClassName: '',
};

const modalIsOpenClass = 'o-bb-modal--is-open';
let modalNestedLevel = 0;

const Modal = ({
  backdropClassName,
  closeOnEsc,
  closeOnBackdropClick,
  children,
  modalClassName,
  onClose,
}) => {
  const timeout = 200;
  const [active, setActive] = useState(true);
  const timeoutRef = useRef(0);

  useEffect(() => {
    if (modalNestedLevel === 0) {
      document.body.classList.add(modalIsOpenClass);
    }
    modalNestedLevel += 1;

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      modalNestedLevel -= 1;
      if (modalNestedLevel === 0) {
        document.body.classList.remove(modalIsOpenClass);
      }
    };
  }, []);

  const handleClose = useCallback(() => {
    setActive(false);
    timeoutRef.current = setTimeout(() => {
      onClose();
    }, timeout);
  }, [setActive, onClose]);

  const contextValue = useMemo(() => ({
    closeModal: handleClose,
  }), [handleClose]);

  return (
    <Gateway into="modal">
      <ModalContext.Provider value={contextValue}>
        <CSSTransition appear in={active} timeout={timeout} classNames="modal-animation-default">
          <ReactModal2
            backdropClassName={classNames(backdropClassName, 'o-bb-backdrop')}
            closeOnEsc={closeOnEsc}
            closeOnBackdropClick={closeOnBackdropClick}
            modalClassName={classNames(modalClassName, 'o-bb-modal')}
            onClose={handleClose}
          >
            {children}
          </ReactModal2>
        </CSSTransition>
      </ModalContext.Provider>
    </Gateway>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

Modal.Context = ModalContext.Consumer;

export default Modal;
