import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getYoutubeEmbedUrl, isVideoUrl } from '@liswood-tache/browsbox-static';
import { FormInput } from '../FormElements';
import i18n from '../../internationalization/i18n';

const propTypes = {
  onChange: PropTypes.func.isRequired, // on video data change
  url: PropTypes.string.isRequired, // Media data
};

const defaultProps = {};
const inputStyles = {
  width: '80%',
};

class MediaPickerVideo extends Component {
  static onItemClick(evt) {
    evt.preventDefault();
  }

  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.state = {
      embedUrl: getYoutubeEmbedUrl(props.url),
      isValidUrl: props.url === '' ? true : isVideoUrl(props.url),
    };
  }

  componentDidUpdate(prevProps) {
    const { url } = this.props;

    if (prevProps.url !== url) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        embedUrl: isVideoUrl(url) ? getYoutubeEmbedUrl(url) : false,
        isValidUrl: url === '' ? true : isVideoUrl(url),
      });
    }
  }

  // Handle input element value change
  onInputChange(evt) {
    const { target: { name, value } } = evt;
    this.props.onChange(name, typeof value === 'string' ? value.trim() : value);
  }

  render() {
    const { url } = this.props;
    const { embedUrl, isValidUrl } = this.state;
    return (
      <div className="c-bb-video-picker">
        { (embedUrl && isValidUrl) && (
        <div className="c-bb-video-picker__placeholder">
          <iframe
            title="title"
            width="480"
            height="270"
            frameBorder="0"
            allowFullScreen="1"
            src={embedUrl}
          />
        </div>
        )}
        { (!embedUrl || !isValidUrl) && (
          <div className="c-bb-video-picker__placeholder c-bb-video-picker__placeholder--empty" />
        )}
        <FormInput
          id="media-picker-video"
          name="videoUrl"
          value={url}
          label={i18n.t('CONTENT.pasteVideoUrl')}
          onChange={this.onInputChange}
          style={inputStyles}
          autoFocus
        />
        { !isValidUrl && (
          <div className="o-bb-form-field--error o-bb-form-field--validation">{i18n.t('CONTENT.invalidVideoUrl')}</div>
        )}
      </div>
    );
  }
}

MediaPickerVideo.propTypes = propTypes;
MediaPickerVideo.defaultProps = defaultProps;

export default MediaPickerVideo;
