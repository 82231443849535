import {
  OPEN_PAGE_PROPERTIES,
  CLOSE_PAGE_PROPERTIES,
} from '../actions/pageProperties';
import {
  OPEN_DUPLICATE_PAGE,
  CLOSE_DUPLICATE_PAGE,
} from '../actions/pageDuplicate';
import {
  OPEN_NEW_PAGE,
  CLOSE_NEW_PAGE,
} from '../actions/pageNew';
import {
  EXPANDED_PAGE,
  EXPANDED_PAGES,
  COLLAPSE_PAGE,
  PAGE_SETTINGS,
  GET_CURRENT_PAGE_SUCCESS,
  PAGES_REORDER_REQUEST,
  PAGES_REORDER_SUCCESS,
  PAGES_REORDER_FAILURE,
} from '../actions/pages';

import {
  PAGE_HEADER_SUCCESS,
  PAGE_FOOTER_SUCCESS,
} from '../actions/pageHtml';

const initialState = {
  isPagePropsModalOpen: false,
  isDuplicatePageModalOpen: false,
  isNewPageModalOpen: false,
  duplicatePageId: false,
  expandedPages: [],
  header: '',
  footer: '',
  settings: {
    pageid: null,
  },
  currentPage: null,
  isPagesReorderPending: false,
};

const pages = (state = initialState, action) => {
  const newDialogState = {
    ...state,
    isPagePropsModalOpen: false,
    isDuplicatePageModalOpen: false,
    isNewPageModalOpen: false,
    duplicatePageId: false,
  };

  switch (action.type) {
    case CLOSE_NEW_PAGE:
    case CLOSE_DUPLICATE_PAGE:
    case CLOSE_PAGE_PROPERTIES:
      return newDialogState;
    case OPEN_PAGE_PROPERTIES:
      newDialogState.isPagePropsModalOpen = true;
      return newDialogState;
    case OPEN_DUPLICATE_PAGE:
      newDialogState.isDuplicatePageModalOpen = true;
      newDialogState.duplicatePageId = action.id;
      return newDialogState;
    case OPEN_NEW_PAGE:
      newDialogState.isNewPageModalOpen = true;
      return newDialogState;
    case COLLAPSE_PAGE: {
      const expandedPages = state.expandedPages.slice();
      const idx = expandedPages.indexOf(action.id);
      if (idx === -1) { return state; }
      expandedPages.splice(idx, 1);
      return {
        ...state,
        expandedPages,
      };
    }
    case EXPANDED_PAGE: {
      const expandedPages = state.expandedPages.slice();
      const idx = expandedPages.indexOf(action.id);
      if (idx !== -1) { return state; }
      expandedPages.push(action.id);
      return {
        ...state,
        expandedPages,
      };
    }
    case EXPANDED_PAGES: {
      const expandedPages = action.value;
      return {
        ...state,
        expandedPages,
      };
    }
    case PAGE_SETTINGS: {
      const settings = action.value;
      return {
        ...state,
        settings,
      };
    }
    case PAGE_HEADER_SUCCESS: {
      const { response: header } = action;
      if (header) {
        return {
          ...state,
          header,
        };
      }
      return state;
    }
    case PAGE_FOOTER_SUCCESS: {
      const { response: footer } = action;
      if (footer) {
        return {
          ...state,
          footer,
        };
      }
      return state;
    }
    case GET_CURRENT_PAGE_SUCCESS: {
      return {
        ...state,
        currentPage: action.response,
      };
    }
    case PAGES_REORDER_REQUEST: {
      return {
        ...state,
        isPagesReorderPending: true,
      };
    }
    case PAGES_REORDER_SUCCESS: {
      return {
        ...state,
        isPagesReorderPending: false,
      };
    }
    case PAGES_REORDER_FAILURE: {
      return {
        ...state,
        isPagesReorderPending: false,
      };
    }
    default:
      return state;
  }
};

export default pages;
