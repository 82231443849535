import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../Icon/Icon';

const Pagination = ({
  page = 1, total, perPage, onChange,
}) => {
  const maxPages = 5;
  const tolerance = Math.ceil(maxPages / 2);
  const totalPages = React.useMemo(() => Math.ceil(total / perPage), [total, perPage]);
  const totalPagesArray = Array.from(new Array(totalPages).keys());
  const offset = Math.max(Math.min(page - tolerance, totalPages - maxPages), 0);
  const limit = offset + maxPages;
  const currentPages = totalPagesArray.slice(offset, limit);
  const isLastPage = page === totalPages;

  const handleChange = (nextPage) => {
    onChange(nextPage);
  };

  return (
    <ul className="c-bb-pagination">
      <li className="c-bb-pagination__item">
        <button
          type="button"
          className="c-bb-pagination__button"
          disabled={page === 1}
          onClick={() => handleChange(1)}
        >
          <Icon name="first-page" />
        </button>
      </li>
      <li className="c-bb-pagination__item">
        <button
          type="button"
          className="c-bb-pagination__button"
          disabled={page === 1}
          onClick={() => handleChange(page - 1)}
        >
          <Icon name="prev" />
        </button>
      </li>
      {currentPages.map(key => (
        <li className="c-bb-pagination__item" key={key}>
          <button
            type="button"
            className={classNames(
              'c-bb-pagination__button',
              { 'c-bb-pagination__button--selected': page === key + 1 },
            )}
            onClick={() => handleChange(key + 1)}
          >
            {key + 1}
          </button>
        </li>
      ))}
      <li className="c-bb-pagination__item">
        <button
          type="button"
          className="c-bb-pagination__button"
          disabled={isLastPage}
          onClick={() => handleChange(page + 1)}
        >
          <Icon name="next" />
        </button>
      </li>
      <li className="c-bb-pagination__item">
        <button
          type="button"
          className="c-bb-pagination__button"
          disabled={isLastPage}
          onClick={() => handleChange(totalPages)}
        >
          <Icon name="last-page" />
        </button>
      </li>
    </ul>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
