/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

function copyStyles(sourceDoc, targetDoc) {
  const styles = Array.from(sourceDoc.head.children).filter(node => ['style', 'script', 'link'].includes(node.tagName.toLowerCase()));

  styles.forEach((style) => {
    targetDoc.head.appendChild(style.cloneNode(true));
  });
}

function copySvgSprite(sourceDoc, targetDoc) {
  const sprites = Array.from(document.querySelectorAll('body > svg'));

  sprites.forEach((sprite) => {
    targetDoc.body.appendChild(sprite.cloneNode(true));
  });
}

class ExternalWindowPortal extends React.Component {
  static propTypes = {
    closeWindowPortal: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
  };

  static defaultProps = {
    children: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      window: null,
      element: null,
    };
  }

  componentDidMount() {
    const width = window.outerWidth;
    const height = window.outerHeight * 0.9;
    const top = (window.outerHeight - height) * 0.5;
    const win = window.open('', '', `width=${width},height=${height},left=0,top=${top}`);
    const element = win.document.createElement('div');
    win.document.body.appendChild(element);
    copyStyles(document, win.document);
    copySvgSprite(document, win.document);

    win.addEventListener('beforeunload', () => {
      this.props.closeWindowPortal();
    });

    // Some libraries delegates events to the document
    // to make them work in new window
    // we need to override listeners management
    // and attach listeners to the document which belongs to new window.
    this.originalAddEventListener = document.addEventListener;
    this.originalRemoveEventListener = document.removeEventListener;
    document.addEventListener = this.addEventListenerDecorator;
    document.removeEventListener = this.removeEventListenerDecorator;

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      window: win,
      element,
    });
  }

  componentWillUnmount() {
    if (this.state.window) {
      this.state.window.close();
    }

    // Now we can restore the default behaviour.
    // We'll remove methods which was overridden
    // so original methods will be called from the document's prototype.
    delete document.addEventListener;
    delete document.removeEventListener;
  }

  addEventListenerDecorator = (type, listener, options) => {
    this.state.window.document.addEventListener(type, listener, options);
    this.originalAddEventListener.call(document, type, listener, options);
  };

  removeEventListenerDecorator = (type, listener, options) => {
    this.state.window.document.removeEventListener(type, listener, options);
    this.originalRemoveEventListener.call(document, type, listener, options);
  };

  render() {
    const { element } = this.state;

    if (!element) {
      return null;
    }

    return ReactDOM.createPortal(
      this.props.children,
      element,
    );
  }
}

export default ExternalWindowPortal;
