import PropTypes from 'prop-types';

export const pagePropsPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pagetitle: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  in_editor: PropTypes.bool.isRequired,
  // used by 'add page' or 'duplicate page' modal
  duplicateId: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
});

export const pagePropsDefaultProps = {
  description: '',
  keywords: '',
  name: '',
  pagetitle: '',
  published: true,
  publishable: true,
  title: '',
  visible: true,
  in_editor: false,
};

export const pageItemPropType = PropTypes.shape({
  children: PropTypes.array,
  id: PropTypes.number.isRequired,
  route: PropTypes.string,
  title: PropTypes.string,
  restricted: PropTypes.bool,
  published: PropTypes.bool,
  deletable: PropTypes.bool,
  publishable: PropTypes.bool,
  copiable: PropTypes.bool,
});

export const pageTypePropType = PropTypes.shape({
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
});
