import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../Icon/Icon';

const propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  icon: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  underline: PropTypes.bool,
  noPadding: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  className: '',
  text: '',
  icon: '',
  type: 'button',
  onClick: () => {},
  primary: false,
  secondary: false,
  underline: false,
  noPadding: false,
  children: undefined,
};

class BbButton extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(evt) {
    this.props.onClick(evt);
  }

  render() {
    const {
      className,
      text,
      icon,
      primary,
      type,
      secondary,
      underline,
      children,
      noPadding,
      ...attributes
    } = this.props;

    const classes = classNames(
      className,
      'c-bb-button',
      { 'c-bb-button--primary': primary },
      { 'c-bb-button--secondary': !primary && secondary },
      { 'c-bb-button--underline': underline },
      { 'c-bb-button--no-padding': noPadding },
    );

    return (
      // eslint-disable-next-line react/button-has-type
      <button type={type} className={classes} onClick={this.onClick} {...attributes}>
        {icon
          && <Icon name={icon} medium />}
        { text || children }
      </button>
    );
  }
}

BbButton.propTypes = propTypes;
BbButton.defaultProps = defaultProps;

export default BbButton;
