import React from 'react';

export const useSyncTablesColumnsWidth = (sourceRef, destRef) => {
  React.useLayoutEffect(() => {
    const source = sourceRef.current;
    const dest = destRef.current;

    if (!source) {
      return;
    }

    const widths = Array.from(source.firstChild.children).map(child => child.clientWidth);
    Array.from(dest.firstChild.children).forEach((child, index) => {
      // eslint-disable-next-line no-param-reassign
      child.style.width = `${widths[index]}px`;
    });
  });
};

export default useSyncTablesColumnsWidth;
