import React from 'react';

const PageContext = React.createContext({
  redirect: () => {
    throw new Error('Redirect method has not been provided');
  },
});

export function usePageContext() {
  return React.useContext(PageContext);
}

export default PageContext;
