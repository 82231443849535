import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageContext from './PageContext';
import ConfirmationModal from '../Modals/ConfirmationModal';
import i18n from '../../internationalization/i18n';

const redirectToUrl = (url) => {
  window.location.href = url;
};

const isInsideEditor = (element) => {
  let node = element;

  // eslint-disable-next-line no-cond-assign
  while ((node = node.parentNode) !== null) {
    if (node === document.body) {
      break;
    }

    if (node && node.getAttribute('contenteditable')) {
      return true;
    }
  }

  return false;
};

const onWindowUnload = (e) => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  e.preventDefault();
  e.returnValue = '';
};

const RedirectWithWarningProvider = ({ isModified, children }) => {
  const [url, setUrl] = React.useState(null);

  const onDocumentClick = React.useCallback((event) => {
    const maxDepth = 100;
    let depth = 0;
    let node = event.target;
    // eslint-disable-next-line no-constant-condition
    do {
      if (typeof node.matches === 'function' && node.matches('a') && node.getAttribute('href')) {
        const href = node.getAttribute('href');

        if (!href.startsWith('#') && isModified && !isInsideEditor(node)) {
          event.preventDefault();
          setUrl(href);
        }

        break;
      }

      node = node.parentNode;
      depth += 1;

      if (!node || (node === document.body)) {
        break;
      }
    } while (depth <= maxDepth);
  }, [setUrl, isModified]);

  const redirect = React.useCallback((pathOrUrl) => {
    if (isModified) {
      setUrl(pathOrUrl);
      return;
    }

    redirectToUrl(pathOrUrl);
  }, [setUrl, isModified]);

  React.useEffect(() => {
    document.addEventListener('click', onDocumentClick);

    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [onDocumentClick]);

  React.useEffect(() => {
    if (isModified) {
      window.addEventListener('beforeunload', onWindowUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', onWindowUnload);
    };
  }, [isModified]);

  const value = React.useMemo(() => ({ redirect }), [redirect]);

  const navigateWithoutWarning = (redirectUrl) => {
    // user already confirmed to leave the page, no need to show additional native warning
    window.removeEventListener('beforeunload', onWindowUnload);
    redirectToUrl(redirectUrl);
  };

  return (
    <PageContext.Provider value={value}>
      {children}
      {url && (
        <ConfirmationModal
          icon="icon-basic_elaboration_document_refresh"
          onCancel={() => setUrl(null)}
          onClose={() => setUrl(null)}
          onOk={() => { navigateWithoutWarning(url); }}
          title={i18n.t('CONTENT.refreshPageModalTitle')}
          submitButtonText={i18n.t('CONTENT.continue')}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: i18n.t('CONTENT.refreshPageModalQuestion') }} />
        </ConfirmationModal>
      )}
    </PageContext.Provider>
  );
};

RedirectWithWarningProvider.propTypes = {
  isModified: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => {
  const {
    content: {
      isModified,
    },
  } = state;

  return {
    isModified,
  };
};

export default connect(mapStateToProps)(RedirectWithWarningProvider);
