/* eslint-disable import/prefer-default-export */

export const BUTTON_MODE = {
  NO_BUTTON: 'no_button',
  BUTTON_PER_COLUMN: 'button_per_column',
  BUTTON_PER_LAYOUT: 'button_per_layout',
};

export const MIN_RATIO = 0.2;
export const MAX_RATIO = 2.0;
export const DEFAULT_RATIO = 0.75;
export const IMAGE_TYPE_REGULAR = 'regular';
export const IMAGE_TYPE_ROUNDED = 'rounded';
export const MIN_ROUNDED_HEIGHT = 80;
