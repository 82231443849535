import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { renderError } from '../../tools/validation';

const propTypes = {
  index: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  langs: PropTypes.array.isRequired,
};

const defaultProps = {
  label: null,
  className: '',
};

const TextField = ({
  inputName, fieldName, index, label, langs, className, ...props
}) => {
  const input = props[inputName][index][fieldName];
  return (
    <div className={`c-bb-form__input-wrapper ${className}`}>
      {langs.map(lng => (
        <div
          key={lng.code}
          className={classNames([
            'c-bb-form__input',
            { 'c-bb-form__input--has-error': input[lng.code].meta.touched && input[lng.code].meta.error },
          ])}
        >
          <label htmlFor={input[lng.code].input.name}>{lng.code.toUpperCase()}</label>
          <input
            onChange={input[lng.code].input.onChange}
            value={input[lng.code].input.value}
            name={input[lng.code].input.name}
            type="text"
          />
          {input[lng.code].meta.touched && input[lng.code].meta.error
          && <span className="c-bb-form__input-error">{renderError(input[lng.code].meta.error)}</span>}
        </div>
      ))}
    </div>
  );
};

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;

export default withNamespaces()(TextField);
