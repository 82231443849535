/* eslint-disable import/prefer-default-export */
import isEmail from 'validator/lib/isEmail';
import isUrl from 'validator/lib/isURL';
import { LINK_TYPE_EXTERNAL, LINK_TYPE_EMAIL } from '@liswood-tache/browsbox-static';
import i18n from '../../internationalization/i18n';

export const validate = (link) => {
  const { type, value } = link;
  // validate email and external url
  if (type === LINK_TYPE_EXTERNAL && !isUrl(value)) {
    return i18n.t('CONTENT.invalidUrl');
  } if (type === LINK_TYPE_EMAIL && !isEmail(value)) {
    return i18n.t('CONTENT.invalidEmail');
  }
  return true;
};
