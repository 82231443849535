/**
  // usage:

  $(document).on('appAction', function(event, type, value) {
    console.group(type);
    console.log('type', type);
    console.info('value', value && value.id, value && value.type);
    console.groupEnd(type);
  });

  // On drop of new slider
  $(document).on('appAction', function(event, type, value) {
    if (type === 'ADD_CONTENT' && value && value.type === 'slider') {
        var sliderSection = document.getElementById(value.id);
        console.log(sliderSection);
    }
  });

*/
const jqueryCustomEvents = () => next => (action) => {
  const { type, value } = action;
  if (window.jQuery) {
    window.requestAnimationFrame(() => {
      window.jQuery(window.document).trigger('appAction', [type, value]);
    });
  }
  return next(action);
};

export default jqueryCustomEvents;
