/**
 * Creates encoded string of cropper settings to store in database in feature use
 *
 * @param settings
 * @returns {string|null}
 */
function encodeCropperData(settings) {
  try {
    return btoa(JSON.stringify(settings));
  } catch (e) {
    return null;
  }
}

/**
 * Transforms stored settings string into settings object
 *
 * @param settings
 * @returns {null|any}
 */
function decodeCropperData(settings) {
  try {
    return JSON.parse(atob(settings));
  } catch (e) {
    return null;
  }
}

export {
  decodeCropperData,
  encodeCropperData,
};
