import { CALL_BB_API } from '../api';
import { ROUTE_PAGE_HEADER, ROUTE_PAGE_FOOTER } from '../api/routes';

export const PAGE_HEADER_REQUEST = 'PAGE_HEADER_REQUEST';
export const PAGE_HEADER_SUCCESS = 'PAGE_HEADER_SUCCESS';
export const PAGE_HEADER_FAILURE = 'PAGE_HEADER_FAILURE';

// BrowsBox API middleware.
const getPageHeaderCall = () => ({
  [CALL_BB_API]: {
    types: [PAGE_HEADER_REQUEST, PAGE_HEADER_SUCCESS, PAGE_HEADER_FAILURE],
    endpoint: ROUTE_PAGE_HEADER,
    method: 'GET',
  },
});

export const PAGE_FOOTER_REQUEST = 'PAGE_FOOTER_REQUEST';
export const PAGE_FOOTER_SUCCESS = 'PAGE_FOOTER_SUCCESS';
export const PAGE_FOOTER_FAILURE = 'PAGE_FOOTER_FAILURE';

const getPageFooterCall = () => ({
  [CALL_BB_API]: {
    types: [PAGE_FOOTER_REQUEST, PAGE_FOOTER_SUCCESS, PAGE_FOOTER_FAILURE],
    endpoint: ROUTE_PAGE_FOOTER,
    method: 'GET',
  },
});

// Redux Thunk middleware.
export const getPageHeader = () => dispatch => dispatch(getPageHeaderCall());
export const getPageFooter = () => dispatch => dispatch(getPageFooterCall());
