import React from 'react';
import PropTypes from 'prop-types';

const UsersContent = ({ children }) => (
  <div className="c-users-view__content">
    {children}
  </div>
);

UsersContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UsersContent;
