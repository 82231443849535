import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
// URLSearchParams polyfill
import 'url-search-params-polyfill';
import { composeWithDevTools } from 'redux-devtools-extension';
import { api } from '../api';
import sagas from '../sagas';
import jqueryCustomEvents from './jqueryCustomEvents';
import reducer from '../reducers';
import { persistExpandedPages, persistResponsiveView } from './persistToSessionStorage';
import pageSettings from './pageSettings';
import responsiveView from './responsiveView';
import { initInternationalization } from '../internationalization/i18n';
import isDebug from '../tools/is-debug';

const isDev = isDebug();

const defaultState = {
  activeMenuItem: '',
  activeModuleItem: '',
};

const preloadedState = {

  ...defaultState,
};

const loggerConfig = {
  collapsed: true,
};

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  thunk, api, jqueryCustomEvents, sagaMiddleware,
];

if (isDev) {
  middlewares.push(createLogger(loggerConfig));
}

const store = createStore(
  reducer,
  preloadedState,
  composeWithDevTools(
    applyMiddleware(...middlewares),
  ),
);
sagaMiddleware.run(sagas);

const getStore = () => store;
// Add expanded pages from local storage to the store, watch for changes to store
// and write to local storage.
persistExpandedPages(store);
// Get page settings from script tag within html
pageSettings(store);
// Set responsive preview on page load ( set and used by PreviewContainer iframe )
responsiveView(store);
// Set responsive view for 'main window' ( 'desktop preview' or 'default view' )
persistResponsiveView(store);
// get the Browsbox languages
initInternationalization(store);

export default getStore;
