import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { renderError } from '../../tools/validation';

const propTypes = {
  addClassName: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  max: PropTypes.number,
  meta: PropTypes.object.isRequired,
  min: PropTypes.number,
  step: PropTypes.number,
  type: PropTypes.string,
};

const defaultProps = {
  addClassName: '',
  disabled: false,
  label: null,
  max: null,
  min: null,
  step: null,
  type: 'number',
};

const NumberField = ({
  input,
  label,
  meta: { touched, error },
  addClassName,
  disabled,
  type,
  min,
  max,
  step,
}) => (
  <div
    className={classNames([
      'c-bb-form__input',
      addClassName,
      { 'c-bb-form__input--has-error': touched && error },
    ])}
  >
    {label && <label htmlFor="title">{label}</label>}
    <input {...input} type={type} disabled={disabled} min={min} max={max} step={step} />
    {touched && error
      && <span className="c-bb-form__input-error">{renderError(error)}</span>}
  </div>
);

NumberField.propTypes = propTypes;
NumberField.defaultProps = defaultProps;

export default withNamespaces()(NumberField);
