import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { renderError } from '../../tools/validation';
import Icon from '../Icon/Icon';

const propTypes = {
  input: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
};

const defaultProps = {
  label: null,
  meta: {},
};

const CategoriesCheckboxField = ({
  input, label, meta: { touched, error }, categories,
}) => {
  const handleCategoryChange = (categoryId) => {
    if (input.value.includes(categoryId)) {
      input.onChange(input.value.filter(value => value !== categoryId));
    } else {
      input.onChange([...input.value, categoryId]);
    }
  };

  const isChecked = id => input.value.includes(id);

  const renderComposeCategories = function renderComposeCategories(ctgs) {
    return ctgs.map(ctg => (
      <div key={`${ctg.id}-${ctg.title || ctg.label}-checkbox`} style={{ marginLeft: '10px' }}>
        { !!ctg.children && !!ctg.children.length && <div style={{ padding: '10px' }}>{ctg.title.toUpperCase()}</div> }
        { !!ctg.children && !!ctg.children.length && renderComposeCategories(ctg.children) }
        { !!ctg.children && !ctg.children.length
          && (
          <div key={ctg.id} className="c-bb-form__checkbox">
            <input
              id={`${ctg.id}-${ctg.title || ctg.label}-checkbox`}
              name={`${ctg.id}-${ctg.title || ctg.label}-checkbox`}
              type="checkbox"
              checked={isChecked(ctg.id)}
              onChange={() => handleCategoryChange(ctg.id)}
            />
            {isChecked(ctg.id) && (
            <Icon className="checked-icon" name="check" />
            )}
            <label htmlFor={`${ctg.id}-${ctg.title || ctg.label}-checkbox`}>
              {ctg.title || ctg.label}
            </label>
          </div>
          )}
      </div>
    ));
  };

  return (
    <div className="c-bb-form__input-item-group  c-bb-form__input-item-group--filters">
      <div className="input-item-group__label">{ label }</div>
      <div className="input-item-group">
        {renderComposeCategories(categories)}
      </div>
      {touched && error
      && <span className="input-item-group__error c-bb-form__input-error">{renderError(error)}</span>}
    </div>
  );
};

CategoriesCheckboxField.propTypes = propTypes;
CategoriesCheckboxField.defaultProps = defaultProps;

export default withNamespaces()(CategoriesCheckboxField);
