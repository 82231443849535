import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const CircleLoader = ({ width, height, small }) => (
  <div className={classNames(
    'c-bb-uploader-progress-circle',
    { 'c-bb-uploader-progress-circle--small': small },
  )}
  >
    <div
      className="c-bb-uploader-progress-circle--pending"
      style={{
        width,
        height,
      }}
    />
  </div>
);

CircleLoader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  small: PropTypes.bool,
};

CircleLoader.defaultProps = {
  width: 56,
  height: 56,
  small: false,
};

export default CircleLoader;
