/* eslint-disable react/forbid-prop-types */
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../Modals/ConfirmationModal';
import i18n from '../../../../internationalization/i18n';
import {
  readBaseModuleItem,
  cloneBaseModuleItemAction,
  removeBaseModuleItem,
  togglePublishBaseModuleItem,
} from '../../../../actions/baseModuleItems';
import MoveItemModal from './MoveItemModal';

const AbstractItemView = (props) => {
  const {
    item,
    parentId,
    component: Component,
    readItem,
    deleteItem,
    togglePublish,
    cloneItem,
    ...rest
  } = props;
  const history = useHistory();
  const { name } = useParams();
  const [deletingId, setDeletingId] = React.useState(null);
  const [movingId, setMovingId] = React.useState(null);
  const previewRef = useRef(null);

  const handleDelete = async (itemId) => {
    await deleteItem({ id: itemId });
    setDeletingId(null);

    if (parentId) {
      await readItem(parentId);
    }
  };

  const extraProps = React.useMemo(() => {
    const {
      route: {
        route: previewPath = null,
      },
    } = item;

    const onRead = () => {
      readItem(item.id);
    };

    const onEdit = () => {
      history.push(`/item/edit/${name}/${item.id}`);
    };

    const onPreview = previewPath ? () => {
      if (previewRef.current && !previewRef.current.closed) {
        previewRef.current.location.href = previewPath;
        previewRef.current.focus();
      } else {
        previewRef.current = window.open(previewPath, '_blank');
      }
    } : undefined;

    const onDelete = () => {
      setDeletingId(item.id);
    };

    const onPublish = async (selectedItem) => {
      await togglePublish(selectedItem.id, selectedItem.route.id, selectedItem.route.published);
      if (parentId) {
        await readItem(parentId);
      }
    };

    const onMove = () => {
      setMovingId(item.id);
    };

    const onClone = async (language) => {
      await cloneItem({ id: item.id, language });
      await readItem(item.id);
    };

    return {
      onRead,
      onEdit,
      onDelete,
      onPublish,
      onPreview,
      onMove,
      onClone,
      item,
    };
  }, [item, setDeletingId]);

  return (
    <>
      <Component
        {...rest}
        {...extraProps}
      />
      {deletingId
        && (
        <ConfirmationModal
          onClose={() => setDeletingId(null)}
          onOk={() => handleDelete(deletingId)}
          onCancel={() => setDeletingId(null)}
          title={i18n.t('CONTENT.remove').toUpperCase()}
          submitButtonText={i18n.t('CONTENT.remove').toUpperCase()}
          icon="icon-basic_sheet"
        >
          <div style={{ textAlign: 'center' }}>
            {i18n.t('CONTENT.deleteConfirmation')}
          </div>
        </ConfirmationModal>
        )}
      {movingId && (
        <MoveItemModal id={item.id} onClose={() => setMovingId(null)} />
      )}
    </>
  );
};

AbstractItemView.propTypes = {
  parentId: PropTypes.number,
  item: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  readItem: PropTypes.func.isRequired,
  cloneItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  togglePublish: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
};

AbstractItemView.defaultProps = {
  parentId: null,
};

const mapDispatchToProps = {
  readItem: readBaseModuleItem,
  cloneItem: cloneBaseModuleItemAction,
  deleteItem: removeBaseModuleItem,
  togglePublish: togglePublishBaseModuleItem,
};

export default connect(null, mapDispatchToProps)(AbstractItemView);
