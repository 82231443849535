import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Icon from '../Icon/Icon';

const EmptyView = ({ onCreateFolderClick, onUploadMediaClick, t }) => (
  <div className="c-bb-media-manager__empty">
    <button
      type="button"
      className="c-bb-media-manager__empty__button"
      onClick={onCreateFolderClick}
    >
      <Icon name="folder-create" />
      <span className="c-bb-media-manager__empty__button__title">
        {t('MEDIA.emptyFolderCreate')}
      </span>
    </button>
    <button
      type="button"
      className="c-bb-media-manager__empty__button"
      onClick={onUploadMediaClick}
    >
      <Icon name="add" />
      <span className="c-bb-media-manager__empty__button__title">
        {t('MEDIA.emptyMediaCreate')}
      </span>
    </button>
  </div>
);

EmptyView.propTypes = {
  onCreateFolderClick: PropTypes.func.isRequired,
  onUploadMediaClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(EmptyView);
