import get from 'lodash/get';

function compareByValue(val1, val2) {
  if (val1 > val2) {
    return 1;
  }

  if (val1 < val2) {
    return -1;
  }

  return 0;
}

export function compareDates(a, b, field) {
  const valA = new Date(get(a, field, 0)).getTime();
  const valB = new Date(get(b, field, 0)).getTime();

  return compareByValue(valA, valB);
}

export function compareValues(a, b, field) {
  const valA = get(a, field, 0);
  const valB = get(b, field, 0);

  return compareByValue(valA, valB);
}
