import React from 'react';
import {
  reduxForm, Field, Fields, FieldArray,
} from 'redux-form';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import TextField from '../FormElements/TextField';
import LangField from '../FormElements/LangField';
import RenderContentFields from '../FormElements/RenderContentFields';
import RenderCategoriesFields from '../FormElements/RenderCategoriesFields';
import CheckboxField from '../FormElements/CheckboxField';
import validation from '../../tools/validation';
import i18n from '../../internationalization/i18n';
import ColumnsField from '../FormElements/ColumnsField';
import { BbButton, BbButtonGroup } from '../@browsbox-ui';

const validationRules = [validation.required, validation.maxLength(30)];

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  langs: PropTypes.array.isRequired,
  fieldTypes: PropTypes.array.isRequired,
  defaultLang: PropTypes.object.isRequired,
};

const BaseModuleEditForm = ({
  handleSubmit, lang, langs, fieldTypes, defaultLang, onCancel,
}) => (
  <form className="c-bb-form__container c-bb-form__base-module-entity-form" onSubmit={handleSubmit}>
    <div className="c-bb-form__block">
      <div className="c-bb-form__group">
        <Field
          name="hasDetailPages"
          component={CheckboxField}
          label={i18n.t('CONTENT.detailPagesEnabled')}
        />
      </div>
      <div className="c-bb-form__group">
        <Field
          name="sortingEnabled"
          component={CheckboxField}
          label={i18n.t('BASE_MODULE.itemsSortableViaDragAndDrop')}
        />
      </div>
      <div className="c-bb-form__group">
        <Field
          name="internalName"
          component={TextField}
          label={i18n.t('CONTENT.workName')}
          validate={validationRules}
        />
        <div className="c-bb-form__input" />
      </div>
      <div className="c-bb-form__group">
        <Fields
          className="o-base-module__labels"
          names={langs.map(item => `label_${item.code}`)}
          langs={langs}
          component={LangField}
          lang={lang}
          label={i18n.t('CONTENT.name')}
        />
      </div>
    </div>

    <FieldArray
      name="fields"
      lang={lang}
      langs={langs}
      defaultLang={defaultLang}
      fieldTypes={fieldTypes}
      component={RenderContentFields}
    />

    <FieldArray
      name="categories"
      lang={lang}
      langs={langs}
      defaultLang={defaultLang}
      component={RenderCategoriesFields}
    />

    <FieldArray
      name="columns"
      lang={lang}
      langs={langs}
      defaultLang={defaultLang}
      component={ColumnsField}
    />
    <div className="c-bb-base-module__default-seo">
      <h3 className="c-bb-base-module__default-seo__title">
        {i18n.t('BASE_MODULE.defaultSeoTemplates')}
      </h3>
      <p className="c-bb-base-module__help-text">
        The <strong>%variables%</strong> are the <strong>internal_names</strong> that you give a certain field,
        you can use any field you declared in the Entity.
      </p>
      <Field
        name="templateRouteTitle"
        component={TextField}
        label={i18n.t('BASE_MODULE.templateSeoTitle')}
        placeholder="Route template with %variables%"
      />
      <Field
        name="templatePageTitle"
        component={TextField}
        label={i18n.t('BASE_MODULE.templatePageTitle')}
        placeholder="Page title template with %variables%"
      />
      <Field
        name="templateMetaDescription"
        component={TextField}
        label={i18n.t('BASE_MODULE.templateMetaDescription')}
        placeholder="Description template with %variables%"
      />
    </div>
    <div className="c-bb-form__footer">
      <BbButtonGroup>
        <BbButton
          text={i18n.t('CONTENT.save').toUpperCase()}
          className="c-bb-button c-bb-form__submit c-bb-button--primary "
          type="submit"
        />
        <BbButton
          onClick={onCancel}
          text={i18n.t('CONTENT.cancel').toUpperCase()}
          className="c-bb-button c-bb-button--secondary"
          type="button"
        />
      </BbButtonGroup>
    </div>
  </form>
);

BaseModuleEditForm.propTypes = propTypes;

const validateForm = (values, { langs }) => {
  const errors = {
    languages: [],
  };

  const messages = {
    required: 'required',
  };

  const isValidName = (name) => {
    if (!name) {
      return false;
    }

    return String(name).trim().length > 0;
  };

  langs.map((lang) => {
    const key = `label_${lang.code}`;
    const name = get(values, key);

    if (!isValidName(name)) {
      errors.languages.push(lang.code);
      errors[key] = messages.required;
    }

    return lang;
  });

  const internalNames = [];
  errors.fields = Array.from(values.fields || []).map((field) => {
    const fieldErrors = { label: {} };

    if (field && field.internalName) {
      if (internalNames.includes(field.internalName)) {
        fieldErrors.internalName = 'unique';
      }
      internalNames.push(field.internalName);
    }

    if (!field || !field.label || Object.keys(field.label).length < langs.length) {
      langs.forEach((lang) => {
        const name = get(field, `label.${lang.code}`);
        if (!isValidName(name)) {
          errors.languages.push(lang.code);
          fieldErrors.label[lang.code] = messages.required;
        }
      });

      return fieldErrors;
    }

    Object.keys(field.label).forEach((langKey) => {
      const name = field.label[langKey];
      if (!isValidName(name)) {
        errors.languages.push(langKey);
        fieldErrors.label[langKey] = messages.required;
      }
    });

    return fieldErrors;
  });

  const validateCategory = (category) => {
    const categoryErrors = { title: {}, children: { categories: {} } };
    langs.forEach((lang) => {
      const name = get(category, `title.${lang.code}`);
      if (!isValidName(name)) {
        errors.languages.push(lang.code);
        categoryErrors.title[lang.code] = messages.required;
      }
    });

    categoryErrors.children.categories = Array.from(get(category, 'children.categories', [])).map(validateCategory);

    return categoryErrors;
  };

  errors.categories = Array.from(values.categories || []).map(validateCategory);
  errors.languages = uniq(errors.languages);

  return errors;
};

export default reduxForm({
  form: 'baseModuleForm',
  validate: validateForm,
})(withNamespaces()(BaseModuleEditForm));
