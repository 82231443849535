/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CONTENT_COMPONENT_TYPE from './types';
import { getYoutubeEmbedUrl } from '../utils/media';
import ContentComponentImageContainer from './ContentComponentImageContainer';

const propTypes = {
  classes: PropTypes.string,
  containerClassName: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  imageType: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
};

const defaultProps = {
  classes: '',
  containerClassName: '',
  type: CONTENT_COMPONENT_TYPE.image,
  imageType: '',
  url: '',
  imageWidth: null,
  imageHeight: null,
};

function ContentComponentVideo(props) {
  const {
    classes,
    containerClassName,
    type,
    imageType,
    url,
    imageWidth,
    imageHeight,
  } = props;
  const embedUrl = getYoutubeEmbedUrl(url);

  const componentClasses = classNames(
    classes,
    containerClassName,
    `c-${type}`,
  );

  return (
    <div className={componentClasses}>
      <ContentComponentImageContainer
        type={type}
        imageType={imageType}
        width={imageWidth}
        height={imageHeight}
      >
        <div className="c-image__iframe-wrapper">
          <iframe
            className="c-image__iframe"
            title="title"
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen="1"
            src={embedUrl}
          />
        </div>
      </ContentComponentImageContainer>
    </div>
  );
}

ContentComponentVideo.propTypes = propTypes;
ContentComponentVideo.defaultProps = defaultProps;

export default ContentComponentVideo;
