import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Editor from '../Editor/Editor';
import { renderError } from '../../tools/validation';

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
};

const defaultProps = {
  label: null,
  disabled: false,
  rows: 3,
};

const TextareaField = ({
  input: { value, onChange, name },
  label,
  meta: { touched, error },
}) => {
  const id = React.useMemo(() => `fixed-toolbar-${name.replace(/\./g, '-')}`, [name]);

  return (
    <div className="c-bb-form__input c-bb-form__input--longtext o-content" style={{ position: 'relative' }}>
      <div id={id} style={{ position: 'absolute', zIndex: 99999, transform: 'translateY(-10px)' }} />
      {label && <label htmlFor="title">{label}</label>}
      <div className="c-bb-form__textarea c-text c-bb-form__textarea_with-resize">
        <Editor
          content={value}
          onChange={onChange}
          shouldBroadCastUpdate={false}
          extraOptions={{
            fixed_toolbar_container: `#${id}`,
          }}
        />
      </div>
      {touched && error
      && <span className="c-bb-form__input-error">{renderError(error)}</span>}
    </div>
  );
};

TextareaField.propTypes = propTypes;
TextareaField.defaultProps = defaultProps;

export default withNamespaces()(TextareaField);
