import { SET_UI_OPTIONS } from '../actions/ui';

const initialState = {
  isCropping: false,
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    case SET_UI_OPTIONS: {
      const { options = {} } = action;

      return {
        ...state,
        ...options,
      };
    }
    default:
      return state;
  }
}
