import { useQuery } from 'react-query';
import axios from 'axios';

export default function useBaseModuleItemSearch({ language, entity }) {
  const params = {
    language,
    entity,
  };

  return useQuery(
    `base_module_search_entity_items_${entity}_${language || 'all'}`,
    async () => {
      const response = await axios('/api/base-module/items/search', { params });

      return response.data;
    },
  );
}
