import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { BbMenu, BbMenuItem } from '../../@browsbox-ui';
import Icon from '../../Icon/Icon';

const FolderContextMenu = ({
  folderId, onClose, onRename, onRemove, onMove, t,
}) => (
  <BbMenu
    className="c-bb-media-context-menu"
    key={folderId}
    onClick={() => onClose()}
  >
    <BbMenuItem
      left={<Icon name="edit" />}
      onClick={onRename}
    >
      {t('MEDIA.folderRename')}
    </BbMenuItem>
    <BbMenuItem
      left={<Icon name="move-to" />}
      onClick={onMove}
    >
      {t('MEDIA.moveTo')}
    </BbMenuItem>
    <BbMenuItem
      left={<Icon name="trash" />}
      onClick={onRemove}
    >
      {t('MEDIA.folderRemove')}
    </BbMenuItem>
  </BbMenu>
);

FolderContextMenu.propTypes = {
  folderId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(FolderContextMenu);
