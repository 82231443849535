import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { renderError } from '../../tools/validation';
import Icon from '../Icon/Icon';

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  label: null,
  className: undefined,
};

const CheckboxField = ({
  className, input, meta: { touched, error }, label,
}) => (
  <div className={classNames('c-bb-form__checkbox', className)}>
    <input
      {...input}
      id={input.name}
      type="checkbox"
      checked={input.value}
    />
    {input.value && (
      <Icon className="checked-icon" name="check" />
    )}
    {label && <label htmlFor={input.name}>{label}</label>}
    {touched && error
    && <span className="c-bb-form__input-error">{renderError(error)}</span>}
  </div>
);

CheckboxField.propTypes = propTypes;
CheckboxField.defaultProps = defaultProps;

export default withNamespaces()(CheckboxField);
