/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Modal from '../../../Modals/Modal';
import ModalFooter from '../../../Modals/ModalFooter';
import BbButton from '../../../@browsbox-ui/bb-button';
import i18n from '../../../../internationalization/i18n';
import ModalHeader from '../../../Modals/ModalHeader';
import ModalMain from '../../../Modals/ModalMain';
import BBSelect from '../../../@browsbox-ui/bb-select/BBSelect';
import useBaseModuleItemSearch from '../../../../hooks/baseModule/items';
import { selectCurrentModuleName } from '../../../../selectors/baseModule';
import Skeleton from '../../../Loader';
import { moveBaseModuleItem, readBaseModuleItem, refreshBaseModuleList } from '../../../../actions/baseModuleItems';
import { selectBaseModuleItemById } from '../../../../selectors';

const itemToParent = ({ id, language, title }) => ({
  id,
  title: `${title} - ${language.toUpperCase()}`,
  language,
});

const ParentItemSelect = ({
  currentItem,
  readItem,
  currentId,
  entity,
  value: initialValue = null,
  onChange,
}) => {
  const [value, setValue] = React.useState(initialValue);
  const { data = [], isLoading } = useBaseModuleItemSearch({ entity });

  React.useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  React.useEffect(() => {
    readItem(currentId);
  }, [readItem, currentId]);

  const parents = React.useMemo(() => data
    .reduce((prev, current) => {
      prev.push(itemToParent(current));

      Array.from(current.translations).forEach((item) => {
        prev.push(itemToParent({
          ...item,
          id: current.id,
        }));
      });

      return prev;
    }, [])
    .filter((item) => {
      if (item.id === currentId) {
        return false;
      }

      if (!currentItem) {
        return false;
      }

      if (item.id === currentItem.original_item_id) {
        return false;
      }

      return item.language !== currentItem.language;
    }), [data, currentItem, currentId]);

  const items = [
    ...parents,
  ];

  if (currentItem && currentItem.original_item_id) {
    items.unshift({ id: 0, title: i18n.t('BASE_MODULE.moveOutOfGroup') });
  }

  return (
    <div className={classNames(
      'c-bb-filter c-bb-filter--select',
      { 'is-loading': isLoading },
    )}
    >
      {isLoading && data.length === 0 ? (
        <Skeleton height="48px" />
      ) : (
        <BBSelect
          placeholder={i18n.t('BASE_MODULE.selectCorrespondingItem')}
          value={value}
          onChange={setValue}
          items={items}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  language: state.global.websiteSettings.defaultLanguage,
  entity: selectCurrentModuleName(state),
  currentItem: selectBaseModuleItemById(state, props.currentId),
});

const mapDispatch = {
  readItem: readBaseModuleItem,
};

const ConnectedPicker = connect(mapStateToProps, mapDispatch)(ParentItemSelect);

const MoveItemModal = ({
  id, onClose, moveItem, readItem, refreshList,
}) => {
  const [item, setItem] = React.useState(null);

  const handleMove = React.useCallback(async () => {
    onClose();
    const { response } = await moveItem({ id, original: item.id });
    if (response.previous) {
      readItem(response.previous);
    }

    if (response.current) {
      readItem(response.current);
    }

    refreshList();
  }, [id, item, onClose, moveItem]);

  return (
    <Modal onClose={onClose} modalClassName={classNames('o-bb-modal--move', 'o-bb-modal--extra-small')}>
      <ModalHeader icon="fas fa-layer-group" title={i18n.t('BASE_MODULE.groupWith')} />
      <ModalMain>
        <p className={classNames('o-bb-modal__introductory-text')}>
          {i18n.t('BASE_MODULE.groupWithExplanation')}
        </p>
        <ConnectedPicker currentId={id} onChange={setItem} />
      </ModalMain>
      <Modal.Context>
        {({ closeModal }) => (
          <ModalFooter>
            <BbButton type="button" className="c-bb-button--secondary" onClick={closeModal} text={i18n.t('CONTENT.cancel')} />
            <BbButton className="c-bb-button--primary" onClick={handleMove} text={i18n.t('BASE_MODULE.group')} disabled={!item} />
          </ModalFooter>
        )}
      </Modal.Context>
    </Modal>
  );
};

const mapDispatchToProps = {
  moveItem: moveBaseModuleItem,
  readItem: readBaseModuleItem,
  refreshList: refreshBaseModuleList,
};

export default connect(null, mapDispatchToProps)(MoveItemModal);
