/* eslint-disable */
if (window.tinymce) {
  //prevetn typing in div
  window.tinyMCE.PluginManager.add('preventTypeOutside', function(editor, url) {
    editor.on('keydown', function(e) {
      // not left/right arrows + delete keys
      if(!(e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 8 || e.keyCode == 46)) {
        const tagName = editor.selection.getNode().tagName;
        if(tagName === 'DIV') {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
      }
    });
  });
}
