/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { htmlDecode } from 'htmlencode';
import CONTENT_COMPONENT_TYPE from './types';

export const MAP_STYLES = {
  default: { value: '', title: 'standaard' },
  grayscale: { value: 'grayscale', title: 'zwart wit' },
};

const propTypes = {
  address: PropTypes.string,
  classes: PropTypes.string,
  containerClassName: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  mapStyle: PropTypes.string,
  mapZoom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  markerTitle: PropTypes.string,
  markerValue: PropTypes.string,
  type: PropTypes.string,
  hasStaticMap: PropTypes.bool,
};

const defaultProps = {
  address: '',
  classes: '',
  containerClassName: '',
  latitude: '50.8251460',
  longitude: '4.3110560',
  mapStyle: MAP_STYLES.DEFAULT,
  mapZoom: 15,
  markerTitle: '',
  markerValue: '',
  type: CONTENT_COMPONENT_TYPE.googleMaps,
  hasStaticMap: false,
};

const stripTags = str => str.replace(/<\/?[^>]+(>|$)/g, '');

const ContentGoogleMaps = (props) => {
  const {
    address,
    classes,
    containerClassName,
    latitude,
    longitude,
    mapStyle,
    mapZoom,
    markerTitle: markerTitleEncoded,
    markerValue,
    type,
    hasStaticMap,
  } = props;

  const componentClasses = classNames(
    classes,
    containerClassName,
    `c-${type}`,
  );
  const markerTitle = htmlDecode(markerTitleEncoded);

  if (hasStaticMap) {
    const sizes = {
      phone: 600,
      tablet: 960,
      desktop: 1280,
    };

    const srcSet = Object.keys(sizes)
      .map((key) => {
        const url = `${props[`static_image_${key}`]} 1x, ${props[`static_image_${key}2x`]} 2x`;
        const constraint = key === 'desktop' ? 'min' : 'max';

        if (!url) {
          return null;
        }

        return {
          url,
          media: `(${constraint}-width: ${sizes[key]}px)`,
        };
      })
      .filter(item => item !== null);

    const firstItem = srcSet[0];
    const plainUrl = props.static_image_phone;

    return (
      <div className="c-googleMaps c-googleMaps--static">
        <a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/place/${address}`}>
          <picture>
            {srcSet.map(({ url, media }) => (
              <source key={url} media={media} srcSet={url} />
            ))}
            {firstItem && (
              <img src={plainUrl} srcSet={firstItem.url} alt={stripTags(markerTitle)} className={`c-googleMaps--style_${mapStyle ? 'grayscale' : 'normal'}`} />
            )}
          </picture>
        </a>
      </div>
    );
  }

  return (
    <div
      className={componentClasses}
      data-lat={latitude}
      data-lon={longitude}
      data-address={address}
      data-markervalue={markerValue}
      data-markertitle={markerTitle}
      data-map-style={mapStyle}
      data-zoom={mapZoom}
    />
  );
};

ContentGoogleMaps.propTypes = propTypes;
ContentGoogleMaps.defaultProps = defaultProps;

export default ContentGoogleMaps;
