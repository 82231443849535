import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-twilight';
import { htmlDecode, htmlEncode } from 'htmlencode';
import ExternalWindowPortal from '../ExternalWindowPortal/ExternalWindowPortal';
import Icon from '../Icon/Icon';
import i18n from '../../internationalization/i18n';
import { isIE, isEdge } from '../../tools/browsers';
import { Gateway } from '../Gateway';

const propTypes = {
  content: PropTypes.string,
  contentId: PropTypes.number,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  content: '',
  contentId: null,
  type: 'html',
};

class SourceEditor extends Component {
  constructor(props) {
    super(props);
    this.onUpdate = this.onUpdate.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onChange = this.onChange.bind(this);

    let { content } = props;

    if (this.props.type === 'html') {
      content = htmlDecode(props.content);
    }

    this.mounted = false;
    this.state = {
      editorContent: content,
      isChanged: false,
      isValid: false,
      isExternalWindowMode: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.addEventListener('beforeunload', this.closeExternalWindowMode);
  }

  componentDidUpdate(prevProps) {
    // If SourceEditor is reused to show content of another section reset the state
    let editorContent;

    if (this.props.contentId !== prevProps.contentId) {
      if (this.props.type === 'html') {
        editorContent = htmlDecode(this.props.content);
      } else {
        editorContent = this.props.content;
      }

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isChanged: false,
        isValid: false,
        editorContent,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.closeExternalWindowMode);
    this.mounted = false;
  }

  onChange(newContent) {
    this.setState({
      isChanged: true,
      editorContent: newContent,
    });
  }

  onClose(evt) {
    evt.preventDefault();
    this.props.onClose();
  }

  onUpdate(evt) {
    evt.preventDefault();
    const { contentId } = this.props;
    let newContent = this.state.editorContent;

    if (this.props.type === 'html') {
      newContent = htmlEncode(this.state.editorContent);
    }

    this.props.onChange(newContent, contentId);
  }

  onValidate(annotations) {
    // Unmounting the component will still trigger the ace onValidate event and
    // throw an error for setState. Check editorContent first.
    if (!this.mounted) {
      return;
    }

    const isValid = annotations.filter(annotation => annotation.type === 'error').length === 0;
    this.setState({ isValid });
  }

  enableExternalWindowMode = () => this.setState({
    isExternalWindowMode: true,
  });

  closeExternalWindowMode = () => this.setState({
    isExternalWindowMode: false,
  });

  // eslint-disable-next-line
  reloadPage = (evt) => {
    evt.preventDefault();
    this.closeExternalWindowMode();
    window.location.reload();
  };

  renderUpdateButton() {
    const {
      isValid,
      isChanged,
    } = this.state;
    const {
      type,
    } = this.props;
    let buttonText = i18n.t('CONTENT.updateHtml');

    if (type === 'css') {
      buttonText = i18n.t('CONTENT.saveCss');
    } else if (type === 'js') {
      buttonText = i18n.t('CONTENT.saveJs');
    }

    if (isValid && isChanged) {
      return (
        <button type="button" onClick={this.onUpdate} className="o-action-button">
          <Icon name="save" className="o-action-button__icon" />
          {buttonText}
        </button>
      );
    }
    return (
      <button type="button" disabled className="o-action-button o-action-button--disabled">
        <Icon name="save" className="o-action-button__icon" />
        {buttonText}
      </button>
    );
  }

  renderCloseButton() {
    return (
      <button type="button" onClick={this.onClose} className="o-action-button">
        <Icon name="cancel" className="o-action-button__icon" />
        {i18n.t('CONTENT.close')}
      </button>
    );
  }

  renderToggleViewButton() {
    if (isIE() || isEdge()) {
      return null; // external window is not supported yet for IE and Edge.
    }

    if (this.state.isExternalWindowMode) {
      return (
        <button type="button" onClick={this.closeExternalWindowMode} className="o-action-button">
          <Icon name="internal-window" className="o-action-button__icon" />
          {i18n.t('CONTENT.closeWindow')}
        </button>
      );
    }

    return (
      <button type="button" onClick={this.enableExternalWindowMode} className="o-action-button">
        <Icon name="external-window" className="o-action-button__icon" />
        {i18n.t('CONTENT.newWindow')}
      </button>
    );
  }

  renderToolbar() {
    let extraItems;

    if (this.props.type === 'js') {
      extraItems = (
        <li className="o-content-config-menu__item">
          <button type="button" onClick={this.reloadPage} className="o-action-button">
            <Icon name="refresh" className="o-action-button__icon" />
            {i18n.t('EDITOR.reloadPage')}
          </button>
          <span className="o-bb-source-editor__extra-info">(<b>{i18n.t('EDITOR.attention').toLowerCase()}</b>: {i18n.t('EDITOR.javascriptChangeWarning')})</span>
        </li>
      );
    }

    return (
      <ul className="o-content-config-menu">
        <li className="o-content-config-menu__item">{this.renderUpdateButton()}</li>
        <li className="o-content-config-menu__item">{this.renderCloseButton()}</li>
        { extraItems }
        <li className="o-content-config-menu__item o-content-config-menu__item--flex" />
        <li className="o-content-config-menu__item">{this.renderToggleViewButton()}</li>
      </ul>
    );
  }

  // Because of https://github.com/securingsincity/react-ace/issues/181
  // Changing state inside the ace onChange event will trigger a rerender and throw
  // errors in the ace onChange event.
  // By using 'editorContent' in the onChange event and render we get around the issue for now.
  render() {
    let {
      type,
    } = this.props;

    if (type === 'js') {
      type = 'javascript';
    }

    return (
      <div>
        {this.state.isExternalWindowMode ? (
          <ExternalWindowPortal
            closeWindowPortal={this.closeExternalWindowMode}
          >
            <div className="o-bb-source-editor">
              { this.renderToolbar() }
              <AceEditor
                width="100%"
                height="100%"
                mode={type}
                theme="twilight"
                onChange={this.onChange}
                onValidate={this.onValidate}
                value={this.state.editorContent}
                showPrintMargin={false}
                focus
                wrapEnabled
                editorProps={{ $blockScrolling: true }}
              />
            </div>
          </ExternalWindowPortal>
        ) : (
          <Gateway into="source-editor">
            <div className="o-bb-source-editor">
              { this.renderToolbar() }
              <AceEditor
                width="100%"
                mode={type}
                theme="twilight"
                onChange={this.onChange}
                onValidate={this.onValidate}
                value={this.state.editorContent}
                showPrintMargin={false}
                focus
                wrapEnabled
                editorProps={{ $blockScrolling: true }}
              />
            </div>
          </Gateway>
        )}
      </div>
    );
  }
}

SourceEditor.propTypes = propTypes;
SourceEditor.defaultProps = defaultProps;

export default withNamespaces()(connect()(SourceEditor));
