import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import FolderGridItem from './FolderGridItem';
import Icon from '../../Icon/Icon';
import AbstractView from './AbstractView';

const FolderAddButton = (({ onClick, t }) => (
  <li
    className={classNames(
      'c-bb-media-folders-grid__item',
    )}
  >
    <button
      type="button"
      onClick={onClick}
      className="c-bb-media-folders-grid__button c-bb-media-folders-grid__button--create-new"
    >
      <Icon name="folder-create" />
      <span className="c-bb-media-folders-grid__button__name">
        {t('MEDIA.folderCreateTitle')}
      </span>
    </button>
  </li>
));

FolderAddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const TranslatedFolderAddButton = withNamespaces()(FolderAddButton);

const FolderGrid = ({ folders = [], showNewFolderModal, onDrop }) => (
  <ul className="c-bb-media-folders-grid">
    <TranslatedFolderAddButton onClick={showNewFolderModal} />
    {folders.map(folder => (
      <FolderGridItem key={folder.id} id={folder.id} name={folder.name} onDrop={onDrop} />
    ))}
  </ul>
);

FolderGrid.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showNewFolderModal: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
};

export default (props => (
  <AbstractView {...props} component={FolderGrid} />
));
