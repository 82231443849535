import { ROUTE_USER, ROUTE_USER_ENABLE, ROUTE_USERS } from '../api/routes';
import { addToUrlQuery } from '../api/browsboxApi';
import { CALL_BB_API, buildRoute } from '../api';
import { USER_PAGINATION_SCHEMA, USER_SCHEMA } from '../api/schema';

export const USERS_SET_SEARCH_QUERY = 'USERS_SET_SEARCH_QUERY';
export const USERS_SET_SORT_BY = 'USERS_SET_SORT_BY';
export const USERS_SET_PAGE = 'USERS_SET_PAGE';
export const USERS_SET_PER_PAGE = 'USERS_SET_PER_PAGE';
export const USERS_OPEN_VIEW = 'USERS_OPEN_VIEW';
export const USERS_CLOSE_VIEW = 'USERS_CLOSE_VIEW';

export const USERS_FETCH_REQUEST = 'USERS_FETCH_REQUEST';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_FAILURE = 'USERS_FETCH_FAILURE';

export const openUsersView = () => ({
  type: USERS_OPEN_VIEW,
});

export const closeUsersView = () => ({
  type: USERS_CLOSE_VIEW,
});

export const setSearchQuery = query => ({
  type: USERS_SET_SEARCH_QUERY,
  payload: query,
});

export const setPage = page => ({
  type: USERS_SET_PAGE,
  payload: page,
});

export const setPerPage = perPage => ({
  type: USERS_SET_PER_PAGE,
  payload: perPage,
});

export const setSortBy = field => ({
  type: USERS_SET_SORT_BY,
  payload: field,
});

export const fetchUsers = ({
  page, perPage, search, sort, direction,
}) => {
  const url = `${ROUTE_USERS}?sort=${sort}&direction=${direction}&search=${search}&page=${page}`;

  if (perPage) {
    addToUrlQuery(url, 'perPage', perPage);
  }

  return {
    [CALL_BB_API]: {
      types: [USERS_FETCH_REQUEST, USERS_FETCH_SUCCESS, USERS_FETCH_FAILURE],
      endpoint: url,
      method: 'GET',
      schema: USER_PAGINATION_SCHEMA,
    },
  };
};

export const USERS_CREATE_REQUEST = 'USERS_CREATE_REQUEST';
export const USERS_CREATE_SUCCESS = 'USERS_CREATE_SUCCESS';
export const USERS_CREATE_FAILURE = 'USERS_CREATE_FAILURE';

export const createUser = (data, onError) => ({
  [CALL_BB_API]: {
    types: [USERS_CREATE_REQUEST, USERS_CREATE_SUCCESS, USERS_CREATE_FAILURE],
    endpoint: ROUTE_USERS,
    method: 'POST',
    json: data,
    options: {
      onError,
    },
  },
});

export const USERS_READ_REQUEST = 'USERS_READ_REQUEST';
export const USERS_READ_SUCCESS = 'USERS_READ_SUCCESS';
export const USERS_READ_FAILURE = 'USERS_READ_FAILURE';

export const readUser = id => ({
  [CALL_BB_API]: {
    types: [USERS_READ_REQUEST, USERS_READ_SUCCESS, USERS_READ_FAILURE],
    endpoint: buildRoute(ROUTE_USER, { id }),
    method: 'GET',
    schema: USER_SCHEMA,
  },
});

export const USERS_UPDATE_REQUEST = 'USERS_UPDATE_REQUEST';
export const USERS_UPDATE_SUCCESS = 'USERS_UPDATE_SUCCESS';
export const USERS_UPDATE_FAILURE = 'USERS_UPDATE_FAILURE';

export const updateUser = (data, onError) => ({
  [CALL_BB_API]: {
    types: [USERS_UPDATE_REQUEST, USERS_UPDATE_SUCCESS, USERS_UPDATE_FAILURE],
    endpoint: buildRoute(ROUTE_USER, { id: data.id }),
    method: 'PUT',
    json: data,
    options: {
      onError,
    },
  },
});

export const USERS_TOGGLE_ENABLE_REQUEST = 'USERS_TOGGLE_ENABLE_REQUEST';
export const USERS_TOGGLE_ENABLE_SUCCESS = 'USERS_TOGGLE_ENABLE_SUCCESS';
export const USERS_TOGGLE_ENABLE_FAILURE = 'USERS_TOGGLE_ENABLE_FAILURE';

export const toggleEnable = ({ id, isEnabled }) => ({
  [CALL_BB_API]: {
    types: [USERS_TOGGLE_ENABLE_REQUEST, USERS_TOGGLE_ENABLE_SUCCESS, USERS_TOGGLE_ENABLE_FAILURE],
    endpoint: buildRoute(ROUTE_USER_ENABLE, { id }),
    method: 'PATCH',
    json: { isEnabled },
  },
});

export const USERS_DELETE_REQUEST = 'USERS_DELETE_REQUEST';
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
export const USERS_DELETE_FAILURE = 'USERS_DELETE_FAILURE';

export const deleteUser = ({ id }) => ({
  [CALL_BB_API]: {
    types: [USERS_DELETE_REQUEST, USERS_DELETE_SUCCESS, USERS_DELETE_FAILURE],
    endpoint: buildRoute(ROUTE_USER, { id }),
    method: 'DELETE',
  },
});
