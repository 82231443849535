import {
  put, takeLatest, delay,
} from 'redux-saga/effects';
import {
  getBaseModuleList,
  BASE_MODULE_UPDATE_SUCCESS,
  BASE_MODULE_SAVE_SUCCESS,
  BASE_MODULE_DELETE_SUCCESS,
} from '../../actions/baseModule';
import { loadPages } from '../../actions/pages';

/**
 * Watches every create or update success request of base modules list
 */
function* fetchBaseModulesList() {
  yield delay(50);
  yield put(getBaseModuleList());
  yield put(loadPages());
}

export default function* () {
  yield takeLatest(BASE_MODULE_UPDATE_SUCCESS, fetchBaseModulesList);
  yield takeLatest(BASE_MODULE_SAVE_SUCCESS, fetchBaseModulesList);
  yield takeLatest(BASE_MODULE_DELETE_SUCCESS, fetchBaseModulesList);
}
