import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const IconButton = ({
  children, className, noPadding, medium, small, ...rest
}) => (
  <button
    type="button"
    className={classNames(
      'c-bb-icon-button',
      { 'c-bb-icon-button--medium': medium },
      { 'c-bb-icon-button--small': small },
      { 'c-bb-icon-button--no-padding': noPadding },
      className,
    )}
    {...rest}
  >
    {children}
  </button>
);

IconButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  noPadding: PropTypes.bool,
};

IconButton.defaultProps = {
  className: '',
  medium: false,
  small: false,
  noPadding: false,
};

export default IconButton;
