import React from 'react';
import PropTypes from 'prop-types';
import CONTENT_COMPONENT_TYPE from './types';
import { IMAGE_TYPE_ROUNDED } from '../constants';

const createRoundedImagesStyles = (width, height) => {
  const styles = {};

  if (height) {
    styles.width = `${height}px`;
    styles.paddingBottom = `${height}px`;
  }

  return styles;
};

const createRegularImagesStyles = (width, height) => {
  const styles = {};

  if (width && height) {
    const ratio = height / width;
    const paddingBottom = ratio * 100;
    styles.paddingBottom = `${paddingBottom}%`;
  }

  return styles;
};

class ContentComponentImageContainer extends React.Component {
  resolveContainerStyles() {
    const { width, height, imageType } = this.props;

    switch (imageType) {
      case IMAGE_TYPE_ROUNDED:
        return createRoundedImagesStyles(width, height);

      default:
        return createRegularImagesStyles(width, height);
    }
  }

  render() {
    if (this.props.type === CONTENT_COMPONENT_TYPE.image) {
      return (
        <div className="c-image__container" style={this.resolveContainerStyles()}>
          {this.props.children}
        </div>
      );
    }

    return this.props.children;
  }
}

ContentComponentImageContainer.propTypes = {
  type: PropTypes.string,
  imageType: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.element.isRequired,
};

ContentComponentImageContainer.defaultProps = {
  type: CONTENT_COMPONENT_TYPE.image,
  imageType: 'regular',
  width: null,
  height: null,
};

export default ContentComponentImageContainer;
