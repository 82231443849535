import { CONFIGURATION_MENU_FETCH_SUCCESS } from '../actions/menu';

const initialState = {
  configItems: [],
};

const menuItems = (state = initialState, action) => {
  switch (action.type) {
    case CONFIGURATION_MENU_FETCH_SUCCESS: {
      return {
        ...state,
        configItems: action.response,
      };
    }
    default:
      return state;
  }
};

export default menuItems;
