export function clearAllSelection() {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    document.selection.empty();
  }
}

export function isIE() {
  return /MSIE/i.test(navigator.userAgent);
}

export function isEdge() {
  return /Edge\/\d./i.test(navigator.userAgent);
}

export default {
  isIE,
  isEdge,
  clearAllSelection,
};
