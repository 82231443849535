import React from 'react';
import SingleLocationMap from '../../MapBox/SingleLocationMap';

const ContentComponentMaps = ({
  address,
  classes,
  containerClassName,
  latitude,
  longitude,
  mapStyle,
  mapZoom,
  markerTitle: markerTitleEncoded,
  markerValue,
  type,
}) => (
  <SingleLocationMap
    address={address}
    classes={classes}
    containerClassName={containerClassName}
    latitude={latitude}
    longitude={longitude}
    mapStyle={mapStyle}
    mapZoom={mapZoom}
    markerTitleEncoded={markerTitleEncoded}
    markerValue={markerValue}
    type={type}
  />
);

export default ContentComponentMaps;
