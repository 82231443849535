/**
 * Transforms data to store it in Redux
 *
 * @param data
 * @param key
 */
export default function transform(data = [], key = 'id') {
  const entities = {};
  const ids = [];

  data.forEach((entry) => {
    const id = entry[key];

    if (!id) {
      throw new Error(`Possible invalid identifier: ${key}`);
    }

    ids.push(id);
    entities[entry[key]] = entry;
  });

  return {
    ids,
    entities,
  };
}
