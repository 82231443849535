import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

const modes = ['grid', 'table'];

const ViewModeToggle = ({ value, onChange }) => (
  <ul className="o-bb-view-toggle">
    {modes.map(mode => (
      <li key={mode} className={classNames('o-bb-view-toggle__item')}>
        <button
          type="button"
          className={classNames(
            'o-bb-view-toggle__button',
            { 'o-bb-view-toggle__button--is-active': value === mode },
          )}
          onClick={() => onChange(mode)}
        >
          <Icon name={`${mode}-view`} />
        </button>
      </li>
    ))}
  </ul>
);

ViewModeToggle.propTypes = {
  value: PropTypes.oneOf(modes).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ViewModeToggle;
