import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { change, Field } from 'redux-form';
import MediaField from './MediaField';
import { openMediaManager } from '../../actions/mediaManager';
import { clearCategory } from '../../actions/baseModule';
import { renderError } from '../../tools/validation';
import { selectAllMediaItems } from '../../selectors/media';
import { BbIconButton } from '../@browsbox-ui';
import Icon from '../Icon/Icon';

const propTypes = {
  meta: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  label: PropTypes.string,
  media: PropTypes.array.isRequired,
};

const defaultProps = {
  label: 'IMAGES',
  activeCategory: null,
};

function RenderCategoriesFields(props) {
  const {
    fields = [],
    meta: { error },
    label,
    media,
  } = props;

  return (
    <div>
      <div className="c-bb-form__add-field" onClick={() => fields.push()}>
        <Icon name="add" small /> {label}
      </div>
      {fields.map((input, index) => (
        <div key={input} className="c-bb-form__group-wrapper">
          <div className="c-bb-form__group">
            <Field
              name={input}
              component={MediaField}
              media={media}
              type="image"
            />
            <BbIconButton
              className="c-bb-form__remove-image"
              onClick={() => fields.remove(index)}
            >
              <Icon name="close" />
            </BbIconButton>
          </div>
        </div>
      ))}
      {error && <div className="error">{renderError(error)}</div>}
    </div>
  );
}

RenderCategoriesFields.propTypes = propTypes;
RenderCategoriesFields.defaultProps = defaultProps;

const mapStateToProps = state => ({
  media: selectAllMediaItems(state),
  activeCategory: state.baseModule.currentCategory,
});

const mapDispatchToProps = {
  openMediaManager,
  clearCategory,
  change,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(RenderCategoriesFields));
