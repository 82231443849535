import React from 'react';
import * as Sentry from '@sentry/browser';
import { render } from 'react-dom';
import { RootContainer } from './components';
import getStore from './store/getStore';
import './polyfills';
import './assets';
import './style';
import './internationalization/i18n';
import Media from './components/MediaManager/Media';
import Links from './components/Links/Links';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: 'https://d76158246f0e4d3f887e15a035833185@sentry.io/1758367' });
}

const store = getStore();
const root = document.getElementById('root');

Media.setStore(store);
Links.setStore(store);

if (root) {
  render(
    <React.StrictMode>
      <RootContainer store={store} />
    </React.StrictMode>,
    root,
  );
} else {
  // eslint-disable-next-line no-console
  console.warn('Couldn\'t run app, #root element doesn\'t exists in DOM.');
}
