import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const FontAwesomeIcon = ({ name, className, ...other }) => (
  <i
    className={
      classNames(
        name,
        className,
      )
    }
    {...other}
  />
);

FontAwesomeIcon.defaultProps = {
  className: '',
  title: '',
};

FontAwesomeIcon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default FontAwesomeIcon;
