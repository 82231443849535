// Require here all assets which needs to be processed by webpack loaders/plugins

const requireIcons = require.context('./assets/icons', true, /\.svg$/);
requireIcons.keys().forEach(requireIcons);

const loadFontAwesome = () => {
  const src = 'https://kit.fontawesome.com/ad15fda7b2.js';
  const script = document.createElement('script');
  script.src = src;
  script.crossorigin = 'anonymous';
  document.body.appendChild(script);
};

if (document) {
  loadFontAwesome();
}
