import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import classNames from 'classnames';
import Icon from '../../Icon/Icon';
import FolderContextMenu from './FolderContextMenu';
import AbstractItemView from './AbstractItemView';
import { useDidDraggedRef } from '../../DragAndDrop/hooks';

const FolderGridItem = (props) => {
  const {
    id, name,
    onClick, onRename, onRemove, onMove, showMenu, hideMenu, menuVisible,
    connectDragSource, connectDropTarget, isDragging, isOver, canDrop,
  } = props;
  const didDragged = useDidDraggedRef(isDragging);

  const handleClick = () => {
    if (!didDragged.current) {
      onClick(id);
    }
  };

  return connectDragSource(connectDropTarget(
    <li
      onMouseLeave={hideMenu}
      key={id}
      className={classNames(
        'c-bb-media-folders-grid__item',
        { 'is-dragging': isDragging },
        { 'is-drag-over': isOver && !isDragging && canDrop },
      )}
      title={name}
      id={`bb-media-folder-item-${id}`}
    >
      <button
        type="button"
        onClick={handleClick}
        className="c-bb-media-folders-grid__button"
      >
        <Icon name="folder" />
        <span className="c-bb-media-folders-grid__button__name">{name}</span>
      </button>
      <button
        type="button"
        onClick={showMenu}
        className="c-bb-media-folders-grid__actions"
      >
        <Icon name="context-menu" />
      </button>
      {menuVisible && (
        <FolderContextMenu
          folderId={id}
          onRename={onRename}
          onRemove={onRemove}
          onMove={onMove}
          onClose={hideMenu}
        />
      )}
    </li>,
  ));
};

FolderGridItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  showMenu: PropTypes.func.isRequired,
  hideMenu: PropTypes.func.isRequired,
  menuVisible: PropTypes.bool.isRequired,
};

const dndSource = {
  beginDrag: props => ({
    dndType: 'MEDIA_FOLDER',
    name: props.name,
    id: props.id,
  }),
};

const dropSourceCollect = (connectDnd, monitor) => ({
  connectDragSource: connectDnd.dragSource(),
  isDragging: monitor.isDragging(),
  clientOffset: monitor.getClientOffset(),
});

const dropTargetCollect = (connectDnd, monitor) => ({
  connectDropTarget: connectDnd.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
});

const targetModule = {
  drop(props, monitor) {
    const item = monitor.getItem();
    if (item.dndType === 'MEDIA_FOLDER' && item.id === props.id) {
      // dropped into dragged element
      return;
    }

    if (props.onDrop) {
      props.onDrop(item, { id: props.id, name: props.name });
    }
  },
};

const GridItem = props => (
  <AbstractItemView {...props} component={FolderGridItem} />
);

const decorate = compose(
  DropTarget(['MEDIA_FOLDER', 'MEDIA_ITEM'], targetModule, dropTargetCollect),
  DragSource('MEDIA_FOLDER', dndSource, dropSourceCollect),
);

export default decorate(GridItem);
