import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { BbMenu, BbMenuItem } from '../../@browsbox-ui';
import Icon from '../../Icon/Icon';

const MediaContextMenu = ({
  mediaId,
  onClose,
  onRename,
  onRemove,
  onDownload,
  onView,
  onMove,
  t,
}) => (
  <BbMenu
    className="c-bb-media-context-menu"
    key={mediaId}
    onClick={onClose}
  >
    <BbMenuItem
      left={<Icon name="edit" />}
      onClick={onRename}
    >
      {t('MEDIA.mediaRename')}
    </BbMenuItem>
    <BbMenuItem
      left={<Icon name="move-to" />}
      onClick={onMove}
    >
      {t('MEDIA.moveTo')}
    </BbMenuItem>
    <BbMenuItem
      left={<Icon name="external-window" />}
      onClick={onView}
    >
      {t('MEDIA.mediaView')}
    </BbMenuItem>
    <BbMenuItem
      left={<Icon name="download" />}
      onClick={onDownload}
    >
      {t('MEDIA.mediaDownload')}
    </BbMenuItem>
    <BbMenuItem
      left={<Icon name="trash" />}
      onClick={onRemove}
      bordered
    >
      {t('MEDIA.mediaRemove')}
    </BbMenuItem>
  </BbMenu>
);

MediaContextMenu.propTypes = {
  mediaId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(MediaContextMenu);
