import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

const ModalMain = props => (
  <main className="o-bb-modal__main">
    { props.children }
  </main>
);

ModalMain.propTypes = propTypes;
ModalMain.defaultProps = defaultProps;

export default ModalMain;
