import React from 'react';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UsersHeader from './UsersHeader';
import UsersContent from './UsersContent';
import UsersList from './UsersList';
import AddUserButton from './AddUserButton';
import UserSearchBar from './UserSearchbar';
import EditUserView from './EditUserView';
import AddUserView from './AddUserView';
import i18n from '../../internationalization/i18n';

const ListView = ({ onAdd, onEdit }) => (
  <>
    <UsersHeader title={i18n.t('USERS.users')} />
    <UsersContent>
      <AddUserButton onClick={onAdd} />
      <div className="c-users-view__search-bar">
        <UserSearchBar />
      </div>
      <div className="c-users-view__list">
        <UsersList onEdit={onEdit} />
      </div>
    </UsersContent>
  </>
);

ListView.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

const UsersView = ({ viewMode }) => {
  const [current, setCurrent] = React.useState(null);
  const [creating, setCreating] = React.useState(false);

  React.useLayoutEffect(() => {
    document.body.classList.add('o-bb-modal--is-open');

    return () => {
      document.body.classList.remove('o-bb-modal--is-open');
    };
  }, []);

  return (
    <div
      className={classNames(
        'c-users-view',
        `c-users-view--${viewMode}`,
      )}
    >
      {current && <EditUserView id={current} onClose={() => setCurrent(null)} />}
      {creating && <AddUserView onClose={() => setCreating(false)} />}
      {!current && !creating && (
        <ListView
          onAdd={() => setCreating(true)}
          onEdit={id => setCurrent(id)}
        />
      )}
    </div>
  );
};

UsersView.propTypes = {
  viewMode: PropTypes.oneOf(['default', 'desktop']).isRequired,
};

const mapStateToProps = state => ({
  viewMode: state.options.view.responsiveView,
});

export default withNamespaces()(connect(mapStateToProps)(UsersView));
