/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { htmlDecode } from 'htmlencode';
import CONTENT_COMPONENT_TYPE from './types';

const propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  content: PropTypes.string,
  fromSectionOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  type: PropTypes.string,
};

const defaultProps = {
  className: '',
  containerClassName: '',
  content: '',
  fromSectionOption: false,
  type: CONTENT_COMPONENT_TYPE.text,
};

const ContentComponentText = (props) => {
  const {
    className,
    containerClassName,
    content: contentEncoded,
    // eslint-disable-next-line no-unused-vars
    fromSectionOption,
    type,
  } = props;
  const content = htmlDecode(contentEncoded);

  const componentClasses = classNames(
    className,
    containerClassName,
    `c-${type}`,
  );

  // render edit behavior
  return (
    <div
      className={componentClasses}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

ContentComponentText.propTypes = propTypes;
ContentComponentText.defaultProps = defaultProps;

export default ContentComponentText;
