/* eslint-disable lines-between-class-members */
class Marker {
  /** @type {number} */
  latitude;
  /** @type {number} */
  longitude;
  /** @type {string} */
  address;
  /** @type {string} */
  title;
  /** @type {string} */
  value;

  constructor(latitude, longitude, address, title, value) {
    this.latitude = latitude;
    this.longitude = longitude;
    this.address = address;
    this.title = title;
    this.value = value;
  }
}

export default Marker;
