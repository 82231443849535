import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaGridViewItem from './MediaGridViewItem';
import UploadedItem from '../UploadQueue/UploadedItem';
import AbstractView from './AbstractView';
import Icon from '../../Icon/Icon';
import { BbIconButton } from '../../@browsbox-ui';
import MediaSortDirection from './MediaSortDirection';

const MediaCreateButton = ({ onClick }) => (
  <BbIconButton
    onClick={onClick}
    className="c-bb-media-manager__list__create-new-media"
  >
    <Icon name="add" />
  </BbIconButton>
);

MediaCreateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

class MediaListView extends Component {
  renderItem = image => (image.isUploading
    ? this.renderUploadingPlaceholder(image)
    : this.renderImage(image));

  renderUploadingPlaceholder = ({ uniqueId, file, progress }) => (
    <li key={uniqueId} className="c-bb-media-manager__list-item">
      <UploadedItem
        filename={file.name}
        progress={progress}
      />
    </li>
  );

  renderImage = (image) => {
    const {
      onSelect,
      onUnselect,
      onDoubleClick,
      isSelected,
    } = this.props;

    return (
      <li key={image.id} className="c-bb-media-manager__list-item">
        <MediaGridViewItem
          key={image.id}
          onSelect={onSelect}
          onUnSelect={onUnselect}
          onDoubleClick={onDoubleClick}
          isSelected={isSelected(image.id)}
          {...image}
        />
      </li>
    );
  };

  render() {
    const {
      items,
      listRef,
      openMediaUpload,
    } = this.props;

    return (
      <>
        <MediaSortDirection />
        <ul ref={listRef} className="c-bb-media-manager__list">
          {openMediaUpload && (
            <li className="c-bb-media-manager__list-item c-bb-media-manager__list-item__create-media">
              <MediaCreateButton onClick={openMediaUpload} />
            </li>
          )}
          { items.map(this.renderItem) }
        </ul>
      </>
    );
  }
}

MediaListView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  listRef: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUnselect: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  openMediaUpload: PropTypes.func.isRequired,
};

MediaListView.defaultProps = {
  multiple: true,
  selected: false,
};

export default props => (
  <AbstractView
    {...props}
    component={MediaListView}
  />
);
