import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BbButton } from '@browsbox-ui';
import { openNewPage } from '../../actions/pageNew';

const propTypes = {
  openNewPage: PropTypes.func.isRequired,
};

class BrowsboxPageNew extends Component {
  constructor(props) {
    super(props);
    this.onShowNew = this.onShowNew.bind(this);
  }

  onShowNew(evt) {
    evt.preventDefault();
    // show new page modal
    this.props.openNewPage();
  }

  render() {
    return (
      <BbButton
        className="c-bb-button--circle c-bb-button--add-page"
        icon="plus"
        onClick={this.onShowNew}
        noPadding
      />
    );
  }
}

BrowsboxPageNew.propTypes = propTypes;

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  openNewPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrowsboxPageNew);
