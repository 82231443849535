import { expandPages } from '../actions/pages';
import { DEFAULT_VIEW, DESKTOP_RESPONSIVE_VIEW, showResponsiveView } from '../actions/responsive';

const watchExpandedPages = (store) => {
  const select = state => state.pages.expandedPages;
  let currentValue;
  const handleChange = () => {
    const previousValue = currentValue;
    currentValue = select(store.getState());
    if (previousValue !== currentValue) {
      sessionStorage.setItem('expandedPages', JSON.stringify(currentValue));
    }
  };

  return store.subscribe(handleChange);
};

const expandedPagesFromStorage = () => {
  const persistedState = sessionStorage.getItem('expandedPages');
  return persistedState ? JSON.parse(persistedState) : [];
};

export const persistExpandedPages = (store) => {
  const expandedPages = expandedPagesFromStorage();
  if (Array.isArray(expandPages)) {
    store.dispatch(expandPages(expandedPages));
  }
  watchExpandedPages(store);
};

const filterResponsiveViews = [DEFAULT_VIEW, DESKTOP_RESPONSIVE_VIEW];

const watchResponsiveView = (store) => {
  const select = state => state.options.view.responsiveView;
  let currentValue;
  const handleChange = () => {
    const previousValue = currentValue;
    currentValue = select(store.getState());
    if (
      previousValue !== currentValue
      && filterResponsiveViews.filter(view => view === currentValue).length === 1
    ) {
      sessionStorage.setItem('responsiveView', currentValue);
    }
  };

  return store.subscribe(handleChange);
};

export const persistResponsiveView = (store) => {
  const select = state => state.options.view.responsiveView;
  const responsiveView = select(store.getState());
  const newResponsiveView = sessionStorage.getItem('responsiveView');
  //
  if (
    newResponsiveView
    && newResponsiveView !== responsiveView
    && filterResponsiveViews.filter(view => view === newResponsiveView).length === 1
  ) {
    store.dispatch(showResponsiveView(newResponsiveView));
  }

  watchResponsiveView(store);
};
