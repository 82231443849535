/* eslint-disable react/prop-types */
import React from 'react';
import { DragLayer } from 'react-dnd';
import Icon from '../Icon/Icon';

const defaultStyle = (item, currentOffset) => ({
  left: currentOffset.x,
  top: currentOffset.y,
  position: 'fixed',
  zIndex: 999999,
});

const DragFolderPreview = ({ item, currentOffset }) => (
  <div
    className="o-bb-drag-preview o-bb-drag-preview__folder"
    style={defaultStyle(item, currentOffset)}
  >
    <Icon name="folder" />
    <span className="o-bb-drag-preview__name">{item.name}</span>
  </div>
);

const DragMediaPreview = ({ item, clientOffset }) => (
  <div
    className="o-bb-drag-preview o-bb-drag-preview__media"
    style={defaultStyle(item, clientOffset)}
  >
    <div className="o-bb-drag-preview__media__image">
      <img src={item.thumbnail} alt={item.name} />
    </div>
  </div>
);

const DragPreview = (props) => {
  const {
    isDragging,
    itemType,
    currentOffset,
  } = props;

  if (!isDragging || !currentOffset) {
    return null;
  }

  switch (itemType) {
    case 'MEDIA_FOLDER': {
      return (<DragFolderPreview {...props} />);
    }
    case 'MEDIA_ITEM': {
      return (<DragMediaPreview {...props} />);
    }
    default:
      return null;
  }
};

export default DragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
  clientOffset: monitor.getClientOffset(),
}))(DragPreview);
