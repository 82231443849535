import { CALL_BB_API } from '../api';
import { ROUTE_WEBSITE_SETTINGS } from '../api/routes';

export const WEBSITE_SETTINGS_FETCH_REQUEST = 'WEBSITE_SETTINGS_FETCH_REQUEST';
export const WEBSITE_SETTINGS_FETCH_SUCCESS = 'WEBSITE_SETTINGS_FETCH_SUCCESS';
export const WEBSITE_SETTINGS_FETCH_FAILURE = 'WEBSITE_SETTINGS_FETCH_FAILURE';

const fetchWebsiteSettings = () => ({
  [CALL_BB_API]: {
    types: [WEBSITE_SETTINGS_FETCH_REQUEST, WEBSITE_SETTINGS_FETCH_SUCCESS, WEBSITE_SETTINGS_FETCH_FAILURE],
    endpoint: ROUTE_WEBSITE_SETTINGS,
    method: 'GET',
  },
});

export const getWebsiteSettings = () => dispatch => dispatch(
  fetchWebsiteSettings(),
);
