/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CONTENT_COMPONENT_TYPE from './types';

function ContentComponentDynamic(props) {
  const {
    type,
    classes,
    containerClassName,
  } = props;

  const componentClasses = classNames(
    classes,
    containerClassName,
    `c-${type}`,
  );

  return (
    <div className={componentClasses}>
      ###BB_REPLACE_DYNAMIC_{props.url}###
    </div>
  );
}

ContentComponentDynamic.propTypes = {
  classes: PropTypes.string,
  containerClassName: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string.isRequired,
};

ContentComponentDynamic.defaultProps = {
  classes: '',
  containerClassName: '',
  type: CONTENT_COMPONENT_TYPE.dynamic,
};

export default ContentComponentDynamic;
