import PropTypes from 'prop-types';
import React from 'react';

const EmailValue = ({ value }) => {
  if (!value) {
    return null;
  }

  return (
    <a href={`mailto:${value}`}>{value}</a>
  );
};

EmailValue.propTypes = {
  value: PropTypes.string,
};

EmailValue.defaultProps = {
  value: null,
};

export default EmailValue;
