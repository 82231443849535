import p from 'prop-types';

const RoleType = p.oneOf([
  'ROLE_USER',
  'ROLE_ADMIN',
  'ROLE_SUPER_ADMIN',
]).isRequired;

export const UserType = p.shape({
  id: p.number.isRequired,
  email: p.string.isRequired,
  firstName: p.string,
  lastName: p.string,
  roles: p.arrayOf(RoleType).isRequired,
  is_enabled: p.bool.isRequired,
  locale: p.string,
});

export default {
  UserType,
};
