import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadMediaFolders, loadMedia } from '../../../actions/media';
import FolderCreate from './FolderCreate';

const FoldersLoader = (props) => {
  const {
    fetchMedia,
    fetchFolders,
    folderId,
    onFolderChange,
    isCreateModalVisible,
  } = props;

  useEffect(() => {
    onFolderChange(folderId);
    fetchFolders(folderId);
    fetchMedia();
  }, [folderId, fetchFolders, fetchMedia, onFolderChange]);

  return isCreateModalVisible ? <FolderCreate /> : null;
};

FoldersLoader.propTypes = {
  folderId: PropTypes.number,
  isCreateModalVisible: PropTypes.bool.isRequired,
  fetchFolders: PropTypes.func.isRequired,
  fetchMedia: PropTypes.func.isRequired,
  onFolderChange: PropTypes.func.isRequired,
};

FoldersLoader.defaultProps = {
  folderId: null,
};

const mapStateToProps = state => ({
  folderId: state.media.folderId,
  isCreateModalVisible: state.media.isCreateFolderModalVisible,
});

const mapDispatchToProps = {
  fetchFolders: loadMediaFolders,
  fetchMedia: loadMedia,
};

export default connect(mapStateToProps, mapDispatchToProps)(FoldersLoader);
