import PropTypes from 'prop-types';
import {
  IMAGE_TYPE_ROUNDED,
  IMAGE_TYPE_REGULAR,
} from './constants';

export const contentColumnPropType = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
});

export const contentColumnDefaultProps = PropTypes.shape({
  options: {},
});

export const contentPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  type: PropTypes.string,
  index: PropTypes.number,
});

export const contentDefaultProps = {
  id: false,
  value: '',
  type: '',
};

export const contentComponentPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  type: PropTypes.string.isRequired,
  content: PropTypes.string,
  imageType: PropTypes.oneOf([IMAGE_TYPE_REGULAR, IMAGE_TYPE_ROUNDED]),
});

export const contentLayoutProps = PropTypes.shape({
  classes: PropTypes.string,
  img: PropTypes.string,
  imageResizableEnabled: PropTypes.bool,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
});
