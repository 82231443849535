/* global mapboxgl */
/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { htmlDecode } from 'htmlencode';
import PropTypes from 'prop-types';
import MapMarker from './MapMarker';
import MapBoxLoader from './MapBoxLoader';
import Marker from './Object/Marker';

// TODO: merge MultiLocationMap (to some level) with SingleLocationMap)
const MultiLocationMap = ({
  markers,
  classes,
  containerClassName,
  mapStyle,
  mapZoom,
  type,
}) => {
  const containerRef = useRef();
  const mapRef = useRef();
  const [ready, setReady] = useState(false);

  const componentClasses = classNames(
    classes,
    containerClassName,
    `c-${type}`,
    `c-${type}--${mapStyle}`,
  );

  const zoom = mapZoom || 6;
  const styleSet = mapStyle === 'grayscale'
    ? 'mapbox://styles/mapbox/light-v10'
    : 'mapbox://styles/mapbox/streets-v11';

  useEffect(() => {
    markers.forEach((marker) => {
      const params = {
        longitude: marker.longitude,
        latitude: marker.latitude,
      };

      Object
        .keys(params)
        .every(item => {
          if (typeof params[item] === 'undefined') {
            // eslint-disable-next-line no-console
            console.warn(`Required attribute is empty: ${item}.`);
          }

          return item;
        });
    });

    mapRef.current = new mapboxgl.Map({
      container: containerRef.current,
      style: styleSet,
      zoom,
      center: [markers[0].longitude, markers[0].latitude],
      cooperativeGestures: true,
    });

    setReady(true);

    return () => {
      mapRef.current.remove();
    };
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setStyle(styleSet);
    }
  }, [styleSet]);

  useEffect(() => {
    if (mapRef.current && markers.length > 0) {
      const bounds = new mapboxgl.LngLatBounds();

      markers.forEach(({ longitude, latitude }) => {
        bounds.extend([longitude, latitude]);
      });

      mapRef.current.fitBounds(bounds, { padding: 30 });
    }
  }, [markers]);

  return (
    <div ref={containerRef} className={componentClasses}>
      {ready && markers.map((marker) => (
        marker.latitude && marker.longitude && (
          <MapMarker
            key={`${marker.latitude}${marker.longitude}`}
            mapRef={mapRef}
            latitude={marker.latitude}
            longitude={marker.longitude}
            value={marker.value}
          >
            <div className="c-mapbox-marker">
              {htmlDecode(marker.title) && <div className="c-mapbox-marker__content" dangerouslySetInnerHTML={{ __html: htmlDecode(marker.title) }} />}
              <svg className="c-mapbox-marker__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
              </svg>
            </div>
          </MapMarker>
        )
      ))};
    </div>
  );
};

MultiLocationMap.propTypes = {
  classes: PropTypes.string,
  containerClassName: PropTypes.string.isRequired,
  mapStyle: PropTypes.string,
  mapZoom: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  markers: PropTypes.arrayOf(Marker),
  type: PropTypes.string.isRequired,
};

MultiLocationMap.defaultProps = {
  classes: '',
  mapStyle: '',
  mapZoom: null,
  markers: [],
};

export default (props => (
  <MapBoxLoader>
    <MultiLocationMap {...props} />
  </MapBoxLoader>
));
