import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Menu = ({ children, className }) => (
  <div className={classNames('c-bb-menu', className)}>
    <ul className="c-bb-menu__list">
      {children}
    </ul>
  </div>
);

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

Menu.defaultProps = {
  className: '',
};

export default Menu;
