import {
  BASE_MODULE_ITEM_FETCH_RELATED_DATA_REQUEST,
  BASE_MODULE_ITEM_FETCH_RELATED_DATA_SUCCESS,
  BASE_MODULE_ITEM_FETCH_RELATED_DATA_FAILURE,
} from '../actions/baseModuleItems';

const initialState = {
  items: [],
  isLoading: false,
};

const baseModuleRelatedItems = (state = initialState, action) => {
  switch (action.type) {
    case BASE_MODULE_ITEM_FETCH_RELATED_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BASE_MODULE_ITEM_FETCH_RELATED_DATA_SUCCESS: {
      return {
        ...state,
        items: action.response,
        isLoading: false,
      };
    }
    case BASE_MODULE_ITEM_FETCH_RELATED_DATA_FAILURE: {
      return {
        ...state,
        items: [],
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default baseModuleRelatedItems;
