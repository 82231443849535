import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../Icon/Icon';
import FolderContextMenu from './FolderContextMenu';
import AbstractItemView from './AbstractItemView';
import { BbIconButton } from '../../@browsbox-ui';

const FoldersListItem = (props) => {
  const {
    id, name, onClick, onRename, onRemove, onMove, showMenu, hideMenu, menuVisible,
  } = props;

  return (
    <li
      onMouseLeave={hideMenu}
      key={id}
      className={classNames(
        'c-bb-media-folders-list__item',
      )}
      title={name}
    >
      <button
        type="button"
        onClick={() => onClick(id)}
        className="c-bb-media-folders-list__button"
      >
        <Icon name="folder" />
        {name}
      </button>
      <BbIconButton onClick={showMenu} className="c-bb-media-folders-list__actions">
        <Icon name="context-menu" />
      </BbIconButton>
      {menuVisible && (
        <FolderContextMenu
          folderId={id}
          onRename={onRename}
          onRemove={onRemove}
          onClose={hideMenu}
          onMove={onMove}
        />
      )}
    </li>
  );
};

FoldersListItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  showMenu: PropTypes.func.isRequired,
  hideMenu: PropTypes.func.isRequired,
  menuVisible: PropTypes.bool.isRequired,
};

export default props => (
  <AbstractItemView {...props} component={FoldersListItem} />
);
