/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import classNames from 'classnames';
import get from 'lodash/get';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Icon from '../Icon/Icon';
import SelectField from './SelectField';
import CheckboxField from './CheckboxField';
import i18n from '../../internationalization/i18n';
import IconButton from '../@browsbox-ui/bb-icon-button';
import { extraColumns } from '../BaseModule/BaseModuleItems/BaseModuleItemsList/ListItem';

const ColumnFieldSortableHandle = SortableHandle(() => (
  <div className="c-bb-form__group__sortable-handle">
    <Icon name="base-module-drag" />
  </div>
));

const ColumnField = SortableElement(({
  input, fields, fieldIndex, options, isOptionDisabled, sortingViaDragAndDrop,
}) => {
  const classes = classNames(
    'c-bb-form__group-wrapper',
  );

  const possibleColumns = options.map(item => ({
    label: item,
    value: item,
    disabled: isOptionDisabled({ value: item }),
  }));

  return (
    <div className={classes}>
      <div className="c-bb-form__group c-bb-form__group__base-module-columns">
        <ColumnFieldSortableHandle />
        <div className="c-bb-form__control">
          <Field
            name={`${input}.field`}
            options={possibleColumns}
            component={SelectField}
          />
        </div>
        <div className="c-bb-form__control">
          {!sortingViaDragAndDrop && (
            <Field
              label={i18n.t('BASE_MODULE.columnIsSortable')}
              name={`${input}.sortable`}
              component={CheckboxField}
            />
          )}
        </div>
        <IconButton medium className="c-bb-form__remove-field" onClick={() => fields.remove(fieldIndex)}>
          <Icon name="trash" />
        </IconButton>
      </div>
    </div>
  );
});

ColumnField.propTypes = {
  fieldIndex: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  input: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
};

ColumnField.defaultProps = {
  activeCategory: null,
};

const SortableColumnsContainer = SortableContainer(({
  fields,
  availableFields,
  alreadySelected,
  ...rest
}) => {
  const selectedFields = alreadySelected
    .filter(Boolean)
    .map(item => item.field);

  const possibleColumns = availableFields
    .filter(Boolean)
    .map(item => item.internalName)
    .concat(extraColumns);

  const isOptionDisabled = option => selectedFields.includes(option.value);

  const canAddMore = possibleColumns.length > selectedFields.length;

  const handleAdd = () => {
    if (canAddMore) {
      fields.push({
        field: undefined,
        sortable: true,
      });
    }
  };

  return (
    <div className="c-bb-form__block">
      <button
        type="button"
        className="c-bb-form__add-button"
        onClick={handleAdd}
        disabled={!canAddMore}
        title={canAddMore ? 'Add column' : 'Cannot add more columns than fields'}
      >
        <Icon name="add" small /> {i18n.t('BASE_MODULE.addColumn').toUpperCase()}
      </button>
      {fields.map(((input, index) => (
        <ColumnField
          key={`sortable-category-${input}`}
          fields={fields}
          input={input}
          fieldIndex={index}
          index={index}
          options={possibleColumns}
          isOptionDisabled={isOptionDisabled}
          {...rest}
        />
      )))}
    </div>
  );
});

SortableColumnsContainer.propTypes = {
  fields: PropTypes.object.isRequired,
  availableFields: PropTypes.array.isRequired,
  alreadySelected: PropTypes.array.isRequired,
  draggingIndex: PropTypes.number,
};

const mapStateToProps = (state, { meta }) => ({
  sortingViaDragAndDrop: get(state.form[meta.form], 'values.sortingEnabled', false),
  availableFields: get(state.form[meta.form], 'values.fields', []),
  alreadySelected: get(state.form[meta.form], 'values.columns', []),
});

const SortableColumnsField = (props) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    props.fields.move(oldIndex, newIndex);
  };

  return (
    <SortableColumnsContainer
      useDragHandle
      helperClass="c-bb-form__group__sortable-helper"
      lockAxis="y"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
};

SortableColumnsField.propTypes = SortableColumnsContainer.propTypes;

export default withNamespaces()(connect(mapStateToProps)(SortableColumnsField));
