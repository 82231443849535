import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { BbIconButton, BbMenu, BbMenuItem } from '../../@browsbox-ui';
import Icon from '../../Icon/Icon';
import { setSortDirection, setSortType } from '../../../actions/media';

const MediaSortDirection = ({
  direction,
  onChangeDirection,
  type,
  onChangeType,
  t,
}) => {
  const [open, setOpen] = useState(false);

  const handleChangeDirection = (nextValue) => {
    onChangeDirection(nextValue);
    setOpen(false);
  };

  const handleChangeType = (nextType) => {
    onChangeType(nextType);
    setOpen(false);
  };

  const options = [
    { value: 'name', icon: 'sort-alpha', label: 'MEDIA.sortName' },
    { value: 'size', icon: 'filter', label: 'MEDIA.sortSize' },
    { value: 'type', icon: 'file', label: 'MEDIA.sortType' },
    { value: 'date', icon: 'calendar-day', label: 'MEDIA.sortDate' },
  ];

  return (
    <div className="c-bb-media__sort-changer" onMouseLeave={() => setOpen(false)}>
      <BbIconButton onClick={() => setOpen(true)}>
        <Icon name="sort" />
      </BbIconButton>
      {open && (
        <BbMenu>
          {options.map(option => (
            <BbMenuItem
              key={option.value}
              onClick={() => handleChangeType(option.value)}
              selected={type === option.value}
              left={<Icon name={option.icon} />}
            >
              {t(option.label)}
            </BbMenuItem>
          ))}
          <hr className="c-bb-media__sort-changer__divider" />
          <BbMenuItem
            onClick={() => handleChangeDirection('desc')}
            selected={direction === 'desc'}
            left={<Icon name="arrow-down-desc" />}
          >
            {t('MEDIA.sortDirectionDesc')}
          </BbMenuItem>
          <BbMenuItem
            onClick={() => handleChangeDirection('asc')}
            selected={direction === 'asc'}
            left={<Icon name="arrow-down-asc" />}
          >
            {t('MEDIA.sortDirectionAsc')}
          </BbMenuItem>
        </BbMenu>
      )}
    </div>
  );
};

MediaSortDirection.propTypes = {
  direction: PropTypes.string,
  type: PropTypes.string,
  onChangeDirection: PropTypes.func.isRequired,
  onChangeType: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

MediaSortDirection.defaultProps = {
  direction: null,
  type: null,
};

const mapStateToProps = state => ({
  direction: state.media.sortDirection,
  type: state.media.sortType,
});

const mapDispatchToProps = {
  onChangeDirection: setSortDirection,
  onChangeType: setSortType,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withNamespaces()(MediaSortDirection),
);
