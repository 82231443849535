import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import TextField from '../FormElements/TextField';
import { BbButtonGroup } from '../@browsbox-ui';
import CheckboxField from '../FormElements/CheckboxField';
import validation from '../../tools/validation';
import RadioFields from '../FormElements/RadioFields';
import SuperAdminOnly from '../AcessControll/SuperAdminOnly';

const validConfirmation = validation.sameAs('password');

const UserForm = (props) => {
  const {
    handleSubmit,
    submitting,
    onCancel,
    isEdit,
    t,
  } = props;

  return (
    <div className="c-bb-form c-bb-form--manage-user">
      <form onSubmit={handleSubmit}>
        <div className="c-bb-form__groups">
          <div className="c-bb-form__group">
            <h3 className="c-bb-form__group-title">
              {t('USERS.userData')}
            </h3>
            <Field
              validate={[validation.required, validation.email]}
              name="email"
              label={t('USERS.email')}
              component={TextField}
              autocomplete="off"
            />
          </div>
          <div className="c-bb-form__group">
            <h3 className="c-bb-form__group-title">
              {t('USERS.passwordSettings')}
            </h3>
            <Field
              name="password"
              component={TextField}
              type="password"
              label={t('USERS.password')}
              validate={isEdit ? [] : [validation.required]}
              autocomplete="off"
            />
            <Field
              name="passwordConfirmation"
              component={TextField}
              type="password"
              label={t('USERS.passwordConfirmation')}
              autocomplete="off"
              validate={isEdit ? [validConfirmation] : [validation.required, validConfirmation]}
            />
            <Field
              name="isEnabled"
              component={CheckboxField}
              label={t('USERS.activeUser')}
            />
          </div>
          <SuperAdminOnly>
            <div className="c-bb-form__group">
              <h3 className="c-bb-form__group-title">
                {t('USERS.userRole')}
              </h3>
              <Field
                name="role"
                component={RadioFields}
                type="role"
                options={[
                  { value: 'ROLE_USER', label: 'User' },
                  { value: 'ROLE_ADMIN', label: 'Admin' },
                  { value: 'ROLE_SUPER_ADMIN', label: 'System Admin' },
                ]}
              />
            </div>
          </SuperAdminOnly>
        </div>
        <div className="c-bb-form__actions">
          <BbButtonGroup>
            <button onClick={onCancel} className="c-bb-button c-bb-button--primary" type="button">
              {t('CONTENT.cancel')}
            </button>
            <button className="c-bb-button c-bb-button--primary" type="submit" disabled={submitting}>
              {isEdit ? t('CONTENT.update') : t('CONTENT.create')}
            </button>
          </BbButtonGroup>
        </div>
      </form>
    </div>
  );
};

UserForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
};

UserForm.defaultProps = {
  isEdit: false,
};

export default reduxForm({
  form: 'userForm',
})(
  withNamespaces()(UserForm),
);
