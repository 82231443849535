import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../Icon/Icon';
import FontAwesomeIcon from '../../Icon/FontAwesomeIcon';

const propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  active: false,
  className: '',
  href: '#',
  onClick: () => {},
  icon: '',
  children: null,
};

class BbNavItem extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(evt) {
    if (this.props.href === '#') {
      evt.preventDefault();
    }

    if (this.props.onClick) {
      this.props.onClick(evt);
    }
  }

  render() {
    const {
      active,
      className,
      icon,
      ...attributes
    } = this.props;

    const classes = classNames(
      className,
      'o-bb-nav-item',
      {
        'o-bb-nav-item--active': active,
      },
    );

    delete attributes.onClick;

    return (
      <li className={classes}>
        <a
          className="o-bb-nav-item__link"
          onClick={this.onClick}
          {...attributes}
        >
          {this.props.children}
          {icon && (
            icon.startsWith('fa')
              ? <FontAwesomeIcon name={`fak ${icon}`} className="icon" />
              : <Icon name={icon} />
          )}
        </a>
      </li>
    );
  }
}

BbNavItem.propTypes = propTypes;
BbNavItem.defaultProps = defaultProps;

export default BbNavItem;
