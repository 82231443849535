import { updateSessionActivity, openSessionExpiredModal } from '../actions/session';

const defaultIntervalTime = 60 * 5; // 5 minutes (in seconds)
const defaultSessionMaxIdleTime = 60 * 60; // 1 hour (in seconds)

const sessionLastActivityKey = 'sessionLastActivity';
const sessionInitActivityKey = 'sessionInitActivity';
const sessionMaxIdleTimeKey = 'sessionMaxIdleTime';

const userEvents = ['click', 'keyup'];

/**
 * Timestamp in seconds of NOW
 *
 * @returns {number}
 */
const getNowTimestamp = () => (Math.floor(Date.now() / 1000));

/**
 * Initialise an interval timer for checking if user has any activity in frontend.
 * And if so send request to backend to refresh the backend session `lastActivity`.
 *
 * @param store
 * @param {number} intervalTime
 */
const initCheckActivityTimer = (store, intervalTime) => {
  setInterval(() => {
    const lastActivity = localStorage.getItem(sessionLastActivityKey);
    const initActivity = localStorage.getItem(sessionInitActivityKey);

    // Check if `lastActivity` has been updated compared to the `initActivity`, then send request to the backend
    // to update it's own session lastActivity
    if (lastActivity !== initActivity) {
      store.dispatch(updateSessionActivity());

      // reset the session activity timer
      const newNowTimestamp = getNowTimestamp();
      localStorage.setItem(sessionLastActivityKey, newNowTimestamp);
      localStorage.setItem(sessionInitActivityKey, newNowTimestamp);
    } else {
      // check if idle time is over
      const sessionMaxIdleTime = localStorage.getItem(sessionMaxIdleTimeKey);
      const sessionMaxIdleTimestamp = Number(initActivity) + Number(sessionMaxIdleTime);
      const nowTimestamp = getNowTimestamp();

      if (nowTimestamp >= sessionMaxIdleTimestamp) {
        // if so, show session expired modal because user is now logged out
        store.dispatch(openSessionExpiredModal());
      }
    }
  }, intervalTime * 1000);
};

/**
 * Initialise the user activity events to refresh the session last activity parameter
 */
const initUserActivityEvents = () => {
  let i;

  // on every action of the user (mouse or keyboard)
  for (i = 0; i < userEvents.length; i += 1) {
    // update the session last activity timestamp
    window.addEventListener(
      userEvents[i],
      () => {
        localStorage.setItem(sessionLastActivityKey, getNowTimestamp());
      },
      false,
    );
  }
};

/**
 * Initialize a timer to check if the (React version of) session's last activity has been updated
 * to update it to the backend too
 */
const initSessionTimer = (store) => {
  initUserActivityEvents();

  let intervalTime = defaultIntervalTime;
  let sessionMaxIdleTime = defaultSessionMaxIdleTime;

  // retrieve the sessionMaxIdleTime from global set variable from the backend
  if (typeof window.sessionMaxIdleTime !== 'undefined') {
    sessionMaxIdleTime = window.sessionMaxIdleTime;
  }

  // check if the max idle time is not smaller then the default interval time
  if (intervalTime >= sessionMaxIdleTime) {
    // if so, take the half of the max idle time
    intervalTime = sessionMaxIdleTime / 2;
  }

  // Set the initial and the last timestamp to be checked later on
  const nowTimestamp = getNowTimestamp();
  localStorage.setItem(sessionLastActivityKey, nowTimestamp);
  localStorage.setItem(sessionInitActivityKey, nowTimestamp);
  localStorage.setItem(sessionMaxIdleTimeKey, sessionMaxIdleTime);

  initCheckActivityTimer(store, intervalTime);
};

export default initSessionTimer;
