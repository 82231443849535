import { upperFirst } from 'lodash';
import {
  OPEN_BASE_MODULE_VIEW, CLOSE_BASE_MODULE_VIEW, CHANGE_BASE_MODULE_SCREEN,
  CHANGE_BASE_MODULE_LANG, CLEAR_BASE_MODULE_CURRENT_CATEGORY,
  SET_CURRENT_MODULE,
  DOMAIN_FETCH_SUCCESS,
  LANGUAGES_FETCH_REQUEST, LANGUAGES_FETCH_SUCCESS, LANGUAGES_FETCH_FAILURE,
  FIELD_TYPES_FETCH_REQUEST, FIELD_TYPES_FETCH_SUCCESS, FIELD_TYPES_FETCH_FAILURE,
  BASEMODULE_LIST_FETCH_REQUEST, BASEMODULE_LIST_FETCH_SUCCESS, BASEMODULE_LIST_FETCH_FAILURE,
  BASE_MODULE_UPDATE_REQUEST, BASE_MODULE_UPDATE_SUCCESS, BASE_MODULE_UPDATE_FAILURE,
  BASE_MODULE_DELETE_REQUEST, BASE_MODULE_DELETE_SUCCESS, BASE_MODULE_DELETE_FAILURE,
  BASE_MODULE_SAVE_REQUEST, BASE_MODULE_SAVE_SUCCESS, BASE_MODULE_SAVE_FAILURE,
  POPULATE_BASE_MODULE_CATEGORY,
} from '../actions/baseModule';

import { OPEN_MEDIA_MANAGER, CLOSE_MEDIA_MANAGER } from '../actions/mediaManager';
import { CLOSE_CONTENT_MODALS } from '../actions/content';
import { SET_ACTIVE_MENU_ITEM } from '../actions/menu';

const initialState = {
  domain: null,
  isOpen: false,
  currentScreen: 'overview',
  currentCategory: null,
  lang: null,
  langs: [],
  fieldTypes: [],
  currentModule: null,
  modulesList: [],
  fetchingLangs: false,
  fetchingFieldTypes: false,
  fetchingModulesList: false,
  fetchingUpdateModule: false,
  fetchingDeleteModule: false,
};

const activeMenuItem = (state = initialState, action) => {
  switch (action.type) {
    // eslint-disable-next-line
    case POPULATE_BASE_MODULE_CATEGORY:
      let modulesList = [...state.modulesList];
      const { entityId, categories } = action.value;
      if (!categories.length) return state;
      modulesList = modulesList.map((bm) => {
        const clonedBm = { ...bm };
        clonedBm.populated = true;
        if (clonedBm.id === entityId) clonedBm.categories = categories;
        return clonedBm;
      });
      return {
        ...state,
        modulesList,
      };
    case OPEN_BASE_MODULE_VIEW:
      return {
        ...state,
        isOpen: true,
      };
    case CLOSE_BASE_MODULE_VIEW:
      return {
        ...state,
        isOpen: false,
      };
    case SET_ACTIVE_MENU_ITEM:
      return {
        ...state,
        isOpen: false,
      };
    case CHANGE_BASE_MODULE_SCREEN: {
      const nextState = {
        ...state,
        currentScreen: action.payload,
      };

      if (!nextState.lang) {
        nextState.lang = state.langs.length ? state.langs.find(lng => lng.default === true).code : null;
      }

      return nextState;
    }
    case DOMAIN_FETCH_SUCCESS:
      return {
        ...state,
        domain: action.response,
      };
    case CHANGE_BASE_MODULE_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case CLEAR_BASE_MODULE_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: null,
      };
    case OPEN_MEDIA_MANAGER:
      if (action.value.from === 'basemodule') {
        return {
          ...state,
          currentCategory: { name: action.value.name },
        };
      }
      return state;
    case CLOSE_MEDIA_MANAGER:
      if (state.currentCategory && action.value) {
        return {
          ...state,
          currentCategory: {
            ...state.currentCategory,
            media: action.value,
          },
        };
      }
      return state;
    case CLOSE_CONTENT_MODALS:
      return {
        ...state,
        currentCategory: null,
      };
    case BASE_MODULE_SAVE_REQUEST:
      return {
        ...state,
        fetchingUpdateModule: true,
      };
    case BASE_MODULE_SAVE_SUCCESS:
      return {
        ...state,
        fetchingUpdateModule: false,
        fetchingModulesList: true,
      };
    case BASE_MODULE_SAVE_FAILURE:
      return {
        ...state,
        fetchingUpdateModule: false,
      };
    case BASE_MODULE_UPDATE_REQUEST:
      return {
        ...state,
        fetchingUpdateModule: true,
      };
    case BASE_MODULE_UPDATE_SUCCESS:
      return {
        ...state,
        fetchingUpdateModule: false,
        fetchingModulesList: true,
      };
    case BASE_MODULE_UPDATE_FAILURE:
      return {
        ...state,
        fetchingUpdateModule: false,
      };
    case BASE_MODULE_DELETE_REQUEST:
      return {
        ...state,
        fetchingDeleteModule: true,
      };
    case BASE_MODULE_DELETE_SUCCESS:
      return {
        ...state,
        fetchingDeleteModule: false,
        fetchingModulesList: true,
      };
    case BASE_MODULE_DELETE_FAILURE:
      return {
        ...state,
        fetchingDeleteModule: false,
        fetchingModulesList: false,
      };
    case LANGUAGES_FETCH_REQUEST:
      return {
        ...state,
        fetchingLangs: true,
      };
    case LANGUAGES_FETCH_SUCCESS:
      return {
        ...state,
        lang: action.response ? action.response.find(lng => lng.active === true).code : null,
        langs: action.response ? action.response : [],
        fetchingLangs: false,
      };
    case LANGUAGES_FETCH_FAILURE:
      return {
        ...state,
        fetchingLangs: false,
      };
    case FIELD_TYPES_FETCH_REQUEST:
      return {
        ...state,
        fetchingFieldTypes: true,
      };
    case FIELD_TYPES_FETCH_SUCCESS:
      return {
        ...state,
        fieldTypes: action.response && action.response.map(fieldType => ({
          value: fieldType,
          label: upperFirst(fieldType),
        })),
        fetchingFieldTypes: false,
      };
    case FIELD_TYPES_FETCH_FAILURE:
      return {
        ...state,
        fetchingFieldTypes: false,
      };
    case BASEMODULE_LIST_FETCH_REQUEST:
      return {
        ...state,
        fetchingModulesList: true,
      };
    case BASEMODULE_LIST_FETCH_SUCCESS:
      return {
        ...state,
        fetchingModulesList: false,
        modulesList: action.response,
      };
    case BASEMODULE_LIST_FETCH_FAILURE:
      return {
        ...state,
        fetchingModulesList: false,
        modulesList: initialState.modulesList,
      };
    case SET_CURRENT_MODULE:
      return {
        ...state,
        currentModule: action.payload,
      };
    default:
      return state;
  }
};

export default activeMenuItem;
