import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../Modals/Modal';
import ModalHeader from '../../Modals/ModalHeader';
import FolderTreeSelect from './FolderTreeSelect';
import ModalFooter from '../../Modals/ModalFooter';
import i18n from '../../../internationalization/i18n';
import BbButton from '../../@browsbox-ui/bb-button/button';

const FolderPickerModal = ({
  onClose, onSelect, isDisabled, canExpand,
}) => {
  const handleChange = (value) => {
    onSelect(value);
    onClose();
  };

  return (
    <Modal modalClassName="o-bb-modal--picker" onClose={onClose}>
      <ModalHeader onClose={onClose} title={i18n.t('MEDIA.moveFolder')} />
      <div className="o-bb-modal--picker__content">
        <FolderTreeSelect
          canExpand={canExpand}
          isDisabled={isDisabled}
          onChange={handleChange}
        />
      </div>
      <Modal.Context>
        {({ closeModal }) => (
          <ModalFooter>
            <BbButton className="c-bb-button--secondary" onClick={closeModal} text={i18n.t('CONTENT.cancel')} />
          </ModalFooter>
        )}
      </Modal.Context>
    </Modal>
  );
};

FolderPickerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.func,
  canExpand: PropTypes.func,
};

FolderPickerModal.defaultProps = {
  isDisabled: () => false,
  canExpand: () => true,
};

export default connect(null)(FolderPickerModal);
