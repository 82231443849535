import get from 'lodash/get';
import { DISPLAY_ERROR_MESSAGE, ERROR_MESSAGE, CLEAR_MESSAGE } from '../actions/messages';

const initialState = {
  title: '',
  message: '',
  status: 0,
};

const errorStatusCodes = [500, 503, 403, 401];
const validationCodes = [400, 422, 406];

const messages = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_ERROR_MESSAGE: {
      const { title, message } = action.value;

      return {
        ...state,
        title,
        message,
      };
    }
    case ERROR_MESSAGE: {
      const { value } = action;
      const status = get(value, 'error.error.response.status');
      if (errorStatusCodes.indexOf(status) !== -1) {
        const title = get(value, 'error.error.response.data.title');
        const message = get(value, 'error.error.response.data.message')
          || get(value, 'error.message')
          || get(value, 'error.error.message')
          || 'Error';
        return {
          ...state,
          title,
          message,
          status,
        };
      }

      if (validationCodes.includes(status)) {
        const title = get(value, 'error.error.response.data.title');
        const message = get(value, 'error.error.response.data.message');

        return {
          ...state,
          title,
          message,
        };
      }

      return state;
    }
    case CLEAR_MESSAGE: {
      return {
        ...state,
        message: '',
        status: 0,
      };
    }
    default:
      return state;
  }
};

export default messages;
