/**
 * Selects content from state by given Id
 * @param state
 * @param id
 * @returns {*}
 */
export function getContentById(state, id) {
  const { entities: { content } } = state;

  return content.find(item => item.id === id);
}

export default {
  getContentById,
};
