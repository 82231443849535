// URLSearchParams polyfill
import 'url-search-params-polyfill';
import {
  showResponsiveView,
  DESKTOP_RESPONSIVE_VIEW,
} from '../actions/responsive';

const responsiveView = (store) => {
  const location = window.location.search;
  const isPreviewActive = new URLSearchParams(location).get('preview');
  if (isPreviewActive) {
    store.dispatch(showResponsiveView(DESKTOP_RESPONSIVE_VIEW));
  }
};

export default responsiveView;
