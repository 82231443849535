import React, { useContext } from 'react';
import PropTypes from 'prop-types';

export const MediaContext = React.createContext({
  selected: null,
  onChange: undefined,
  onDoubleClick: undefined,
  multiple: false,
});

export const useMediaContext = () => useContext(MediaContext);

export const MediaContextProvider = ({
  children, selected, onChange, onDoubleClick, multiple,
}) => {
  const contextValue = React.useMemo(() => ({
    selected,
    onChange,
    onDoubleClick,
    multiple,
  }), [
    selected,
    onChange,
    onDoubleClick,
    multiple,
  ]);

  return (
    <MediaContext.Provider value={contextValue}>
      {children}
    </MediaContext.Provider>
  );
};

MediaContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  onChange: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

MediaContextProvider.defaultProps = {
  multiple: false,
  selected: null,
};
