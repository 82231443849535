const selectUsers = state => state.users.ids.map(id => state.users.items[id]);

const selectUsersIsLoading = state => state.users.isLoading;

const selectUser = (state, id) => state.users.items[id];

export default {
  selectUsers,
  selectUser,
  selectUsersIsLoading,
};
