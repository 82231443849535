import React from 'react';
import PropTypes from 'prop-types';

const RangeSlider = ({
  value,
  min,
  max,
  step,
  disabled,
  onChange,
}) => (
  <input
    className="c-bb-range-slider"
    type="range"
    value={value}
    min={min}
    max={max}
    step={step}
    onChange={onChange}
    disabled={disabled}
  />
);

RangeSlider.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

RangeSlider.defaultProps = {
  disabled: false,
};

export default RangeSlider;
