import { CALL_BB_API } from '../api';
import { ROUTE_SESSION_ACTIVITY } from '../api/routes';

export const UPDATE_SESSION_ACTIVITY_REQUEST = 'UPDATE_SESSION_ACTIVITY_REQUEST';
export const UPDATE_SESSION_ACTIVITY_SUCCESS = 'UPDATE_SESSION_ACTIVITY_SUCCESS';
export const UPDATE_SESSION_ACTIVITY_FAILURE = 'UPDATE_SESSION_ACTIVITY_FAILURE';

const doUpdateSessionActivity = () => ({
  [CALL_BB_API]: {
    types: [UPDATE_SESSION_ACTIVITY_REQUEST, UPDATE_SESSION_ACTIVITY_SUCCESS, UPDATE_SESSION_ACTIVITY_FAILURE],
    endpoint: ROUTE_SESSION_ACTIVITY,
    method: 'POST',
  },
});

export const updateSessionActivity = () => dispatch => dispatch(doUpdateSessionActivity());

export const OPEN_SESSION_EXPIRED_MODAL = 'OPEN_SESSION_EXPIRED_MODAL';
export const openSessionExpiredModal = () => ({ type: OPEN_SESSION_EXPIRED_MODAL });
