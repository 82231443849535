import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import isUrl from 'validator/lib/isURL';
import { getLinkType, completeUrl } from '@liswood-tache/browsbox-static';
import BbButton from '../@browsbox-ui/bb-button';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import Links, {
  LINK_TYPE_INTERNAL, LINK_TYPE_EXTERNAL, LINK_TYPE_EMAIL, LINK_TYPE_PHONE,
} from './Links';
import i18n from '../../internationalization/i18n';
import Modal from './Modal';
import LinksBridge from '../Links/Links';

const propTypes = {
  icon: PropTypes.string, // modal property
  onCancel: PropTypes.func.isRequired, // modal property
  onClose: PropTypes.func.isRequired, // modal property
  onOk: PropTypes.func.isRequired, // modal property
  submitButtonText: PropTypes.string, // modal property
  title: PropTypes.string, // modal property
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]), // Link data, hyperlink
};

const defaultProps = {
  icon: 'icon-attachments',
  submitButtonText: null,
  title: null,
  value: false,
};

class LinkPickerModal extends Component {
  constructor(props) {
    super(props);
    this.onOk = this.onOk.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onLinkChange = this.onLinkChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    const { value } = props;
    const type = getLinkType(value, LINK_TYPE_INTERNAL);
    const hyperlink = (type === LINK_TYPE_EMAIL && value.indexOf('mailto:') === 0)
      ? value.replace(/^mailto:/, '') : value;

    this.state = {
      hasSelection: props.value && props.value.length !== 0,
      value: hyperlink,
      type,
    };
  }

  componentWillUnmount() {
    LinksBridge.notify({ canceled: true, link: null });
  }

  onTypeChange() {
    this.setState({
      validationMessage: '',
    });
  }

  onLinkChange(newLink) {
    const { type, value } = newLink;
    this.setState({
      hasSelection: value.length !== 0,
      value,
      type,
    });
  }

  onOk() {
    const { type, value } = this.state;
    // validate email and external url
    let validationMessage = '';
    if (type === LINK_TYPE_EXTERNAL && !isUrl(value)) {
      validationMessage = i18n.t('CONTENT.invalidUrl');
    } else if (type === LINK_TYPE_EMAIL && !isEmail(value)) {
      validationMessage = i18n.t('CONTENT.invalidEmail');
    }

    if (validationMessage) {
      this.setState({ validationMessage });
    } else {
      let hyperlink = value;
      if (type === LINK_TYPE_EMAIL && value.indexOf('mailto:') !== 0) {
        hyperlink = `mailto:${value}`;
      } else if (type === LINK_TYPE_PHONE && value.indexOf('tel:') !== 0) {
        hyperlink = `tel:${value}`;
      } else if (type === LINK_TYPE_EXTERNAL) {
        hyperlink = completeUrl(value);
      }

      LinksBridge.notify({ canceled: false, link: hyperlink });
      this.props.onOk(hyperlink);
    }
  }

  onCancel(evt) {
    evt.preventDefault();
    this.props.onCancel();
  }

  render() {
    const {
      icon,
      onClose,
    } = this.props;
    const {
      hasSelection,
      type,
      value,
      validationMessage,
    } = this.state;

    const submitButtonText = this.props.submitButtonText || i18n.t('CONTENT.add');
    const title = this.props.title || i18n.t('CONTENT.updateLink');

    return (
      <Modal
        onClose={onClose}
        modalClassName="o-bb-modal--small"
      >
        <ModalHeader icon={icon} title={title} />
        <Links
          hyperlink={value}
          type={type}
          onChange={this.onLinkChange}
          onTypeChange={this.onTypeChange}
          validationMessage={validationMessage}
        />
        <Modal.Context>
          {({ closeModal }) => (
            <ModalFooter>
              <BbButton className="c-bb-button--primary" disabled={!hasSelection} onClick={this.onOk} text={submitButtonText} />
              <BbButton className="c-bb-button--secondary" onClick={closeModal} text={i18n.t('CONTENT.cancel')} />
            </ModalFooter>
          )}
        </Modal.Context>
      </Modal>
    );
  }
}

LinkPickerModal.propTypes = propTypes;
LinkPickerModal.defaultProps = defaultProps;

const mapStateToProps = () => ({});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LinkPickerModal);
