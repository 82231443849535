import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BbPopup } from '@browsbox-ui';
import { Gateway } from '../Gateway';
import {
  showResponsiveView,
  SMARTPHONE_RESPONSIVE_VIEW,
  TABLET_RESPONSIVE_VIEW,
  DESKTOP_RESPONSIVE_VIEW,
} from '../../actions/responsive';
import i18n from '../../internationalization/i18n';

const popupClasses = 'o-bb-popup--responsive u-text-center';
const popupItemClasses = 'o-bb-popup--responsive-action u-text-uppercase';

const propTypes = {
  target: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  showResponsiveView: PropTypes.func.isRequired,
};
const defaultProps = {};

class ResponsivePopup extends Component {
  static getMenuItems() {
    return [
      { id: 'smartphone', label: i18n.t('CONTENT.smartphone'), view: SMARTPHONE_RESPONSIVE_VIEW },
      { id: 'tablet', label: i18n.t('CONTENT.tablet'), view: TABLET_RESPONSIVE_VIEW },
      { id: 'desktop', label: i18n.t('CONTENT.desktop'), view: DESKTOP_RESPONSIVE_VIEW },
    ];
  }

  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  onClick(evt, item) {
    const { view } = item;
    evt.preventDefault();
    this.props.onClose();
    this.props.showResponsiveView(view);
  }

  renderItem(item) {
    const { id, label } = item;
    return (
      <li key={id}>
        <a href="#" onClick={evt => this.onClick(evt, item)} className={popupItemClasses}>
          {label}
        </a>
      </li>
    );
  }

  render() {
    const {
      target: tetherTarget,
    } = this.props;

    const menuItems = ResponsivePopup.getMenuItems();

    return (
      <Gateway into="popup">
        <BbPopup
          target={tetherTarget}
          className={popupClasses}
          onClose={this.onClose}
          placement="bottom center"
        >
          <ul>
            { menuItems.map(this.renderItem) }
          </ul>
        </BbPopup>
      </Gateway>
    );
  }
}

ResponsivePopup.propTypes = propTypes;
ResponsivePopup.defaultProps = defaultProps;

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  showResponsiveView,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsivePopup);
