import {
  takeLatest, select, put, delay,
} from 'redux-saga/effects';
import { ADD_CONTENT, clearLastAddedContentId } from '../../actions/contentAdd';
import { SET_CONTENT_OPTION } from '../../actions/contentConfig';
import { setActiveSection } from '../../actions/content';

function scrollToSection(section) {
  if (!section) {
    return;
  }

  const offset = (section.offsetTop + (section.clientHeight * 0.5)) - (window.innerHeight * 0.5);

  window.scrollTo({
    top: offset,
    duration: 500,
    behavior: 'smooth',
  });
}

function* handleSectionLayoutChange(action) {
  const { option } = action.value;

  if (option !== 'layoutClasses') {
    return;
  }

  const id = yield select(state => state.content.lastAddedContentId);
  const section = document.querySelector(`section[id="${id}"]`);

  if (!section || !id) {
    return;
  }

  yield delay(250);
  yield put(setActiveSection(id));
  yield scrollToSection(section);
  yield put(clearLastAddedContentId());
}

function* handleContentAdd(action) {
  const { type, id } = action.value;
  const sectionsWithoutLayout = [
    'html',
    'breadcrumbs',
    'news',
    'googleMaps',
  ];

  if (!sectionsWithoutLayout.includes(type) && !String(type).startsWith('form')) {
    return;
  }

  const section = document.querySelector(`section[id="${id}"]`);
  if (!section) {
    return;
  }

  yield delay(250);
  yield put(setActiveSection(id));
  yield scrollToSection(section);
}

export default function* () {
  yield takeLatest(SET_CONTENT_OPTION, handleSectionLayoutChange);
  yield takeLatest(ADD_CONTENT, handleContentAdd);
}
