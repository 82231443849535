import PropTypes from 'prop-types';

const DateValue = ({ value }) => {
  if (!value) {
    return null;
  }

  return value;
};

DateValue.propTypes = {
  value: PropTypes.string,
};

DateValue.defaultProps = {
  value: null,
};

export default DateValue;
