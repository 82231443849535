import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { FieldArray, Field } from 'redux-form';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import SelectField from './SelectField';
import IconButton from '../@browsbox-ui/bb-icon-button';
import Icon from '../Icon/Icon';
import i18n from '../../internationalization/i18n';

const SelectOptionSortableHandle = SortableHandle(() => (
  <IconButton type="button" className="c-bb-base-module__select__drag-handle">
    <Icon name="base-module-drag" />
  </IconButton>
));

const SortableSelectOption = SortableElement(({ fields, name, fieldIndex }) => (
  <div key={name} className="c-bb-base-module__select-option">
    <SelectOptionSortableHandle />
    <Field name={name} component="input" className="c-bb-base-module__select-option-input" />
    <IconButton tabIndex={-1} type="button" onClick={() => fields.remove(fieldIndex)}>
      <Icon name="close" />
    </IconButton>
  </div>
));

SortableSelectOption.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  fieldIndex: PropTypes.number.isRequired,
};

const SelectOptionsSortable = SortableContainer(({ fields }) => (
  <div>
    {fields.map((name, index) => (
      <SortableSelectOption
        fields={fields}
        name={name}
        index={index}
        fieldIndex={index}
        key={name}
      />
    ))}
  </div>
));

const SelectOptionsFields = withNamespaces()(({ t, fields }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    fields.move(oldIndex, newIndex);
  };

  return (
    <div className="c-bb-base-module__select__options">
      <SelectOptionsSortable
        useDragHandle
        helperClass="c-bb-base-module__select-option__sortable-helper"
        lockAxis="y"
        fields={fields}
        onSortEnd={onSortEnd}
      />
      <button
        className="c-bb-base-module__select__add-option"
        tabIndex={-1}
        type="button"
        onClick={() => fields.push()}
      >
        <Icon name="add" />
        {t('CONTENT.create')}
      </button>
    </div>
  );
});

const SelectExtraFields = ({ fieldName }) => (
  <FieldArray
    name={`${fieldName}.options`}
    component={SelectOptionsFields}
  />
);

SelectExtraFields.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

const RangeValidator = ({ meta: { error } }) => {
  if (!error) {
    return null;
  }

  return (
    <span className="c-bb-form__error">{error}</span>
  );
};

const RangeExtraFields = ({ fieldName }) => (
  <div className="c-bb-base-module__range-settings">
    <div className="c-bb-base-module__range-settings__inner">
      <Field
        name={`${fieldName}.settings.min`}
        component="input"
        className="c-bb-base-module__range-input"
        type="number"
        placeholder="min"
      />
      <Field
        name={`${fieldName}.settings.step`}
        component="input"
        className="c-bb-base-module__range-input"
        type="number"
        min={0}
        placeholder="step"
      />
      <Field
        name={`${fieldName}.settings.max`}
        component="input"
        className="c-bb-base-module__range-input"
        type="number"
        placeholder="max"
      />
    </div>
    <Field
      component={RangeValidator}
      name={`${fieldName}.settings`}
      validate={(values = {}) => {
        const min = parseInt(values.min, 10);
        const step = parseInt(values.step, 10);
        const max = parseInt(values.max, 10);

        if ([min, max, step].some(item => (+item !== 0) && !item)) {
          return i18n.t('BASE_MODULE.rangeSettingsRequired');
        }

        if (max < (min + step)) {
          return i18n.t('BASE_MODULE.rangeSettingsInvalid');
        }

        return undefined;
      }}
    />
  </div>
);

RangeExtraFields.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

const extraUI = {
  select: SelectExtraFields,
  range: RangeExtraFields,
};

const FieldTypeSelect = (props) => {
  const { value } = props.input;
  const ExtraComponent = (value && extraUI[value]) || null;

  return (
    <div className="c-bb-form__field-type">
      <SelectField {...props} />
      {ExtraComponent && (
        <ExtraComponent {...props} />
      )}
    </div>
  );
};

FieldTypeSelect.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default FieldTypeSelect;
