export const fieldTypes = {
  text: 'text',
  longtext: 'longtext',
  date: 'date',
  number: 'number',
  boolean: 'boolean',
  email: 'email',
  url: 'url',
  phone_number: 'phone_number',
  image: 'image',
  images: 'images',
  file: 'file',
  video: 'video',
  select: 'select',
  range: 'range',
};

export const baseModuleScreens = {
  moduleItemsList: 'moduleItemsList',
  createModuleItem: 'createModuleItem',
  editModuleItem: 'editModuleItem',
};

export const baseModuleItemsFuseOptions = {
  shouldSort: false,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    'title',
  ],
};

export const dateInputFormat = 'DD/MM/YYYY';
