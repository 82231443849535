import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import BrowsboxModuleItem from './BrowsboxModuleItem';
import { DND_MENU_MODULE } from '../DragAndDrop/dndTypes';
import Loader from '../Loader';
import { isStaticPage } from '../../tools/page-utils';
import i18n from '../../internationalization/i18n';

const propTypes = {
  moduleItems: PropTypes.array,
  className: PropTypes.string,
  addContent: PropTypes.func.isRequired,
  currentPage: PropTypes.object,
};

const defaultProps = {
  moduleItems: [],
  className: '',
  currentPage: null,
};

class BrowsboxModuleList extends Component {
  isListDisabled = () => {
    const { currentPage } = this.props;

    if (isStaticPage()) {
      return true;
    }

    return currentPage && currentPage.module && currentPage.method === 'list';
  };

  renderItem(moduleItem, idx) {
    const {
      addContent,
    } = this.props;

    const {
      type,
    } = moduleItem;

    return (
      <BrowsboxModuleItem
        key={type}
        id={type}
        index={idx}
        className={`index${idx + 1}`}
        dndSource={DND_MENU_MODULE}
        dndType={DND_MENU_MODULE}
        addContent={addContent}
        {...moduleItem}
      />
    );
  }

  renderLoader = () => {
    const items = [...Array(12).keys()];

    return items.map(value => (
      <li className="c-bb-block-list__item" key={value}>
        <div style={{ margin: '10px' }}>
          <Loader
            width="100px"
            height="100px"
            borderRadius="0"
          />
        </div>
      </li>
    ));
  };

  render() {
    const {
      className,
      moduleItems,
      currentPage,
      ...attributes
    } = this.props;

    delete attributes.addContent;
    delete attributes.dispatch;

    const classes = classNames(
      className,
      'c-bb-block-list',
      { 'c-bb-block-list--disabled': this.isListDisabled() },
    );

    const moduleItemsContent = (!moduleItems || moduleItems.length === 0)
      ? this.renderLoader() : moduleItems.map(this.renderItem, this);
    return (
      <div className="c-bb-block-list__wrapper">
        { this.isListDisabled() && <p className="c-bb-block-list__message">{i18n.t('CONTENT.blocksDisabled')}</p> }
        <ul className={classes} {...attributes}>
          {moduleItemsContent}
        </ul>
      </div>
    );
  }
}

BrowsboxModuleList.propTypes = propTypes;
BrowsboxModuleList.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const {
    pages: {
      currentPage,
    },
  } = state;

  return {
    currentPage,
  };
};

export default connect(mapStateToProps, null)(BrowsboxModuleList);
