/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { openCodeEditor } from '../../actions/codeEditor';
import { ROUTE_LOGOUT } from '../../api/routes';
import { openBaseModuleView } from '../../actions/baseModule';
import i18n from '../../internationalization/i18n';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';
import { openUsersView, closeUsersView } from '../../actions/users';

const actionLinkClasses = 'o-bb-menu__item';

const propTypes = {
  closeUsersView: PropTypes.func.isRequired,
  openUsersView: PropTypes.func.isRequired,
  extraMenuItems: PropTypes.array,
};

const defaultProps = {
  extraMenuItems: [],
};

class ConfigurationContainer extends React.Component {
  static propTypes = {
    openCodeEditor: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.closeUsersView();
  }

  showCssEditor = () => {
    this.props.openCodeEditor('css');
  };

  showJsEditor = () => {
    this.props.openCodeEditor('js');
  };

  showUsersView = () => {
    this.props.openUsersView();
  };

  renderExtraMenuItems = () => {
    const { extraMenuItems } = this.props;
    const amountItems = extraMenuItems.length;

    return extraMenuItems.map((menuItem, key) => (
      // eslint-disable-next-line prefer-template
      <li
        key={menuItem.url}
        className={`${amountItems === key + 1 ? `${actionLinkClasses}--spacing ` : ''}${actionLinkClasses}`}
      >
        <a rel="noreferrer" className="o-bb-menu-item" href={menuItem.url} target="_blank">
          <FontAwesomeIcon name="fas fa-globe" className="o-bb-menu-item__icon" />
          <span className="o-bb-menu-item__title">{menuItem.label}</span>
        </a>
      </li>
    ));
  };

  render() {
    return (
      <ul className="o-bb-menu">
        <li className={`${actionLinkClasses}`}>
          <div className="o-bb-menu-item is-disabled">
            <FontAwesomeIcon name="fas fa-user" className="o-bb-menu-item__icon" />
            <span className="o-bb-menu-item__title">{i18n.t('CONTENT.yourProfile')}</span>
          </div>
        </li>
        <li className={`${actionLinkClasses}`}>
          <div className="o-bb-menu-item is-disabled">
            <FontAwesomeIcon name="fas fa-lock-alt" className="o-bb-menu-item__icon" />
            <span className="o-bb-menu-item__title">{i18n.t('CONTENT.changePassword')}</span>
          </div>
        </li>
        <li className={`${actionLinkClasses}--spacing ${actionLinkClasses}`} onClick={this.showUsersView}>
          <div className="o-bb-menu-item">
            <FontAwesomeIcon name="fas fa-user-group" className="o-bb-menu-item__icon" />
            <span className="o-bb-menu-item__title">{i18n.t('USERS.users')}</span>
          </div>
        </li>
        <li key="5" className={actionLinkClasses} onClick={this.showCssEditor}>
          <div className="o-bb-menu-item">
            <FontAwesomeIcon name="fas fa-code" className="o-bb-menu-item__icon" />
            <span className="o-bb-menu-item__title">{i18n.t('CONTENT.cssEditor')}</span>
          </div>
        </li>
        <li className={`${actionLinkClasses}--spacing ${actionLinkClasses}`} onClick={this.showJsEditor}>
          <div className="o-bb-menu-item">
            <FontAwesomeIcon name="fas fa-code" className="o-bb-menu-item__icon" />
            <span className="o-bb-menu-item__title">{i18n.t('CONTENT.jsEditor')}</span>
          </div>
        </li>
        {this.renderExtraMenuItems()}
        <li className={actionLinkClasses}>
          <a className="o-bb-menu-item" href={ROUTE_LOGOUT}>
            <FontAwesomeIcon name="fas fa-right-from-bracket" className="o-bb-menu-item__icon" />
            <span className="o-bb-menu-item__title">{i18n.t('CONTENT.logout')}</span>
          </a>
        </li>
      </ul>
    );
  }
}

ConfigurationContainer.propTypes = propTypes;
ConfigurationContainer.defaultProps = defaultProps;

const mapDispatchToProps = {
  openBaseModuleView,
  openUsersView,
  closeUsersView,
  openCodeEditor,
};
export default withNamespaces()(connect(null, mapDispatchToProps)(ConfigurationContainer));
