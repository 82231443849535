import PropTypes from 'prop-types';

export const moduleOptionsProps = PropTypes.shape({
  backgroundColor: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  backgroundImage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  backgroundImageId: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
});

export const moduleDefaultOptionsProps = {
  backgroundColor: false,
  backgroundImage: false,
  backgroundImageId: false,
};
