/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { htmlDecode } from 'htmlencode';
import CONTENT_COMPONENT_TYPE from './types';

const propTypes = {
  classes: PropTypes.string,
  containerClassName: PropTypes.string,
  content: PropTypes.string,
  type: PropTypes.string,
};

const defaultProps = {
  classes: '',
  containerClassName: '',
  content: '',
  type: CONTENT_COMPONENT_TYPE.html,
};

const ContentComponentHtml = (props) => {
  const {
    classes,
    containerClassName,
    content: contentEncoded,
    type,
  } = props;
  const content = htmlDecode(contentEncoded);

  const componentClasses = classNames(
    classes,
    containerClassName,
    `c-${type}`,
  );

  return (
    <div
      className={componentClasses}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

ContentComponentHtml.propTypes = propTypes;
ContentComponentHtml.defaultProps = defaultProps;

export default ContentComponentHtml;
