import { takeLatest, put } from 'redux-saga/effects';
import {
  OPEN_CODE_EDITOR,
  CODE_EDITOR_STORAGE_KEY,
  clearCodeEditorContent,
  loadCssEditorContent,
  loadJsEditorContent,
  openCodeEditor, JS_EDITOR_POST_SUCCESS,
} from '../../actions/codeEditor';
import {
  CLOSE_CONTENT_MODALS,
  CONTENT_SUCCESS,
} from '../../actions/content';

function loadEditorContent(codeEditorLanguage) {
  if (codeEditorLanguage === 'css') {
    return loadCssEditorContent();
  }

  if (codeEditorLanguage === 'js') {
    return loadJsEditorContent();
  }

  return false;
}

function* saveEditorState(action) {
  const { codeLanguage } = action;

  yield put(clearCodeEditorContent());
  yield put(loadEditorContent(codeLanguage));
  yield localStorage.setItem(CODE_EDITOR_STORAGE_KEY, codeLanguage);
}

function* removeEditorState() {
  yield localStorage.removeItem(CODE_EDITOR_STORAGE_KEY);
}

function* handleContentSuccess() {
  const codeLanguage = localStorage.getItem(CODE_EDITOR_STORAGE_KEY);
  const validLanguages = ['js'];

  if (codeLanguage && validLanguages.includes(codeLanguage)) {
    yield put(openCodeEditor(codeLanguage));
  }
}

function* handleJSEditorSaveSuccess() {
  yield removeEditorState();
  yield window.location.reload(true);
}

export default function* () {
  yield takeLatest(OPEN_CODE_EDITOR, saveEditorState);
  yield takeLatest(CLOSE_CONTENT_MODALS, removeEditorState);
  yield takeLatest(CONTENT_SUCCESS, handleContentSuccess);
  yield takeLatest(JS_EDITOR_POST_SUCCESS, handleJSEditorSaveSuccess);
}
