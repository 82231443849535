import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { renderError } from '../../tools/validation';
import Icon from '../Icon/Icon';

const propTypes = {
  input: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
};

const defaultProps = {
  label: null,
  meta: {},
};

const DetailFiltersCheckboxField = ({
  input, label, meta: { touched, error }, categories,
}) => {
  const handleCategoryChange = (categoryId) => {
    if (input.value.includes(categoryId)) {
      input.onChange(input.value.filter(value => value !== categoryId));
    } else {
      input.onChange([...input.value, categoryId]);
    }
  };

  const isChecked = id => input.value.includes(id);

  const renderCategories = () => categories.map(category => (
    <div key={category.id} className="c-bb-form__checkbox">
      <input
        id={`${category.id}-${category.title || category.label}-checkbox`}
        name={`${category.id}-${category.title || category.label}-checkbox`}
        type="checkbox"
        checked={isChecked(category.id)}
        onChange={() => handleCategoryChange(category.id)}
      />
      {isChecked(category.id) && (
        <Icon className="checked-icon" name="check" />
      )}
      <label htmlFor={`${category.id}-${category.title || category.label}-checkbox`}>
        {category.title || category.label}
      </label>
    </div>
  ));

  return (
    <div className="c-bb-form__input-item-group  c-bb-form__input-item-group--filters">
      <div className="input-item-group__label">{ label }</div>
      <div className="input-item-group">
        {renderCategories()}
      </div>
      {touched && error
      && <span className="input-item-group__error c-bb-form__input-error">{renderError(error)}</span>}
    </div>
  );
};

DetailFiltersCheckboxField.propTypes = propTypes;
DetailFiltersCheckboxField.defaultProps = defaultProps;

export default withNamespaces()(DetailFiltersCheckboxField);
