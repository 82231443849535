import React from 'react';
import SkeletonLoader from '../Loader/SkeletonLoader';

const BaseModuleLoader = () => (
  <div className="c-bb-base-module c-bb-base-module__skeleton-loader">
    <div className="c-bb-base-module__skeleton-loader__content">
      <SkeletonLoader width="45%" height="40px" />
      <SkeletonLoader width="65%" height="40px" />
      <SkeletonLoader width="80%" height="40px" />
      <SkeletonLoader width="65%" height="40px" />
      <SkeletonLoader width="80%" height="40px" />
      <SkeletonLoader height="300px" />
    </div>
  </div>
);

export default BaseModuleLoader;
