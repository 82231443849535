import React from 'react';
import PropTypes from 'prop-types';
import { CONTENT_COMPONENT_TYPE, Hr } from '@liswood-tache/browsbox-static';

const propTypes = {
  classes: PropTypes.string,
  containerClassName: PropTypes.string,
  type: PropTypes.string,
};

const defaultProps = {
  classes: '',
  containerClassName: '',
  type: CONTENT_COMPONENT_TYPE.hr,
};

const ContentComponentHr = props => (<Hr {...props} />);

ContentComponentHr.propTypes = propTypes;
ContentComponentHr.defaultProps = defaultProps;

export default ContentComponentHr;
