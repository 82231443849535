/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import flatten from 'lodash/flatten';
import {
  prepareBaseModuleItemFieldsToSubmit,
  convertBaseModuleItemFieldsToForm,
  convertBaseModuleItemCategoriesToForm,
} from '../../../../common';
import {
  readBaseModuleItem,
  updateBaseModuleItem,
  removeBaseModuleItem,
  receiveNsiProductType,
  getNsiItemDetailFilters,
  saveNsiProductType,
  saveNsiDetailFilters,
  togglePublishBaseModuleItem,
  getBaseModuleRelatedItemsData,
} from '../../../../actions/baseModuleItems';
import { changeBaseModuleLang, setCurrentModule } from '../../../../actions/baseModule';
import BaseModuleItemForm from './BaseModuleItemForm';
import ConfirmationModal from '../../../Modals/ConfirmationModal';
import i18n from '../../../../internationalization/i18n';
import { loadTags } from '../../../../actions/tags';
import {
  getModuleByName,
  selectBaseModuleItemById,
} from '../../../../selectors';
import { normalizeSlug } from '../../../../tools/url';
import BaseModuleLoader from '../../BaseModuleLoader';
import BaseModuleItemLanguageTabs from './BaseModuleItemLanguageTabs';
import FontAwesomeIcon from '../../../Icon/FontAwesomeIcon';

const propTypes = {
  currentModule: PropTypes.object,
  originalItem: PropTypes.object,
  currentModuleItem: PropTypes.object,
  readBaseModuleItem: PropTypes.func.isRequired,
  updateBaseModuleItem: PropTypes.func.isRequired,
  removeBaseModuleItem: PropTypes.func.isRequired,
  selectableItems: PropTypes.array.isRequired,
  productTypes: PropTypes.array.isRequired,
  saveNsiDetailFilters: PropTypes.func.isRequired,
  saveNsiProductType: PropTypes.func.isRequired,
  getNsiItemDetailFilters: PropTypes.func.isRequired,
  detailFilters: PropTypes.array.isRequired,
  allTags: PropTypes.array,
  isReading: PropTypes.bool.isRequired,
};

const defaultProps = {
  currentModule: null,
  originalItem: null,
  currentModuleItem: null,
  domain: null,
  allTags: [],
};

const BaseModuleItemEdit = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [extraValues, setExtraValues] = React.useState({});
  const { id: itemId, name } = useParams();
  const history = useHistory();

  React.useEffect(() => {
    if (props.currentModule) {
      props.setCurrentModule(props.currentModule);
    }
  }, [props.currentModule.id]);

  React.useEffect(() => {
    props.readBaseModuleItem(itemId);
  }, [itemId]);

  React.useEffect(() => {
    if (props.currentModuleItem) {
      const { original_item_id: originalItemId } = props.currentModuleItem;

      if (originalItemId) {
        props.readBaseModuleItem(originalItemId);
      }

      props.changeBaseModuleLang(props.currentModuleItem.language);
    }
  }, [props.currentModuleItem]);

  const initialValues = React.useMemo(() => {
    if (!props.currentModuleItem) {
      return null;
    }

    const {
      title, categories = [], field_values, route,
      parents, children,
      seo_title, seo_description,
      shop_data,
      auto_seo_title, auto_seo_description,
    } = props.currentModuleItem;

    return {
      routeId: route.id,
      title,
      categories: convertBaseModuleItemCategoriesToForm(categories),
      fields: convertBaseModuleItemFieldsToForm(field_values),
      tags: route.tags,
      route,
      parents: (parents || []).map(item => item.id),
      children: (children || []).map(item => item.id),
      // eslint-disable-next-line camelcase
      manualSeo: !(route.autogenerated_route && auto_seo_title && auto_seo_description),
      autoSeoTitle: auto_seo_title,
      autoSeoDescription: auto_seo_description,
      autoSeoUrl: route.autogenerated_route,
      seoUrl: route.custom_route || normalizeSlug(title),
      seoTitle: seo_title,
      seoDescription: seo_description,
      shopData: shop_data,
      autoSeoValues: {},
    };
  }, [props.currentModuleItem]);

  const fullInitialValues = React.useMemo(() => ({
    ...initialValues,
    ...extraValues,
  }), [initialValues, extraValues]);

  React.useEffect(() => {
    if (!props.currentModuleItem) {
      return;
    }

    const promises = [
      props.loadTags(),
      props.getBaseModuleRelatedItemsData(),
    ];

    // custom nsi logic @todo extract it
    if (props.domain === 'nsi-be.com') {
      const { id } = props.currentModuleItem;
      // custom nsi types
      const typePromise = props.receiveNsiProductType(id).then(({ response }) => {
        if (response && response.type) {
          setExtraValues(prev => ({ ...prev, type: response.type.toString() }));
        }
      });
      promises.push(typePromise);

      // custom nsi detail filters
      const filtersPromise = props.getNsiItemDetailFilters(id).then(({ response }) => {
        if (response && response.detailFilters) {
          const detailFilters = {};
          response.detailFilters.forEach((filter) => {
            if (!detailFilters[filter.group.label]) detailFilters[filter.group.label] = [];
            detailFilters[filter.group.label].push(filter.id);
          });
          const productCodes = {};
          response.productCodes.forEach((code) => {
            productCodes[code.detail_filter_ids.split(',').sort((a, b) => a - b).join(',')] = code.code;
          });

          setExtraValues(prev => ({ ...prev, detailFilters, productCodes }));
        }
      });
      promises.push(filtersPromise);
    }

    Promise.all(promises).then(() => setIsLoading(false));
  }, [props.currentModuleItem]);

  const openModal = React.useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const closeModal = React.useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const navigateToList = React.useCallback(() => {
    history.push(`/items/${name}`);
  }, [history]);

  const modalSubmit = async () => {
    await props.removeBaseModuleItem({ id: itemId });
    navigateToList();
  };

  const handleSubmit = React.useCallback((formValues) => {
    const formBody = { ...formValues };
    const fields = prepareBaseModuleItemFieldsToSubmit(formBody.fields);
    const detailFiltersIds = formBody.detailFilters ? flatten(Object.values(formBody.detailFilters)) : [];
    formBody.fields = fields;
    let productCodes = [];
    if (formBody.productCodes) {
      productCodes = Object.keys(formBody.productCodes).map(key => ({
        detailFilterIds: key.split(','),
        code: formBody.productCodes[key],
      }));
    }

    return props.updateBaseModuleItem(itemId, formBody)
      .then(() => {
        const { type } = formBody;
        // custom nsi types
        if (type) props.saveNsiProductType(props.currentModuleItem.id, { type });
        if (detailFiltersIds.length) {
          const detailFilters = {
            baseModuleItemId: props.currentModuleItem.id,
            detailFiltersIds,
            productCodes,
          };
          props.saveNsiDetailFilters(detailFilters);
        }

        navigateToList();
      });
  }, [
    props.saveNsiDetailFilters,
    props.saveNsiProductType,
    props.updateBaseModuleItem,
    props.currentModuleItem,
    itemId,
  ]);

  const {
    currentModule,
    currentModuleItem,
    selectableItems,
    productTypes,
    detailFilters,
  } = props;

  if (isLoading || props.isReading) {
    return (
      <BaseModuleLoader />
    );
  }

  if (!currentModule || !currentModuleItem) {
    // eslint-disable-next-line no-console
    console.warn('Required data empty: ', {
      currentModule,
      currentModuleItem,
    });

    return null;
  }

  const originalItem = currentModuleItem.original_item_id
    ? props.originalItem
    : currentModuleItem;

  return (
    <div className="c-bb-base-module">
      <div className="c-bb-base-module__header">
        <FontAwesomeIcon name={currentModule.icon || 'fak fa-module'} className="c-bb-base-module__icon" />
        <h1 className="c-bb-base-module__title">
          {currentModule.label}
        </h1>
        <BaseModuleItemLanguageTabs item={originalItem} />
      </div>
      <div className="c-bb-base-module-body">
        <div className="c-bb-form">
          <BaseModuleItemForm
            initialValues={fullInitialValues}
            currentModule={currentModule}
            selectable={selectableItems}
            onSubmit={handleSubmit}
            productTypes={productTypes}
            detailFilters={detailFilters}
            onReset={navigateToList}
            onDelete={openModal}
            allTags={props.allTags}
            routePrefix={currentModule.route}
          />
        </div>
      </div>
      {modalOpen
      && (
      <ConfirmationModal
        onClose={closeModal}
        onOk={modalSubmit}
        onCancel={closeModal}
        title={i18n.t('CONTENT.remove').toUpperCase()}
        submitButtonText={i18n.t('CONTENT.remove').toUpperCase()}
        icon="icon-basic_sheet"
      >
        <div style={{ textAlign: 'center' }}>
          {i18n.t('CONTENT.deleteConfirmation')}
        </div>
      </ConfirmationModal>
      )}
    </div>
  );
};

BaseModuleItemEdit.propTypes = propTypes;
BaseModuleItemEdit.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
  const { id: itemId, name } = props.match.params;
  const currentModuleItem = selectBaseModuleItemById(state, itemId);
  const currentModule = getModuleByName(state, name, currentModuleItem ? currentModuleItem.language : null);
  const originalId = currentModuleItem ? currentModuleItem.original_item_id : null;

  const {
    global: {
      tags,
      websiteSettings: {
        domain,
      },
    },
  } = state;

  let productTypes = [];
  let detailFilters = [];

  if (currentModule && currentModule.internal_name === 'product') {
    productTypes = state.baseModuleItems.customProductTypes;
    detailFilters = state.baseModuleItems.customDetailFilters;
  }

  return {
    domain,
    currentModule,
    currentModuleItem,
    originalItem: originalId ? selectBaseModuleItemById(state, originalId) : null,
    selectableItems: currentModule ? currentModule.categories : [],
    isReading: state.baseModuleItems.isLoading,
    productTypes,
    detailFilters,
    allTags: tags,
  };
};

const mapDispatchToProps = {
  readBaseModuleItem,
  updateBaseModuleItem,
  changeBaseModuleLang,
  removeBaseModuleItem,
  receiveNsiProductType,
  saveNsiProductType,
  getNsiItemDetailFilters,
  saveNsiDetailFilters,
  togglePublishBaseModuleItem,
  loadTags,
  getBaseModuleRelatedItemsData,
  setCurrentModule,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(BaseModuleItemEdit));
