import PropTypes from 'prop-types';
import React from 'react';

const UrlValue = ({ value }) => {
  if (!value) {
    return null;
  }

  return (
    <a href={value} target="_blank" rel="noreferrer">{value}</a>
  );
};

UrlValue.propTypes = {
  value: PropTypes.string,
};

UrlValue.defaultProps = {
  value: null,
};

export default UrlValue;
