import { CALL_BB_API } from '../api';
import { ROUTE_TAGS_GET } from '../api/routes';

export const TAGS_FETCH_REQUEST = 'TAGS_FETCH_REQUEST';
export const TAGS_FETCH_SUCCESS = 'TAGS_FETCH_SUCCESS';
export const TAGS_FETCH_FAILURE = 'TAGS_FETCH_FAILURE';

const fetchTags = () => ({
  [CALL_BB_API]: {
    types: [TAGS_FETCH_REQUEST, TAGS_FETCH_SUCCESS, TAGS_FETCH_FAILURE],
    endpoint: ROUTE_TAGS_GET,
    method: 'GET',
  },
});

export const loadTags = () => dispatch => dispatch(fetchTags());

export const ADD_TAG = 'ADD_TAG';

export const addTag = value => ({ type: ADD_TAG, value });
