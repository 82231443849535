import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  CONTENT_COMPONENT_TYPE,
  getYoutubeEmbedUrl,
  contentComponentPropType,
} from '@liswood-tache/browsbox-static';
import { openMediaPicker } from '../../../actions/mediaManager';
import { DEFAULT_VIEW } from '../../../actions/responsive';
import ResizableContainer from '../ImageTools/ResizableContainer/ResizableContainer';
import { getComponentById } from '../../../utils/entities';
import { clearComponentOption, setComponentOption } from '../../../actions/contentConfig';
import ContentItemContext from '../ContentItemContext';
import ImageToolbar from '../ImageTools/ImageToolbar/ImageToolbar';

const propTypes = {
  classes: PropTypes.string,
  containerClassName: PropTypes.string,
  id: PropTypes.number.isRequired,
  openMediaPicker: PropTypes.func.isRequired,
  renderTarget: PropTypes.string.isRequired, // Render default or preview
  type: PropTypes.string,
  url: PropTypes.string,
  component: contentComponentPropType.isRequired,

  clearComponentOption: PropTypes.func.isRequired,
  setComponentOption: PropTypes.func.isRequired,
};

const defaultProps = {
  classes: '',
  containerClassName: '',
  type: CONTENT_COMPONENT_TYPE.image,
  url: '',
};

class ContentComponentVideo extends Component {
  static contextType = ContentItemContext;

  constructor(props) {
    super(props);
    this.enableHoverMode = this.enableHoverMode.bind(this);
    this.disableHoverMode = this.disableHoverMode.bind(this);
    this.onSelectMediaClick = this.onSelectMediaClick.bind(this);
    this.onResetDimensionsClick = this.onResetDimensionsClick.bind(this);
    this.onRemoveClick = this.onRemoveClick.bind(this);

    this.state = {
      embedUrl: props.component ? getYoutubeEmbedUrl(props.component.url) : '',
      isHover: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.component) {
      return;
    }

    const embedUrl = getYoutubeEmbedUrl(this.props.component.url);

    if (this.props.component.url !== prevProps.component.url && prevState.embedUrl !== embedUrl) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        embedUrl,
      });
    }
  }

  onSelectMediaClick(evt) {
    evt.preventDefault();
    const {
      id,
      type,
    } = this.props;
    this.props.openMediaPicker({ id, type });
  }

  onResetDimensionsClick() {
    const { id } = this.props;

    this.props.clearComponentOption({
      option: 'imageWidth',
      contentId: id,
    });

    this.props.clearComponentOption({
      option: 'imageHeight',
      contentId: id,
    });
  }

  onRemoveClick() {
    const { id } = this.props;
    const attributes = ['url', 'alt'];

    attributes.forEach((attribute) => {
      this.props.clearComponentOption({
        option: attribute,
        contentId: id,
      });
    });

    this.props.setComponentOption({
      option: 'type',
      contentId: id,
      value: 'image',
    });
  }

  enableHoverMode() {
    this.setState({ isHover: true });
  }

  disableHoverMode() {
    this.setState({ isHover: false });
  }

  render() {
    const {
      classes,
      containerClassName,
      type,
      renderTarget,
      component,
    } = this.props;

    const {
      embedUrl,
    } = this.state;

    const componentClasses = classNames(
      classes,
      containerClassName,
      `c-${type}`,
    );

    const containerStyle = {
      cursor: 'pointer',
    };

    if (!component) {
      return null;
    }

    // render edit behavior
    if (renderTarget === DEFAULT_VIEW) {
      return (
        <div
          className={componentClasses}
          style={containerStyle}
          onMouseEnter={this.enableHoverMode}
          onMouseLeave={this.disableHoverMode}
        >
          <ResizableContainer
            content={this.context.content}
            component={component}
          >
            {this.state.isHover && (
              <ImageToolbar
                onChooseImageClick={this.onSelectMediaClick}
                onResetDimensionsClick={this.onResetDimensionsClick}
                onRemoveClick={this.onRemoveClick}
              />
            )}
            <div className="c-image__iframe-wrapper">
              <iframe
                className="c-image__iframe"
                title="title"
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen="1"
                src={embedUrl}
                style={{ pointerEvents: 'none' }}
              />
            </div>
          </ResizableContainer>
        </div>
      );
    }
    // render target is preview or html
    return (
      <div className={componentClasses}>
        <ResizableContainer
          content={this.context.content}
          component={component}
          disabled
        >
          <div className="c-image__iframe-wrapper">
            <iframe
              title="title"
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen="1"
              src={embedUrl}
            />
          </div>
        </ResizableContainer>
      </div>
    );
  }
}

ContentComponentVideo.propTypes = propTypes;
ContentComponentVideo.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const component = getComponentById(state.entities.content, id);

  return {
    component,
  };
};

const mapDispatchToProps = {
  openMediaPicker,
  clearComponentOption,
  setComponentOption,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentComponentVideo);
