/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import * as i18n from 'i18next';
import BBSelect from '../../@browsbox-ui/bb-select/BBSelect';
import useBaseModuleCategories from '../../../hooks/baseModule/categories';
import { selectCurrentModuleName } from '../../../selectors/baseModule';

const BaseModuleCategoryFilter = ({
  value, onChange, language, entity,
}) => {
  const { data = [], isLoading } = useBaseModuleCategories({ language, entity });

  const items = data.map(item => ({
    ...item,
    title: `${item.title} (${item.language})`,
  }));

  return (
    <div className={classNames(
      'c-bb-filter c-bb-filter--select c-bb-filter--categories',
      { 'is-loading': isLoading },
    )}
    >
      <BBSelect
        placeholder={i18n.t('BASE_MODULE.allCategories')}
        value={value}
        onChange={onChange}
        items={items}
        clearable
        multi
      />
    </div>
  );
};

BaseModuleCategoryFilter.propTypes = {
  value: PropTypes.array,
  language: PropTypes.string,
  entity: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

BaseModuleCategoryFilter.defaultProps = {
  value: [],
  language: null,
  entity: null,
};

const mapStateToProps = state => ({
  entity: selectCurrentModuleName(state),
});

export default connect(mapStateToProps)(BaseModuleCategoryFilter);
