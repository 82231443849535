import {
  SET_CONTENT_VIEW_OPTION,
  TOGGLE_CONTENT_VIEW_OPTION,
} from '../actions/options';
import {
  RESPONSIVE_VIEW,
  DEFAULT_VIEW,
} from '../actions/responsive';

const initialState = {
  content: {}, // content (aka section ) view options (example: slider edit view is open)
  view: {
    responsiveView: DEFAULT_VIEW, // show a responsive view
  },
};

const options = (state = initialState, action) => {
  switch (action.type) {
    // Set content view option
    case SET_CONTENT_VIEW_OPTION:
    {
      const {
        id,
        option,
        value,
      } = action.value;
      const {
        content,
      } = state;

      const currentViewOption = content[id] || {};
      content[id] = {
        ...currentViewOption,
        [option]: value,
      };

      return {
        ...state,
        content,
      };
    }
    // Toggle content view option,
    // Current implementation only supports boolean value
    case TOGGLE_CONTENT_VIEW_OPTION:
    {
      const {
        id,
        option,
      } = action.value;
      const {
        content,
      } = state;

      const currentViewOption = content[id] || {};
      const value = currentViewOption[option] || false;

      content[id] = {
        ...currentViewOption,
        [option]: !value,
      };

      return {
        ...state,
        content,
      };
    }
    // Show a responsive preview ( smartphone, tablet, desktop )
    case RESPONSIVE_VIEW:
    {
      const { view: currentView } = state;
      const view = { ...currentView, responsiveView: action.value };
      return {
        ...state,
        view,
      };
    }

    default:
      return state;
  }
};

export default options;
