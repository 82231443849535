import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MessageModal from './MessageModal';
import { ROUTE_LOGOUT } from '../../api/routes';
import i18n from '../../internationalization/i18n';

const propTypes = {
  isSessionExpiredModalOpen: PropTypes.bool,
};

const defaultProps = {
  isSessionExpiredModalOpen: false,
};

class SessionExpiredModal extends Component {
  static logout() {
    window.location.href = ROUTE_LOGOUT;
  }

  render() {
    if (this.props.isSessionExpiredModalOpen) {
      return (
        <MessageModal
          onClose={SessionExpiredModal.logout}
          onOk={SessionExpiredModal.logout}
          title={i18n.t('GLOBAL.sessionExpiredModalTitle')}
        >
          {i18n.t('GLOBAL.sessionExpiredModalText')}
        </MessageModal>
      );
    }

    return null;
  }
}

SessionExpiredModal.propTypes = propTypes;
SessionExpiredModal.defaultProps = defaultProps;

const mapStateToProps = state => ({
  isSessionExpiredModalOpen: state.global.isSessionExpiredModalOpen,
});

export default connect(
  mapStateToProps,
  null,
)(SessionExpiredModal);
