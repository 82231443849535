/* eslint-disable react/no-array-index-key,react/prop-types */
import React from 'react';
import Select from 'react-select';

const BBSelect = ({
  items, value, onChange, valueKey = 'id', labelKey = 'title', ...rest
}) => (
  <Select
    value={value}
    onChange={onChange}
    options={items}
    valueKey={valueKey}
    labelKey={labelKey}
    {...rest}
  />
);

export default BBSelect;
