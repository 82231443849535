import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import { getFilterLabels } from '../../selectors';

const propTypes = {
  detailFilters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  filterLabels: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const defaultProps = {
  detailFilters: [],
  filterLabels: [],
};

const getCombinations = function getCombinations(arr, prefix = '') {
  if (!arr.length) return prefix;

  const resultArr = arr[0].reduce((result, value) => result.concat(getCombinations(arr.slice(1), `${prefix}${value},`)), []);
  return resultArr;
};

const getCleanIds = function getCleanIds(arr) {
  const validArr = (typeof arr === 'string') ? [] : arr;
  return validArr.map(item => item.slice(0, -1).split(','));
};

const ProductCodes = ({ detailFilters, filterLabels }) => {
  const values = Object.values(detailFilters).filter(arr => arr.length);
  const inputs = getCleanIds(getCombinations(values));

  return (
    <div>
      {
        inputs.map((codes) => {
          const sortedCodes = codes.sort((a, b) => a - b);
          const codeString = sortedCodes.join(',');
          return (
            <div key={codeString} className="c-bb-form__input" style={{ marginBottom: '10px', paddingTop: '7px' }}>
              <label htmlFor={codeString}>{
                sortedCodes.length === 1
                  ? filterLabels[sortedCodes[0]].toUpperCase()
                  : sortedCodes.map(code => filterLabels[code].toUpperCase()).join(' + ')
              }
              </label>
              <Field
                id={codeString}
                name={codeString}
                component="input"
              />
            </div>
          );
        })
      }
    </div>
  );
};

ProductCodes.propTypes = propTypes;
ProductCodes.defaultProps = defaultProps;

const selector = formValueSelector('baseModuleItemForm');

const mapStateToProps = state => ({
  detailFilters: selector(state, 'detailFilters'),
  filterLabels: getFilterLabels(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductCodes);
