import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import DangerouslyInnerHtml from 'dangerously-set-inner-html';

const dangerouslyAddScriptSource = (pageHtmlContent) => {
  const regex = /<script.*?src="(.*?)"/gmi;
  const scripts = [];
  const scriptsQueue = [];
  let url;

  do {
    url = regex.exec(pageHtmlContent);

    if (url) {
      const script = document.createElement('script');
      script.async = true;
      // eslint-disable-next-line prefer-destructuring
      script.src = url[1];
      document.body.appendChild(script);
      scripts.push(script);
      scriptsQueue.push(new Promise((resolve) => {
        script.onload = function onLoad() { resolve(this); };
      }));
    }
  } while (url);

  return {
    scripts,
    promise: Promise.all(scriptsQueue),
  };
};

const unloadPageScripts = scripts => scripts.filter((script) => {
  script.remove();
  return false;
});

const ExternalHtmlContainer = ({ html, onLoad }) => {
  const loadedScripts = useRef([]);

  useEffect(() => {
    const { scripts, promise } = dangerouslyAddScriptSource(html);
    loadedScripts.current = scripts;
    promise.then(onLoad);

    return () => {
      // remove scripts when component is unmounted
      unloadPageScripts(loadedScripts.current);
    };
  }, [html]);

  return (
    <DangerouslyInnerHtml html={html} />
  );
};

ExternalHtmlContainer.propTypes = {
  html: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
};

ExternalHtmlContainer.defaultProps = {
  onLoad: () => {},
};

// avoid unnecessary re-renders when static html is equal
export default memo(
  ExternalHtmlContainer,
  (prev, next) => prev.html === next.html,
);
