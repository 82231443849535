import slugify from 'slugify';

slugify.extend({
  '<': '-', '>': '-', $: '', '&': '', '%': '', '|': '', '[': '', ']': '',
});

export function normalizeSlug(slug) {
  return slugify(slug || '', {
    replacement: '-',
    remove: /[*+~.,()'"!?;|:#@%^&$_/\\{}=]/g,
    lower: true,
  });
}

export default {
  normalizeSlug,
};
