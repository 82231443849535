import React, { Component } from 'react';
import { connect } from 'react-redux';
import http from 'axios';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PhoneInput from 'react-phone-number-input';
import {
  BbNav, BbNavItem, BbPane, BbPaneGroup,
} from '@browsbox-ui';
import { FormInput } from '../FormElements';
import i18n from '../../internationalization/i18n';
import Skeleton from '../Loader';

export const LINK_TYPE_NONE = 'LINK_NONE';
export const LINK_TYPE_INTERNAL = 'LINK_INTERNAL';
export const LINK_TYPE_EXTERNAL = 'LINK_EXTERNAL';
export const LINK_TYPE_EMAIL = 'LINK_EMAIL';
export const LINK_TYPE_PHONE = 'LINK_PHONE';

const propTypes = {
  hyperlink: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]), // Link data
  validationMessage: PropTypes.string, // Link data
  onChange: PropTypes.func.isRequired, // called on link selection
  onTypeChange: PropTypes.func, // called on link type change
  type: PropTypes.oneOf([LINK_TYPE_NONE, LINK_TYPE_INTERNAL, LINK_TYPE_EXTERNAL, LINK_TYPE_EMAIL, LINK_TYPE_PHONE]), // Link data
  ignoreLinkType: PropTypes.arrayOf(PropTypes.string), // limit display of possible link types
  lang: PropTypes.string,
};

const defaultProps = {
  lang: null,
  hyperlink: false,
  type: LINK_TYPE_INTERNAL,
  validationMessage: '',
  onTypeChange: () => {},
  showInternalLinkFilter: false,
  ignoreLinkType: [LINK_TYPE_NONE],
};

const mapToFlatList = (rootNodes = []) => {
  const list = [];
  const mapNodes = (nodes = [], prefix = '') => nodes.forEach((node) => {
    list.push({
      id: node.id,
      routeFromTitle: prefix ? `${prefix} / ${node.title}` : node.title,
      path: node.route,
      title: node.title,
    });

    if (node.children) {
      mapNodes(node.children, node.title);
    }
  });

  mapNodes(rootNodes);

  return list;
};

class Links extends Component {
  static renderLinkItem(link) {
    const path = link.routeFromTitle.trim();

    return (
      <option key={link.id} value={link.path}>
        {path}
      </option>
    );
  }

  static getDefaultMenuItems() {
    return [
      { id: LINK_TYPE_NONE, name: i18n.t('CONTENT.nolHyperlink') },
      { id: LINK_TYPE_INTERNAL, name: i18n.t('CONTENT.internalHyperlink') },
      { id: LINK_TYPE_EXTERNAL, name: i18n.t('CONTENT.externalHyperlink') },
      { id: LINK_TYPE_EMAIL, name: i18n.t('CONTENT.emailHyperlink') },
      { id: LINK_TYPE_PHONE, name: i18n.t('CONTENT.phoneHyperlink') },
    ];
  }

  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.state = {
      internalLink: '',
      externalLink: '',
      mailLink: '',
      phoneLink: '',
      type: props.type,
      isLoading: false,
      links: [],
    };
    switch (props.type) {
      case LINK_TYPE_INTERNAL:
        this.state.internalLink = props.hyperlink;
        break;
      case LINK_TYPE_EXTERNAL:
        this.state.externalLink = props.hyperlink;
        break;
      case LINK_TYPE_EMAIL:
        this.state.mailLink = props.hyperlink;
        break;
      case LINK_TYPE_PHONE:
        this.state.phoneLink = props.hyperlink;
        break;
      default:
        this.state.internalLink = props.hyperlink;
    }
  }

  async componentDidMount() {
    this.mounted = true;
    await this.loadPages();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onInputChange(evt) {
    const { target: { name, value } } = evt;
    const { type } = this.state;

    if (type !== LINK_TYPE_INTERNAL) {
      this.setState({ [name]: value });
      this.props.onChange({ value, type });
    }
  }

  onSelect(evt) {
    const { target: { name, value } } = evt;
    const { type } = this.state;
    this.setState({
      [name]: value,
    });
    this.props.onChange({ value, type });
  }

  onTypeChange(item) {
    this.setState({
      type: item,
    });
    this.props.onTypeChange(item);
  }

  getMenuItems() {
    const defaultMenuItems = Links.getDefaultMenuItems();
    return defaultMenuItems.filter(link => this.props.ignoreLinkType.indexOf(link.id) === -1);
  }

  loadPages = async () => {
    const { lang } = this.props;
    this.setState({ isLoading: true });
    const params = {};
    if (lang) {
      params.lang = lang;
    } else {
      params.currentUrl = window.location.pathname + window.location.search;
    }
    const { data } = await http.get('/api/routes', { params });
    if (this.mounted) {
      this.setState({
        links: mapToFlatList(data),
        isLoading: false,
      });
    }
  };

  renderInternal() {
    const {
      internalLink,
      links,
      isLoading,
    } = this.state;

    return (
      <div className="o-bb-form-field">
        <div className="o-bb-form__item">
          { links.length ? (
            <span className="o-bb-form__select-wrapper">
              <select className="o-bb-form__select" value={internalLink} onChange={this.onSelect} name="internalLink">
                <option value="">{ i18n.t('CONTENT.selectPage') }</option>
                { links.map(Links.renderLinkItem) }
              </select>
            </span>
          ) : (
            <div>
              {isLoading ? <Skeleton height="50px" /> : i18n.t('CONTENT.noResults')}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderExternal() {
    const {
      externalLink,
    } = this.state;
    const {
      validationMessage,
    } = this.props;
    const classes = classNames(
      'o-bb-form-field',
      { 'o-bb-form-field--error': validationMessage },
    );
    return (
      <div className={classes}>
        <FormInput
          id="link-external"
          name="externalLink"
          onChange={this.onInputChange}
          placeholder={i18n.t('CONTENT.url')}
          size="512"
          type="url"
          value={externalLink}
          autoFocus
        />
        { validationMessage && (
          <div className="o-bb-form-field--validation">{validationMessage}</div>
        )}
      </div>
    );
  }

  renderEmail() {
    const {
      mailLink,
    } = this.state;
    const {
      validationMessage,
    } = this.props;
    const classes = classNames(
      'o-bb-form-field',
      { 'o-bb-form-field--error': validationMessage },
    );
    return (
      <div className={classes}>
        <FormInput
          id="mail-external"
          name="mailLink"
          onChange={this.onInputChange}
          placeholder={i18n.t('CONTENT.email')}
          type="email"
          value={mailLink}
          autoFocus
        />
        { validationMessage && (
          <div className="o-bb-form-field--validation">{validationMessage}</div>
        )}
      </div>
    );
  }

  renderPhone() {
    const {
      phoneLink,
    } = this.state;

    const {
      validationMessage,
    } = this.props;

    const classes = classNames(
      'o-bb-form-field',
      'o-bb-link-picker--phone',
      { 'o-bb-form-field--error': validationMessage },
    );

    return (
      <div className={classes}>
        <PhoneInput
          numberInputProps={{
            autoFocus: true,
            placeholder: i18n.t('CONTENT.phoneHyperlink'),
          }}
          value={phoneLink}
          onChange={(nextValue) => {
            this.setState({ phoneLink: nextValue });
            this.props.onChange({ value: nextValue || '', type: LINK_TYPE_PHONE });
          }}
        />
        { validationMessage && (
          <div className="o-bb-form-field--validation">{validationMessage}</div>
        )}
      </div>
    );
  }

  renderMediaPanel() {
    const { type } = this.state;
    switch (type) {
      case LINK_TYPE_INTERNAL:
        return this.renderInternal();
      case LINK_TYPE_EXTERNAL:
        return this.renderExternal();
      case LINK_TYPE_EMAIL:
        return this.renderEmail();
      case LINK_TYPE_PHONE:
        return this.renderPhone();
      case LINK_TYPE_NONE:
        return null;
      default:
        return this.renderInternal();
    }
  }

  render() {
    const {
      type,
    } = this.state;
    const menuItems = this.getMenuItems();

    return (
      <div>
        <BbNav>
          {menuItems.map(menuItem => (
            <BbNavItem
              key={menuItem.id}
              active={menuItem.id === type}
              onClick={() => this.onTypeChange(menuItem.id)}
            >
              {menuItem.name}
            </BbNavItem>
          ))}
        </BbNav>
        <BbPaneGroup>
          {menuItems.map(menuItem => (
            <BbPane
              key={menuItem.id}
              active={menuItem.id === type}
            >
              {this.renderMediaPanel()}
            </BbPane>
          ))}
        </BbPaneGroup>
      </div>
    );
  }
}

Links.propTypes = propTypes;
Links.defaultProps = defaultProps;

const mapStateToProps = state => ({
  lang: state.content.linkPickerLang,
});

export default connect(mapStateToProps)(Links);
