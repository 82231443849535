import { CALL_BB_API } from '../api';

export const MAP_MARKERS_REQUEST = 'MAP_MARKERS_REQUEST';
export const MAP_MARKERS_SUCCESS = 'MAP_MARKERS_SUCCESS';
export const MAP_MARKERS_FAILURE = 'MAP_MARKERS_FAILURE';

const fetchMapMarkers = url => ({
  [CALL_BB_API]: {
    types: [MAP_MARKERS_REQUEST, MAP_MARKERS_SUCCESS, MAP_MARKERS_FAILURE],
    endpoint: url,
    method: 'GET',
  },
});

// Redux Thunk middleware.
const loadMapMarkers = url => dispatch => dispatch(fetchMapMarkers(url));

export default loadMapMarkers;
