/* eslint-disable react/prop-types */
import React from 'react';
import { renderError } from '../../tools/validation';
import RangeSlider from './RangeSlider';

const RangeField = ({
  input,
  settings: { min, max, step },
  meta: { touched, error },
  label,
}) => (
  <div className="c-bb-form__input c-bb-form__range-slider">
    {label && <label htmlFor={input.name}>{label}</label>}
    <span className="c-bb-form__range-slider__value">{input.value}</span>
    <RangeSlider
      value={+input.value}
      onChange={input.onChange}
      min={+min}
      step={+step}
      max={+max}
    />
    {touched && error
      && <span className="c-bb-form__input-error">{renderError(error)}</span>}
  </div>
);

export default RangeField;
