import React from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { renderError } from '../../tools/validation';

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  dateFormat: PropTypes.string.isRequired,
};

const defaultProps = {
  label: null,
};

const DateField = ({
  input: { onChange, onBlur, value },
  meta: { touched, error },
  label,
  dateFormat,
}) => {
  const selected = value && moment(value, dateFormat, true);
  return (
    <div className="c-bb-form__input">
      {label && <label htmlFor="title">{label}</label>}
      <DatePicker
        selected={selected && selected.isValid()
          ? selected
          : null}
        onChange={date => date && onChange(date.format(dateFormat))}
        onChangeRaw={event => onBlur(event.target.value)}
        dateFormat={dateFormat}
        disabledKeyboardNavigation
      />
      {touched && error
      && <span className="c-bb-form__input-error">{renderError(error)}</span>}
    </div>
  );
};

DateField.propTypes = propTypes;
DateField.defaultProps = defaultProps;

export default withNamespaces()(DateField);
