import { CALL_BB_API, buildRoute } from '../api';
import {
  ROUTE_PAGE_PUBLISH,
  ROUTE_PAGE_UNPUBLISH,
} from '../api/routes';

import {
  PAGE_PROPS_SUCCESS,
  PAGE_PROPS_FAILURE,
} from './pageProperties';

export const PAGE_PUBLISH = 'PAGE_PUBLISH';
export const PAGE_UNPUBLISH = 'PAGE_UNPUBLISH';
// BrowsBox API middleware.
const publishPageCall = (id, route, type) => {
  const endpoint = buildRoute(route, { id });
  return {
    [CALL_BB_API]: {
      types: [type, PAGE_PROPS_SUCCESS, PAGE_PROPS_FAILURE],
      endpoint,
      method: 'PATCH',
    },
  };
};

// Redux Thunk middleware.
export const publishPage = id => dispatch => dispatch(
  publishPageCall(id, ROUTE_PAGE_PUBLISH, PAGE_PUBLISH),
);
export const unpublishPage = id => dispatch => dispatch(
  publishPageCall(id, ROUTE_PAGE_UNPUBLISH, PAGE_UNPUBLISH),
);
