import {
  takeLatest, select, all, put,
} from 'redux-saga/effects';
import {
  BASE_MODULE_ITEM_SAVE_SUCCESS,
  BASE_MODULE_ITEM_UPDATE_SUCCESS,
  BASE_MODULE_ITEM_DELETE_SUCCESS,
  BASE_MODULE_ITEM_PUBLISH_SUCCESS,
  BASE_MODULE_ITEM_UNPUBLISH_SUCCESS,
  BASE_MODULE_ITEMS_ORDER_SUCCESS,
} from '../../actions/baseModuleItems';
import { fetchDynamicContent } from '../../actions/dynamicContent';

function* handleBaseModuleItemSave() {
  const dynamicContents = yield select(state => state.content.dynamicContent);
  const urls = Object.keys(dynamicContents || {});

  yield all(urls.map(url => put(fetchDynamicContent(url))));
}

export default function* () {
  yield takeLatest([
    BASE_MODULE_ITEM_SAVE_SUCCESS,
    BASE_MODULE_ITEM_UPDATE_SUCCESS,
    BASE_MODULE_ITEM_DELETE_SUCCESS,
    BASE_MODULE_ITEM_PUBLISH_SUCCESS,
    BASE_MODULE_ITEM_UNPUBLISH_SUCCESS,
    BASE_MODULE_ITEMS_ORDER_SUCCESS,
  ], handleBaseModuleItemSave);
}
