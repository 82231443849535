import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const MenuItem = ({
  children, onClick, bordered, selected, className, left, right,
}) => (
  <li
    className={classNames(
      'c-bb-menu__item',
      { 'c-bb-menu__item--bordered': bordered },
      { 'c-bb-menu__item--selected': selected },
      className,
    )}
  >
    <button
      type="button"
      className="c-bb-menu__item__button"
      onClick={onClick}
    >
      {left}
      <span className="c-bb-menu__item__label">{children}</span>
      {right}
    </button>
  </li>
);

MenuItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
  bordered: PropTypes.bool,
  selected: PropTypes.bool,
};

MenuItem.defaultProps = {
  className: '',
  left: null,
  right: null,
  bordered: false,
  selected: false,
};

export default MenuItem;
