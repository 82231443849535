import React from 'react';
import ReactDOMServer from 'react-dom/server';
import ContentList from './ContentList';

export default function render(content) {
  const renderTarget = 'html';
  return ReactDOMServer.renderToStaticMarkup(
    <ContentList
      content={content.sections}
      renderTarget={renderTarget}
    />,
  );
}
