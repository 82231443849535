import { put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import { expandPage, PAGES_SUCCESS, PAGES_REQUEST } from '../../actions/pages';
import { getPageHeader } from '../../actions/pageHtml';

/**
 * Watches every page's success requests and expands current page
 *
 * @param action
 * @returns {IterableIterator<*|PutEffect<{id, type}>>}
 */
function* expandCurrentPage(action) {
  const { response } = action;
  const pages = get(response, 'entities.pages');
  const { pathname } = window.location;

  if (!pages) {
    return;
  }

  const activePage = Object.keys(pages)
    .map(key => pages[key])
    .filter(page => page.route === pathname)
    .pop();

  if (!activePage) {
    return;
  }

  yield put(expandPage(activePage.id));
}

function* fetchPageHeader() {
  yield put(getPageHeader());
}

export default function* watchSuccessPages() {
  yield takeLatest(PAGES_SUCCESS, expandCurrentPage);
  yield takeLatest(PAGES_REQUEST, fetchPageHeader);
}
