import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from './ContentComponentImage';
import CONTENT_COMPONENT_TYPE from './types';

const optionalComponentProp = PropTypes.oneOfType([PropTypes.bool, PropTypes.string]);

class ContentComponentSliderImage extends Component {
  renderImage() {
    const {
      alt,
      url,
      tooltip,
      containerClassName,
    } = this.props;
    const component = {
      url, tooltip, alt, lightbox: false,
    };
    return (
      <Image
        {...component}
        containerClassName={containerClassName}
      />
    );
  }

  render() {
    const {
      classes,
    } = this.props;
    const type = CONTENT_COMPONENT_TYPE.galleryImage;
    const componentClasses = classNames(
      classes,
      `c-${type}`,
    );

    return (
      <div className={componentClasses}>
        {this.renderImage()}
      </div>
    );
  }
}

ContentComponentSliderImage.propTypes = {
  alt: PropTypes.string, // Section Component data
  buttonContent: optionalComponentProp, // Section Component data
  buttonHyperlink: optionalComponentProp, // Section Component data
  classes: PropTypes.string, // Section Component data,
  containerClassName: PropTypes.string,
  subtitle: optionalComponentProp, // Section Component data
  title: optionalComponentProp, // Section Component data
  tooltip: PropTypes.string, // Section Component data
  type: PropTypes.string, // Section Component data
  url: PropTypes.string, // Section Component data
  visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]), // Section Component data
};

// Optional component properties (title, subtitle, buttonContent, buttonHyperlink, ... ) will
// be false if they are not present in the module (aka blocks) definition
ContentComponentSliderImage.defaultProps = {
  alt: '',
  buttonContent: false,
  buttonHyperlink: false,
  classes: '',
  containerClassName: '',
  subtitle: false,
  title: false,
  tooltip: '',
  type: CONTENT_COMPONENT_TYPE.galleryImage,
  url: '',
  visible: true,
};

export default ContentComponentSliderImage;
