import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const bbBlank = (props) => {
  const {
    className,
    ...attributes
  } = props;

  const classes = classNames(
    className,
    'o-bb-blank',
  );

  return (
    <div className={classes} {...attributes} />
  );
};

bbBlank.propTypes = propTypes;
bbBlank.defaultProps = defaultProps;

export default bbBlank;
