import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import BaseModuleUpdate from './BaseModuleUpdate';
import BaseModuleCreate from './BaseModuleCreate';
import {
  getFieldTypes,
  getLangs,
  getBaseModuleList,
  openBaseModuleView,
  setCurrentModule,
} from '../../actions/baseModule';
import { loadMedia } from '../../actions/media';
import BaseModuleItems from './BaseModuleItems/BaseModuleItems';
import BaseModuleItemCreate from './BaseModuleItems/BaseModuleItemEdit/BaseModuleItemCreate';
import BaseModuleItemEdit from './BaseModuleItems/BaseModuleItemEdit/BaseModuleItemEdit';
import { getNsiProductTypes, getNsiDetailFilters } from '../../actions/baseModuleItems';
import { getModuleByName, selectBaseModuleIsLoading } from '../../selectors';
import LanguageContext from '../Languages/LanguageContext';
import useDisableScroll from '../../hooks/common/use-disable-body-scroll';
import BaseModuleLoader from './BaseModuleLoader';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  getBaseModuleList: PropTypes.func.isRequired,
  openBaseModuleView: PropTypes.func.isRequired,
  domain: PropTypes.string,
  getNsiDetailFilters: PropTypes.func.isRequired,
  baseModuleLang: PropTypes.string,
};

const defaultProps = {
  isLoading: true,
  domain: null,
  baseModuleLang: null,
};

const withCurrentModuleSync = Component => connect((state, ownProps) => ({
  currentModule: getModuleByName(state, ownProps.match.params.name),
}), {
  setCurrentModule,
})((props) => {
  // Sync current module with store for backward compatibility
  React.useEffect(() => {
    props.setCurrentModule(props.currentModule);
  }, [props.currentModule.internal_name]);

  return (
    <Component {...props} />
  );
});

const EnhancedUpdateView = withCurrentModuleSync(BaseModuleUpdate);
const EnhancedItemsView = withCurrentModuleSync(BaseModuleItems);

const BaseModuleContainer = (props) => {
  const { isOpen, baseModuleLang, isLoading } = props;
  useDisableScroll(isOpen);

  React.useEffect(() => {
    if (!isOpen && window && window.location.hash.startsWith('#/base-module')) {
      props.openBaseModuleView();
    }
  }, []);

  React.useEffect(() => {
    props.loadMedia();
    props.getFieldTypes();
    props.getLangs();
    props.getBaseModuleList();
  }, []);

  React.useEffect(() => {
    const { domain } = props;
    if (domain && domain === 'nsi-be.com') {
      props.getNsiProductTypes();
      props.getNsiDetailFilters();
    }
  }, [props.domain]);

  const providerValue = React.useMemo(() => ({
    lang: baseModuleLang,
  }), [baseModuleLang]);

  if (!isOpen) {
    return null;
  }

  if (isLoading) {
    return (
      <BaseModuleLoader />
    );
  }

  return (
    <LanguageContext.Provider value={providerValue}>
      <Switch>
        <Route path="/create" component={BaseModuleCreate} exact />
        <Route path="/edit/:name" component={EnhancedUpdateView} exact />
        <Route path="/items/:name" component={EnhancedItemsView} exact />
        <Route path="/item/create/:name" component={BaseModuleItemCreate} exact />
        <Route path="/item/edit/:name/:id" component={BaseModuleItemEdit} exact />
      </Switch>
    </LanguageContext.Provider>
  );
};

BaseModuleContainer.propTypes = propTypes;
BaseModuleContainer.defaultProps = defaultProps;

const mapStateToProps = state => ({
  isOpen: state.baseModule.isOpen,
  isLoading: selectBaseModuleIsLoading(state),
  baseModulesList: state.baseModule.modulesList,
  domain: state.global.websiteSettings.domain,
  baseModuleLang: state.baseModule.lang,
});

const mapDispatchToProps = {
  loadMedia,
  getFieldTypes,
  getLangs,
  getBaseModuleList,
  getNsiProductTypes,
  getNsiDetailFilters,
  openBaseModuleView,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(BaseModuleContainer));
