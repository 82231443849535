/* eslint-disable */

if (window.tinymce) {
  window.tinyMCE.PluginManager.add('preventDelete', function(editor, url) {

    editor.on('change', function (event) {
      const selection = editor.selection;
      if (!selection) {
        return;
      }

      const elem = selection.getNode();
      const value = String(elem.innerText).trim();

      if (!value) {
        event.preventDefault();
        return false;
      }
    });

    editor.on('keydown', function(e) {
      //delete keys
      if (e.keyCode === 8 || e.keyCode === 46) {
        try {
          const startOffset = editor.selection.getRng().startOffset;
          const elem = editor.selection.getNode();
          const tagName = elem.tagName;
          const text = elem.textContent;
            if (tagName === 'A' && (text.replace('&#65279;', '').replace(/[\u200B-\u200D\uFEFF]/g, '').length <= 1 || startOffset === 0)) {
              elem.innerHTML = '&nbsp';
              e.preventDefault();
              e.stopPropagation();
              return false;
            }
        } catch (e) {}
      }
    });
  });
}
