import { CALL_BB_API } from '../api';

export const DYNAMIC_CONTENT_REQUEST = 'DYNAMIC_CONTENT_REQUEST';
export const DYNAMIC_CONTENT_SUCCESS = 'DYNAMIC_CONTENT_SUCCESS';
export const DYNAMIC_CONTENT_FAILURE = 'DYNAMIC_CONTENT_FAILURE';

// BrowsBox API middleware.
export const fetchDynamicContent = url => ({
  [CALL_BB_API]: {
    types: [DYNAMIC_CONTENT_REQUEST, DYNAMIC_CONTENT_SUCCESS, DYNAMIC_CONTENT_FAILURE],
    endpoint: url,
    method: 'GET',
  },
});

// Redux Thunk middleware.
export const loadDynamicContent = url => dispatch => dispatch(fetchDynamicContent(url));
