/* eslint-disable react/forbid-prop-types,react/no-unused-prop-types,class-methods-use-this */
import React, { Component } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import keyBy from 'lodash/keyBy';
import { BbPopup, BbReactSortableTreeTheme } from '@browsbox-ui';
import PropTypes from 'prop-types';
import SortableTree, { getFlatDataFromTree, walk } from 'react-sortable-tree';
import Spinner from '../Spinner';
import Languages from '../Languages';
import BrowsboxPageNew from './PageNew';
import { loadLanguages } from '../../actions/languages';
import i18n from '../../internationalization/i18n';
import PageContext from './PageContext';
import { Gateway } from '../Gateway';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';

const popupClasses = 'o-bb-popup--config';
const popupItemClasses = 'o-bb-popup__action';

export class SortablePageList extends Component {
  static propTypes = {
    maxMenuLevels: PropTypes.number.isRequired,
    pages: PropTypes.array.isRequired,
    pageItems: PropTypes.array.isRequired,
    showProperties: PropTypes.func.isRequired,
    languages: PropTypes.array.isRequired,
    onReorder: PropTypes.func.isRequired,
    onDuplicate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    isPagesReorderPending: PropTypes.bool.isRequired,
  };

  static contextType = PageContext;

  constructor(props) {
    super(props);
    this.handleItemsReorder = this.handleItemsReorder.bind(this);
    this.onShowDuplicate = this.onShowDuplicate.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);

    this.state = {
      pages: props.pages,
    };
  }

  componentDidUpdate(prevProps) {
    const { pages } = this.props;

    if (pages && pages.length && !isEqual(pages, prevProps.pages)) {
      const expandedPages = [];

      // collect expanded pages before update
      walk({
        treeData: this.state.pages,
        callback: ({ node }) => {
          if (node.expanded) {
            expandedPages.push(node.id);
          }
        },
        getNodeKey: ({ node }) => node.id,
      });

      this.getDataForCompare(pages).forEach((page) => {
        if (this.isPageActive(page)) {
          expandedPages.push(...page.path);
        }
      });

      // expand selected pages, so they will not collapse after api update
      walk({
        treeData: pages,
        callback: (item) => {
          if (expandedPages.includes(item.node.id)) {
            // eslint-disable-next-line no-param-reassign
            item.node.expanded = true;
          }
        },
        getNodeKey: ({ node }) => node.id,
      });

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ pages });
    }
  }

  handleItemsReorder(items) {
    const current = this.getDataForCompare(items);
    const prev = this.getDataForCompare(this.state.pages);

    if (isEqual(current, prev)) {
      this.setState({
        pages: items,
      });

      return;
    }

    const pageOrder = items.map(page => page.id);
    const flatTree = keyBy(this.mapToFlatData(items), 'id');

    this.props.onReorder(flatTree, pageOrder, items);
  }

  onShowDuplicate(node) {
    this.props.onDuplicate(node);
  }

  onDeleteClick(node) {
    this.props.onDelete(node);
  }

  getDataForCompare = items => getFlatDataFromTree({
    treeData: items,
    getNodeKey: ({ node }) => node.id,
    ignoreCollapsed: false,
  }).map(({ node, path }) => ({
    id: node.id,
    title: node.title,
    route: node.route,
    path,
    parent: path.length > 1 ? path[path.length - 2] : null,
  }));

  mapToFlatData = items => getFlatDataFromTree({
    treeData: items,
    getNodeKey: ({ node }) => node.id,
    ignoreCollapsed: false,
  }).map(({ node }) => ({
    ...node,
    children: node.children.map(child => child.id),
  }));

  nodeConfigId = node => `node-menu-config-${node.id}`;

  isPageActive = node => node.route === window.location.pathname;

  scrollElementTo = (node, offset, duration = 200) => new Promise((resolve) => {
    node.scrollTo({
      top: offset,
      duration,
      behavior: 'smooth',
    });
    setTimeout(resolve, duration);
  });

  handleDocumentMouseMove = async (event) => {
    if (!this.container || this.scrolling) {
      return;
    }

    const grid = this.container.querySelector('[role="grid"]');

    if (!grid) {
      return;
    }

    const { clientY } = event;
    const react = this.container.getBoundingClientRect();
    const { top } = react;
    const bottom = top + react.height;
    const offset = 100;

    this.scrolling = true;
    if (clientY < (top + offset)) {
      await this.scrollElementTo(grid, grid.scrollTop - offset);
    }

    if (clientY > (bottom - offset)) {
      await this.scrollElementTo(grid, grid.scrollTop + offset);
    }
    this.scrolling = false;
  };

  handleDragStateChange = ({ isDragging }) => {
    if (isDragging) {
      document.addEventListener('mousemove', this.handleDocumentMouseMove);
    } else {
      document.removeEventListener('mousemove', this.handleDocumentMouseMove);
    }
  };

  renderPageSettings(node) {
    return (
      <a
        className={popupItemClasses}
        onClick={() => this.props.showProperties(node)}
      >
        <FontAwesomeIcon name="far fa-cog" />{i18n.t('CONTENT.pageSettings')}
      </a>
    );
  }

  renderPageModules(node) {
    return (
      <a
        onClick={() => this.context.redirect(`${node.route}?tab=modules`)}
        className={popupItemClasses}
      >
        <FontAwesomeIcon name="fas fa-pencil" />{i18n.t('CONTENT.modify')}
      </a>
    );
  }

  renderDuplicate(node) {
    const { copiable } = node;
    const enabled = copiable !== false;
    const icon = <FontAwesomeIcon name="far fa-clone" />;
    const classes = classNames(
      { disabled: !enabled },
      { 'o-disabled': !enabled },
      popupItemClasses,
    );

    if (enabled) {
      return (
        <a
          className={classes}
          onClick={() => this.onShowDuplicate(node)}
        >
          {icon}{i18n.t('CONTENT.duplicate')}
        </a>
      );
    }

    return (
      <span className={classes}>
        {icon}{i18n.t('CONTENT.duplicate')}
      </span>
    );
  }

  renderDelete(node) {
    const { deletable } = node;
    const enabled = deletable !== false;
    const icon = <FontAwesomeIcon name="fas fa-trash" />;
    const classes = classNames(
      { disabled: !enabled },
      { 'o-disabled': !enabled },
      popupItemClasses,
    );

    if (enabled) {
      return (
        <a className={classes} onClick={() => this.onDeleteClick(node)}>
          {icon}{i18n.t('CONTENT.delete')}
        </a>
      );
    }

    return (
      <span className={classes}>
        {icon}{i18n.t('CONTENT.delete')}
      </span>
    );
  }

  renderNodeConfigMenu = ({ node, hideConfigMenu }) => {
    const tetherTarget = this.nodeConfigId(node);
    const classes = classNames(
      'navupdate active o-bb-nav-config',
      popupClasses,
    );

    return (
      <Gateway into="popup">
        <BbPopup
          className={classes}
          target={tetherTarget}
          onClose={hideConfigMenu}
          onMouseLeave={hideConfigMenu}
          placement="bottom left"
        >
          <div onClick={hideConfigMenu}>
            { this.renderPageSettings(node) }
            { this.renderPageModules(node) }
            { this.renderDuplicate(node) }
            { this.renderDelete(node) }
          </div>
        </BbPopup>
      </Gateway>
    );
  };

  renderSortableTree = () => {
    const { pages } = this.state;

    if (!pages || pages.length === 0) {
      return (
        <Spinner />
      );
    }

    return (
      <SortableTree
        treeData={pages}
        onChange={this.handleItemsReorder}
        generateNodeProps={() => ({
          renderConfigMenu: this.renderNodeConfigMenu,
          nodeConfigId: this.nodeConfigId,
          active: this.isPageActive,
        })}
        onDragStateChanged={this.handleDragStateChange}
        theme={BbReactSortableTreeTheme}
        maxDepth={this.props.maxMenuLevels}
      />
    );
  };

  render() {
    const navigationClasses = classNames(
      'bb-page-list__navigation-container',
      { 'bb-page-list__navigation-container--is-pending': this.props.isPagesReorderPending },
    );

    return (
      <nav className="bb-page-list">
        <header className="bb-page-list__header">
          <Languages languages={this.props.languages} />
          <BrowsboxPageNew />
        </header>
        <div className={navigationClasses} ref={(node) => { this.container = node; }}>
          { this.renderSortableTree() }
        </div>
      </nav>
    );
  }
}

const filterPages = page => page !== null;

const mapPageItem = (pages, page) => {
  if (!page.in_editor) {
    return null;
  }

  return {
    ...page,
    children: page.children
      ? page.children.map(childId => mapPageItem(pages, pages[childId])).filter(filterPages)
      : [],
  };
};

const mapStateToProps = (state, ownProps) => {
  const defaultMaxMenuLevels = 2;
  const {
    entities: {
      pages,
    },
    global: {
      websiteSettings,
    },
    pages: {
      isPagesReorderPending,
    },
  } = state;
  const ids = ownProps.pageItems.map(page => page.id);

  return {
    languages: state.entities.languages,
    pages: ids.map(pageId => mapPageItem(pages, pages[pageId])).filter(filterPages),
    maxMenuLevels: websiteSettings.maxMenuLevels || defaultMaxMenuLevels,
    isPagesReorderPending,
  };
};

const mapDispatchToProps = {
  loadLanguages,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(SortablePageList));
