import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const renderTitle = (title) => {
  if (title) {
    return <title>{title}</title>;
  }

  return null;
};

const Icon = ({
  name, small, medium, className, title, ...other
}) => (
  <svg
    className={classNames(
      'icon',
      { 'icon--small': small },
      { 'icon--medium': medium },
      className,
    )}
    {...other}
  >
    {renderTitle(title)}
    <use xlinkHref={`#${name}`} />
  </svg>
);

Icon.defaultProps = {
  className: '',
  title: '',
  small: false,
  medium: false,
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default Icon;
