import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BbButton } from '@browsbox-ui';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import i18n from '../../internationalization/i18n';
import Modal from './Modal';

const propTypes = {
  children: PropTypes.node, // Modal data
  icon: PropTypes.string, // Modal data
  onCancel: PropTypes.func.isRequired, // Modal data
  onClose: PropTypes.func.isRequired, // Modal data
  onOk: PropTypes.func.isRequired, // Modal data
  submitButtonText: PropTypes.string, // Modal data
  title: PropTypes.string.isRequired, // Modal data
};

const defaultProps = {
  children: null,
  icon: '',
  submitButtonText: null,
};

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.onOk = this.onOk.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onOk() {
    this.props.onOk();
  }

  onCancel(evt) {
    evt.preventDefault();
    this.props.onCancel();
  }

  render() {
    const {
      children,
      icon,
      onClose,
      title,
    } = this.props;

    const submitButtonText = this.props.submitButtonText || i18n.t('CONTENT.save');

    return (
      <Modal
        onClose={onClose}
        backdropClassName="o-bb-backdrop--high"
        modalClassName="o-bb-modal--confirmation"
      >
        <ModalHeader icon={icon} title={title} />
        <div className="o-bb-modal__content">
          {children}
        </div>
        <Modal.Context>
          {({ closeModal }) => (
            <ModalFooter>
              <BbButton className="c-bb-button--primary" onClick={this.onOk} text={submitButtonText} />
              <BbButton className="c-bb-button--secondary" onClick={closeModal} text={i18n.t('CONTENT.cancel')} />
            </ModalFooter>
          )}
        </Modal.Context>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = propTypes;
ConfirmationModal.defaultProps = defaultProps;

const mapStateToProps = state => ({
  pageTypes: state.entities.pageTypes,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmationModal);
