import { pageSettings as pageSettingsAction } from '../actions/pages';

const getWindowGlobal = (name, fallback) => {
  if (typeof window[name] === 'undefined') {
    return fallback;
  }
  return window[name];
};

const pageSettings = (store) => {
  const settings = {
    isSuperAdmin: false,
    ...window.$settings,
    themeEditorAvailable: getWindowGlobal('themeEditorAvailable', false),
    adminAssetPath: getWindowGlobal('adminAssetPath', '/'),
  };
  store.dispatch(pageSettingsAction(settings));
};

export default pageSettings;
