/* eslint-disable react/forbid-prop-types,react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import debounce from 'lodash/debounce';
import BaseModuleCategoryFilter from './BaseModuleCategoryFilter';
import i18n from '../../../internationalization/i18n';
import Icon from '../../Icon/Icon';
import BaseModuleLanguageFilter from './BaseModuleLanguageFilter';
import IconButton from '../../@browsbox-ui/bb-icon-button';

const SearchFilter = ({ value, onChange }) => {
  const [inputVal, setInputVal] = React.useState(value);

  const commitChange = React.useCallback(debounce((val) => {
    onChange(val);
  }, 200), [onChange]);

  React.useEffect(() => {
    commitChange(inputVal);
  }, [inputVal, commitChange]);

  return (
    <div className="c-bb-filter c-bb-filter--search">
      <Icon name="search" medium />
      <input
        className="c-bb-filter__input"
        placeholder={i18n.t('BASE_MODULE.searchByName').toUpperCase()}
        type="text"
        value={inputVal}
        onChange={event => setInputVal(event.currentTarget.value)}
      />
      {inputVal && (
        <IconButton small noPadding onClick={() => setInputVal('')}>
          <Icon name="close" medium style={{ fill: '#999', transform: 'scale(1.2)' }} />
        </IconButton>
      )}
    </div>
  );
};

const BaseModuleItemsFilters = ({ onChange, initialFilters = {} }) => {
  const [filters, setFilters] = React.useState(initialFilters);

  React.useEffect(() => {
    onChange(filters);
  }, [onChange, filters]);

  const setFilterValue = React.useCallback((filter, value) => {
    setFilters(prev => ({
      ...prev,
      [filter]: value,
    }));
  }, [setFilters]);

  const handleSearchChange = React.useCallback(
    value => setFilterValue('search', value),
    [setFilterValue],
  );

  return (
    <div className="c-bb-filters">
      <SearchFilter
        value={filters.search || ''}
        onChange={handleSearchChange}
      />
      <BaseModuleCategoryFilter
        language={filters.language ? filters.language.code : undefined}
        value={filters.categories}
        onChange={value => setFilterValue('categories', value)}
      />
      <BaseModuleLanguageFilter
        value={filters.language}
        onChange={value => setFilterValue('language', value)}
      />
    </div>
  );
};

BaseModuleItemsFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default withNamespaces()(BaseModuleItemsFilters);
