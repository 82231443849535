import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { useFolderContext } from './FolderContext';
import { deleteMediaFolder, moveMediaFolder } from '../../../actions/media';
import FolderEdit from './FolderEdit';
import FolderDeleteConfirmationModal from './FolderDeleteConfirmationModal';
import FolderPickerModal from '../FolderPicker/FolderPickerModal';

const AbstractItemView = (props) => {
  const {
    id, currentFolderId, moveFolder, component: Component, removeFolder, ...rest
  } = props;
  const [editing, setEditing] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [moving, setMoving] = useState(false);
  const { id: contextMenuId, setId, onClick: handleClick } = useFolderContext();
  const showMenu = () => setId(id);
  const hideMenu = () => setId(null);
  const menuVisible = contextMenuId === id;

  const onClick = () => {
    handleClick(id);
  };

  const onRemove = () => {
    setRemoving(true);
  };

  const onRename = () => {
    setEditing(true);
  };

  const onMove = () => {
    setMoving(true);
  };

  const handleFolderMove = (dist) => {
    if (dist.id === 0) {
      if (currentFolderId) {
        // move to root when currently we are somewhere in other folder
        moveFolder({ id, parent: null });
      }
      return;
    }

    moveFolder({ id, parent: dist.id });
  };

  const handleFolderRemove = () => {
    removeFolder({ id });
  };

  const isDisabled = folder => [id, currentFolderId].filter(Boolean).includes(folder.id);

  const canExpand = folder => id !== folder.id;

  const extraProps = {
    showMenu,
    hideMenu,
    menuVisible,
    onRemove,
    onRename,
    onClick,
    onMove,
  };

  return (
    <>
      <Component id={id} {...rest} {...extraProps} />
      {editing && (
        <FolderEdit id={id} name={props.name} onClose={() => setEditing(false)} />
      )}
      {removing && (
        <FolderDeleteConfirmationModal
          id={id}
          name={props.name}
          onConfirm={handleFolderRemove}
          onClose={() => setRemoving(false)}
        />
      )}
      {moving && (
        <FolderPickerModal
          canExpand={canExpand}
          isDisabled={isDisabled}
          onSelect={handleFolderMove}
          onClose={() => setMoving(false)}
        />
      )}
    </>
  );
};

AbstractItemView.propTypes = {
  id: PropTypes.number.isRequired,
  currentFolderId: PropTypes.number,
  name: PropTypes.string.isRequired,
  removeFolder: PropTypes.func.isRequired,
  moveFolder: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
};

AbstractItemView.defaultProps = {
  currentFolderId: null,
};

const mapStateToProps = state => ({
  currentFolderId: state.media.folderId,
});

const mapDispatchToProps = {
  removeFolder: deleteMediaFolder,
  moveFolder: moveMediaFolder,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(AbstractItemView));
