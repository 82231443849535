import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import PromptModal from '../../Modals/PromptModal';
import { updateMediaFolder } from '../../../actions/media';

const FolderEdit = ({
  id, name, updateFolder, onClose, t,
}) => {
  const handleUpdate = async ({ value }) => {
    await updateFolder({ id, name: value });
    onClose();
  };

  return (
    <PromptModal
      key={id}
      icon="icon-basic_sheet"
      onCancel={onClose}
      onClose={onClose}
      onOk={handleUpdate}
      submitButtonText={t('MEDIA.folderEditButton')}
      title={t('MEDIA.folderEditTitle')}
      value={name}
      valueMaxLength={100}
      label={t('MEDIA.folderEditLabel')}
      showRequired={false}
    />
  );
};

FolderEdit.propTypes = {
  updateFolder: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  name: PropTypes.string,
  id: PropTypes.number.isRequired,
};

FolderEdit.defaultProps = {
  name: '',
};

const mapDispatchToProps = {
  updateFolder: updateMediaFolder,
};

export default connect(null, mapDispatchToProps)(
  withNamespaces()(FolderEdit),
);
