export const LINK_TYPE_NONE = 'LINK_NONE';
export const LINK_TYPE_INTERNAL = 'LINK_INTERNAL';
export const LINK_TYPE_EXTERNAL = 'LINK_EXTERNAL';
export const LINK_TYPE_EMAIL = 'LINK_EMAIL';

export const getLinkType = (hyperlink, defaultLinkType = LINK_TYPE_NONE) => {
  if (hyperlink === '') {
    return defaultLinkType;
  } if (hyperlink === false) {
    return LINK_TYPE_INTERNAL;
  } if (hyperlink.indexOf('/') === 0) {
    return LINK_TYPE_INTERNAL;
  } if (hyperlink.indexOf('@') !== -1) {
    return LINK_TYPE_EMAIL;
  }

  return LINK_TYPE_EXTERNAL;
};
