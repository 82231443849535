import React from 'react';
import PropTypes from 'prop-types';
import CircleLoader from '../../Loader/CircleLoader';

const UploadProgress = ({
  value, width, height, strokeWidth,
}) => {
  const centerX = width / 2;
  const centerY = height / 2;
  const radius = centerX - (strokeWidth / 2);
  const circumference = 2 * Math.PI * radius;
  const progress = value / 100;
  const progressLabel = `${Math.round(value)}`;
  const dashoffset = circumference * (1 - progress);

  if (progress === 0) {
    return <CircleLoader width={width} height={height} />;
  }

  return (
    <div
      className="c-bb-uploader-progress-circle"
      style={{
        width,
        height,
      }}
    >
      <svg
        className="c-bb-uploader-progress-circle__progress"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      >
        <circle
          className="c-bb-uploader-progress-circle__progress__meter"
          cx={centerX}
          cy={centerY}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="c-bb-uploader-progress-circle__progress__value"
          cx={centerX}
          cy={centerY}
          r={radius}
          strokeWidth={strokeWidth}
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: dashoffset,
          }}
        />
      </svg>
      <span className="c-bb-uploader-progress-circle__label">
        {progressLabel}
        <small>%</small>
      </span>
    </div>
  );
};

UploadProgress.propTypes = {
  value: PropTypes.number.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  strokeWidth: PropTypes.number,
};

UploadProgress.defaultProps = {
  width: 56,
  height: 56,
  strokeWidth: 6,
};

export default UploadProgress;
