/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const ImageValue = ({ value, column }) => {
  const [error, setError] = React.useState(false);

  if (!value) {
    return null;
  }

  const { url } = value;
  const defaultUrl = '/images/block/placehold500x300.png';

  return (
    <img
      loading="lazy"
      className="c-image"
      src={error ? defaultUrl : url || defaultUrl}
      alt={column.id}
      onError={() => setError(true)}
    />
  );
};

ImageValue.propTypes = {
  column: PropTypes.object.isRequired,
  value: PropTypes.any,
};

ImageValue.defaultProps = {
  value: null,
};

export default ImageValue;
