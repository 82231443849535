import { MEDIA_SCHEMA, MEDIA_FOLDER_SCHEMA, MEDIA_ITEM_SCHEMA } from '../api/schema';
import {
  ROUTE_MEDIA,
  ROUTE_MEDIA_FOLDERS,
  ROUTE_MEDIA_FOLDERS_ROOT,
  ROUTE_MEDIA_FOLDER_PATH,
  ROUTE_MEDIA_FOLDER_PATH_ROOT,
  ROUTE_MEDIA_UPLOAD,
  ROUTE_MEDIA_CROP,
  ROUTE_MEDIA_FILE,
  ROUTE_MEDIA_FOLDER_MOVE,
  ROUTE_MEDIA_MOVE,
  ROUTE_MEDIA_SEARCH,
} from '../api/routes';
import { buildRoute, createApiAction, CALL_BB_API } from '../api';

export const MEDIA_REQUEST = 'MEDIA_REQUEST';
export const MEDIA_SUCCESS = 'MEDIA_SUCCESS';
export const MEDIA_FAILURE = 'MEDIA_FAILURE';

// BrowsBox API middleware.
const fetchMedia = folderId => ({
  [CALL_BB_API]: {
    types: [MEDIA_REQUEST, MEDIA_SUCCESS, MEDIA_FAILURE],
    endpoint: `${ROUTE_MEDIA}?folderId=${folderId || ''}`,
    method: 'GET',
    schema: MEDIA_SCHEMA,
  },
});

// Redux Thunk middleware.
export const loadMedia = () => (dispatch, getState) => {
  const state = getState();
  const { folderId } = state.media;

  return dispatch(fetchMedia(folderId));
};

export const MEDIA_FOLDERS_REQUEST = 'MEDIA_FOLDERS_REQUEST';
export const MEDIA_FOLDERS_SUCCESS = 'MEDIA_FOLDERS_SUCCESS';
export const MEDIA_FOLDERS_FAILURE = 'MEDIA_FOLDERS_FAILURE';

const fetchMediaFolders = parentId => ({
  [CALL_BB_API]: {
    types: [MEDIA_FOLDERS_REQUEST, MEDIA_FOLDERS_SUCCESS, MEDIA_FOLDERS_FAILURE],
    endpoint: parentId ? buildRoute(ROUTE_MEDIA_FOLDERS, { id: parentId }) : ROUTE_MEDIA_FOLDERS_ROOT,
    method: 'GET',
    schema: MEDIA_FOLDER_SCHEMA,
  },
});

export const MEDIA_FOLDER_PATH_REQUEST = 'MEDIA_FOLDER_PATH_REQUEST';
export const MEDIA_FOLDER_PATH_SUCCESS = 'MEDIA_FOLDER_PATH_SUCCESS';
export const MEDIA_FOLDER_PATH_FAILURE = 'MEDIA_FOLDER_PATH_FAILURE';

const fetchMediaFolderPath = id => ({
  [CALL_BB_API]: {
    types: [MEDIA_FOLDER_PATH_REQUEST, MEDIA_FOLDER_PATH_SUCCESS, MEDIA_FOLDER_PATH_FAILURE],
    endpoint: id ? buildRoute(ROUTE_MEDIA_FOLDER_PATH, { id }) : ROUTE_MEDIA_FOLDER_PATH_ROOT,
    method: 'GET',
  },
});

export const loadMediaFolders = parentId => dispatch => Promise.all([
  dispatch(fetchMediaFolders(parentId)),
  dispatch(fetchMediaFolderPath(parentId)),
]);

export const createMediaFolder = createApiAction('MEDIA_FOLDER_CREATE', ({ name, parent }) => ({
  endpoint: ROUTE_MEDIA_FOLDERS_ROOT,
  method: 'POST',
  json: { name, parent },
  meta: {
    parent,
  },
}));

export const updateMediaFolder = createApiAction('MEDIA_FOLDER_UPDATE', ({ id, name }) => ({
  endpoint: buildRoute(ROUTE_MEDIA_FOLDERS, { id }),
  method: 'PUT',
  json: { name },
}));

export const moveMediaFolder = createApiAction('MEDIA_FOLDER_MOVE', ({ id, parent }) => ({
  endpoint: buildRoute(ROUTE_MEDIA_FOLDER_MOVE, { id }),
  method: 'PATCH',
  json: { parent },
  meta: {
    id, parent,
  },
}));

export const deleteMediaFolder = createApiAction('MEDIA_FOLDER_DELETE', ({ id }) => ({
  endpoint: buildRoute(ROUTE_MEDIA_FOLDERS, { id }),
  method: 'DELETE',
  meta: {
    id,
  },
}));

export const moveMedia = createApiAction('MEDIA_MOVE', ({ id, parent }) => ({
  endpoint: buildRoute(ROUTE_MEDIA_MOVE, { id }),
  method: 'PATCH',
  json: { parent },
  meta: {
    id, parent,
  },
}));

export const RESET_SEARCH_VIEW = 'RESET_SEARCH_VIEW';

export const resetSearchView = () => ({
  type: RESET_SEARCH_VIEW,
});

export const searchMedia = createApiAction('MEDIA_SEARCH', ({ query }) => ({
  endpoint: `${ROUTE_MEDIA_SEARCH}?query=${query}`,
  method: 'GET',
  meta: {
    query,
  },
}));

export const MEDIA_UPLOAD_REQUEST = 'MEDIA_UPLOAD_REQUEST';
export const MEDIA_UPLOAD_SUCCESS = 'MEDIA_UPLOAD_SUCCESS';
export const MEDIA_UPLOAD_FAILURE = 'MEDIA_UPLOAD_FAILURE';

// BrowsBox API middleware.
const postUploadMedia = ({
  file, folderId, options = {}, metadata = {},
}) => ({
  [CALL_BB_API]: {
    types: [MEDIA_UPLOAD_REQUEST, MEDIA_UPLOAD_SUCCESS, MEDIA_UPLOAD_FAILURE],
    endpoint: ROUTE_MEDIA_UPLOAD,
    method: 'POST:FORM',
    json: { file, folderId },
    options,
  },
  metadata,
});

// Redux Thunk middleware.
export const uploadMedia = (file, options, metadata) => (dispatch, getState) => {
  const state = getState();
  const { folderId } = state.media;

  return dispatch(postUploadMedia({
    file, folderId, options, metadata,
  }));
};

export const MEDIA_CROP_REQUEST = 'MEDIA_CROP_REQUEST';
export const MEDIA_CROP_SUCCESS = 'MEDIA_CROP_SUCCESS';
export const MEDIA_CROP_FAILURE = 'MEDIA_CROP_FAILURE';

// BrowsBox API middleware.
const postCropMedia = (value) => {
  const { id, ...options } = value;
  const endpoint = buildRoute(ROUTE_MEDIA_CROP, { id });
  return {
    [CALL_BB_API]: {
      types: [MEDIA_CROP_REQUEST, MEDIA_CROP_SUCCESS, MEDIA_CROP_FAILURE],
      endpoint,
      method: 'PATCH',
      json: {
        ...options,
      },
    },
  };
};

// Redux Thunk middleware.
export const cropMedia = value => dispatch => dispatch(postCropMedia(value));

export const MEDIA_FILE_REQUEST = 'MEDIA_FILE_REQUEST';
export const MEDIA_FILE_SUCCESS = 'MEDIA_FILE_SUCCESS';
export const MEDIA_FILE_FAILURE = 'MEDIA_FILE_FAILURE';

// BrowsBox API middleware.
const getMediaCall = (value) => {
  const { id } = value;
  const endpoint = buildRoute(ROUTE_MEDIA_FILE, { id });
  return {
    [CALL_BB_API]: {
      types: [MEDIA_FILE_REQUEST, MEDIA_FILE_SUCCESS, MEDIA_FILE_FAILURE],
      endpoint,
      method: 'GET',
      schema: MEDIA_ITEM_SCHEMA,
    },
  };
};

// Redux Thunk middleware.
export const getMedia = value => dispatch => dispatch(getMediaCall(value));

export const MEDIA_UPLOAD_FILE_START = 'MEDIA_UPLOAD_FILE_START';
export const startUploadFile = (file, uniqId) => ({
  type: MEDIA_UPLOAD_FILE_START,
  value: {
    file,
    uniqId,
  },
});

export const MEDIA_UPDATE_UPLOAD_PROGRESS = 'MEDIA_UPDATE_UPLOAD_PROGRESS';
export const updateUploadProgress = (file, progress, uniqId) => ({
  type: MEDIA_UPDATE_UPLOAD_PROGRESS,
  value: {
    file,
    progress,
    uniqId,
  },
});

export const MEDIA_UPLOAD_ERROR = 'MEDIA_UPLOAD_ERROR';
export const mediaUploadError = (uniqId, message) => ({
  type: MEDIA_UPLOAD_ERROR,
  value: {
    message,
    uniqId,
  },
});

export const MEDIA_REMOVE_UPLOAD_ITEM = 'MEDIA_REMOVE_UPLOAD_ITEM';
export const mediaRemoveUploadedItem = uniqId => ({
  type: MEDIA_REMOVE_UPLOAD_ITEM,
  value: {
    uniqId,
  },
});

export const MEDIA_LIBRARY_RESET_STATE = 'MEDIA_LIBRARY_RESET_STATE';
export const mediaLibraryResetState = () => ({
  type: MEDIA_LIBRARY_RESET_STATE,
});

export const MEDIA_LIBRARY_SET_PAGE = 'MEDIA_LIBRARY_SET_PAGE';
export const mediaLibrarySetPage = page => ({
  type: MEDIA_LIBRARY_SET_PAGE,
  value: {
    page,
  },
});

export const MEDIA_LIBRARY_INCREASE_PAGE = 'MEDIA_LIBRARY_INCREASE_PAGE';
export const mediaLibraryIncreasePage = () => ({
  type: MEDIA_LIBRARY_INCREASE_PAGE,
});

export const MEDIA_LIBRARY_SET_SEARCH_PHRASE = 'MEDIA_LIBRARY_SET_SEARCH_PHRASE';
export const mediaLibrarySetSearchPhrase = phrase => ({
  type: MEDIA_LIBRARY_SET_SEARCH_PHRASE,
  value: {
    phrase,
  },
});

export const MEDIA_LIBRARY_SET_FOLDER_ID = 'MEDIA_LIBRARY_SET_FOLDER_ID';
export const mediaLibrarySetFolderId = id => ({
  type: MEDIA_LIBRARY_SET_FOLDER_ID,
  value: { id },
});

export const MEDIA_LIBRARY_CLEAR_FOLDER_ID = 'MEDIA_LIBRARY_CLEAR_FOLDER_ID';
export const mediaLibraryClearFolderId = id => ({
  type: MEDIA_LIBRARY_CLEAR_FOLDER_ID,
  value: { id },
});

export const MEDIA_LIBRARY_SET_VIEW_MODE = 'MEDIA_LIBRARY_SET_VIEW_MODE';
export const mediaLibrarySetViewMode = mode => ({
  type: MEDIA_LIBRARY_SET_VIEW_MODE,
  value: { mode },
});

export const MEDIA_LIBRARY_SHOW_CREATE_FOLDER_MODAL = 'MEDIA_LIBRARY_SHOW_CREATE_FOLDER_MODAL';
export const showCreateFolderModal = () => ({
  type: MEDIA_LIBRARY_SHOW_CREATE_FOLDER_MODAL,
});

export const MEDIA_LIBRARY_HIDE_CREATE_FOLDER_MODAL = 'MEDIA_LIBRARY_HIDE_CREATE_FOLDER_MODAL';
export const hideCreateFolderModal = () => ({
  type: MEDIA_LIBRARY_HIDE_CREATE_FOLDER_MODAL,
});

export const MEDIA_LIBRARY_SET_SORT_TYPE = 'MEDIA_LIBRARY_SET_SORT_TYPE';
export const setSortType = value => ({
  type: MEDIA_LIBRARY_SET_SORT_TYPE,
  value,
});

export const MEDIA_LIBRARY_SET_SORT_DIRECTION = 'MEDIA_LIBRARY_SET_SORT_DIRECTION';
export const setSortDirection = value => ({
  type: MEDIA_LIBRARY_SET_SORT_DIRECTION,
  value,
});
