import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';

const propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

const defaultProps = {
  icon: '',
  title: '',
  onClose: false,
};

const ModalHeader = (props) => {
  const {
    icon,
    title,
    onClose,
  } = props;

  return (
    <header className="o-bb-modal__header">
      <h1 className="o-bb-modal__header-title">{icon && <FontAwesomeIcon name={icon} className={classNames('o-bb-modal__header-icon', icon)} />} {title}</h1>
      {onClose
        && <i onClick={onClose} className="o-bb-modal__close icon-close" />}
    </header>
  );
};

ModalHeader.propTypes = propTypes;
ModalHeader.defaultProps = defaultProps;

export default ModalHeader;
