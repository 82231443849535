import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getLinkType, LINK_TYPE_EXTERNAL } from '../utils/links';

const propTypes = {
  hyperlink: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
  hyperlink: '',
};

const LinkedImage = (props) => {
  const linkType = getLinkType(props.hyperlink);
  if (props.hyperlink && props.hyperlink !== '') {
    const attrs = {
      href: props.hyperlink,
      className: classNames(props.className, 'c-image__link'),
    };
    if (linkType === LINK_TYPE_EXTERNAL) {
      attrs.target = '_blank';
    }
    return (
      <a {...attrs}>
        {props.children}
      </a>
    );
  }

  return (
    <div className={classNames(props.className, 'c-image__link')}>
      {props.children}
    </div>
  );
};

LinkedImage.propTypes = propTypes;
LinkedImage.defaultProps = defaultProps;

export default LinkedImage;
