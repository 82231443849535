import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { SubmissionError } from 'redux-form';
import UsersHeader from './UsersHeader';
import UsersContent from './UsersContent';
import UserForm from './UserForm';
import selectors from '../../selectors/users';
import { updateUser } from '../../actions/users';
import { isValidationError, transformValidationErrors } from '../../tools/validation';
import { UserType } from './types';

const EditUser = ({
  id, onClose, user, update, t,
}) => {
  const onSubmit = async ({
    email, password, isEnabled, role,
  }) => {
    const data = {
      id, email, password, isEnabled, roles: role ? [role] : undefined,
    };
    await update(data, (error) => {
      if (isValidationError(error)) {
        throw new SubmissionError(transformValidationErrors(error));
      }
    });
    onClose();
  };

  const initialValues = React.useMemo(() => ({
    email: user.email,
    role: user.roles[0] || 'ROLE_USER',
    isEnabled: user.is_enabled,
    groups: user.groups,
    locale: user.locale,
  }), [user]);

  return (
    <>
      <UsersHeader title={t('USERS.userManage')} onClose={onClose} />
      <UsersContent>
        <UserForm
          isEdit
          onCancel={onClose}
          onSubmit={onSubmit}
          initialValues={initialValues}
        />
      </UsersContent>
    </>
  );
};

EditUser.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  user: UserType.isRequired,
  update: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { id }) => ({
  user: selectors.selectUser(state, id),
});

const mapDispatchToProps = {
  update: updateUser,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(EditUser));
