import { takeEvery, select, put } from 'redux-saga/effects';
import get from 'lodash/get';
import { MEDIA_UPLOAD_FAILURE, mediaRemoveUploadedItem } from '../../actions/media';
import { displayErrorMessage } from '../../actions/messages';
import i18n from '../../internationalization/i18n';
import { selectUploadedItemByUniqId } from '../../selectors/media';

function* displayFailedUploadMediaMessage(action) {
  const { uploadId } = action.metadata;
  const title = get(action, 'response.data.title', i18n.t('CONTENT.fileUploadError'));
  const message = get(action, 'response.data.message', i18n.t('CONTENT.fileUploadErrorMessage'));

  const uploadedItem = yield select(state => selectUploadedItemByUniqId(state, uploadId));

  yield put(displayErrorMessage({
    title,
    message: [
      message,
      uploadedItem.file.name,
    ].join(' '),
  }));

  yield put(mediaRemoveUploadedItem(uploadId));
}

export default function* () {
  yield takeEvery(MEDIA_UPLOAD_FAILURE, displayFailedUploadMediaMessage);
}
