import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import BrowsboxModuleList from './BrowsboxModuleList';
import { loadModules } from '../../actions/modules';
import { addContent } from '../../actions/contentAdd';
import { openContentLayout } from '../../actions/contentLayout';

const propTypes = {
  loadModules: PropTypes.func.isRequired,
  moduleItems: PropTypes.array,
  addContent: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  openContentLayout: PropTypes.func.isRequired,
};

const defaultProps = {
  moduleItems: [],
};

class BrowsboxModuleContainer extends Component {
  constructor(props) {
    super(props);
    this.addContent = this.addContent.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    if (!this.props.moduleItems) {
      this.loadData();
    }
  }

  loadData() {
    this.props.loadModules();
  }

  addContent(item, moduleDef) {
    this.props.addContent(item);
    const { layouts } = moduleDef;
    const hasLayouts = Array.isArray(layouts) && layouts.length;
    const { id } = item;
    if (hasLayouts) {
      // isAddingContent for deleting content item from store if canceled
      this.props.openContentLayout({ id, isAddingContent: true });
    }
  }

  render() {
    const { moduleItems } = this.props;
    return (
      <BrowsboxModuleList moduleItems={moduleItems} addContent={this.addContent} />
    );
  }
}

BrowsboxModuleContainer.propTypes = propTypes;
BrowsboxModuleContainer.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const { modules } = state.entities;
  const keys = Object.keys(modules).map(m => modules[m]);

  return {
    moduleItems: keys,
  };
};

const mapDispatchToProps = {
  loadModules,
  addContent,
  openContentLayout,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(BrowsboxModuleContainer));
