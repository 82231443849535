import React from 'react';
import classNames from 'classnames';

const ContentComponentMultiLocationMap = ({
  classes,
  containerClassName,
  mapStyle,
  mapZoom,
  type,
  markersLoaderUrl,
}) => {
  const componentClasses = classNames(
    classes,
    containerClassName,
    `c-${type}`,
    `c-${type}--static`,
  );

  return (
    <div
      className={componentClasses}
      data-markers-url={markersLoaderUrl}
      data-style={mapStyle}
      data-zoom={mapZoom}
    />
  );
};

export default ContentComponentMultiLocationMap;
