/* global mapboxgl */
import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const MapMarker = ({
  children,
  longitude,
  latitude,
  mapRef,
  value,
}) => {
  const elementRef = useRef(null);
  const markerRef = useRef(null);

  const createPopup = () => {
    if (value !== null) {
      // TODO: the popup is renderd on top of the title --> change the offset / position of popup?
      const popup = new mapboxgl.Popup()
        .setHTML(value)
        .setOffset();

      markerRef.current.setPopup(popup);
    }
  };

  useLayoutEffect(() => {
    markerRef.current = new mapboxgl.Marker(elementRef.current)
      .setLngLat([longitude, latitude])
      .addTo(mapRef.current);

    createPopup();

    return () => {
      if (markerRef.current) {
        markerRef.current.remove();
      }
    };
  }, [longitude, longitude]);

  return (
    <div ref={elementRef}>
      {children}
    </div>
  );
};

MapMarker.propTypes = {
  children: PropTypes.node.isRequired,
  longitude: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  latitude: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  mapRef: PropTypes.shape({
    current: PropTypes.any.isRequired,
  }).isRequired,
  value: PropTypes.string,
};

MapMarker.defaultProps = {
  value: null,
};

export default MapMarker;
