import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UploadProgress from './UploadProgress';

const UploadedTableItem = ({ filename, progress, error }) => {
  const classes = classNames(
    'c-bb-media__table__item',
    'c-bb-upload-queue__table-item',
    { 'c-bb-upload-queue__table-item--upload-failed': Boolean(error) },
  );

  return (
    <tr className={classes} title={error}>
      <td className="c-bb-media__table__item__image">
        <div className="c-bb-upload-queue__table-item__progress">
          <UploadProgress value={progress} />
        </div>
      </td>
      <td colSpan={4}>
        <div className="c-bb-upload-queue__table-item__filename">
          {filename}
        </div>
      </td>
    </tr>
  );
};

UploadedTableItem.propTypes = {
  filename: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  error: PropTypes.string,
};

UploadedTableItem.defaultProps = {
  error: null,
};

export default UploadedTableItem;
