import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import Multiselect from 'react-widgets/lib/Multiselect';
import 'react-widgets/dist/css/react-widgets.css';
import { addTag } from '../../actions/tags';

const propTypes = {
  input: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
  valueField: PropTypes.string,
  textField: PropTypes.string,
  addTag: PropTypes.func.isRequired,
};

const defaultProps = {
  label: null,
  valueField: '',
  textField: '',
};

class MultiselectField extends React.Component {
  constructor(props) {
    super(props);
    this.handleCreate = this.handleCreate.bind(this);
  }

  handleCreate(tag) {
    // check tag isn't already in data of all tags
    if (!this.props.data.includes(tag)) {
      this.props.addTag(tag);
      this.props.input.onChange([
        ...this.props.input.value,
        tag,
      ]);
    }
  }

  render() {
    const {
      input, data, valueField, textField, label,
    } = this.props;

    return (
      <div className="c-bb-form__input c-bb-form__input-item-group  c-bb-form__input-item-group--filters">
        {label && <label htmlFor={input.name}>{label}</label>}
        <Multiselect
          {...input}
          className="input-item-group"
          onBlur={() => input.onBlur()}
          onCreate={tag => this.handleCreate(tag)}
          value={input.value || []}
          data={data}
          valueField={valueField}
          textField={textField}
        />
      </div>
    );
  }
}

MultiselectField.propTypes = propTypes;
MultiselectField.defaultProps = defaultProps;

const mapDispatchToProps = {
  addTag,
};

export default withNamespaces()(connect(null, mapDispatchToProps)(MultiselectField));
