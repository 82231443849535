import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { contentPropType } from '@liswood-tache/browsbox-static';
import { addComponentToColumn } from '../../../actions/contentAdd';
import Icon from '../../Icon/Icon';
import SuperAdminOnly from '../../AcessControll/SuperAdminOnly';
import { editorUpdate } from '../../../actions/contentEditor';

const ContentAppendComponent = ({
  addComponent,
  columnId,
  components,
  contentId,
  updateEditor,
}) => {
  const [selectActive, setSelectActive] = useState(false);

  const showPicker = () => {
    setSelectActive(true);
  };

  const hidePicker = () => {
    setSelectActive(false);
  };

  const handleAddComponent = (event) => {
    hidePicker();
    addComponent({
      contentId,
      columnId,
      type: event.target.value,
    });
    updateEditor();
  };

  return (
    <div className="c-component-tools__add-component">
      {selectActive ? (
        <select
          defaultValue={0}
          onChange={handleAddComponent}
        >
          <option key={0} value={0} disabled>-- Select --</option>
          {components.map(component => (
            <option
              key={component.type}
              value={component.type}
            >
              {component.type}
            </option>
          ))}
        </select>
      ) : (
        <button type="button" className="c-component-tools__add-button" onClick={showPicker}>
          <Icon name="plus" />
        </button>
      )}
    </div>
  );
};

ContentAppendComponent.propTypes = {
  addComponent: PropTypes.func.isRequired,
  columnId: PropTypes.number.isRequired,
  components: PropTypes.arrayOf(
    contentPropType.isRequired,
  ).isRequired,
  contentId: PropTypes.number.isRequired,
  updateEditor: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  components: Object.values(state.entities.components || {}),
});

const mapDispatchToProps = {
  addComponent: addComponentToColumn,
  updateEditor: editorUpdate,
};

const ConnectedAppend = connect(mapStateToProps, mapDispatchToProps)(ContentAppendComponent);

export default props => (
  <SuperAdminOnly>
    <ConnectedAppend {...props} />
  </SuperAdminOnly>
);
