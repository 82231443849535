import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../Icon/Icon';

const BooleanValue = ({ value }) => {
  if (typeof value === 'undefined') {
    return null;
  }

  const icon = value ? 'check' : 'close';

  return <Icon name={icon} />;
};

BooleanValue.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

BooleanValue.defaultProps = {
  value: null,
};

export default BooleanValue;
