import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import i18n from '../../internationalization/i18n';
import MediaManagerModal from '../MediaManager/MediaManagerModal';
import MediaPickerModal from '../MediaManager/MediaPickerModal';
import { setMediaPickerType } from '../../actions/mediaManager';
import { selectAllMediaItems } from '../../selectors/media';
import { BbButton } from '../@browsbox-ui';
import { getMedia } from '../../actions/media';

const propTypes = {
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
  media: PropTypes.array.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  setMediaPickerType: PropTypes.func.isRequired,
  getMedia: PropTypes.func.isRequired,
  showTypeFilter: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  className: '',
  label: '',
  showTypeFilter: false,
};

const MediaField = ({
  input, type, media, disabled, className, label, showTypeFilter, ...props
}) => {
  const [isManagerActive, setManagerActive] = useState(false);
  const [isPickerActive, setPickerActive] = useState(false);

  // keep BC, when input value was stored as simple integer
  let mediaId = Number.isInteger(input.value) ? Number(input.value) : null;

  if (!mediaId && input.value && input.value.id) {
    mediaId = input.value.id;
  }

  React.useEffect(() => {
    if (mediaId) {
      props.getMedia({ id: mediaId });
    }
  }, [mediaId]);

  const mediaData = media.find(item => Number(item.id) === Number(mediaId));

  const openManagerModal = () => {
    props.setMediaPickerType(type);
    setManagerActive(true);
  };

  const closeManagerModal = () => {
    setManagerActive(false);
  };

  const openPickerModal = () => {
    props.setMediaPickerType(type);
    setPickerActive(true);
  };

  const closePickerModal = () => {
    setPickerActive(false);
  };

  const handleMediaManagerChange = (contentId, mediaType, mediaItem) => {
    input.onChange({
      ...input.value,
      id: mediaItem.id,
      type: mediaItem.type,
      url: mediaItem.url,
    });

    closeManagerModal();

    if (mediaType === 'image') {
      openPickerModal();
    }
  };

  const handlePickerChange = (contentId, mediaItem) => {
    input.onChange({
      ...input.value,
      alt: mediaItem.alt,
      tooltip: mediaItem.tooltip,
      hyperlink: mediaItem.hyperlink,
    });

    closePickerModal();
  };

  const renderModal = () => {
    if (isManagerActive) {
      return (
        <MediaManagerModal
          onCancel={closeManagerModal}
          onClose={closeManagerModal}
          onOk={handleMediaManagerChange}
          mediaId={mediaId}
        />
      );
    }

    if (isPickerActive) {
      const selectedMedia = input.value;

      return (
        <MediaPickerModal
          key={mediaId}
          showMetaData
          showMenuItems={false}
          iconUrl="/bundles/browsbox/Themes/Admin/images/media.svg"
          onCancel={closePickerModal}
          onClose={closePickerModal}
          onOk={handlePickerChange}
          submitButtonText={i18n.t('CONTENT.add')}
          mediaManagerContentType="image"
          {...selectedMedia}
        />
      );
    }

    return null;
  };

  const fieldLabel = type === 'document' ? 'BASE_MODULE.addFile' : 'BASE_MODULE.addImage';

  return (
    <>
      <div className={`c-bb-form__upload-field ${className}`} onClick={openManagerModal}>
        {!mediaData
        && (
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <BbButton type="button" secondary>
            {i18n.t(fieldLabel).toUpperCase()}
          </BbButton>
        </div>
        )}
        {mediaData
        && (
        <div className="c-bb-form__upload-image">
          {mediaData.type === 'image' && (
          <img
            height="45"
            src={mediaData.thumbnail}
            alt={mediaData.filename}
          />
          )}
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <span style={{ whiteSpace: 'nowrap' }}>{`${mediaData.filename}${mediaData.fileExtension}`}</span>
          </div>
        </div>
        )}
        {label && <label htmlFor="media">{label}</label>}
        {disabled && <div className="c-bb-form__disabled-lock" />}
      </div>
      {renderModal()}
    </>
  );
};

MediaField.propTypes = propTypes;
MediaField.defaultProps = defaultProps;

const mapStateToProps = state => ({
  media: selectAllMediaItems(state),
});

const mapDispatchToProps = {
  setMediaPickerType,
  getMedia,
};

const ConnectedMediaField = connect(mapStateToProps, mapDispatchToProps)(MediaField);

export default withNamespaces()(ConnectedMediaField);
