import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import validation from '../../../../tools/validation';
import * as constants from '../../../../constants';
import TextField from '../../../FormElements/TextField';
import TextareaField from '../../../FormElements/TextareaField';
import DateField from '../../../FormElements/DateField';
import CheckboxField from '../../../FormElements/CheckboxField';
import PhoneField from '../../../FormElements/PhoneField';
import RenderMediaField from '../../../FormElements/RenderMediaField';
import RenderImagesFields from '../../../FormElements/RenderImagesFields';
import SelectField from '../../../FormElements/SelectField';
import RangeField from '../../../FormElements/RangeField';

const textInputValidationRules = [validation.maxLength(255)];
const textareaInputValidationRules = [];
const emailInputValidationRules = [...textInputValidationRules, validation.email];
const urlInputValidationRules = [validation.url];
const numberInputValidationRules = [validation.number];
const dateInputValidationRules = [validation.dateFormat(constants.dateInputFormat)];
const phoneInputValidationRules = [validation.phoneNumber];

const normalizeNumber = (value) => {
  const nextValue = value.replaceAll(',', '.');

  if (!value) {
    return null;
  }

  return nextValue;
};

const propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const renderFiledByType = (field) => {
  let fieldComponent = null;
  switch (field.type) {
    case constants.fieldTypes.text:
      fieldComponent = (
        <div key={field.internal_name} className="c-bb-form__input-wrap">
          <Field
            name={`fields.${field.internal_name}`}
            component={TextField}
            label={field.label}
            validate={textInputValidationRules}
          />
        </div>
      );
      break;
    case constants.fieldTypes.longtext:
      fieldComponent = (
        <div key={field.internal_name} className="c-bb-form__input-wrap">
          <Field
            name={`fields.${field.internal_name}`}
            component={TextareaField}
            label={field.label}
            validate={textareaInputValidationRules}
          />
        </div>
      );
      break;
    case constants.fieldTypes.email:
      fieldComponent = (
        <div key={field.internal_name} className="c-bb-form__input-wrap">
          <Field
            name={`fields.${field.internal_name}`}
            component={TextField}
            label={field.label}
            validate={emailInputValidationRules}
          />
        </div>
      );
      break;
    case constants.fieldTypes.number:
      fieldComponent = (
        <div key={field.internal_name} className="c-bb-form__input-wrap">
          <Field
            name={`fields.${field.internal_name}`}
            component={TextField}
            label={field.label}
            validate={numberInputValidationRules}
            normalize={normalizeNumber}
          />
        </div>
      );
      break;
    case constants.fieldTypes.url:
    case constants.fieldTypes.video:
      fieldComponent = (
        <div key={field.internal_name} className="c-bb-form__input-wrap">
          <Field
            name={`fields.${field.internal_name}`}
            component={TextField}
            label={field.label}
            validate={urlInputValidationRules}
          />
        </div>
      );
      break;
    case constants.fieldTypes.date:
      fieldComponent = (
        <div key={field.internal_name} className="c-bb-form__input-wrap">
          <Field
            name={`fields.${field.internal_name}`}
            component={DateField}
            label={field.label}
            validate={dateInputValidationRules}
            dateFormat={constants.dateInputFormat}
          />
        </div>
      );
      break;
    case constants.fieldTypes.phone_number:
      fieldComponent = (
        <div key={field.internal_name} className="c-bb-form__input-wrap">
          <Field
            name={`fields.${field.internal_name}`}
            component={PhoneField}
            label={field.label}
            validate={phoneInputValidationRules}
            defaultCountry="BE"
            favoriteCountries={['BE', 'NL', 'FR', 'GB', 'DE', 'PL', '|']}
          />
        </div>
      );
      break;
    case constants.fieldTypes.boolean:
      fieldComponent = (
        <div key={field.internal_name} className="c-bb-form__input-wrap">
          <Field
            name={`fields.${field.internal_name}`}
            component={CheckboxField}
            label={field.label}
          />
        </div>
      );
      break;
    case constants.fieldTypes.image:
      fieldComponent = (
        <div key={field.internal_name} className="c-bb-form__input-wrap">
          <Field
            name={`fields.${field.internal_name}`}
            component={RenderMediaField}
            label={field.label}
          />
        </div>
      );
      break;
    case constants.fieldTypes.file:
      fieldComponent = (
        <div key={field.internal_name} className="c-bb-form__input-wrap">
          <Field
            name={`fields.${field.internal_name}`}
            component={RenderMediaField}
            sometext="text"
            type="document"
            label={field.label}
          />
        </div>
      );
      break;
    case constants.fieldTypes.images:
      fieldComponent = (
        <div key={field.internal_name} className="c-bb-form__input-wrap">
          <FieldArray
            name={`fields.${field.internal_name}`}
            component={RenderImagesFields}
            label={field.label}
          />
        </div>
      );
      break;
    case constants.fieldTypes.select:
      fieldComponent = (
        <div key={field.internal_name} className="c-bb-form__input-wrap">
          <Field
            name={`fields.${field.internal_name}`}
            component={SelectField}
            label={field.label}
            options={field.options}
          />
        </div>
      );
      break;
    case constants.fieldTypes.range:
      fieldComponent = (
        <div key={field.internal_name} className="c-bb-form__input-wrap">
          <Field
            name={`fields.${field.internal_name}`}
            component={RangeField}
            label={field.label}
            settings={field.settings}
          />
        </div>
      );
      break;
    default:
      break;
  }
  return fieldComponent;
};

const RenderItemFields = ({ fields }) => (
  <div>
    { fields.map(field => (
      renderFiledByType(field)
    ))}
  </div>
);

RenderItemFields.propTypes = propTypes;

export default withNamespaces()(RenderItemFields);
