import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { renderError } from '../../tools/validation';

const propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  names: PropTypes.array.isRequired,
  langs: PropTypes.array.isRequired,
};

const defaultProps = {
  label: null,
  className: '',
};

const LangField = ({ names, langs, ...props }) => (
  <div className={`c-bb-form__input-wrapper ${props.className}`}>
    {names.map((name, index) => (
      <div
        key={name}
        className={classNames([
          'c-bb-form__input',
          { 'c-bb-form__input--has-error': props[name].meta.touched && props[name].meta.error },
        ])}
      >
        <label htmlFor="title">{props.label} {langs[index].code.toUpperCase()}</label>
        <input
          onChange={props[name].input.onChange}
          value={props[name].input.value}
          name={props[name].input.name}
          type="text"
        />
        {props[name].meta.touched && props[name].meta.error
          && <span className="c-bb-form__input-error">{renderError(props[name].meta.error)}</span>}
      </div>
    ))}
  </div>
);

LangField.propTypes = propTypes;
LangField.defaultProps = defaultProps;

export default withNamespaces()(LangField);
