import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import activeMenuItem from './activeMenuItem';
import activeModuleItem from './activeModuleItem';
import entities from './entities';
import menuItems from './menuItems';
import pages from './pages';
import content from './content';
import options from './options';
import messages from './messages';
import global from './global';
import baseModule from './baseModule';
import baseModuleItems from './baseModuleItems';
import baseModuleRelatedItems from './baseModuleRelatedItems';
import media from './media';
import users from './users';
import ui from './ui';

const reducer = combineReducers({
  activeMenuItem,
  activeModuleItem,
  entities,
  menuItems,
  pages,
  content,
  options,
  messages,
  global,
  baseModule,
  baseModuleItems,
  baseModuleRelatedItems,
  media,
  users,
  ui,
  form,
});

export default reducer;
