import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  active: false,
  className: '',
};

const BbPane = (props) => {
  const {
    active,
    className,
    ...attributes
  } = props;

  const classes = classNames(
    className,
    'o-bb-pane',
    {
      'o-bb-pane--active': active,
    },
  );

  if (active) {
    return (
      <div
        className={classes}
        {...attributes}
      />
    );
  }
  return null;
};

BbPane.propTypes = propTypes;
BbPane.defaultProps = defaultProps;

export default BbPane;
