import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import i18n from '../../internationalization/i18n';

const createSlug = (name) => {
  slugify.extend({
    '<': '-', '>': '-', $: '', '&': '', '%': '',
  });

  if (name) {
    return slugify(name, {
      replacement: '-',
      remove: /[*+~.()'"!?:#@%^&$_]/g,
      lower: true,
    });
  }

  return i18n.t('CONTENT.pageFormGoogleExampleNavigationButton');
};

const GooglePreview = ({
  title, route, description, url, origin, websiteTitle,
}) => {
  const createPageTitle = (pageTitle) => {
    if (!pageTitle) {
      return null;
    }

    return `${pageTitle} | ${websiteTitle}`;
  };

  return (
    <div className="c-google-preview">
      <div className="c-google-preview__wrapper">
        <img src="/images/core/google_logo.png" alt="google logo" />
        <div className="c-google-preview__content">
          <div className="c-google-preview__header">
            {title ? createPageTitle(title) : i18n.t('CONTENT.pageFormGoogleExampleTitle')}
          </div>
          <div className="c-google-preview__link">{origin}{url || `/${createSlug(route || title)}`}</div>
          <div className="c-google-preview__description">{description}</div>
        </div>
      </div>
    </div>
  );
};

GooglePreview.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  route: PropTypes.string,
  url: PropTypes.string,
  origin: PropTypes.string,
  websiteTitle: PropTypes.string.isRequired,
};

GooglePreview.defaultProps = {
  title: '',
  route: '',
  url: '',
  origin: window?.location?.origin,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean finibus ornare ultricies. Etiam dictum imperdiet ante, sit amet eleifend nunc consequat',
};

const mapStateToProps = state => ({
  websiteTitle: state.global.websiteSettings.title,
});

export default connect(mapStateToProps)(GooglePreview);
