import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import i18n from '../../../internationalization/i18n';

const FolderDeleteConfirmationModal = ({
  id, name, onClose, onConfirm,
}) => (
  <ConfirmationModal
    key={id}
    icon="icon-basic_sheet"
    onCancel={onClose}
    onClose={onClose}
    onOk={onConfirm}
    submitButtonText={i18n.t('CONTENT.delete')}
    title={i18n.t('CONTENT.deleteFolderTitle')}
  >
    <p className="u-mb-l u-mt-l">{i18n.t('CONTENT.deleteFolderQuestion')}</p>
    <p className="u-mb-l u-mt-l"><strong>{name}</strong></p>
  </ConfirmationModal>
);

FolderDeleteConfirmationModal.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default FolderDeleteConfirmationModal;
