import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../Icon/Icon';

const ImageToolbar = ({
  onChooseImageClick,
  onCropClick,
  onRemoveClick,
  onResetDimensionsClick,
}) => (
  <div className="c-bb-inline-image-toolbar">
    <ul className="c-bb-inline-image-toolbar__list">
      {onChooseImageClick && (
        <li className="c-bb-inline-image-toolbar__list_item">
          <button type="button" className="button--mini" onClick={onChooseImageClick} data-testid="choose-image">
            <Icon name="image" />
          </button>
        </li>
      )}
      {onCropClick && (
        <li className="c-bb-inline-image-toolbar__list_item">
          <button type="button" className="button--mini" onClick={onCropClick} data-testid="crop-image">
            <Icon name="crop" />
          </button>
        </li>
      )}
      {onResetDimensionsClick && (
        <li className="c-bb-inline-image-toolbar__list_item">
          <button type="button" className="button--mini" onClick={onResetDimensionsClick} data-testid="reset-image">
            <Icon name="reset" />
          </button>
        </li>
      )}
      {onRemoveClick && (
        <li className="c-bb-inline-image-toolbar__list_item">
          <button type="button" className="button--mini" onClick={onRemoveClick} data-testid="remove-image">
            <Icon name="delete" />
          </button>
        </li>
      )}
    </ul>
  </div>
);

ImageToolbar.propTypes = {
  onChooseImageClick: PropTypes.func,
  onCropClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  onResetDimensionsClick: PropTypes.func,
};

ImageToolbar.defaultProps = {
  onChooseImageClick: null,
  onCropClick: null,
  onRemoveClick: null,
  onResetDimensionsClick: null,
};

export default ImageToolbar;
