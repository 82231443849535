import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UsersView from './UsersView';

const UsersContainer = (props) => {
  const {
    isOpen,
  } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <UsersView />
  );
};

UsersContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isOpen: state.users.isOpen,
});

export default connect(mapStateToProps)(UsersContainer);
