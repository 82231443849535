import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import Icon from '../../Icon/Icon';
import i18n from '../../../internationalization/i18n';

const NodeType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.number.isRequired,
  children: PropTypes.array.isRequired,
}).isRequired;

const TreeContext = React.createContext({
  onChange: () => {},
  isDisabled: () => false,
  canExpand: () => true,
});

const TreeContainer = ({
  items, onChange, isDisabled, canExpand,
}) => {
  const value = useMemo(() => ({ onChange, isDisabled, canExpand }), [onChange, isDisabled, canExpand]);

  return (
    <TreeContext.Provider value={value}>
      <TreeList items={items} />
    </TreeContext.Provider>
  );
};

TreeContainer.propTypes = {
  items: PropTypes.arrayOf(NodeType).isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.func.isRequired,
  canExpand: PropTypes.func.isRequired,
};

const TreeList = ({ items, level }) => (
  <ul className="o-bb-tree-picker">
    {items.map(item => (
      <TreeNode
        key={item.id}
        id={item.id}
        title={item.title}
        items={item.children}
        expanded={item.expanded}
        level={level}
      />
    ))}
  </ul>
);

TreeList.propTypes = {
  items: PropTypes.arrayOf(NodeType).isRequired,
  level: PropTypes.number,
};

TreeList.defaultProps = {
  level: 0,
};

const TreeNode = ({
  id, title, items, level, expanded: defaultExpanded,
}) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  const { onChange, isDisabled, canExpand } = React.useContext(TreeContext);
  const disabled = isDisabled({ id });
  const expandable = canExpand({ id });

  const hasChildren = items && items.length > 0;

  const handleToggle = () => {
    setExpanded(prev => !prev);
  };

  const handleChange = () => {
    if (disabled) {
      return;
    }

    onChange({ id, title });
  };

  return (
    <li className={classNames(
      'o-bb-tree-picker__node',
      { 'o-bb-tree-picker__node--expanded': expanded },
      { 'o-bb-tree-picker__node--disabled': disabled },
    )}
    >
      <div className="o-bb-tree-picker__content">
        {Array.from(new Array(level).keys()).map(key => (
          <div key={key} className="o-bb-tree-picker__padder" />
        ))}
        {hasChildren && expandable ? (
          <Icon className="o-bb-tree-picker__toggle" name="chevron-right" onClick={handleToggle} />
        ) : (
          <div className="o-bb-tree-picker__padder" />
        )}
        {title}
        {!disabled && (
          <button type="button" className="o-bb-tree-picker__button" onClick={handleChange}>
            {i18n.t('MEDIA.moveTo')}
          </button>
        )}
      </div>
      {expanded && hasChildren && (
        <TreeList items={items} level={level + 1} />
      )}
    </li>
  );
};

TreeNode.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(NodeType).isRequired,
  level: PropTypes.number,
  expanded: PropTypes.bool,
};

TreeNode.defaultProps = {
  level: 0,
  expanded: false,
};

const FolderTreeSelect = ({ onChange, isDisabled, canExpand }) => {
  const { data, isLoading } = useQuery(
    'folder_picker_directories',
    () => fetch('/api/media/folders/tree').then(res => res.json()),
  );

  const handleChange = (node) => {
    onChange(node);
  };

  if (isLoading) {
    return null;
  }

  const finalData = [{
    id: 0,
    title: i18n.t('MEDIA.library'),
    children: data,
    expanded: true,
  }];

  return (
    <TreeContainer
      items={finalData}
      onChange={handleChange}
      isDisabled={isDisabled}
      canExpand={canExpand}
    />
  );
};

FolderTreeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.func.isRequired,
  canExpand: PropTypes.func.isRequired,
};

export default FolderTreeSelect;
