import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UploadProgress from './UploadProgress';

const UploadedItem = ({ filename, progress, error }) => {
  const classes = classNames(
    'c-bb-upload-queue__item',
    { 'c-bb-upload-queue__item--upload-failed': Boolean(error) },
  );

  return (
    <div className={classes} title={error}>
      <div className="c-bb-upload-queue__item__progress">
        <UploadProgress value={progress} />
      </div>
      <div className="c-bb-upload-queue__item__filename">
        {filename}
      </div>
    </div>
  );
};

UploadedItem.propTypes = {
  filename: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  error: PropTypes.string,
};

UploadedItem.defaultProps = {
  error: null,
};

export default UploadedItem;
