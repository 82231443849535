import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeUsersView } from '../../actions/users';
import Icon from '../Icon/Icon';

const UsersHeader = ({ title, onClose }) => (
  <div className="c-users-view__header">
    <h1 className="c-users-view__title">{title}</h1>
    <button type="button" className="c-users-view__button" onClick={onClose}>
      <Icon name="close" />
    </button>
  </div>
);

UsersHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  onClose: closeUsersView,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  onClose: ownProps.onClose || dispatchProps.onClose,
});

export default connect(null, mapDispatchToProps, mergeProps)(UsersHeader);
