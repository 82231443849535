import {
  takeLatest, select, put, delay,
} from 'redux-saga/effects';
import { CLONE_CONTENT, contentWasCloned } from '../../actions/contentClone';
import { setActiveSection } from '../../actions/content';

function* handleContentClone(action) {
  yield delay(250);
  const { id } = action.value;
  const section = document.querySelector(`section[id="${id}"]`);

  if (!section) {
    return;
  }

  const offset = (section.offsetTop + section.clientHeight) - (window.innerHeight * 0.25);
  const originalIndex = yield select(state => state.entities.content.findIndex(item => item.id === id));
  const clonedIndex = originalIndex + 1;
  const clonedItem = yield select(state => state.entities.content[clonedIndex]);

  yield put(setActiveSection(clonedItem.id));
  yield put(contentWasCloned(clonedItem));

  window.scrollTo({
    top: offset,
    duration: 500,
    behavior: 'smooth',
  });
}

export default function* () {
  yield takeLatest(CLONE_CONTENT, handleContentClone);
}
