import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { withNamespaces } from 'react-i18next';
import { DND_MENU_MODULE, DND_CONTENT_MODULE } from '../DragAndDrop/dndTypes';
import i18n from '../../internationalization/i18n';

const style = {
};

const dropTargetCollect = (connectDnd, monitor) => ({
  connectDropTarget: connectDnd.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
});

const targetModule = {
  drop(props, monitor) {
    monitor.getItem().dndType = DND_CONTENT_MODULE; // eslint-disable-line
    monitor.getItem().index = 0; // eslint-disable-line
    props.addItem(0, monitor.getItem());
    return { isDroppedIntoContent: true };
  },
};

@DropTarget([DND_MENU_MODULE], targetModule, dropTargetCollect)

class BrowsboxContentEmpty extends Component {
  static propTypes = {
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool,
    canDrop: PropTypes.bool,
  };

  static defaultProps = {
    isOver: false,
    canDrop: false,
  };

  render() {
    const { canDrop, isOver, connectDropTarget } = this.props;
    const isActive = canDrop && isOver;

    let modifierClass = '';
    if (isActive) {
      modifierClass = ' c-bb-section__empty--is-active';
    } else if (canDrop) {
      modifierClass = ' c-bb-section__empty--can-drop';
    }

    return connectDropTarget(
      <div className={`c-bb-section__empty ${modifierClass}`} style={style}>
        <h3>
          { i18n.t('CONTENT.emptyLayout') }
        </h3>
        <p>
          { i18n.t('CONTENT.emptyLayoutDrag') }
        </p>
        {canDrop && (
          <p>
            {i18n.t('CONTENT.dropHere')}
          </p>
        )}
      </div>,
    );
  }
}

export default withNamespaces()(BrowsboxContentEmpty);
