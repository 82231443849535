import { all, fork } from 'redux-saga/effects';
import baseModule from './baseModuleList';
import baseModuleForm from './baseModuleForm';
import baseModuleItems from './baseModuleItems';

export default all([
  fork(baseModule),
  fork(baseModuleForm),
  fork(baseModuleItems),
]);
