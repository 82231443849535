export const SHOW_CONTENT_COMPONENT_BY_TYPE = 'SHOW_CONTENT_COMPONENT_BY_TYPE';
export const HIDE_CONTENT_COMPONENT_BY_TYPE = 'HIDE_CONTENT_COMPONENT_BY_TYPE';
export const showContentComponentByType = value => ({
  type: SHOW_CONTENT_COMPONENT_BY_TYPE,
  value,
});
export const hideContentComponentByType = value => ({
  type: HIDE_CONTENT_COMPONENT_BY_TYPE,
  value,
});
