import flatten from 'lodash/flatten';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';

export const getColumnsAndComponents = (content) => {
  const columns = flatten(map(content, 'columns'));
  const components = flatten(map(columns, 'components'));
  return { columns, components };
};

export const getComponentById = (content, id) => {
  const { components } = getColumnsAndComponents(content);
  return components.filter(c => c.id === id).pop();
};

export const getSectionForComponentById = (content, id) => content.filter((section) => {
  const components = flatten(map(section.columns, 'components'));
  const component = components.filter(c => c.id === id).pop();

  return !!component;
}).pop();

// Temporary Section and Component id's
// Backend will assign final id's on post of page sections
let idCounter = -1;
export const getNewId = () => {
  idCounter -= 1;
  return idCounter;
};

export const createNewColumn = () => ({
  id: getNewId(),
  components: [],
});

export const resetComponentIds = sections => (
  sections.map((section) => {
    const newColumns = section.columns.map(column => ({
      ...column,
      components: column.components.map(component => ({ ...component, id: getNewId() })),
    }));

    return {
      ...section,
      columns: newColumns,
    };
  })
);

export const getContentById = (state, contentId) => {
  const content = state.content.map(c => ({ ...c }));
  const originalContent = content.filter(c => c.id === contentId).shift();
  const contentIndex = content.indexOf(originalContent);
  const newContent = cloneDeep(originalContent);
  return {
    content, originalContent, newContent, contentIndex,
  };
};

export const getMediaById = (state, mediaId) => {
  const media = { ...state.media };
  const originalMedia = media[mediaId];
  const newMedia = { ...originalMedia };
  return { media, originalMedia, newMedia };
};

export const resolveNewModuleComponents = (module, column) => {
  if (module.type === 'gallery') {
    // When inserting the gallery, there should not be placeholder images
    // https://browsbox.atlassian.net/browse/BB-507
    return [];
  }

  if (!column.components) {
    return [];
  }

  return column.components.map(component => ({ ...component, id: getNewId() }));
};
