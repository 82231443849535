import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CONTENT_COMPONENT_TYPE from './types';

const propTypes = {
  classes: PropTypes.string,
  containerClassName: PropTypes.string,
  type: PropTypes.string,
};

const defaultProps = {
  classes: '',
  containerClassName: '',
  type: CONTENT_COMPONENT_TYPE.hr,
};

const ContentComponentHr = (props) => {
  const {
    type,
    classes,
    containerClassName,
  } = props;

  const componentClasses = classNames(
    classes,
    containerClassName,
    `c-${type}`,
  );

  return <hr className={componentClasses} />;
};

ContentComponentHr.propTypes = propTypes;
ContentComponentHr.defaultProps = defaultProps;

export default ContentComponentHr;
