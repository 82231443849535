import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { get, castArray, throttle } from 'lodash';
import { BbButton } from '@browsbox-ui';
import uniqid from 'uniqid';
import {
  loadMedia,
  uploadMedia,
  startUploadFile,
  updateUploadProgress,
  mediaUploadError,
  mediaLibraryIncreasePage,
  mediaLibrarySetSearchPhrase,
  showCreateFolderModal,
} from '../../actions/media';
import { setMediaPickerType } from '../../actions/mediaManager';
import { closeDeleteMedia, deleteMedia } from '../../actions/mediaDelete';
import { setMediaOption, closeRenameMedia, renameMedia } from '../../actions/mediaConfig';
import { ROUTE_MEDIA_GET, ROUTE_MEDIA_GET_LARGE } from '../../api/routes';
import ModalFooter from '../Modals/ModalFooter';
import MediaView from './Media/MediaView';
import ConfirmationModal from '../Modals/ConfirmationModal';
import PromptModal from '../Modals/PromptModal';
import i18n from '../../internationalization/i18n';
import Skeleton from '../Loader';
import { selectMediaItems, selectUploadedItems, selectMediaFolders } from '../../selectors/media';
import Media from './Media';
import FoldersContainer from './Folders/FoldersContainer';
import FoldersLoader from './Folders/FoldersLoader';
import MediaManagerToolbar from './MediaManagerToolbar';
import SearchView from './SearchView';
import EmptyView from './EmptyView';
import { MediaContextProvider } from './Media/MediaContext';
import Icon from '../Icon/Icon';
import { withConfirmation } from '../Confirmation/ConfirmationProvider';
import ConfirmationContext from '../Confirmation/ConfirmationContext';

const propTypes = {
  deleteMedia: PropTypes.func.isRequired, // Redux action creator
  setMediaOption: PropTypes.func.isRequired, // Redux action creator
  renameMedia: PropTypes.func.isRequired, // Redux action creator
  closeDeleteMedia: PropTypes.func.isRequired, // Redux action creator
  renameMediaProps: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired, // from Redux store
  isRenameMediaModalOpen: PropTypes.bool.isRequired, // from Redux store
  deleteMediaProps: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired, // from Redux store
  isDeleteMediaModalOpen: PropTypes.bool.isRequired, // from Redux store
  loadMedia: PropTypes.func.isRequired, // Redux action creator
  uploadMedia: PropTypes.func.isRequired, // Redux action creator
  startUploadFile: PropTypes.func.isRequired,
  updateUploadProgress: PropTypes.func.isRequired,
  mediaUploadError: PropTypes.func.isRequired,
  media: PropTypes.array.isRequired, // Media data
  mediaEntities: PropTypes.object.isRequired, // Media data
  folders: PropTypes.array.isRequired, // Media data
  mediaId: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]), // Media data
  onCancel: PropTypes.func.isRequired, // Modal data
  onOk: PropTypes.func.isRequired, // Modal data
  submitButtonText: PropTypes.string, // Modal data
  closeRenameMedia: PropTypes.func.isRequired, // Redux action creator
  mediaLibraryIncreasePage: PropTypes.func.isRequired, // Redux action creator
  mediaLibrarySetSearchPhrase: PropTypes.func.isRequired, // Redux action creator
  showFolderCreate: PropTypes.func.isRequired, // Redux action creator
  searchPhrase: PropTypes.string, // filter media by type and hide type filter
  mediaManagerContentType: PropTypes.string, // filter media by type and hide type filter
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    hasMorePages: PropTypes.bool.isRequired,
    isFirstPage: PropTypes.bool.isRequired,
    isLastPage: PropTypes.bool.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  showTypeFilter: PropTypes.bool,
};

const defaultProps = {
  iconUrl: '',
  mediaId: false,
  submitButtonText: null,
  searchPhrase: '',
  showTypeFilter: false,
  mediaManagerContentType: null,
  folderId: null,
};

const ADDITIONAL_IMAGE_TYPES = [
  'image',
  'gallery',
  'galleryImage',
  'sliderImage',
];

class MediaManager extends Component {
  static contextType = ConfirmationContext;

  constructor(props) {
    super(props);
    this.dropzoneRef = ''; // Reference to mounted dropzone
    this.listRef = null;
    this.onOk = this.onOk.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDoubleClick = this.onDoubleClick.bind(this);
    this.onFileDrop = this.onFileDrop.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.triggerFileUpload = this.triggerFileUpload.bind(this);
    this.onDropzoneMounted = this.onDropzoneMounted.bind(this);
    this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this);
    this.onRenameConfirmation = this.onRenameConfirmation.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onFilenameFilter = this.onFilenameFilter.bind(this);
    this.isMultipleMediaSelection = this.isMultipleMediaSelection.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.handleContentScroll = throttle(this.handleContentScroll, 500);

    this.state = {
      hasSelection: this.hasSelection(props.mediaId), // has media item selected
      selectedMediaId: props.mediaId, // id of the selected media item
      dropzoneActive: false, // hover over dropzone is active
      uploadActive: false, // hover over dropzone is active
      isSearchActive: false,
    };
  }

  componentDidMount() {
    this.props.loadMedia();
  }

  componentWillUnmount() {
    Media.flushListeners({ canceled: true, media: null });
  }

  handleContentScroll(event) {
    const { target } = event;
    const scrollThreshold = 0.75; // when 75% of scroll is achieved raise a new page
    const { pagination } = this.props;

    if (pagination.hasMorePages && target.offsetHeight + target.scrollTop >= (target.scrollHeight * scrollThreshold)) {
      this.props.mediaLibraryIncreasePage();
    }
  }

  onUploadError(xhr, uniqId) {
    const message = get(xhr, 'error.response.data.message', 'Failed to upload file');
    this.props.mediaUploadError(uniqId, message);
  }

  onBeforeStartFilesUpload() {
    if (!(this.contentRef && this.listRef)) {
      return;
    }

    this.contentRef.scrollTo({
      top: this.listRef.offsetTop,
      behavior: 'smooth',
      duration: 300,
    });
  }

  // Start uploading files
  onFileDrop(files) {
    this.setState({
      dropzoneActive: false,
    });

    if (files.length) {
      this.onBeforeStartFilesUpload({ files });

      files.map((file) => {
        const uploadId = uniqid();
        this.onUploadStart(file, uploadId);

        const metadata = { uploadId };
        return this.props.uploadMedia(file, {
          onUploadProgress: event => this.onUploadProgress(file, event, uploadId),
          onError: xhr => this.onUploadError(xhr, uploadId),
        }, metadata)
          .then((response) => {
            const uploadedId = get(response, 'response.id');

            if (uploadedId) {
              if (this.isMultipleMediaSelection()) {
                const prev = Array.from(this.state.selectedMediaId || []);
                this.onChange([
                  ...prev,
                  uploadedId,
                ]);
              } else {
                this.onChange(uploadedId);
              }
            }
          });
      });
    }
  }

  onOk() {
    const {
      mediaEntities,
      mediaManagerContentType,
    } = this.props;
    const {
      selectedMediaId,
    } = this.state;

    castArray(selectedMediaId).forEach((mediaId) => {
      const selectedMedia = mediaEntities[mediaId];

      if (!selectedMedia) {
        // eslint-disable-next-line no-console
        console.error(`Selected media ${mediaId} not found in app state!`);
        return;
      }

      const media = { ...selectedMedia };
      const { id, filename, fileExtension } = media;
      const path = `${id}/${encodeURIComponent(filename)}${fileExtension}`;
      media.url = `${ROUTE_MEDIA_GET}/${path}`;
      media.urlLarge = `${ROUTE_MEDIA_GET_LARGE}/${path}`;

      Media.flushListeners({ canceled: false, media });
      this.props.onOk(media, mediaManagerContentType);
    });
  }

  onCancel(evt) {
    evt.preventDefault();
    this.props.onCancel();
  }

  onChange(mediaId) {
    this.setState({
      hasSelection: this.hasSelection(mediaId),
      selectedMediaId: mediaId,
    });
  }

  onDoubleClick(mediaId) {
    if (!this.isMultipleMediaSelection()) {
      this.setState({
        hasSelection: this.hasSelection(mediaId),
        selectedMediaId: mediaId,
      }, () => {
        this.onOk();
      });
    }
  }

  onDropzoneMounted(ref) {
    if (ref) {
      this.dropzoneRef = ref;
    }
  }

  // On confirmation of delete media modal
  onDeleteConfirmation() {
    const confirm = this.context;
    const { deleteMediaProps: { id } } = this.props;

    this.props
      .deleteMedia({ id })
      .then((action) => {
        const { error, response = {} } = action;

        // Conflict - media already in use
        if (response.status === 409) {
          confirm({
            title: i18n.t('CONTENT.deleteMediaTitle'),
            message: response.data.message,
            buttonText: i18n.t('CONTENT.delete'),
            action: async () => {
              await this.props.deleteMedia({ id, force: true });
              await this.props.loadMedia();
            },
          });
        }

        if (!error) {
          this.props.loadMedia();
        }
      });

    // if media to be deleted is selected media, unselect media
    if (this.state.selectedMediaId === id) {
      this.setState({
        hasSelection: false,
        selectedMediaId: null,
      });
    }

    this.closeModal();
  }

  onUploadProgress(uploadedFile, event, uniqId) {
    const progress = Math.round((event.loaded * 100) / event.total);
    const file = {
      name: uploadedFile.name,
      lastModified: uploadedFile.lastModified,
      size: uploadedFile.size,
      type: uploadedFile.type,
    };

    this.props.updateUploadProgress(file, progress, uniqId);
  }

  onUploadStart(uploadedFile, uniqId) {
    const file = {
      name: uploadedFile.name,
      lastModified: uploadedFile.lastModified,
      size: uploadedFile.size,
      type: uploadedFile.type,
    };

    this.props.startUploadFile(file, uniqId);
  }

  onDragLeave() {
    this.setState({
      dropzoneActive: false,
    });
  }

  onDragEnter() {
    this.setState({
      dropzoneActive: true,
    });
  }

  // Handle filter input element value change.
  // The name of the input element should match the state filter property name
  onFilenameFilter(evt) {
    const { target: { value } } = evt;
    this.props.mediaLibrarySetSearchPhrase(value);
  }

  // On confirmation of rename media modal
  onRenameConfirmation(options) {
    const { renameMediaProps: { id } } = this.props;
    const { value } = options;
    const option = 'filename';
    this.props.renameMedia({ id, value })
      .then((response) => {
        const { error } = response;
        if (!error) {
          this.props.setMediaOption({ id, option, value });
          this.props.loadMedia();
        }
      });
    this.closeModal();
  }

  onContentScroll = (event) => {
    event.persist();
    this.handleContentScroll(event);
  };

  onFolderChange = () => {
    this.setState({
      selectedMediaId: null,
      hasSelection: false,
    });
  };

  getAcceptedFileTypes = () => {
    const { mediaManagerContentType } = this.props;

    if (ADDITIONAL_IMAGE_TYPES.includes(mediaManagerContentType)) {
      return 'image/*';
    }

    return null;
  };

  hasSelection = (selection) => {
    if (Array.isArray(selection)) {
      return selection.filter(Boolean).length > 0;
    }

    return Boolean(selection);
  };

  toggleSearchMode = () => {
    this.setState(prev => ({
      isSearchActive: !prev.isSearchActive,
    }));
  };

  // Close confirmation modals
  closeModal() {
    if (this.props.isDeleteMediaModalOpen) {
      this.props.closeDeleteMedia();
    }
    if (this.props.isRenameMediaModalOpen) {
      this.props.closeRenameMedia();
    }
  }

  triggerFileUpload(evt) {
    if (evt) {
      evt.preventDefault();
    }
    // show OS "file selector"
    this.dropzoneRef.open();
  }

  isMultipleMediaSelection() {
    const { mediaManagerContentType } = this.props;
    const multipleTypes = [
      'gallery',
    ];

    return Boolean(mediaManagerContentType && multipleTypes.includes(mediaManagerContentType));
  }

  renderModal() {
    const {
      deleteMediaProps,
      isDeleteMediaModalOpen,
      isRenameMediaModalOpen,
      renameMediaProps,
    } = this.props;
    // Show "delete media" modal
    if (isDeleteMediaModalOpen) {
      return (
        <ConfirmationModal
          icon="fal fa-exclamation-square"
          onCancel={this.closeModal}
          onClose={this.closeModal}
          onOk={this.onDeleteConfirmation}
          submitButtonText={i18n.t('CONTENT.delete')}
          title={i18n.t('CONTENT.deleteMediaTitle')}
        >
          <p className="u-mb-l u-mt-l">{i18n.t('CONTENT.deleteMediaQuestion')}</p>
          <p className="u-mb-l u-mt-l"><strong>{deleteMediaProps.filename}{deleteMediaProps.fileExtension}</strong></p>
        </ConfirmationModal>
      );
    }
    // Show "rename media" modal
    if (isRenameMediaModalOpen) {
      return (
        <PromptModal
          icon="fal fa-exclamation-square"
          onCancel={this.closeModal}
          onClose={this.closeModal}
          onOk={this.onRenameConfirmation}
          submitButtonText={i18n.t('CONTENT.update')}
          title={i18n.t('CONTENT.updateMediaName')}
          value={renameMediaProps.filename}
          label={i18n.t('CONTENT.newMediaName')}
          showRequired={false}
        />
      );
    }

    return null;
  }

  renderLoader = () => (
    <ul className="c-bb-media-manager__list">
      {
        Array.from(Array(4).keys()).map(key => (
          <li className="c-bb-media-manager__list-item" key={key}>
            <Skeleton
              height="52px"
              widthRandomness={0}
            />
          </li>
        ))
      }
      {
        Array.from(Array(4).keys()).map(key => (
          <li className="c-bb-media-manager__list-item" key={key}>
            <Skeleton
              height="247px"
              widthRandomness={0}
            />
          </li>
        ))
      }
    </ul>
  );

  renderEmpty = () => {
    const { searchPhrase, showFolderCreate } = this.props;

    if (searchPhrase) {
      return (
        <div className="c-bb-media-manager__no-result">
          {i18n.t('CONTENT.noResults')}
        </div>
      );
    }

    return (
      <EmptyView
        onCreateFolderClick={showFolderCreate}
        onUploadMediaClick={this.triggerFileUpload}
      />
    );
  };

  render() {
    const {
      hasSelection,
      dropzoneActive,
      uploadActive,
      selectedMediaId,
    } = this.state;
    const {
      media,
      folders,
      isLoading,
      showTypeFilter,
    } = this.props;

    const submitButtonText = this.props.submitButtonText || i18n.t('CONTENT.save');
    const hasMedia = media.length > 0;
    const hasFolders = folders.length > 0;
    const isEmpty = !(hasMedia || hasFolders);

    const content = (
      <div className="c-bb-media-manger__view">
        <MediaManagerToolbar
          enableTypeFilter={showTypeFilter}
          onSearchClick={this.toggleSearchMode}
        />
        <div
          ref={(node) => { this.contentRef = node; }}
          className="o-bb-modal__content"
          onScroll={this.onContentScroll}
        >
          <Dropzone
            onDrop={this.onFileDrop}
            onDragEnter={this.onDragEnter}
            onDragLeave={this.onDragLeave}
            accept={this.getAcceptedFileTypes()}
            disableClick
            disablePreview
            ref={this.onDropzoneMounted}
            className="c-bb-media-manager__dropzone"
            inputProps={{
              style: {
                visibility: 'hidden',
              },
            }}
          >
            {!isLoading && !isEmpty && (
              <FoldersContainer />
            )}
            {
              (!isLoading && !isEmpty)
                && (
                <MediaView
                  items={media}
                  listRef={(node) => { this.listRef = node; }}
                  openMediaUpload={this.triggerFileUpload}
                />
                )
            }
            { (!isLoading && isEmpty) && this.renderEmpty() }
            { dropzoneActive
            && (
            <div className="c-bb-media-manager__dropzone-overlay">
              <Icon name="upload" /> {i18n.t('CONTENT.fileDrop')}
            </div>
            )}
            { (isLoading || uploadActive) && this.renderLoader() }
          </Dropzone>
        </div>
      </div>
    );

    const searchView = (
      <div className="c-bb-media-manger__view">
        <SearchView
          onBackClick={this.toggleSearchMode}
          active={this.state.isSearchActive}
        />
      </div>
    );

    return (
      <div className="c-bb-media-manager__wrapper">
        <FoldersLoader onFolderChange={this.onFolderChange} />
        <div className={classNames(
          'c-bb-media-manager__wide-view',
          { 'c-bb-media-manager__wide-view--search-active': this.state.isSearchActive },
        )}
        >
          <MediaContextProvider
            onChange={this.onChange}
            onDoubleClick={this.onDoubleClick}
            selected={selectedMediaId}
            multiple={this.isMultipleMediaSelection()}
          >
            {content}
            {searchView}
          </MediaContextProvider>
        </div>
        {this.renderModal()}
        <ModalFooter>
          <BbButton className="c-bb-button--primary" disabled={!hasSelection} onClick={this.onOk} text={submitButtonText} />
          <BbButton className="c-bb-button--secondary" onClick={this.onCancel} text={i18n.t('CONTENT.cancel')} />
        </ModalFooter>
      </div>
    );
  }
}

MediaManager.propTypes = propTypes;
MediaManager.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const {
    content: {
      deleteMediaProps,
      isDeleteMediaModalOpen,
      isRenameMediaModalOpen,
      renameMediaProps,
      mediaManagerContentType,
    },
    media: {
      folderId,
      isFetchingFolders,
      isFetchingMedia,
      library: {
        searchPhrase,
      },
    },
    entities: {
      media: mediaEntities,
    },
  } = state;

  const { items, pagination } = selectMediaItems(state);
  const searchedMedia = state.media.searchResult.media || [];

  return {
    deleteMediaProps,
    isDeleteMediaModalOpen,
    isRenameMediaModalOpen,
    mediaEntities,
    media: [
      ...selectUploadedItems(state),
      ...items,
    ],
    availableMedia: [
      ...items,
      ...searchedMedia,
    ],
    folders: selectMediaFolders(state),
    renameMediaProps,
    mediaManagerContentType,
    searchPhrase,
    pagination,
    folderId,
    isLoading: isFetchingFolders || isFetchingMedia,
  };
};

const mapDispatchToProps = {
  closeDeleteMedia,
  closeRenameMedia,
  deleteMedia,
  loadMedia,
  setMediaOption,
  setMediaPickerType,
  renameMedia,
  uploadMedia,
  startUploadFile,
  updateUploadProgress,
  mediaUploadError,
  mediaLibraryIncreasePage,
  mediaLibrarySetSearchPhrase,
  showFolderCreate: showCreateFolderModal,
};

const ConnectedMediaManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaManager);

export default withConfirmation(ConnectedMediaManager);
