import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  contentPropType,
  CONTENT_COMPONENT_TYPE,
  CONTENT_SECTION_TYPE,
  DEFAULT_COLUMN_COUNT,
} from '@liswood-tache/browsbox-static';
import BrowsboxSortableList from '../ContentSortableList';
import { setComponentOption } from '../../../actions/contentConfig';
import { openMediaManager } from '../../../actions/mediaManager';
import { DEFAULT_VIEW } from '../../../actions/responsive';
import { getNewId } from '../../../utils/entities';
import Icon from '../../Icon/Icon';

const propTypes = {
  button: PropTypes.func.isRequired, // The function to render a 'section' button
  content: contentPropType.isRequired, // Section data
  openMediaManager: PropTypes.func.isRequired, // Redux action creator
  setComponentOption: PropTypes.func.isRequired, // Redux action creator
  title: PropTypes.func.isRequired, // The function to render a 'section' title
  maxImageCount: PropTypes.number, // The maximum amount of images to add
  defaultColumnCount: PropTypes.number, // The default number of images per 'column'
  renderTarget: PropTypes.string.isRequired, // Render default or preview
};

const defaultProps = {
  maxImageCount: 999,
  defaultColumnCount: DEFAULT_COLUMN_COUNT,
};

class ContentSectionGallery extends Component {
  constructor(props) {
    super(props);
    this.renderColumn = this.renderColumn.bind(this);
    this.addImage = this.addImage.bind(this);
    this.onComponentContentChange = this.onComponentContentChange.bind(this);
  }

  onComponentContentChange(componentProps, newContent) {
    this.props.setComponentOption({
      option: 'content',
      value: newContent,
      contentId: componentProps.id,
    });
  }

  getComponents = () => {
    const {
      content: { columns },
    } = this.props;

    if (columns[0]) {
      return columns[0].components;
    }

    return [];
  };

  addImage(evt) {
    if (evt) {
      evt.preventDefault();
    }
    const {
      content: { id },
    } = this.props;
    this.props.openMediaManager({ id, type: CONTENT_SECTION_TYPE.gallery });
  }

  renderColumn(column, idx) {
    // Use "type" to override the content.type to add "ContentComponentGalleryImage" instead of
    // "ContentComponentImage".
    const {
      content: { id: contentId, type: contentType },
      renderTarget,
    } = this.props;
    return (
      <BrowsboxSortableList
        components={column.components}
        onChange={this.onComponentContentChange}
        type={CONTENT_COMPONENT_TYPE.galleryImage}
        key={idx}
        id={contentId}
        contentType={contentType}
        renderTarget={renderTarget}
      >
        { renderTarget === DEFAULT_VIEW && this.renderAddImage() }
      </BrowsboxSortableList>
    );
  }

  renderAddImage() {
    const {
      maxImageCount,
    } = this.props;
    const currentImageCount = this.getComponents().length;
    const itemStyles = {
      fontSize: '28px',
    };
    // Allow to add up to n images
    if (currentImageCount < maxImageCount) {
      return (
        <div className="c-galleryImage" style={itemStyles}>
          <div className="c-gallery__placeholder" onClick={this.addImage}>
            <Icon className="icon-add" name="add" medium />
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      content: { id, columns, options: { columnCount } },
      title,
      button,
      defaultColumnCount,
    } = this.props;
    const mainClasses = classNames(
      'c-section__main js-lightbox-group',
      `c-gallery__${columnCount || defaultColumnCount}columns`,
      { 'l-columns': (columns.length > 1) },
    );

    const column = columns[0] || {
      id: getNewId(),
      components: [],
    };

    return (
      <div className="c-section__wrapper" key={id}>
        <header className="c-section__header">
          { title() }
        </header>
        <main className={mainClasses}>
          { this.renderColumn(column, 0) }
        </main>
        <footer className="c-section__footer">
          { button() }
        </footer>
      </div>
    );
  }
}

ContentSectionGallery.propTypes = propTypes;
ContentSectionGallery.defaultProps = defaultProps;

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  setComponentOption,
  openMediaManager,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentSectionGallery);
