/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as i18n from 'i18next';
import BBSelect from '../../@browsbox-ui/bb-select/BBSelect';
import { selectIsMultiLanguage } from '../../../selectors';

const BaseModuleLanguageFilter = ({
  value, onChange, languages, isMultiLanguage,
}) => {
  if (!isMultiLanguage) {
    return null;
  }

  return (
    <div className="c-bb-filter c-bb-filter--select c-bb-filter--language">
      <BBSelect
        placeholder={i18n.t('BASE_MODULE.allLanguages')}
        value={value}
        onChange={onChange}
        items={languages}
        clearable
        valueKey="code"
        labelKey="code"
      />
    </div>
  );
};

BaseModuleLanguageFilter.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  isMultiLanguage: PropTypes.bool.isRequired,
};

BaseModuleLanguageFilter.defaultProps = {
  value: null,
};

const mapStateToProps = state => ({
  languages: Object.values(state.entities.languages),
  isMultiLanguage: selectIsMultiLanguage(state),
});

export default connect(mapStateToProps)(BaseModuleLanguageFilter);
