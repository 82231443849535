import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FolderListItem from './FoldersListItem';
import Icon from '../../Icon/Icon';
import AbstractView from './AbstractView';

const FolderAddButton = ({ onClick }) => (
  <li
    className={classNames(
      'c-bb-media-folders-list__item',
    )}
  >
    <button
      type="button"
      onClick={onClick}
      className="c-bb-media-folders-list__button c-bb-media-folders-list__button--create-new"
    >
      <Icon name="folder-create" />
    </button>
  </li>
);

FolderAddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const FoldersList = ({
  folders,
  showNewFolderModal,
}) => (
  <ul className="c-bb-media-folders-list">
    {showNewFolderModal && (
      <FolderAddButton onClick={showNewFolderModal} />
    )}
    {folders.map(folder => (
      <FolderListItem key={folder.id} id={folder.id} name={folder.name} />
    ))}
  </ul>
);

FoldersList.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  showNewFolderModal: PropTypes.func,
};

FoldersList.defaultProps = {
  showNewFolderModal: undefined,
  folders: [],
};

export default props => (
  <AbstractView {...props} component={FoldersList} />
);
