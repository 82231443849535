/* eslint-disable import/prefer-default-export */
import { useRef, useEffect } from 'react';

/**
 * Returns a mutable ref object which indicates if drag event has occurs
 * base upon passed isDragging bool flag.
 * It can be used to determine if drag event occurs inside other event handler
 * of function component.
 *
 * @param isDragging
 * @param delay
 * @returns {React.MutableRefObject<boolean>}
 */
export function useDidDraggedRef(isDragging, delay = 100) {
  const didDragged = useRef(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (isDragging) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      didDragged.current = true;
    } else {
      timerRef.current = setTimeout(() => {
        didDragged.current = false;
      }, delay);
    }
  }, [isDragging]);

  return didDragged;
}
