import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  contentColumnPropType,
  contentPropType,
  contentComponentPropType,
  isVideoUrl,
  CONTENT_COMPONENT_TYPE,
  CONTENT_SECTION_TYPE,
  BUTTON_MODE,
  containerClass,
} from '@liswood-tache/browsbox-static';
import ContentComponents from './ContentComponents';
import ContentAppendComponent from './BlockBuilderTools/ContentAppendComponent';
import ComponentTools from './BlockBuilderTools/ComponentTools';
import { DEFAULT_VIEW } from '../../actions/responsive';

class BrowsboxContentColumn extends Component {
  static propTypes = {
    content: contentPropType.isRequired,
    column: contentColumnPropType.isRequired,
    components: PropTypes.arrayOf(contentComponentPropType),
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node,
    type: PropTypes.string, // Override component.type to determine ContentComponents to add
    contentType: PropTypes.string,
    renderTarget: PropTypes.string.isRequired,
  };

  static defaultProps = {
    components: [],
    children: null,
    type: '',
    contentType: '',
  };

  constructor(props) {
    super(props);
    this.renderComponent = this.renderComponent.bind(this);
  }

  shouldRenderComponent(component) {
    const { content: { options, type: contentType } } = this.props;
    const { type } = component;

    if (contentType === CONTENT_SECTION_TYPE.blockBuilder) {
      return true;
    }

    if (type === CONTENT_COMPONENT_TYPE.button) {
      return options.primaryButtonsActive && options.buttonDisplay === BUTTON_MODE.BUTTON_PER_COLUMN;
    }

    if (type === CONTENT_COMPONENT_TYPE.buttonSecondary) {
      return options.secondaryButtonsActive && options.buttonDisplay === BUTTON_MODE.BUTTON_PER_COLUMN;
    }

    return true;
  }

  renderComponent(component) {
    if (!this.shouldRenderComponent(component)) {
      return null;
    }

    const {
      onChange,
      type,
      column,
      contentType,
      renderTarget,
    } = this.props;
    let componentTag = type || component.type;
    // Component type image with video url
    if (componentTag === 'image' && isVideoUrl(component.url)) {
      componentTag = 'video';
    }

    if (componentTag === 'buttonSecondary') {
      componentTag = 'button';
    }

    const Tag = ContentComponents[componentTag];
    const containerClassName = containerClass(contentType, componentTag);

    if (Tag) {
      return (
        <ComponentTools
          key={`${component.id}_${componentTag}`}
          componentId={component.id}
          contentType={contentType}
          componentType={component.type}
          columnId={column.id}
          disabled={renderTarget !== DEFAULT_VIEW}
        >
          <Tag
            key={`${component.id}_${componentTag}`}
            onChange={onChange}
            containerClassName={containerClassName}
            renderTarget={renderTarget}
            {...component}
          />
        </ComponentTools>
      );
    }

    return <div key={component.id}>Missing component <b>{component.type}</b></div>;
  }

  render() {
    const {
      column,
      content,
      components,
      children,
      type,
      renderTarget,
    } = this.props;

    const classes = classNames(
      'l-column',
      column.classes,
      { [`o-content-column-${type}`]: type },
    );

    if (column.enabled === false) {
      return null;
    }

    return (
      <div
        className={classes}
        id={column.id}
      >
        {components.map(this.renderComponent)}
        {children}
        {renderTarget === DEFAULT_VIEW && CONTENT_SECTION_TYPE.blockBuilder === content.type && (
          <ContentAppendComponent
            contentId={content.id}
            columnId={column.id}
          />
        )}
      </div>
    );
  }
}

export default BrowsboxContentColumn;
