/* global $settings, initialPageContents */
/* eslint-disable no-global-assign */

$settings = $settings || {};

export function isStaticPage() {
  return $settings && $settings.pageType === 'static';
}

export function getInitialPageContents() {
  return initialPageContents || '';
}
