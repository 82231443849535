import React from 'react';
import {
  reduxForm, Field, FormSection,
} from 'redux-form';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import get from 'lodash/get';
import 'react-widgets/dist/css/react-widgets.css';
import TextField from '../../../FormElements/TextField';
import NumberField from '../../../FormElements/NumberField';
import validation from '../../../../tools/validation';
import CategoriesCheckboxField from '../../../FormElements/CategoriesCheckboxField';
import DetailFiltersCheckboxField from '../../../FormElements/DetailFiltersCheckboxField';
import RenderItemFields from './RenderItemFields';
import ProductCodes from '../../../FormElements/ProductCodes';
import i18n from '../../../../internationalization/i18n';
import MultiselectTagsField from '../../../FormElements/MultiselectTagsField';
import RelatedItemsField from '../../../FormElements/RelatedItemsField';
import CheckboxField from '../../../FormElements/CheckboxField';
import { BbButton, BBSelect } from '../../../@browsbox-ui';
import BaseModuleItemGooglePreview from './BaseModuleItemGooglePreview';
import { SeoTitle, SeoDescription, SeoUrl } from '../../../FormElements/SeoFields';
import Icon from '../../../Icon/Icon';
import { normalizeSlug } from '../../../../tools/url';
import { changeBaseModuleLang } from '../../../../actions/baseModule';

const textInputValidationRules = [validation.required, validation.maxLength(255)];

const propTypes = {
  currentModule: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  selectable: PropTypes.array.isRequired,
  routePrefix: PropTypes.string.isRequired,
  productTypes: PropTypes.array,
  detailFilters: PropTypes.array,
  onReset: PropTypes.func,
  onDelete: PropTypes.func,
  changeLang: PropTypes.func.isRequired,
  allTags: PropTypes.array,
  enabledModules: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
  langs: PropTypes.array,
  values: PropTypes.object.isRequired,
  allowForLangChange: PropTypes.bool,
};

const defaultProps = {
  productTypes: [],
  detailFilters: [],
  onReset: () => {},
  allTags: [],
  langs: [],
  allowForLangChange: false,
  onDelete: null,
};

const renderPublishedWarning = (currentModule) => {
  const isPublished = get(currentModule, 'is_published', false);

  if (isPublished) {
    return null;
  }

  return (
    <div className="c-bb-form__input-warning">
      {i18n.t('CONTENT.baseModuleNotPublished')}
    </div>
  );
};

const asyncValidate = async (values, dispatch, props) => {
  const errors = {};

  if (values.seoUrl && values.manualSeo) {
    const { data: { exists } } = await axios('/api/routes/exists', {
      params: {
        slug: `${props.routePrefix}/${values.seoUrl}`,
        id: values.routeId,
      },
    });

    if (exists) {
      // eslint-disable-next-line no-throw-literal
      throw { seoUrl: 'url_already_taken' };
    }
  }

  return errors;
};

const BaseModuleItemForm = ({
  handleSubmit,
  submitting,
  onReset,
  onDelete,
  currentModule,
  selectable,
  productTypes,
  detailFilters,
  allTags,
  enabledModules,
  values,
  change,
  routePrefix,
  allowForLangChange,
  lang,
  changeLang,
  langs,
}) => {
  React.useEffect(() => {
    // when manualSeo is toggled off, then also enable other related autoSeo fields
    if (!values.manualSeo) {
      change('autoSeoTitle', true);
      change('autoSeoDescription', true);
      change('autoSeoUrl', true);
    }
  }, [values.manualSeo]);
  const hansManyLanguages = Array.from(langs || []).length > 1;

  return (
    <form className="c-bb-form__container" onSubmit={handleSubmit}>
      <div className="c-bb-form__group c-bb-form__group--spaced">
        <Field
          name="route.published"
          component={CheckboxField}
          label={i18n.t('CONTENT.publish').toUpperCase()}
        />
        {onDelete && (
          <BbButton
            secondary
            type="button"
            onClick={onDelete}
          >
            <Icon className="c-bb-button__icon" name="trash" small />
            {i18n.t('CONTENT.remove').toUpperCase()}
          </BbButton>
        )}
        {allowForLangChange && hansManyLanguages && (
          <div>
            <BBSelect
              onChange={item => {
                changeLang(item.id);
                // just trigger the title change, so that seo values can update for correct lang
                change('title', values.title);
              }}
              clearable={false}
              searchable={false}
              value={lang}
              placeholder="Select language..."
              items={langs.map(item => ({ id: item.code, title: item.code.toUpperCase() }))}
            />
          </div>
        )}
      </div>
      {renderPublishedWarning(currentModule)}
      <div className="c-bb-form__group">
        <Field
          name="title"
          component={TextField}
          label={i18n.t('BASE_MODULE.title')}
          validate={textInputValidationRules}
        />
      </div>
      <RenderItemFields fields={currentModule.fields} />
      {/* @todo extract to components nsi poduct types */}
      {!!productTypes.length
      && (
      <div>
        <div className="c-bb-form__input-item-group" style={{ marginBottom: '20px' }}>
          <div className="input-item-group__label">TYPE</div>
          <div className="input-item-group">
            {productTypes.map(type => (
              <label key={`${type.id}_${type.label}`} htmlFor={`${type.id}_${type.label}`} style={{ marginRight: '20px' }}>
                <Field
                  id={`${type.id}_${type.label}`}
                  name="type"
                  component="input"
                  type="radio"
                  value={type.id.toString()}
                />
                &nbsp;&nbsp;
                {type.label}
              </label>
            ))}
          </div>
        </div>
      </div>
      )}
      {enabledModules.includes('base_module_shop')
      && currentModule.has_shop_data
      && (
      <div className="c-bb-form__input-wrap">
        <Field
          name="shopData.price"
          component={NumberField}
          label={i18n.t('BASE_MODULE.price')}
          validate={[validation.required]}
          min={0}
          step={0.01}
        />
      </div>
      )}
      {!!selectable.length
      && (
      <div>
        <h2 className="c-bb-form__subtitle">Categorieën</h2>
        <Field
          name="categories"
          categories={selectable}
          label={i18n.t('CONTENT.category')}
          component={CategoriesCheckboxField}
        />
      </div>
      )}
      {enabledModules.includes('base_module_tags') && (
      <Field
        name="tags"
        component={MultiselectTagsField}
        label={i18n.t('CONTENT.tags')}
        data={allTags}
      />
      )}
      {!!detailFilters.length
      && (
      <div className="c-bb-form__input-item-group" style={{ marginBottom: '20px' }}>
        <div className="input-item-group__label">DETAIL FILTERS</div>
        <FormSection
          name="detailFilters"
          style={{
            display: 'flex', padding: '15px', paddingTop: '45px', flexWrap: 'wrap',
          }}
        >
          {detailFilters.map(filter => (
            <Field
              key={filter.label}
              name={filter.label}
              categories={filter.filters}
              label={filter.label}
              component={DetailFiltersCheckboxField}
            />
          ))}
        </FormSection>
      </div>
      )}
      {!!detailFilters.length
      && (
      <div className="c-bb-form__input-item-group" style={{ marginBottom: '20px' }}>
        <div className="input-item-group__label">PRODUCT CODES</div>
        <FormSection name="productCodes">
          <ProductCodes />
        </FormSection>
      </div>
      )}
      <h2 className="c-bb-form__subtitle">Gerelateerde items</h2>
      <Field
        name="parents"
        component={RelatedItemsField}
        label={i18n.t('CONTENT.parentItems')}
      />
      <Field
        name="children"
        component={RelatedItemsField}
        label={i18n.t('CONTENT.childItems')}
      />
      {currentModule.has_detail_pages && (
        <>
          <h2 className="c-bb-form__subtitle">Instellingen Google zoekresultaten</h2>
          <div className="c-bb-form__google-preview">
            <BaseModuleItemGooglePreview
              routePrefix={routePrefix}
              values={values}
            />
          </div>
          <Field
            name="manualSeo"
            component={CheckboxField}
            label={i18n.t('CONTENT.manualSeo')}
          />
          {values.manualSeo && (
            <div className="o-bb-base-module__manual-seo">
              <Field
                name="seoTitle"
                validate={values.autoSeoTitle ? undefined : validation.required}
                checked={!!values.autoSeoTitle}
                onSwitchChange={(value) => {
                  change('autoSeoTitle', value);
                }}
                component={SeoTitle}
                label={i18n.t('CONTENT.pageTitle')}
                disabledValue={values.autoSeoValues.title}
                maxLength={70}
              />
              <Field
                name="seoDescription"
                validate={values.autoSeoDescription ? undefined : validation.required}
                checked={!!values.autoSeoDescription}
                onSwitchChange={(value) => {
                  change('autoSeoDescription', value);
                }}
                component={SeoDescription}
                label={i18n.t('CONTENT.pageDescription')}
                disabledValue={values.autoSeoValues.description}
                maxLength={155}
              />
              <Field
                name="seoUrl"
                validate={values.autoSeoUrl ? undefined : validation.required}
                checked={!!values.autoSeoUrl}
                onSwitchChange={(value) => {
                  change('autoSeoUrl', value);
                }}
                component={SeoUrl}
                urlPrefix={values.autoSeoUrl ? values.autoSeoValues.url : routePrefix}
                disabledValue={(values.autoSeoValues.slug || '').replace(/\//g, '')}
                normalize={normalizeSlug}
                label={i18n.t('CONTENT.url')}
                maxLength={60}
              />
            </div>
          )}
        </>
      )}
      <div className="c-bb-form__footer c-bb-form__actions c-bb-form__actions--fixed">
        <BbButton
          primary
          type="submit"
          disabled={submitting}
        >
          {i18n.t('CONTENT.save').toUpperCase()}
        </BbButton>
        <BbButton
          secondary
          underline
          className="c-bb-form__reset"
          type="button"
          onClick={onReset}
        >
          {i18n.t('CONTENT.reset').toUpperCase()}
        </BbButton>
      </div>
    </form>
  );
};

BaseModuleItemForm.propTypes = propTypes;
BaseModuleItemForm.defaultProps = defaultProps;

const mapStateToProps = (state, { form }) => {
  const {
    global: {
      websiteSettings: {
        enabledModules,
      },
    },
    baseModule: {
      lang,
      langs,
    },
  } = state;

  return {
    lang,
    langs,
    enabledModules,
    values: state.form[form].values,
  };
};

const mapDispatchToProps = {
  changeLang: changeBaseModuleLang,
};

export default reduxForm({
  form: 'baseModuleItemForm',
  enableReinitialize: true,
  asyncValidate,
  asyncChangeFields: ['manualSeo', 'seoUrl', 'autoSeoTitle', 'autoSeoUrl'],
})(
  withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(BaseModuleItemForm)),
);
