/* eslint-disable jsx-a11y/control-has-associated-label,jsx-a11y/mouse-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { DragSource, DropTarget } from 'react-dnd';
import { CONTENT_COMPONENT_TYPE, LinkedImage } from '@liswood-tache/browsbox-static';
import { openDeleteContent } from '../../../actions/contentDelete';
import { DND_CONTENT_COMPONENT } from '../../DragAndDrop/dndTypes';
import { DEFAULT_VIEW } from '../../../actions/responsive';
import { openMediaPicker } from '../../../actions/mediaManager';
import withCropper, { withCropperPropTypes } from '../ImageTools/Cropper/withCropper';
import Cropper from '../ImageTools/Cropper/Cropper';
import Icon from '../../Icon/Icon';

const dndSource = {
  beginDrag(props, monitor, component) {
    const {
      id, dndIndex, dndValidTarget, url,
    } = props;
    // eslint-disable-next-line react/no-find-dom-node
    const { width, height } = component.imageRef.getBoundingClientRect();

    props.onBeginDrag(id);
    return {
      id, dndIndex, dndValidTarget, originalIndex: dndIndex, imageUrl: url, width, height,
    };
  },
  endDrag(props, monitor) {
    const item = monitor.getItem();
    props.onEndDrag(item);
  },
};

const dndTarget = {
  hover(props, monitor) {
    if (
      !props.dndValidTarget
      || props.dndValidTarget !== monitor.getItem().dndValidTarget
    ) {
      return;
    }
    const dragIndex = monitor.getItem().dndIndex;
    const hoverIndex = props.dndIndex;
    if (dragIndex === hoverIndex) {
      return;
    }
    props.moveItem(dragIndex, hoverIndex);
    monitor.getItem().dndIndex = hoverIndex; // eslint-disable-line no-param-reassign
  },
};

const dropTargetCollect = connectDnd => ({
  connectDropTarget: connectDnd.dropTarget(),
});

const dragSourceCollect = (connectDnd, monitor) => ({
  connectDragSource: connectDnd.dragSource(),
  connectDragPreview: connectDnd.dragPreview(),
  dndIsDragging: monitor.isDragging(),
});

@DropTarget(DND_CONTENT_COMPONENT, dndTarget, dropTargetCollect)
@DragSource(DND_CONTENT_COMPONENT, dndSource, dragSourceCollect)

class ContentComponentGalleryImage extends Component {
  static propTypes = {
    alt: PropTypes.string, // Section Component data
    classes: PropTypes.string, // Section Component datag,
    connectDragPreview: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    connectDragSource: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    connectDropTarget: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    dndAllowMove: PropTypes.bool.isRequired,
    dndIndex: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
    dndIsDragging: PropTypes.bool.isRequired,
    dndValidTarget: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    hyperlink: PropTypes.string, // Section Component data
    id: PropTypes.number.isRequired, // Section Component data
    isDeletable: PropTypes.bool, // Show delete action
    isCroppingSupported: PropTypes.bool.isRequired,
    moveItem: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    onBeginDrag: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    onEndDrag: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    openDeleteContent: PropTypes.func.isRequired, // Redux action creator
    openMediaPicker: PropTypes.func.isRequired,
    renderTarget: PropTypes.string.isRequired, // Render default or preview
    tooltip: PropTypes.string, // Section Component data
    type: PropTypes.string, // Section Component data
    url: PropTypes.string, // Section Component data

    ...withCropperPropTypes,
  };

  static defaultProps = {
    alt: '',
    classes: '',
    containerClassName: '',
    tooltip: '',
    type: CONTENT_COMPONENT_TYPE.galleryImage,
    url: '',
    hyperlink: '',
    isDeletable: true,
  };

  static renderDraghandle() {
    return <button type="button" className="button--mini"><Icon name="move" small /></button>;
  }

  constructor(props) {
    super(props);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onMediaPickerClick = this.onMediaPickerClick.bind(this);
    this.onCropperClick = this.onCropperClick.bind(this);
    this.disableImageCropping = this.disableImageCropping.bind(this);
    this.setImageRef = this.setImageRef.bind(this);
  }

  onDeleteClick(evt) {
    evt.preventDefault();
    const { id, type } = this.props;
    this.props.openDeleteContent({ id, type });
  }

  onMediaPickerClick(evt) {
    evt.preventDefault();
    const {
      id,
    } = this.props;
    const bbox = this.imageRef ? this.imageRef.getBoundingClientRect() : { width: 0, height: 0 };
    const { width, height } = bbox;
    this.props.openMediaPicker({
      id, type: CONTENT_COMPONENT_TYPE.galleryImage, width, height,
    });
  }

  onCropperClick() {
    this.props.enableImageCropping();
  }

  setImageRef(imageRef) {
    this.imageRef = imageRef;
  }

  resolveImageSrc = () => {
    const { cropperData, url } = this.props;

    if (cropperData && cropperData.src) {
      return cropperData.src;
    }

    return url;
  };

  disableImageCropping() {
    this.props.disableImageCropping();
  }

  renderImage() {
    const {
      renderTarget,
      classes,
      containerClassName,
      isCropping,
      component: {
        type,
        hyperlink,
        url,
        alt,
        tooltip,
        mediaId,
      },
    } = this.props;
    const componentClasses = classNames(
      classes,
      containerClassName,
      'c-image',
      `c-image-${type}`,
    );

    if (renderTarget === DEFAULT_VIEW) {
      return (
        <div className={componentClasses}>
          {isCropping && (
            <Cropper
              src={this.resolveImageSrc()}
              originalMediaId={Number(mediaId)}
              onCrop={this.props.handleCropImage}
              onCropCancel={this.props.disableImageCropping}
              isCropping={this.props.isCropping}
              cropperData={this.props.cropperData}
              isCropPending={this.props.isCropPending}
            />
          )}
          {!isCropping && (
            <LinkedImage hyperlink={hyperlink}>
              <img
                className={`c-${type}__element c-image__element`}
                src={url}
                alt={alt}
                title={tooltip}
                onClick={this.onSelect}
                ref={this.onMounted}
              />
            </LinkedImage>
          )}
        </div>
      );
    }

    return (
      <div className={componentClasses}>
        <div className="c-image__container">
          <LinkedImage hyperlink={hyperlink}>
            <img
              className={`c-${type}__element c-image__element`}
              src={url}
              alt={alt}
              title={tooltip}
            />
          </LinkedImage>
        </div>
      </div>
    );
  }

  renderToolbar() {
    return (
      <button type="button" onClick={this.onDeleteClick} className="button--mini">
        <Icon name="trash" small />
      </button>
    );
  }

  renderImageManager() {
    return (
      <button type="button" onClick={this.onMediaPickerClick} className="button--mini">
        <Icon name="image" medium />
      </button>
    );
  }

  renderCropButton() {
    if (!this.props.isCroppingSupported) {
      return null;
    }

    return (
      <button type="button" onClick={this.onCropperClick} className="button--mini">
        <Icon name="crop" medium />
      </button>
    );
  }

  render() {
    const {
      classes,
      connectDragPreview,
      connectDragSource,
      connectDropTarget,
      dndAllowMove,
      dndIsDragging,
      renderTarget,
      isDeletable,
    } = this.props;
    const type = CONTENT_COMPONENT_TYPE.galleryImage;
    const componentClasses = classNames(
      classes,
      `c-${type}`,
    );
    const opacity = dndIsDragging ? 0.6 : 1;

    // render edit behavior
    if (renderTarget === DEFAULT_VIEW) {
      return (
        connectDropTarget(
          connectDragPreview(
            <div
              ref={this.setImageRef}
              className={componentClasses}
              style={{ opacity }}
              onMouseLeave={this.onMouseLeave}
              onMouseOver={this.onMouseOver}
            >
              { this.renderImage() }
              { !this.props.isCropping && (
                <div className="c-bb-image-config">
                  {isDeletable && this.renderToolbar()}
                  {dndAllowMove && connectDragSource(ContentComponentGalleryImage.renderDraghandle())}
                  {this.renderImageManager()}
                  {this.renderCropButton()}
                </div>
              )}
            </div>,
          ),
        )
      );
    }
    // render target is preview or html
    return (
      <div
        className={componentClasses}
        style={{ opacity }}
      >
        {this.renderImage()}
      </div>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  openDeleteContent,
  openMediaPicker,
};

export default withCropper(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContentComponentGalleryImage),
);
