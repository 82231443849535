import { openLinkPicker } from '../../actions/links';

class Links {
  store = null;

  listener;

  setStore(store) {
    this.store = store;
  }

  launchLinkPickerAsync(options = {}) {
    if (!this.store) {
      throw new Error('Unable to lunch Link Picker. Store is not set.');
    }

    const defaults = {
      link: '',
      lang: null,
    };

    const settings = {
      ...defaults,
      ...options,
    };

    this.store.dispatch(openLinkPicker(settings));

    if (this.listener) {
      this.listener({ canceled: true, link: null });
    }

    return new Promise((resolve) => {
      this.listener = resolve;
    });
  }

  notify({ canceled, link }) {
    if (this.listener) {
      this.listener({ canceled, link });
    }

    this.listener = null;
  }
}

export default new Links();
