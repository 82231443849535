import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import MouseBackEnd from 'react-dnd-mouse-backend';

function BrowsboxDndContainer(props) {
  const {
    responsiveView,
  } = props;

  const classes = `o-view--${responsiveView}`;

  return (
    <DndProvider backend={MouseBackEnd}>
      <div id="dndContainer" className={classes}>
        {props.children}
      </div>
    </DndProvider>
  );
}

BrowsboxDndContainer.propTypes = {
  children: PropTypes.node.isRequired,
  responsiveView: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const {
    options: {
      view: {
        responsiveView,
      },
    },
  } = state;

  return {
    responsiveView,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BrowsboxDndContainer);
