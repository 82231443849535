import {
  ROUTE_NSI_PRODUCT_TYPES,
  ROUTE_BASEMODULE_ITEMS_ORDER,
  ROUTE_BASEMODULE_ITEMS,
  ROUTE_BASEMODULE_ITEM,
  ROUTE_BASEMODULE_ITEM_PUBLISH,
  ROUTE_BASEMODULE_ITEM_UNPUBLISH,
  ROUTE_NSI_PRODUCT_TYPE,
  ROUTE_NSI_DETAIL_FILTERS,
  ROUTE_NSI_ITEM_DETAIL_FILTERS,
  ROUTE_BASEMODULE_ALL_ITEMS,
  ROUTE_BASEMODULE_ITEMS_SEARCH,
  ROUTE_BASEMODULE_ITEM_MOVE,
  ROUTE_BASEMODULE_ITEM_SEO_PREVIEW,
  ROUTE_BASEMODULE_ITEM_CLONE,
} from '../api/routes';
import { buildRoute, createApiAction, CALL_BB_API } from '../api';
import { selectCurrentModuleName } from '../selectors/baseModule';

export const BASE_MODULE_ITEMS_ORDER_REQUEST = 'BASE_MODULE_ITEMS_ORDER_REQUEST';
export const BASE_MODULE_ITEMS_ORDER_SUCCESS = 'BASE_MODULE_ITEMS_ORDER_SUCCESS';
export const BASE_MODULE_ITEMS_ORDER_FAILURE = 'BASE_MODULE_ITEMS_ORDER_FAILURE';

const postBaseModuleItemsOrder = (value, id) => {
  const endpoint = buildRoute(ROUTE_BASEMODULE_ITEMS_ORDER, { id });
  return {
    [CALL_BB_API]: {
      types: [BASE_MODULE_ITEMS_ORDER_REQUEST, BASE_MODULE_ITEMS_ORDER_SUCCESS, BASE_MODULE_ITEMS_ORDER_FAILURE],
      endpoint,
      method: 'POST',
      json: value,
    },
  };
};

export const orderBaseModuleItems = value => (dispatch, getState) => {
  const { baseModule } = getState();
  return dispatch(postBaseModuleItemsOrder(value, baseModule.currentModule.id));
};

export const BASE_MODULE_ITEMS_REORDER = 'BASE_MODULE_ITEMS_REORDER';

export const reorderBaseModuleItems = ({ oldIndex, newIndex }) => ({
  type: BASE_MODULE_ITEMS_REORDER,
  payload: {
    oldIndex, newIndex,
  },
});

export const BASE_MODULE_ITEMS_SET_SORT_BY = 'BASE_MODULE_ITEMS_SET_SORT_BY';

export const baseModuleSetSortBy = ({ field, direction }) => ({
  type: BASE_MODULE_ITEMS_SET_SORT_BY,
  payload: {
    field, direction,
  },
});

export const BASE_MODULE_ITEM_SAVE_REQUEST = 'BASE_MODULE_ITEM_SAVE_REQUEST';
export const BASE_MODULE_ITEM_SAVE_SUCCESS = 'BASE_MODULE_ITEM_SAVE_SUCCESS';
export const BASE_MODULE_ITEM_SAVE_FAILURE = 'BASE_MODULE_ITEM_SAVE_FAILURE';

const postBaseModuleItem = (value, entityId) => {
  const endpoint = buildRoute(ROUTE_BASEMODULE_ITEMS, { entityId });
  return {
    [CALL_BB_API]: {
      types: [BASE_MODULE_ITEM_SAVE_REQUEST, BASE_MODULE_ITEM_SAVE_SUCCESS, BASE_MODULE_ITEM_SAVE_FAILURE],
      endpoint,
      method: 'POST',
      json: value,
    },
  };
};

export const saveBaseModuleItem = (value, entityId) => dispatch => dispatch(postBaseModuleItem(value, entityId));

export const BASE_MODULE_ITEM_UPDATE_REQUEST = 'BASE_MODULE_ITEM_UPDATE_REQUEST';
export const BASE_MODULE_ITEM_UPDATE_SUCCESS = 'BASE_MODULE_ITEM_UPDATE_SUCCESS';
export const BASE_MODULE_ITEM_UPDATE_FAILURE = 'BASE_MODULE_ITEM_UPDATE_FAILURE';

const putBaseModuleItem = (value, itemId) => {
  const endpoint = buildRoute(ROUTE_BASEMODULE_ITEM, { itemId });
  return {
    [CALL_BB_API]: {
      types: [BASE_MODULE_ITEM_UPDATE_REQUEST, BASE_MODULE_ITEM_UPDATE_SUCCESS, BASE_MODULE_ITEM_UPDATE_FAILURE],
      endpoint,
      method: 'PUT',
      json: value,
    },
  };
};

export const updateBaseModuleItem = (id, values) => dispatch => dispatch(putBaseModuleItem(values, id));

export const BASE_MODULE_ITEM_NSI_TYPES_REQUEST = 'BASE_MODULE_ITEM_NSI_TYPES_REQUEST';
export const BASE_MODULE_ITEM_NSI_TYPES_SUCCESS = 'BASE_MODULE_ITEM_NSI_TYPES_SUCCESS';
export const BASE_MODULE_ITEM_NSI_TYPES_FAILURE = 'BASE_MODULE_ITEM_NSI_TYPES_FAILURE';

const nsiProductTypes = () => {
  const endpoint = ROUTE_NSI_PRODUCT_TYPES;
  return {
    [CALL_BB_API]: {
      types: [
        BASE_MODULE_ITEM_NSI_TYPES_REQUEST,
        BASE_MODULE_ITEM_NSI_TYPES_SUCCESS,
        BASE_MODULE_ITEM_NSI_TYPES_FAILURE,
      ],
      endpoint,
      method: 'GET',
    },
  };
};

export const getNsiProductTypes = () => dispatch => dispatch(nsiProductTypes());

export const NSI_DETAIL_FILTERS_REQUEST = 'NSI_DETAIL_FILTERS_REQUEST';
export const NSI_DETAIL_FILTERS_SUCCESS = 'NSI_DETAIL_FILTERS_SUCCESS';
export const NSI_DETAIL_FILTERS_FAILURE = 'NSI_DETAIL_FILTERS_FAILURE';

const fetchNsiDetailFilters = () => {
  const endpoint = ROUTE_NSI_DETAIL_FILTERS;
  return {
    [CALL_BB_API]: {
      types: [NSI_DETAIL_FILTERS_REQUEST, NSI_DETAIL_FILTERS_SUCCESS, NSI_DETAIL_FILTERS_FAILURE],
      endpoint,
      method: 'GET',
    },
  };
};

export const getNsiDetailFilters = () => dispatch => dispatch(
  fetchNsiDetailFilters(),
);

export const NSI_SAVE_DETAIL_FILTERS_REQUEST = 'NSI_SAVE_DETAIL_FILTERS_REQUEST';
export const NSI_SAVE_DETAIL_FILTERS_SUCCESS = 'NSI_SAVE_DETAIL_FILTERS_SUCCESS';
export const NSI_SAVE_DETAIL_FILTERS_FAILURE = 'NSI_SAVE_DETAIL_FILTERS_FAILURE';

const postNsiDetailFilters = (value) => {
  const endpoint = ROUTE_NSI_DETAIL_FILTERS;
  return {
    [CALL_BB_API]: {
      types: [NSI_SAVE_DETAIL_FILTERS_REQUEST, NSI_SAVE_DETAIL_FILTERS_SUCCESS, NSI_SAVE_DETAIL_FILTERS_FAILURE],
      endpoint,
      method: 'POST',
      json: value,
    },
  };
};

export const saveNsiDetailFilters = value => dispatch => dispatch(
  postNsiDetailFilters(value),
);

export const NSI_GET_ITEM_DETAIL_FILTERS_REQUEST = 'NSI_GET_ITEM_DETAIL_FILTERS_REQUEST';
export const NSI_GET_ITEM_DETAIL_FILTERS_SUCCESS = 'NSI_GET_ITEM_DETAIL_FILTERS_SUCCESS';
export const NSI_GET_ITEM_DETAIL_FILTERS_FAILURE = 'NSI_GET_ITEM_DETAIL_FILTERS_FAILURE';

const fetchNsiItemDetailFilters = (id) => {
  const endpoint = buildRoute(ROUTE_NSI_ITEM_DETAIL_FILTERS, { id });
  return {
    [CALL_BB_API]: {
      types: [
        NSI_GET_ITEM_DETAIL_FILTERS_REQUEST,
        NSI_GET_ITEM_DETAIL_FILTERS_SUCCESS,
        NSI_GET_ITEM_DETAIL_FILTERS_FAILURE,
      ],
      endpoint,
      method: 'GET',
    },
  };
};

export const getNsiItemDetailFilters = id => dispatch => dispatch(
  fetchNsiItemDetailFilters(id),
);

export const NSI_TYPE_SAVE_REQUEST = 'NSI_TYPE_SAVE_REQUEST';
export const NSI_TYPE_SAVE_SUCCESS = 'NSI_TYPE_SAVE_SUCCESS';
export const NSI_TYPE_SAVE_FAILURE = 'NSI_TYPE_SAVE_FAILURE';

const postNsiProductType = (id, value) => {
  const endpoint = buildRoute(ROUTE_NSI_PRODUCT_TYPE, { id });
  return {
    [CALL_BB_API]: {
      types: [NSI_TYPE_SAVE_REQUEST, NSI_TYPE_SAVE_SUCCESS, NSI_TYPE_SAVE_FAILURE],
      endpoint,
      method: 'POST',
      json: value,
    },
  };
};

export const saveNsiProductType = (id, value) => dispatch => dispatch(
  postNsiProductType(id, value),
);

export const NSI_TYPE_GET_REQUEST = 'NSI_TYPE_GET_REQUEST';
export const NSI_TYPE_GET_SUCCESS = 'NSI_TYPE_GET_SUCCESS';
export const NSI_TYPE_GET_FAILURE = 'NSI_TYPE_GET_FAILURE';

const getNsiProductType = (id) => {
  const endpoint = buildRoute(ROUTE_NSI_PRODUCT_TYPE, { id });
  return {
    [CALL_BB_API]: {
      types: [NSI_TYPE_GET_REQUEST, NSI_TYPE_GET_SUCCESS, NSI_TYPE_GET_FAILURE],
      endpoint,
      method: 'GET',
    },
  };
};

export const receiveNsiProductType = id => dispatch => dispatch(
  getNsiProductType(id),
);

export const BASE_MODULE_ITEM_DELETE_REQUEST = 'BASE_MODULE_ITEM_DELETE_REQUEST';
export const BASE_MODULE_ITEM_DELETE_SUCCESS = 'BASE_MODULE_ITEM_DELETE_SUCCESS';
export const BASE_MODULE_ITEM_DELETE_FAILURE = 'BASE_MODULE_ITEM_DELETE_FAILURE';

const deleteBaseModuleItem = (itemId) => {
  const endpoint = buildRoute(ROUTE_BASEMODULE_ITEM, { itemId });
  return {
    [CALL_BB_API]: {
      types: [BASE_MODULE_ITEM_DELETE_REQUEST, BASE_MODULE_ITEM_DELETE_SUCCESS, BASE_MODULE_ITEM_DELETE_FAILURE],
      endpoint,
      method: 'DELETE',
      meta: {
        id: itemId,
      },
    },
  };
};

export const removeBaseModuleItem = ({ id }) => dispatch => dispatch(deleteBaseModuleItem(id));

export const BASE_MODULE_ITEMS_LIST_REQUEST = 'BASE_MODULE_ITEMS_LIST_REQUEST';
export const BASE_MODULE_ITEMS_LIST_SUCCESS = 'BASE_MODULE_ITEMS_LIST_SUCCESS';
export const BASE_MODULE_ITEMS_LIST_FAILURE = 'BASE_MODULE_ITEMS_LIST_FAILURE';

const fetchBaseModuleItemsList = params => ({
  [CALL_BB_API]: {
    types: [BASE_MODULE_ITEMS_LIST_REQUEST, BASE_MODULE_ITEMS_LIST_SUCCESS, BASE_MODULE_ITEMS_LIST_FAILURE],
    endpoint: ROUTE_BASEMODULE_ITEMS_SEARCH,
    method: 'GET',
    params,
  },
});

export const getBaseModuleItemsList = (params = {}) => (dispatch, getState) => dispatch(fetchBaseModuleItemsList({
  ...params,
  entity: selectCurrentModuleName(getState()),
}));

export const readBaseModuleItem = createApiAction('BASE_MODULE_ITEM_READ', itemId => ({
  endpoint: buildRoute(ROUTE_BASEMODULE_ITEM, { itemId }),
  method: 'GET',
}));

export const BASE_MODULE_ITEM_PUBLISH_REQUEST = 'BASE_MODULE_ITEM_PUBLISH_REQUEST';
export const BASE_MODULE_ITEM_PUBLISH_SUCCESS = 'BASE_MODULE_ITEM_PUBLISH_SUCCESS';
export const BASE_MODULE_ITEM_PUBLISH_FAILURE = 'BASE_MODULE_ITEM_PUBLISH_FAILURE';

const patchPublishBaseModuleItem = (routeId, itemId) => {
  const endpoint = buildRoute(ROUTE_BASEMODULE_ITEM_PUBLISH, { routeId });
  return {
    [CALL_BB_API]: {
      types: [
        BASE_MODULE_ITEM_PUBLISH_REQUEST,
        BASE_MODULE_ITEM_PUBLISH_SUCCESS,
        BASE_MODULE_ITEM_PUBLISH_FAILURE,
      ],
      endpoint,
      method: 'PATCH',
      meta: {
        id: itemId,
        route: routeId,
      },
    },
  };
};

export const BASE_MODULE_ITEM_UNPUBLISH_REQUEST = 'BASE_MODULE_ITEM_UNPUBLISH_REQUEST';
export const BASE_MODULE_ITEM_UNPUBLISH_SUCCESS = 'BASE_MODULE_ITEM_UNPUBLISH_SUCCESS';
export const BASE_MODULE_ITEM_UNPUBLISH_FAILURE = 'BASE_MODULE_ITEM_UNPUBLISH_FAILURE';

const patchUnpublishBaseModuleItem = (routeId, itemId) => {
  const endpoint = buildRoute(ROUTE_BASEMODULE_ITEM_UNPUBLISH, { routeId });
  return {
    [CALL_BB_API]: {
      types: [
        BASE_MODULE_ITEM_UNPUBLISH_REQUEST,
        BASE_MODULE_ITEM_UNPUBLISH_SUCCESS,
        BASE_MODULE_ITEM_UNPUBLISH_FAILURE,
      ],
      endpoint,
      method: 'PATCH',
      meta: {
        id: itemId,
        route: routeId,
      },
    },
  };
};

export const togglePublishBaseModuleItem = (itemId, routeId, published) => (dispatch) => {
  const action = published
    ? patchUnpublishBaseModuleItem(routeId, itemId)
    : patchPublishBaseModuleItem(routeId, itemId);

  return dispatch(action);
};

export const cloneBaseModuleItemAction = createApiAction('BASE_MOUDLE_ITEM_CLONE', ({ id, language }) => ({
  endpoint: buildRoute(ROUTE_BASEMODULE_ITEM_CLONE, { itemId: id }),
  method: 'POST',
  json: {
    language,
  },
}));

export const SET_CURRENT_MODULE_ITEM = 'SET_CURRENT_MODULE_ITEM';
export const setCurrentModuleItem = function setCurrentModuleItem(item) {
  return { type: SET_CURRENT_MODULE_ITEM, payload: item };
};

export const SET_MODULE_ITEMS_FILTER_BY_NAME = 'SET_MODULE_ITEMS_FILTER_BY_NAME';
export const setModuleItemsFilterByName = function setModuleItemsFilterByName(filterValue) {
  return { type: SET_MODULE_ITEMS_FILTER_BY_NAME, payload: filterValue };
};

export const SET_MODULE_ITEMS_FILTER_BY_CATEGORY = 'SET_MODULE_ITEMS_FILTER_BY_CATEGORY';
export const setModuleItemsFilterByCategory = function setModuleItemsFilterByCategory(filterValue) {
  return { type: SET_MODULE_ITEMS_FILTER_BY_CATEGORY, payload: filterValue };
};

export const CLEAR_FORM_MEDIA_FILED = 'CLEAR_FORM_MEDIA_FILED';
export const clearFormMediaField = () => ({ type: CLEAR_FORM_MEDIA_FILED });

export const SET_BASE_MODULE_LIST_FILTERS = 'SET_BASE_MODULE_LIST_FILTERS';
export const setBaseModuleListFilters = filters => ({ type: SET_BASE_MODULE_LIST_FILTERS, payload: { filters } });

export const BASE_MODULE_LIST_REFRESH = 'BASE_MODULE_LIST_REFRESH';
export const refreshBaseModuleList = () => ({
  type: BASE_MODULE_LIST_REFRESH,
});

export const BASE_MODULE_ITEM_FETCH_RELATED_DATA_REQUEST = 'BASE_MODULE_ITEM_FETCH_RELATED_DATA_REQUEST';
export const BASE_MODULE_ITEM_FETCH_RELATED_DATA_SUCCESS = 'BASE_MODULE_ITEM_FETCH_RELATED_DATA_SUCCESS';
export const BASE_MODULE_ITEM_FETCH_RELATED_DATA_FAILURE = 'BASE_MODULE_ITEM_FETCH_RELATED_DATA_FAILURE';

const fetchRelatedData = () => {
  const endpoint = buildRoute(ROUTE_BASEMODULE_ALL_ITEMS);
  return {
    [CALL_BB_API]: {
      types: [
        BASE_MODULE_ITEM_FETCH_RELATED_DATA_REQUEST,
        BASE_MODULE_ITEM_FETCH_RELATED_DATA_SUCCESS,
        BASE_MODULE_ITEM_FETCH_RELATED_DATA_FAILURE,
      ],
      endpoint,
      method: 'GET',
    },
  };
};

export const getBaseModuleRelatedItemsData = () => dispatch => dispatch(
  fetchRelatedData(),
);

export const moveBaseModuleItem = createApiAction('BASE_MODULE_ITEM_MOVE', ({ id, original }) => ({
  endpoint: buildRoute(ROUTE_BASEMODULE_ITEM_MOVE, { itemId: id }),
  method: 'PATCH',
  json: {
    original,
  },
}));

export const getSeoPreview = createApiAction('BASE_MODULE_ITEM_SEO_PREVIEW', ({ entityId, values }) => ({
  endpoint: buildRoute(ROUTE_BASEMODULE_ITEM_SEO_PREVIEW, { entityId }),
  method: 'POST',
  json: values,
}));

export const BASE_MODULE_ITEMS_RESET = 'BASE_MODULE_ITEMS_RESET';

export const resetBaseModuleItems = () => ({
  type: BASE_MODULE_ITEMS_RESET,
});
