import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MultiLocationMap from '../../MapBox/MultiLocationMap';
import loadMapMarkersAction from '../../../actions/mapMarkers';
import ContentLoader from '../ContentLoader';

const ContentComponentMultiLocationMap = ({
  classes,
  containerClassName,
  mapStyle,
  mapZoom,
  type,
  markersLoaderUrl,
  markers,
  loadMapMarkers,
}) => {
  useEffect(() => {
    loadMapMarkers(markersLoaderUrl);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { typeof markers[markersLoaderUrl] === 'undefined' || !markers[markersLoaderUrl]
        ? <ContentLoader />
        : (
          <MultiLocationMap
            markers={markers[markersLoaderUrl]}
            classes={classes}
            containerClassName={containerClassName}
            mapStyle={mapStyle}
            mapZoom={mapZoom}
            type={type}
          />
        )}
    </>
  );
};

const mapStateToProps = state => ({
  markers: state.content.mapMarkers,
});

const mapDispatchToProps = {
  loadMapMarkers: loadMapMarkersAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentComponentMultiLocationMap);
