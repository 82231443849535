import React from 'react';
import BbButton from '@browsbox-ui/bb-button';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { usePageContext } from '../Page/PageContext';

const LangType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
}).isRequired;

const Languages = ({ languages }) => {
  const { redirect } = usePageContext();

  const changeLanguage = (lang) => {
    if (lang.active) {
      return;
    }

    redirect(lang.url || (lang.default ? '/' : `/${lang.code}`));
  };

  return (
    <ul className="c-bb-languages">
      {languages.map((language) => {
        const classes = classNames(
          'c-bb-button--circle',
          { 'c-bb-button--inactive': !language.active },
        );

        return (
          <li key={language.code} className="c-bb-languages__item">
            <BbButton
              onClick={() => changeLanguage(language)}
              className={classes}
              text={language.code}
              noPadding
            />
          </li>
        );
      })}
    </ul>
  );
};

Languages.propTypes = {
  languages: PropTypes.arrayOf(LangType),
};

Languages.defaultProps = {
  languages: [],
};

export default Languages;
