// import { CALL_BB_API } from '../api';
// import { LINKS_SCHEMA } from '../api/schema';
// import { ROUTE_LINKS } from '../api/routes';

// export const LINKS_REQUEST = 'LINKS_REQUEST';
// export const LINKS_SUCCESS = 'LINKS_SUCCESS';
// export const LINKS_FAILURE = 'LINKS_FAILURE';
//
// // BrowsBox API middleware.
// const fetchLinks = () => ({
//   [CALL_BB_API]: {
//     types: [LINKS_REQUEST, LINKS_SUCCESS, LINKS_FAILURE],
//     endpoint: ROUTE_LINKS,
//     method: 'GET',
//     schema: LINKS_SCHEMA,
//   },
// });
//
// // Redux Thunk middleware.
// export const loadLinks = () => dispatch => dispatch(fetchLinks());

// LinkPicker modal
export const OPEN_LINK_PICKER = 'OPEN_LINK_PICKER';
export const CLOSE_LINK_PICKER = 'CLOSE_LINK_PICKER';
export const openLinkPicker = value => ({ type: OPEN_LINK_PICKER, value });
export const closeLinkPicker = value => ({ type: CLOSE_LINK_PICKER, value });
