import React from 'react';
import PropTypes from 'prop-types';
import { castArray } from 'lodash';
import { useMediaContext } from './MediaContext';

const propTypes = {
  items: PropTypes.array.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
};

const defaultProps = {
  multiple: true,
  selected: false,
};

const AbstractView = (props) => {
  const {
    multiple, selected, onChange, onDoubleClick,
  } = useMediaContext();

  const onSelect = (mediaId) => {
    let values = mediaId;

    if (multiple) {
      const prevSelection = castArray(selected).filter(i => i != null);
      values = [...prevSelection, mediaId];
    }

    if (onChange) {
      onChange(values);
    }
  };

  const onUnselect = (mediaId) => {
    let values = castArray(selected).filter(i => i != null);

    if (multiple) {
      values = values.filter(id => id !== mediaId);
    }

    if (onChange) {
      onChange(values);
    }
  };

  const isSelected = (imageId) => {
    if (Array.isArray(selected)) {
      return selected.includes(imageId);
    }

    if (Number.isInteger(Number(selected))) {
      return selected === imageId;
    }

    return false;
  };

  const {
    component: ViewComponent,
    ...rest
  } = props;

  const extraProps = {
    onSelect,
    onUnselect,
    onDoubleClick,
    isSelected,
  };

  return (
    <ViewComponent {...rest} {...extraProps} />
  );
};

AbstractView.propTypes = propTypes;
AbstractView.defaultProps = defaultProps;

export default AbstractView;
