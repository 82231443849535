import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { mediaLibraryClearFolderId, mediaLibrarySetFolderId } from '../../../actions/media';

const BreadcrumbItem = ({
  label, title, isActive, onClick,
}) => (
  <li className="c-bb-folder-breadcrumbs__item">
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        'c-bb-folder-breadcrumbs__item__button',
        { 'c-bb-folder-breadcrumbs__item__button--is-active': isActive },
      )}
      title={title || label}
    >{label}
    </button>
  </li>
);

BreadcrumbItem.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

BreadcrumbItem.defaultProps = {
  onClick: () => {},
  title: '',
};

const FolderBreadcrumbs = ({
  breadcrumbs, setFolder, resetFolder, t,
}) => {
  let finalItems = breadcrumbs;
  const maxCount = 4;
  const needsSlice = breadcrumbs.length > maxCount;

  if (needsSlice) {
    finalItems = breadcrumbs.slice(Math.max(breadcrumbs.length - maxCount, 1));
  }

  const items = [
    { label: t('MEDIA.folderBreadcrumbsStart'), id: 'start', onClick: resetFolder },
    ...finalItems.map((item, index) => ({
      id: item.id,
      label: index === 0 && needsSlice ? '...' : item.name,
      title: item.name,
      onClick: () => setFolder(item.id),
    })),
  ];

  const isActive = item => items.findIndex(i => i.id === item.id) === (items.length - 1);

  return (
    <ul className="c-bb-folder-breadcrumbs">
      {items.map(breadcrumb => (
        <BreadcrumbItem
          key={breadcrumb.id}
          label={breadcrumb.label}
          title={breadcrumb.title}
          onClick={breadcrumb.onClick}
          isActive={isActive(breadcrumb)}
        />
      ))}
    </ul>
  );
};

FolderBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  setFolder: PropTypes.func.isRequired,
  resetFolder: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  folderId: state.media.folderId,
  breadcrumbs: state.media.breadcrumbs,
});

const mapDispatchToProps = {
  setFolder: mediaLibrarySetFolderId,
  resetFolder: mediaLibraryClearFolderId,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(FolderBreadcrumbs));
