/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import Icon from '../../Icon/Icon';

export const Table = ({ children, ...rest }) => (
  <table className="c-table" {...rest}>
    {children}
  </table>
);

export const TableHead = React.forwardRef(({ children, ...rest }, ref) => (
  <thead ref={ref} className="c-table__head" {...rest}>
    {children}
  </thead>
));

export const TableBody = React.forwardRef(({ children, ...rest }, ref) => (
  <tbody ref={ref} className="c-table__body" {...rest}>
    {children}
  </tbody>
));

export const TableFooter = ({ children, ...rest }) => (
  <tfoot className="c-table__footer" {...rest}>
    {children}
  </tfoot>
);

export const TableRow = ({ children, ...rest }) => (
  <tr className="c-table__row" {...rest}>
    {children}
  </tr>
);

export const TableCell = ({
  children, className, actions, center, ...rest
}) => (
  <td
    className={classNames(
      'c-table__cell',
      { 'c-table__cell--actions': actions },
      { 'c-table__cell--center': center },
      className,
    )}
    {...rest}
  >
    {children}
  </td>
);

export const SortableLabel = ({
  className, children, isActive, isAsc, ...rest
}) => (
  <span
    {...rest}
    className={classNames(
      'c-sortable-label',
      { 'c-sortable-label--active': isActive },
      { 'c-sortable-label--asc': isAsc },
      { 'c-sortable-label--desc': !isAsc },
      className,
    )}
  >
    {children}
    {isActive && (
      <Icon name={isAsc ? 'arrow-down' : 'arrow-up'} />
    )}
  </span>
);
