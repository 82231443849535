/* eslint-disable no-await-in-loop */

class WindowScrollManager {
  /**
   * @type {boolean}
   */
  isScrolling = false;

  /**
   * @type {boolean}
   */
  shouldScrollTop = false;

  /**
   * @type {boolean}
   */
  shouldScrollBottom = false;

  defaults = {
    duration: 100,
    offset: 100,
    scrollStep: 300,
  };

  options = {};

  moveEvents = [
    'mousemove',
    'touchmove',
  ];

  constructor(options) {
    Object.assign(this.options, this.defaults, options);

    this.handleMove = this.handleMove.bind(this);
  }

  init() {
    this.shouldScrollTop = true;
    this.shouldScrollBottom = true;
    this.moveEvents.forEach(event => document.addEventListener(event, this.handleMove));
  }

  destroy() {
    this.shouldScrollTop = false;
    this.shouldScrollBottom = false;
    this.moveEvents.forEach(event => document.removeEventListener(event, this.handleMove));
  }

  scrollWindowTo = offset => new Promise((resolve) => {
    setTimeout(resolve, this.options.duration);
    window.scrollTo({
      top: offset,
      duration: this.options.duration,
      behavior: 'smooth',
    });
  });

  async handleMove(event) {
    if (event.clientY < this.options.offset && !this.isScrolling) {
      this.shouldScrollTop = true;
      while (window.scrollY > 0 && this.shouldScrollTop) {
        this.isScrolling = true;
        await this.scrollWindowTo(window.scrollY - this.options.scrollStep);
        this.isScrolling = false;
      }
    }

    if (event.clientY > this.options.offset) {
      this.shouldScrollTop = false;
    }

    if (event.clientY > (window.innerHeight - this.options.offset) && !this.isScrolling) {
      this.shouldScrollBottom = true;
      while (window.scrollY <= document.body.clientHeight && this.shouldScrollBottom) {
        this.isScrolling = true;
        await this.scrollWindowTo(window.scrollY + this.options.scrollStep);
        this.isScrolling = false;
      }
    }

    if (event.clientY < (window.innerHeight - this.options.offset)) {
      this.shouldScrollBottom = false;
    }
  }
}

export default WindowScrollManager;
