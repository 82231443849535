import { CALL_BB_API, buildRoute } from '../api';
import { PAGE_PROPS_SCHEMA } from '../api/schema';
import {
  ROUTE_PAGE_RESTRICT,
  ROUTE_PAGE_UNRESTRICT,
} from '../api/routes';

import {
  PAGE_PROPS_SUCCESS,
  PAGE_PROPS_FAILURE,
} from './pageProperties';

export const PAGE_RESTRICT = 'PAGE_RESTRICT';
export const PAGE_UNRESTRICT = 'PAGE_UNRESTRICT';
// BrowsBox API middleware.
const patchRestrictPage = (id, route, type) => {
  const endpoint = buildRoute(route, { id });
  return {
    [CALL_BB_API]: {
      types: [type, PAGE_PROPS_SUCCESS, PAGE_PROPS_FAILURE],
      endpoint,
      method: 'PATCH',
      schema: PAGE_PROPS_SCHEMA,
    },
  };
};

// Redux Thunk middleware.
export const restrictPage = id => dispatch => dispatch(
  patchRestrictPage(id, ROUTE_PAGE_RESTRICT, PAGE_RESTRICT),
);
export const unrestrictPage = id => dispatch => dispatch(
  patchRestrictPage(id, ROUTE_PAGE_UNRESTRICT, PAGE_UNRESTRICT),
);
