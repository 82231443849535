/* eslint-disable camelcase */
import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { htmlEncode } from 'htmlencode';
import BbButton from '@browsbox-ui/bb-button';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import i18n from '../../internationalization/i18n';
import Modal from './Modal';
import MapBoxLoader from '../MapBox/MapBoxLoader';
import Icon from '../Icon/Icon';

export const MAP_STYLES = {
  default: { value: '', title: 'standaard' },
  grayscale: { value: 'grayscale', title: 'zwart wit' },
};

const propTypes = {
  address: PropTypes.string, // Google maps query value
  componentId: PropTypes.number.isRequired, // Component id
  icon: PropTypes.string,
  mapStyle: PropTypes.string, // Map style
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  title: PropTypes.string,
};

const defaultProps = {
  address: '',
  icon: 'fal fa-search-location',
  mapStyle: MAP_STYLES.default.value,
  markerValue: '',
  submitButtonText: null,
  title: null,
};

// eslint-disable-next-line no-unused-vars
const MapSearch = ({ address, onChange }) => {
  useEffect(() => {
    const geocoder = new window.MapboxGeocoder({
      accessToken: window.mapboxgl.accessToken,
      placeholder: address,
      language: window.userLanguage,
    });

    geocoder.on('result', ({ result }) => {
      const { geometry: { coordinates }, place_name } = result;
      onChange({
        address: place_name,
        latitude: coordinates[1],
        longitude: coordinates[0],
      });
    });

    geocoder.addTo('#geocoder');
  }, []);

  const mapQueryId = 'mapstyle';

  return (
    <div>
      <div>
        <label htmlFor={mapQueryId} className="o-bb-label">
          {i18n.t('CONTENT.address')}&nbsp;*
        </label>
        <div id="geocoder" />
      </div>
    </div>
  );
};

MapSearch.propTypes = {
  address: PropTypes.string.isRequired,
  bounds: PropTypes.any, // eslint-disable-line
  onChange: PropTypes.func.isRequired,
};

class GoogleMapsEditorModal extends Component { // eslint-disable-line react/no-multi-comp
  constructor(props) {
    super(props);
    this.onOk = this.onOk.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onStyleChange = this.onStyleChange.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.state = {
      isValid: false,
      address: props.address, // from Google maps query result
      mapStyle: props.mapStyle, // from Google maps query result
      latitude: false, // from Google maps query result
      longitude: false, // from Google maps query result
    };
  }

  onOk() {
    const {
      componentId,
    } = this.props;
    const {
      address,
      mapStyle,
      latitude,
      longitude,
    } = this.state;
    const result = { mapStyle };
    // Only return latitude, longitude if new valid google maps query was made
    if (latitude !== false) {
      result.latitude = `${latitude}`;
      result.longitude = `${longitude}`;
      result.address = address;
      result.markerValue = address;
      result.markerTitle = htmlEncode(address.split(',').join('<br />'));
    }
    this.props.onOk(componentId, result);
  }

  onCancel(evt) {
    evt.preventDefault();
    this.props.onCancel();
  }

  onClose(evt) {
    if (evt) {
      evt.preventDefault();
    }
    this.props.onClose();
  }

  onStyleChange(evt) {
    const { value: mapStyle } = evt.target;
    const isValid = this.props.address !== '';
    this.setState({ mapStyle, isValid });
  }

  onAddressChange(value) {
    this.setState({ ...value, isValid: true });
  }

  render() {
    const {
      address,
      icon,
    } = this.props;
    const {
      isValid,
      mapStyle,
    } = this.state;

    const title = this.props.title || i18n.t('CONTENT.googleMaps');
    const submitButtonText = this.props.submitButtonText || i18n.t('CONTENT.update');
    const mapStyleId = 'mapstyle';
    const styleOption = (style) => {
      const mapStyleOption = MAP_STYLES[style];
      return (
        <option key={style} value={mapStyleOption.value}>{mapStyleOption.title}</option>
      );
    };

    return (
      <Modal
        onClose={this.onClose}
        modalClassName="o-bb-modal--small"
      >
        <div>
          <ModalHeader icon={icon} title={title} />
          <div>
            <div className="o-bb-form-field">
              <MapSearch
                address={address}
                onChange={this.onAddressChange}
              />
              { isValid && <Icon name="check" /> }
            </div>
            <div className="o-bb-form-field">
              <label htmlFor={mapStyleId} className="o-bb-label">
                {i18n.t('CONTENT.theme')}
              </label>
              <span className="o-bb-form__select-wrapper">
                <select className="o-bb-form__select" value={mapStyle} onChange={this.onStyleChange} id={mapStyleId}>
                  { Object.keys(MAP_STYLES).map(styleOption) }
                </select>
              </span>
            </div>
          </div>
          <Modal.Context>
            {({ closeModal }) => (
              <ModalFooter>
                <BbButton className="c-bb-button--primary" disabled={!isValid} onClick={this.onOk} text={submitButtonText} />
                <BbButton className="c-bb-button--secondary" onClick={closeModal} text={i18n.t('CONTENT.cancel')} />
              </ModalFooter>
            )}
          </Modal.Context>
        </div>
      </Modal>
    );
  }
}

GoogleMapsEditorModal.propTypes = propTypes;
GoogleMapsEditorModal.defaultProps = defaultProps;

export default props => (
  <MapBoxLoader>
    <GoogleMapsEditorModal {...props} />
  </MapBoxLoader>
);
