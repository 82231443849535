/* eslint-disable react/prop-types,jsx-a11y/label-has-associated-control */
import React from 'react';
import classNames from 'classnames';
import { withNamespaces } from 'react-i18next';
import i18n from '../../internationalization/i18n';
import Switch from './Switch';

const ToggleableField = (props) => {
  const {
    label,
    checked: defaultValue = false,
    component: Component,
    onSwitchChange,
    ...rest
  } = props;
  const [disabled, setDisabled] = React.useState(defaultValue);

  React.useEffect(() => {
    if (onSwitchChange) {
      onSwitchChange(disabled);
    }
  }, [disabled]);

  return (
    <div className="c-bb-toggleable">
      <div className="c-bb-toggleable__header">
        <label className="c-bb-toggleable__label">{label}</label>
        <div className="c-bb-toggleable__switch" onClick={() => setDisabled(prev => !prev)}>
          <span className={classNames(
            'c-bb-toggleable__state',
            { 'c-bb-toggleable__state--active': disabled },
          )}
          >{i18n.t('CONTENT.automated')}
          </span>
          <Switch checked={!disabled} />
          <span className={classNames(
            'c-bb-toggleable__state',
            { 'c-bb-toggleable__state--active': !disabled },
          )}
          >{i18n.t('CONTENT.manual')}
          </span>
        </div>
      </div>
      <div>
        <Component {...rest} disabled={disabled} />
      </div>
    </div>
  );
};

export default withNamespaces()(ToggleableField);
