import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import i18n from 'i18next';
import {
  fetchUsers, toggleEnable, readUser, deleteUser, setSortBy, setPage,
} from '../../actions/users';
import selectors from '../../selectors/users';
import { UserType } from './types';
import CircleLoader from '../Loader/CircleLoader';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  BbIconButton,
  SortableLabel,
  BBPagination,
} from '../@browsbox-ui';
import Icon from '../Icon/Icon';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { useSyncTablesColumnsWidth } from '../../tools/tables';

const UsersList = ({
  users,
  fetch,
  isLoading,
  onEdit,
  remove,
  toggle,
  read,
  search,
  setSort,
  sortBy,
  sortDirection,
  total,
  page,
  perPage,
  changePage,
}) => {
  const headRef = React.useRef();
  const bodyRef = React.useRef();
  const [removing, setRemoving] = React.useState(null);
  useSyncTablesColumnsWidth(bodyRef, headRef);

  const debouncedFetch = React.useCallback(debounce((params) => {
    fetch(params);
  }, 300), [fetch]);

  const fetchUsersList = React.useCallback(() => {
    debouncedFetch({
      page,
      search,
      sort: sortBy,
      direction: sortDirection,
    });
  }, [debouncedFetch, search, sortBy, sortDirection, page]);

  React.useEffect(() => {
    fetchUsersList();
  }, [fetchUsersList]);

  const handleEnableChange = async (user) => {
    await toggle({ id: user.id, isEnabled: !user.is_enabled });
    await read(user.id);
  };

  const handleUserRemove = React.useCallback(async () => {
    await remove({ id: removing });
    setRemoving(null);
    fetchUsersList();
  }, [remove, removing, setRemoving, fetch]);

  return (
    <div className="c-users-container">
      {isLoading && users.length === 0 && (
        <CircleLoader />
      )}
      {users.length > 0 && (
        <>
          <Table>
            <TableHead ref={headRef}>
              <TableRow>
                <TableCell>
                  <SortableLabel
                    isActive={sortBy === 'email'}
                    isAsc={sortDirection === 'asc'}
                    onClick={() => setSort('email')}
                  >
                    {i18n.t('USERS.email')}
                  </SortableLabel>
                </TableCell>
                <TableCell center>
                  <SortableLabel
                    isActive={sortBy === 'isEnabled'}
                    isAsc={sortDirection === 'asc'}
                    onClick={() => setSort('isEnabled')}
                  >
                    {i18n.t('USERS.active')}
                  </SortableLabel>
                </TableCell>
                <TableCell actions>
                  {isLoading ? <CircleLoader width={24} height={24} /> : null}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <div className="c-users-list">
            <Table>
              <TableBody ref={bodyRef}>
                {users.map(user => (
                  <TableRow key={user.id}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell center>
                      <input
                        type="checkbox"
                        onChange={() => handleEnableChange(user)}
                        checked={user.is_enabled}
                      />
                    </TableCell>
                    <TableCell actions>
                      <BbIconButton medium onClick={() => onEdit(user.id)}>
                        <Icon name="edit" />
                      </BbIconButton>
                      <BbIconButton medium onClick={() => setRemoving(user.id)}>
                        <Icon name="trash" />
                      </BbIconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          {total > perPage && (
            <div className="c-users-pagination">
              <BBPagination
                page={page}
                total={total}
                perPage={perPage}
                onChange={changePage}
              />
            </div>
          )}
        </>
      )}
      {removing && (
        <ConfirmationModal
          title="Confirm"
          onClose={() => setRemoving(null)}
          onCancel={() => setRemoving(null)}
          onOk={handleUserRemove}
          submitButtonText={i18n.t('CONTENT.remove')}
        >
          {i18n.t('CONTENT.deleteConfirmation')}
        </ConfirmationModal>
      )}
    </div>
  );
};

UsersList.propTypes = {
  users: PropTypes.arrayOf(UserType).isRequired,
  fetch: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  read: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  users: selectors.selectUsers(state),
  search: state.users.searchQuery,
  total: state.users.total,
  page: state.users.page,
  perPage: state.users.perPage,
  isLoading: selectors.selectUsersIsLoading(state),
  sortBy: state.users.sortBy,
  sortDirection: state.users.sortDirection,
});

const mapDispatchToProps = {
  fetch: fetchUsers,
  toggle: toggleEnable,
  read: readUser,
  remove: deleteUser,
  setSort: setSortBy,
  changePage: setPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
