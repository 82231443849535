import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import SelectTypeOptions from './SelectTypeOptions';
import { renderError } from '../../tools/validation';

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
};

const defaultProps = {
  label: null,
  disabled: false,
};

const Arrow = ({ label }) => (
  <div style={{ color: '#9F9F9F', fontSize: '12px' }}>{label}</div>
);

const SelectField = ({
  input: { value, onChange }, meta, label, disabled, options,
}) => {
  const ArrowRenderer = React.useCallback(() => (
    <Arrow label={label} />
  ), [label]);

  return (
    <div className="c-bb-form__select">
      <Select
        value={value}
        onChange={e => e && onChange(e.value)}
        optionComponent={SelectTypeOptions}
        disabled={disabled}
        clearable={false}
        placeholder=""
        arrowRenderer={ArrowRenderer}
        options={options}
      />
      {meta.touched && meta.error
        && <span className="c-bb-form__input-error">{renderError(meta.error)}</span>}
    </div>
  );
};

SelectField.propTypes = propTypes;
SelectField.defaultProps = defaultProps;

export default withNamespaces()(SelectField);
