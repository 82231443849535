import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { renderError } from '../../tools/validation';

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

const defaultProps = {
  label: null,
  className: '',
  placeholder: '',
  disabled: false,
  type: 'text',
};

const TextField = ({
  input, label, meta: { touched, error }, className, disabled, type, placeholder,
}) => (
  <div
    className={classNames([
      'c-bb-form__input',
      className,
      { 'c-bb-form__input--has-error': touched && error },
    ])}
  >
    {label && <label htmlFor="title">{label}</label>}
    <input {...input} type={type} disabled={disabled} placeholder={placeholder} />
    {touched && error
    && <span className="c-bb-form__input-error">{renderError(error)}</span>}
  </div>
);

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;

export default TextField;
