/* eslint-disable react/self-closing-comp */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isDescendant from '../utils/isDescendant';
import Icon from '../../../Icon/Icon';
import { usePageContext } from '../../../Page/PageContext';
import FontAwesomeIcon from '../../../Icon/FontAwesomeIcon';

const themeClass = 'bb-react-sortable-tree';

const NodeLink = ({ title, route }) => {
  const { redirect } = usePageContext();

  return (
    <a
      className="bb-tree-item__title"
      onClick={() => redirect(route)}
      rel="noopener noreferrer"
    >
      {title}
    </a>
  );
};

NodeLink.propTypes = {
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};

class NodeContentRenderer extends Component {
  constructor(props) {
    super(props);
    this.toggleConfigMenu = this.toggleConfigMenu.bind(this);
    this.hideOnlyConfigMenu = this.hideOnlyConfigMenu.bind(this);
    this.hideConfigMenu = this.hideConfigMenu.bind(this);
    this.renderConfigMenuToggle = this.renderConfigMenuToggle.bind(this);
    this.renderChildrenToggle = this.renderChildrenToggle.bind(this);
    this.renderItemLabel = this.renderItemLabel.bind(this);

    this.state = {
      configMenuActive: false,
    };
  }

  componentDidUpdate() {
    const { isDragging } = this.props;

    if (isDragging && this.state.configMenuActive) {
      this.hideOnlyConfigMenu();
    }
  }

  toggleConfigMenu() {
    this.setState(state => ({
      configMenuActive: !state.configMenuActive,
    }));
  }

  hideOnlyConfigMenu() {
    this.setState({
      configMenuActive: false,
    });
  }

  hideConfigMenu() {
    this.setState({
      configMenuActive: false,
    });
  }

  renderConfigMenuToggle() {
    const className = `${themeClass}__config-menu-toggle`;

    return (
      <button
        type="button"
        id={this.props.nodeConfigId(this.props.node)}
        className={className}
        onClick={this.toggleConfigMenu}
      >
        <Icon name="context-menu" />
      </button>
    );
  }

  renderChildrenToggle() {
    const {
      node,
      toggleChildrenVisibility,
      path,
      treeIndex,
    } = this.props;
    const className = `${themeClass}__button`;

    return toggleChildrenVisibility
      && node.children
      && (node.children.length > 0 || typeof node.children === 'function')
      && (
        <div
          aria-label={node.expanded ? 'Collapse' : 'Expand'}
          className={classNames(
            className,
            { [`${className}--collapse`]: node.expanded },
            { [`${className}--expand`]: !node.expanded },
          )}
          onClick={() => toggleChildrenVisibility({
            node,
            path,
            treeIndex,
          })}
        />
      );
  }

  renderStatusIcons() {
    const { published, restricted } = this.props.node;
    if (!published || restricted) {
      return (
        <div className="bb-admin__menu__status-icon">
          { restricted && <FontAwesomeIcon name="fas fa-key" className="bb-tree-item__icon bb-tree-item__icon--restricted" /> }
          { !published && (
            <FontAwesomeIcon name="fas fa-eye-slash" className="bb-tree-item__icon bb-tree-item__icon--restricted" />
          ) }
        </div>
      );
    }

    return null;
  }

  renderItemLabel() {
    const {
      node, title, active, isDragging,
    } = this.props;
    const nodeTitle = title || node.title;
    const isActive = active(node);

    return (
      <div
        className={classNames(
          'bb-tree-item__label',
          { 'bb-tree-item__label--active': isActive },
        )}
      >
        {isDragging ? (
          <span className="bb-tree-item__title">{nodeTitle}</span>
        ) : (
          <NodeLink title={nodeTitle} route={node.route} />
        )}
        { this.renderStatusIcons() }
      </div>
    );
  }

  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      node,
      title,
      subtitle,
      active,
      draggedNode,
      path,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      icons,
      buttons,
      className,
      style,
      didDrop,
      swapFrom,
      swapLength,
      swapDepth,
      treeId,
      isOver,
      parentNode,
      rowDirection,
      nodeConfigId,
      renderConfigMenu,
      ...otherProps
    } = this.props;

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;
    const nodeTitle = title || node.title;

    const draggableWrapper = (
      <div className="bb-tree-item__draggable-wrapper">
        <Icon name="page" className="bb-tree-item__icon bb-tree-item__icon--page" />
        <Icon name="drag" className="bb-tree-item__icon bb-tree-item__icon--drag" />
        {this.renderItemLabel()}
      </div>
    );

    const nodeContent = connectDragPreview(
      <div
        className={classNames(
          'bb-tree-item__contents',
          { 'bb-tree-item__contents--search-match': isSearchMatch },
          { 'bb-tree-item__contents--search-focus': isSearchFocus },
          { 'bb-tree-item__contents--drag-disabled': !canDrag || !node.movable },
        )}
        title={nodeTitle}
      >
        <div className="bb-tree-item__button-wrapper">
          {this.renderChildrenToggle()}
        </div>
        {node.movable ? connectDragSource(draggableWrapper, { dropEffect: 'copy' }) : draggableWrapper}
        <div className={`${themeClass}__actions`}>
          {this.renderConfigMenuToggle()}
        </div>
        <div className="itemConfigMenu">
          {this.state.configMenuActive && renderConfigMenu({
            node,
            hideConfigMenu: this.hideConfigMenu,
          })}
        </div>
      </div>,
    );

    return (
      <div style={{ height: '100%' }} {...otherProps}>
        <div
          className={classNames(
            `${themeClass}__item-wrapper`,
            { [`${themeClass}__item-wrapper--drag-disabled`]: !canDrag },
            { isOver },
          )}
        >
          <div
            className={classNames(
              `${themeClass}__item`,
              { [`${themeClass}__item--cancel-pad`]: isLandingPadActive && !canDrop },
              { [`${themeClass}__item--landing-pad`]: isLandingPadActive },
              { '--has-parent-node': Boolean(parentNode) },
              { '--is-dragged-descendant': isDraggedDescendant },
              className,
            )}
            style={{
              opacity: isDraggedDescendant ? 0.5 : 1,
              ...style,
            }}
          >
            {nodeContent}
          </div>
        </div>
      </div>
    );
  }
}

NodeContentRenderer.defaultProps = {
  buttons: [],
  canDrag: false,
  canDrop: false,
  className: '',
  draggedNode: null,
  icons: [],
  isSearchFocus: false,
  isSearchMatch: false,
  parentNode: null,
  style: {},
  subtitle: null,
  swapDepth: null,
  swapFrom: null,
  swapLength: null,
  title: null,
  toggleChildrenVisibility: null,
  rowDirection: 'ltr',
};

NodeContentRenderer.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.node),
  canDrag: PropTypes.bool,
  className: PropTypes.string,
  icons: PropTypes.arrayOf(PropTypes.node),
  isSearchFocus: PropTypes.bool,
  isSearchMatch: PropTypes.bool,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    published: PropTypes.bool.isRequired,
    restricted: PropTypes.bool.isRequired,
    movable: PropTypes.bool.isRequired,
    route: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    expanded: PropTypes.bool,
  }).isRequired,
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  style: PropTypes.shape({}),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  swapDepth: PropTypes.number,
  swapFrom: PropTypes.number,
  swapLength: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  toggleChildrenVisibility: PropTypes.func,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,

  renderConfigMenu: PropTypes.func.isRequired,
  nodeConfigId: PropTypes.func.isRequired,
  active: PropTypes.func.isRequired,

  // Drag and drop API functions
  // Drag sourcerender
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  isDragging: PropTypes.bool.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager

  // Drop target
  canDrop: PropTypes.bool,
  isOver: PropTypes.bool.isRequired,
  rowDirection: PropTypes.string,
};

export default NodeContentRenderer;
