import regex from 'es6-template-regex';

const buildRoute = (template, data) => template.replace(regex(), (m, prop) => {
  if (typeof data[prop] !== 'undefined') {
    return data[prop];
  }
  return prop;
});

// Update url query string parameter
export const updateQueryStringParameter = (uri, key, value) => {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  const queryValue = typeof value === 'undefined' ? '' : `=${value}`;
  if (uri.match(re)) {
    return uri.replace(re, `$1${key}${queryValue}$2`);
  }
  return `${uri}${separator}${key}${queryValue}`;
};

export default buildRoute;
