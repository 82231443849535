import React from 'react';
import classNames from 'classnames';

export const FormInput = (inputProps) => {
  const {
    id,
    label,
    type,
    info,
    maxLength,
    valueLength,
    icon,
    iconClickHandler,
    labelInfo,
    value,
    showRequired = true,
    ...attributes
  } = inputProps;
  attributes.type = type || 'text';
  attributes.className = classNames(
    attributes.className,
    `o-bb-form__input--${attributes.type}`,
    { 'o-bb-form__input--has-max-length': !!maxLength },
  );
  return (
    <div className="o-bb-form__item">
      {label
        && (
        <label htmlFor={id} className="o-bb-label">
          {label}&nbsp;
          {inputProps.required && showRequired && '*' }
        </label>
        )}
      {labelInfo && <span className="o-bb-form__label-info" style={{ color: labelInfo.color }}>{labelInfo.message}</span>}
      <div className="o-bb-form__input--wrapper">
        <input
          id={id}
          maxLength={maxLength}
          value={value || ''}
          {...attributes}
        />
        {!!maxLength && (
        <span className="o-bb-form__input--info">
          {valueLength}/{maxLength}
          {!!icon
            && (
            <i
              className={icon}
              onClick={iconClickHandler}
              style={{
                cursor: 'pointer', color: '#000', fontSize: '16px', position: 'relative', top: '2px', right: '-4px',
              }}
            />
            )}
        </span>
        )}
      </div>
      {info
        && <p className="o-bb-form__info">{info}</p>}
    </div>
  );
};

export const FormCheckbox = (inputProps) => {
  const {
    id,
    label,
    ...attributes
  } = inputProps;
  attributes.className = `o-bb-form__input--${attributes.type}`;
  return (
    <div className={classNames(
      'o-bb-form__item',
      { 'o-bb-form__item--disabled': attributes.disabled },
    )}
    >
      <input
        id={id}
        className="o-bb-form-checkbox o-bb-form__input--checkbox"
        {...attributes}
      />
      <label htmlFor={id} className="o-bb-label o-bb-form__label--checkbox">
        {label}
        {inputProps.required && '*' }
      </label>
    </div>
  );
};

export const FormTextArea = (inputProps) => {
  const {
    id,
    label,
    maxLength,
    valueLength,
    ...attributes
  } = inputProps;
  attributes.className = `o-bb-form__input--${attributes.type}`;
  return (
    <div className="o-bb-form__item">
      <label htmlFor={id} className="o-bb-label">
        {label}
        {inputProps.required && '*' }
      </label>
      <div className="o-bb-form__input--wrapper">
        <textarea
          id={id}
          className="o-bb-form-textarea"
          {...attributes}
        />
        {!!maxLength && <span className="o-bb-form__input--info">{valueLength}/{maxLength}</span>}
      </div>
    </div>
  );
};
