export const DELETE_CONTENT = 'DELETE_CONTENT';
export const OPEN_DELETE_CONTENT = 'OPEN_DELETE_CONTENT';
export const CLOSE_DELETE_CONTENT = 'CLOSE_DELETE_CONTENT';

export const deleteContent = value => ({ type: DELETE_CONTENT, value });
export const openDeleteContent = value => ({ type: OPEN_DELETE_CONTENT, value });
export const closeDeleteContent = () => ({ type: CLOSE_DELETE_CONTENT });

export const DELETE_COMPONENT = 'DELETE_COMPONENT';
export const deleteComponent = value => ({ type: DELETE_COMPONENT, value });
