import { CALL_BB_API } from './contants';

const createApiAction = (prefix, configCreator) => {
  const REQUEST = `${prefix}_REQUEST`;
  const SUCCESS = `${prefix}_SUCCESS`;
  const FAILURE = `${prefix}_FAILURE`;
  let config = {};

  const actionCreator = (data) => {
    if (typeof configCreator === 'function') {
      config = configCreator(data) || {};
    }

    return {
      [CALL_BB_API]: {
        types: [REQUEST, SUCCESS, FAILURE],
        ...config,
      },
    };
  };

  const action = data => dispatch => dispatch(actionCreator(data));
  action.REQUEST = REQUEST;
  action.SUCCESS = SUCCESS;
  action.FAILURE = FAILURE;

  return action;
};

export default createApiAction;
