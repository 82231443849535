import { ROUTE_BROWSBOX_MENU } from '../api/routes';
import { CALL_BB_API } from '../api';

export const SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM';
export const setActiveMenuItem = id => ({ type: SET_ACTIVE_MENU_ITEM, id });

export const CONFIGURATION_MENU_FETCH_REQUEST = 'CONFIGURATION_MENU_FETCH_REQUEST';
export const CONFIGURATION_MENU_FETCH_SUCCESS = 'CONFIGURATION_MENU_FETCH_SUCCESS';
export const CONFIGURATION_MENU_FETCH_FAILURE = 'CONFIGURATION_MENU_FETCH_FAILURE';

const fetchConfigurationMenuItems = () => ({
  [CALL_BB_API]: {
    types: [CONFIGURATION_MENU_FETCH_REQUEST, CONFIGURATION_MENU_FETCH_SUCCESS, CONFIGURATION_MENU_FETCH_FAILURE],
    endpoint: ROUTE_BROWSBOX_MENU,
    method: 'GET',
  },
});

export const loadConfigurationMenuItems = () => dispatch => dispatch(fetchConfigurationMenuItems());
