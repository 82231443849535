import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CONTENT_COMPONENT_TYPE } from '@liswood-tache/browsbox-static';
import { deleteComponent } from '../../../actions/contentDelete';
import { openGoogleMapsEditor } from '../../../actions/googleMaps';
import Icon from '../../Icon/Icon';
import ContentItemContext from '../ContentItemContext';
import SuperAdminOnly from '../../AcessControll/SuperAdminOnly';

const ContentComponentToolbar = ({
  componentType,
  componentId,
  columnId,
  removeComponent,
  openGoogleMaps,
}) => {
  const { content } = useContext(ContentItemContext);

  const handleRemoveComponent = () => {
    removeComponent({
      id: componentId,
      columnId,
    });
  };

  const handleOpenGoogleMaps = () => {
    openGoogleMaps({
      id: content.id,
      componentId,
    });
  };

  return (
    <div className="c-component-tools__toolbar">
      {componentType === CONTENT_COMPONENT_TYPE.googleMaps && (
        <button
          type="button"
          className="c-component-tools__button"
          onClick={handleOpenGoogleMaps}
        >
          <Icon name="google-maps" />
        </button>
      )}
      <SuperAdminOnly>
        <button
          type="button"
          className="c-component-tools__button"
          onClick={handleRemoveComponent}
        >
          <Icon name="delete" />
        </button>
      </SuperAdminOnly>
    </div>
  );
};

ContentComponentToolbar.propTypes = {
  removeComponent: PropTypes.func.isRequired,
  openGoogleMaps: PropTypes.func.isRequired,
  componentType: PropTypes.string.isRequired,
  componentId: PropTypes.number.isRequired,
  columnId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  removeComponent: deleteComponent,
  openGoogleMaps: openGoogleMapsEditor,
};

export default connect(null, mapDispatchToProps)(ContentComponentToolbar);
