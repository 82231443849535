/* eslint-disable react/no-array-index-key,react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Field, Fields } from 'redux-form';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import TextField from './TextField';
import MultiLangFields from './MultiLangFields';
import validation, { renderError } from '../../tools/validation';
import i18n from '../../internationalization/i18n';
import Icon from '../Icon/Icon';
import IconButton from '../@browsbox-ui/bb-icon-button';
import FieldTypeSelect from './FieldTypeSelect';

const ContentFieldSortableHandle = SortableHandle(() => (
  <div className="c-bb-form__group__sortable-handle">
    <Icon name="base-module-drag" />
  </div>
));

const ContentField = ({
  fields, input, fieldIndex, lang, langs, fieldTypes,
}) => (
  <div className="c-bb-form__group c-bb-form__group__base-module-fields">
    <ContentFieldSortableHandle />
    <Field
      name={`${input}.internalName`}
      type="text"
      component={TextField}
      validate={validation.required}
      label={i18n.t('CONTENT.internalName')}
    />
    <Field
      name={`${input}.type`}
      component={FieldTypeSelect}
      label={i18n.t('CONTENT.type')}
      options={fieldTypes}
      validate={validation.required}
      fieldName={input}
    />
    <Fields
      names={langs.map(lng => `${input}.label.${lng.code}`)}
      lang={lang}
      langs={langs}
      index={String(fieldIndex)}
      label={i18n.t('CONTENT.label')}
      inputName="fields"
      fieldName="label"
      component={MultiLangFields}
    />
    <IconButton medium className="c-bb-form__remove-field" onClick={() => fields.remove(fieldIndex)}>
      <Icon name="trash" />
    </IconButton>
  </div>
);

ContentField.propTypes = {
  fieldIndex: PropTypes.number.isRequired,
  input: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  langs: PropTypes.array.isRequired,
  fieldTypes: PropTypes.array.isRequired,
};

const SortableContentField = SortableElement(props => <ContentField {...props} />);
SortableContentField.propTypes = ContentField.propTypes;

const SortableContentFieldsList = SortableContainer(({ fields, ...props }) => (
  <div>
    {fields.map((input, index) => (
      <SortableContentField
        {...props}
        fields={fields}
        key={`sortable-field-${input}`}
        index={index}
        fieldIndex={index}
        input={input}
      />
    ))}
  </div>
));

const RenderContentFields = ({
  fields, meta: { error }, lang, langs, defaultLang, fieldTypes,
}) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    fields.move(oldIndex, newIndex);
  };

  return (
    <div className="c-bb-form__block">
      <div className="c-bb-form__add-field" onClick={() => fields.push()}>
        <Icon name="add" small /> {i18n.t('BASE_MODULE.addField').toUpperCase()}
      </div>
      <div>
        <SortableContentFieldsList
          useDragHandle
          helperClass="c-bb-form__group__sortable-helper"
          lockAxis="y"
          fields={fields}
          lang={lang}
          langs={langs}
          defaultLang={defaultLang}
          fieldTypes={fieldTypes}
          onSortEnd={onSortEnd}
        />
      </div>
      {error && <div className="error">{renderError(error)}</div>}
    </div>
  );
};

RenderContentFields.propTypes = {
  meta: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  langs: PropTypes.array.isRequired,
  defaultLang: PropTypes.object.isRequired,
  fieldTypes: PropTypes.array.isRequired,
};

export default withNamespaces()(RenderContentFields);
