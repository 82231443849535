import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { openMediaManager, openMediaPicker } from '../../actions/mediaManager';
import MediaField from './MediaField';
import { clearCategory } from '../../actions/baseModule';

const propTypes = {
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
};

const defaultProps = {
  type: 'image',
  activeCategory: null,
};

function RenderMediaField({ type, ...reduxFormProps }) {
  return (
    <div className="c-bb-form__input">
      <MediaField
        {...reduxFormProps}
        className="c-bb-form__upload-field_single"
        type={type}
      />
    </div>
  );
}

RenderMediaField.propTypes = propTypes;
RenderMediaField.defaultProps = defaultProps;

const mapStateToProps = state => ({
  activeCategory: state.baseModule.currentCategory,
});

const mapDispatchToProps = {
  openMediaManager,
  openMediaPicker,
  clearCategory,
  change,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(RenderMediaField));
