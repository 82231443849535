import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';

const AddUserButton = ({ t, onClick }) => (
  <button type="button" className="c-users-view__add-button" onClick={onClick}>
    <FontAwesomeIcon name="fas fa-circle-plus" />
    {t('USERS.userAdd')}
  </button>
);

AddUserButton.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withNamespaces()(connect(null)(AddUserButton));
