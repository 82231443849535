import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BrowsboxPageEditForm from './PageEditForm';
import { pagePropsPropType, pagePropsDefaultProps } from './PagePropTypes';
import ModalHeader from '../Modals/ModalHeader';
import Spinner from '../Spinner';
import i18n from '../../internationalization/i18n';
import Modal from '../Modals/Modal';
import Icon from '../Icon/Icon';

export const EDIT_PAGE_VIEW = 'EDIT_PAGE_VIEW';
export const DUPLICATE_PAGE_VIEW = 'DUPLICATE_PAGE_VIEW';
export const NEW_PAGE_VIEW = 'NEW_PAGE_VIEW';

const propTypes = {
  messages: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  pageProps: pagePropsPropType,
  title: PropTypes.string.isRequired,
  view: PropTypes.oneOf([EDIT_PAGE_VIEW, DUPLICATE_PAGE_VIEW, NEW_PAGE_VIEW]).isRequired,
  pageTypes: PropTypes.object,
};

const defaultProps = {
  messages: [],
  pageTypes: {},
  pageProps: pagePropsDefaultProps,
};

class BrowsboxPageEditModal extends Component {
  static renderLoading() {
    return <div className="o-loading"><Spinner /></div>;
  }

  static renderSaving() {
    return <div className="o-loading"><Spinner />{i18n.t('CONTENT.saveingPage')}</div>;
  }

  static getIcon(view) {
    switch (view) {
      case EDIT_PAGE_VIEW:
        return 'fas fa-pencil';
      case DUPLICATE_PAGE_VIEW:
        return 'far fa-save';
      case NEW_PAGE_VIEW:
        return 'far fa-file';
      default:
        return '';
    }
  }

  constructor(props) {
    super(props);
    this.onUpdate = this.onUpdate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSelectType = this.onSelectType.bind(this);
    this.state = {
      isLoading: true,
      isSaving: false,
      selectType: false,
    };
  }

  componentDidMount() {
    const { pageProps } = this.props;
    //
    // Show input form for NEW_PAGE_VIEW
    //
    if (
      this.props.view === NEW_PAGE_VIEW
      && this.state.isLoading
    ) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        selectType: false,
        isSaving: false,
        isLoading: false,
      });
    //
    // Show input form for DUPLICATE_PAGE_VIEW
    //
    } else if (
      this.props.view === DUPLICATE_PAGE_VIEW
      && this.state.isLoading
      && typeof pageProps.duplicateId !== 'undefined'
    ) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        isSaving: false,
        isLoading: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.view === EDIT_PAGE_VIEW
      && this.state.isLoading
      && this.props.pageProps
      && typeof this.props.pageProps.id === 'number'
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isSaving: false, isLoading: false });
    } else if (
      this.state.isSaving
      && this.props.messages
      && this.props.messages.length !== 0
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isSaving: false, isLoading: false });
    }
  }

  onUpdate(pageProps) {
    this.setState({ isSaving: true });
    this.props.onUpdate(pageProps);
  }

  onSelectType(evt, pageType) {
    // PageTypes without a url should show the input form,
    // PageTypes with url will navigate to the provided url
    if (!pageType.url) {
      evt.preventDefault();
      // Show input form for NEW_PAGE_VIEW
      this.setState({ isSaving: false, isLoading: false, selectType: false });
    }
  }

  onClose(evt) {
    evt.preventDefault();
    this.props.onClose();
  }

  renderForm() {
    const {
      pageProps,
      onClose,
      messages,
      view,
    } = this.props;

    const attributes = {
      value: pageProps,
      onSubmit: this.onUpdate,
      onCancel: onClose,
      messages,
      submitButtonText: view === EDIT_PAGE_VIEW ? i18n.t('CONTENT.save') : i18n.t('CONTENT.add'),
    };

    return (
      <BrowsboxPageEditForm {...attributes} />
    );
  }

  renderSelectType() {
    const { pageTypes } = this.props;
    const typeItem = (pageTypeKey) => {
      const pageType = pageTypes[pageTypeKey];
      const url = pageType.url || '#';
      const key = `type-${pageTypeKey}`;
      return (
        <li key={key}>
          <a href={url} onClick={(evt) => { this.onSelectType(evt, pageType); }}>
            <Icon name={pageType.icon} medium />
            <span>{pageType.title}</span>
          </a>
        </li>
      );
    };

    return (
      <ul className="types">
        { Object.keys(pageTypes).map(typeItem)}
      </ul>
    );
  }

  render() {
    const {
      onClose,
      view,
    } = this.props;

    const {
      isLoading,
      isSaving,
      selectType,
    } = this.state;

    let { title } = this.props;
    const titleIcon = BrowsboxPageEditModal.getIcon(view);
    let content;

    if (isLoading) {
      content = BrowsboxPageEditModal.renderLoading();
    } else if (isSaving) {
      content = BrowsboxPageEditModal.renderSaving();
    } else if (selectType) {
      title = i18n.t('CONTENT.selectPageType');
      content = this.renderSelectType();
    } else {
      content = this.renderForm();
    }

    return (
      <Modal
        onClose={onClose}
        modalClassName="c-modal-page-edit"
      >
        <ModalHeader icon={titleIcon} title={title} />
        { content }
      </Modal>
    );
  }
}

BrowsboxPageEditModal.propTypes = propTypes;
BrowsboxPageEditModal.defaultProps = defaultProps;

const mapStateToProps = state => ({
  pageTypes: state.entities.pageTypes,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrowsboxPageEditModal);
