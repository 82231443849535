import { all, fork } from 'redux-saga/effects';
import contentAdd from './contentAdd';
import codeEditor from './codeEditor';
import contentClone from './contentClone';
import dynamicContent from './dynamicContent';

export default all([
  fork(codeEditor),
  fork(contentClone),
  fork(contentAdd),
  fork(dynamicContent),
]);
