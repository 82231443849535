export function selectCurrentModule(state) {
  return state.baseModule.currentModule;
}

export function selectCurrentModuleName(state) {
  const entity = selectCurrentModule(state);

  if (entity) {
    return entity.internal_name;
  }

  return null;
}
