import React, { Component, Children, cloneElement } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const themeClass = 'bb-react-sortable-tree';

class TreeNodeRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.bound = {
      handleMouseOver: this.handleMouseOver.bind(this),
      handleMouseLeave: this.handleMouseLeave.bind(this),
    };
  }

  handleMouseOver() {
    if (!this.state.highlight) {
      this.setState({ highlight: true });
    }
  }

  handleMouseLeave() {
    this.setState({ highlight: false });
  }

  render() {
    const {
      children,
      swapFrom,
      swapLength,
      swapDepth,
      scaffoldBlockPxWidth,
      lowerSiblingCounts,
      connectDropTarget,
      isOver,
      draggedNode,
      canDrop,
      treeIndex,
      treeId,
      listIndex,
      rowDirection,
      getPrevItem,
      getPrevRow,
      node,
      path,
      ...otherProps
    } = this.props;
    const scaffoldBlockCount = lowerSiblingCounts.length - 1;
    let dropType;
    if (canDrop && !isOver) {
      dropType = 'valid-drop';
    } else if (!canDrop && isOver) {
      dropType = 'invalid-drop';
    }

    return connectDropTarget(
      <div
        {...otherProps}
        onMouseOver={this.bound.handleMouseOver}
        onFocus={this.bound.handleMouseOver}
        onMouseLeave={this.bound.handleMouseLeave}
        className={classNames(
          `${themeClass}__node`,
          { [`${themeClass}__node--highlight`]: this.state.highlight },
          { [`${themeClass}__node--${dropType}`]: dropType },
        )}
      >
        <div
          className={`${themeClass}__node-content`}
          style={{
            paddingLeft: scaffoldBlockPxWidth * scaffoldBlockCount,
          }}
        >
          {Children.map(children, child => cloneElement(child, {
            isOver,
            canDrop,
            draggedNode,
          }))}
        </div>
      </div>,
    );
  }
}
TreeNodeRenderer.defaultProps = {
  swapFrom: null,
  swapDepth: null,
  swapLength: null,
  canDrop: false,
  draggedNode: null,
  rowDirection: 'ltr',
  getPrevItem: () => null,
  getPrevRow: () => null,
};

TreeNodeRenderer.propTypes = {
  treeIndex: PropTypes.number.isRequired,
  swapFrom: PropTypes.number,
  swapDepth: PropTypes.number,
  swapLength: PropTypes.number,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  lowerSiblingCounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  treeId: PropTypes.string.isRequired,
  listIndex: PropTypes.number.isRequired,
  rowDirection: PropTypes.string,
  children: PropTypes.node.isRequired,

  // Drop target
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,
  draggedNode: PropTypes.shape({}),

  getPrevItem: PropTypes.func,
  getPrevRow: PropTypes.func,
  node: PropTypes.shape({}).isRequired,
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
};

export default TreeNodeRenderer;
