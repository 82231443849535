import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showCreateFolderModal, moveMediaFolder, moveMedia } from '../../../actions/media';

const AbstractView = (props) => {
  const {
    component: Component,
    showCreateModal,
    moveFolder,
    moveMedia: mediaMove,
    ...rest
  } = props;

  const showNewFolderModal = () => {
    showCreateModal();
  };

  const handleDrop = (source, dist) => {
    switch (source.dndType) {
      case 'MEDIA_FOLDER': {
        moveFolder({ id: source.id, parent: dist.id });
        break;
      }
      case 'MEDIA_ITEM': {
        mediaMove({ id: source.id, parent: dist.id });
        break;
      }
      default:
        break;
    }
  };

  const extraProps = {
    showNewFolderModal,
    onDrop: handleDrop,
  };

  return (
    <Component {...rest} {...extraProps} />
  );
};

AbstractView.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  moveFolder: PropTypes.func.isRequired,
  moveMedia: PropTypes.func.isRequired,
  showCreateModal: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
};

const mapDispatchToProps = {
  showCreateModal: showCreateFolderModal,
  moveFolder: moveMediaFolder,
  moveMedia,
};

export default connect(null, mapDispatchToProps)(AbstractView);
