import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { SubmissionError } from 'redux-form';
import UsersHeader from './UsersHeader';
import UsersContent from './UsersContent';
import UserForm from './UserForm';
import { createUser } from '../../actions/users';
import { isValidationError, transformValidationErrors } from '../../tools/validation';

const AddUserView = ({ onClose, create, t }) => {
  const onSubmit = async ({
    email, isEnabled, password, role,
  }) => {
    await create({
      email, isEnabled, password, roles: [role],
    }, (error) => {
      if (isValidationError(error)) {
        throw new SubmissionError(transformValidationErrors(error));
      }
    });
    onClose();
  };

  return (
    <>
      <UsersHeader title={t('USERS.creatingUser')} onClose={onClose} />
      <UsersContent>
        <UserForm
          onCancel={onClose}
          onSubmit={onSubmit}
          initialValues={{
            isEnabled: true,
            role: 'ROLE_USER',
          }}
        />
      </UsersContent>
    </>
  );
};

AddUserView.propTypes = {
  onClose: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  create: createUser,
};

export default withNamespaces()(connect(null, mapDispatchToProps)(AddUserView));
