import { CALL_BB_API } from '../api';
import { PAGE_ARRAY_SCHEMA } from '../api/schema';
import { ROUTE_PAGES, ROUTE_PAGE_REORDER, ROUTE_CURRENT_PAGE } from '../api/routes';

export const PAGES_REQUEST = 'PAGES_REQUEST';
export const PAGES_SUCCESS = 'PAGES_SUCCESS';
export const PAGES_FAILURE = 'PAGES_FAILURE';

// BrowsBox API middleware.
const fetchPages = () => ({
  [CALL_BB_API]: {
    types: [PAGES_REQUEST, PAGES_SUCCESS, PAGES_FAILURE],
    endpoint: ROUTE_PAGES,
    method: 'GET',
    schema: PAGE_ARRAY_SCHEMA,
  },
});

// Redux Thunk middleware.
export const loadPages = () => dispatch => dispatch(fetchPages());

export const EXPANDED_PAGE = 'EXPANDED_PAGE';
export const EXPANDED_PAGES = 'EXPANDED_PAGES';
export const COLLAPSE_PAGE = 'COLLAPSE_PAGE';
export const expandPage = id => ({ type: EXPANDED_PAGE, id });
export const expandPages = value => ({ type: EXPANDED_PAGES, value });
export const collapsePage = id => ({ type: COLLAPSE_PAGE, id });

export const PAGE_SETTINGS = 'PAGE_SETTINGS';
export const pageSettings = value => ({ type: PAGE_SETTINGS, value });

export const PAGE_REORDER = 'PAGE_ORDER';
export const pageReorder = value => ({ type: PAGE_REORDER, value });

export const PAGES_REORDER_REQUEST = 'PAGES_REORDER_REQUEST';
export const PAGES_REORDER_SUCCESS = 'PAGES_REORDER_SUCCESS';
export const PAGES_REORDER_FAILURE = 'PAGES_REORDER_FAILURE';

// BrowsBox API middleware.
const callPagesReorder = (value) => {
  const { pages: routeIds } = value;
  const json = {
    routeIds,
  };
  return {
    [CALL_BB_API]: {
      types: [PAGES_REORDER_REQUEST, PAGES_REORDER_SUCCESS, PAGES_REORDER_FAILURE],
      endpoint: ROUTE_PAGE_REORDER,
      method: 'POST',
      json,
    },
  };
};

// Redux Thunk middleware.
export const pagesReorder = value => dispatch => dispatch(callPagesReorder(value));

export const GET_CURRENT_PAGE_REQUEST = 'GET_CURRENT_PAGE_REQUEST';
export const GET_CURRENT_PAGE_SUCCESS = 'GET_CURRENT_PAGE_SUCCESS';
export const GET_CURRENT_PAGE_FAILURE = 'GET_CURRENT_PAGE_FAILURE';

// BrowsBox API middleware.
const fetchCurrentPage = () => ({
  [CALL_BB_API]: {
    types: [GET_CURRENT_PAGE_REQUEST, GET_CURRENT_PAGE_SUCCESS, GET_CURRENT_PAGE_FAILURE],
    endpoint: ROUTE_CURRENT_PAGE,
    method: 'GET',
  },
});

// Redux Thunk middleware.
export const getCurrentPage = () => dispatch => dispatch(fetchCurrentPage());
