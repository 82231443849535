import { CALL_BB_API, buildRoute } from '../api';
import { ROUTE_PAGE_ITEM } from '../api/routes';

export const PAGE_DELETE_REQUEST = 'PAGE_DELETE_REQUEST';
export const PAGE_DELETE_SUCCESS = 'PAGE_DELETE_SUCCESS';
export const PAGE_DELETE_FAILURE = 'PAGE_DELETE_FAILURE';

// BrowsBox API middleware.
const deletePageCall = (value) => {
  const { id } = value;
  const endpoint = buildRoute(ROUTE_PAGE_ITEM, { id });
  return {
    [CALL_BB_API]: {
      types: [PAGE_DELETE_REQUEST, PAGE_DELETE_SUCCESS, PAGE_DELETE_FAILURE],
      endpoint,
      method: 'DELETE',
    },
  };
};

// Redux Thunk middleware.
export const deletePage = value => dispatch => dispatch(deletePageCall(value));
