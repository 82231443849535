import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { contentPropType, IMAGE_PLACEHOLDER_URL } from '@liswood-tache/browsbox-static';
import BrowsboxSortableList from '../ContentSortableList';
import { setComponentOption } from '../../../actions/contentConfig';
import { addComponent } from '../../../actions/contentAdd';
import { DEFAULT_VIEW } from '../../../actions/responsive';
import { clearAllSelection } from '../../../tools/browsers';
import Icon from '../../Icon/Icon';
import { setContentViewOption } from '../../../actions/options';

/* Slick carousel */
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');

const propTypes = {
  button: PropTypes.func.isRequired, // The function to render a 'section' button
  content: contentPropType.isRequired, // Section data
  setComponentOption: PropTypes.func.isRequired, // Redux action creator
  title: PropTypes.func.isRequired, // The function to render a 'section' title
  subTitle: PropTypes.func.isRequired, // The function to render a 'section' title
  addComponent: PropTypes.func.isRequired, // Redux action creator
  setContentViewOption: PropTypes.func.isRequired, // Redux action creator
  maxImageCount: PropTypes.number, // The maximum amount of images to add
  showEditView: PropTypes.bool, // Render as editable component (Redux store property)
  renderTarget: PropTypes.string.isRequired, // Render default or preview
};

const defaultProps = {
  maxImageCount: 12,
  showEditView: false,
};

class ContentSectionSlider extends Component {
  constructor(props) {
    super(props);
    this.renderColumn = this.renderColumn.bind(this);
    this.addImage = this.addImage.bind(this);
    this.onComponentContentChange = this.onComponentContentChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.renderTarget !== this.props.renderTarget && this.props.renderTarget !== DEFAULT_VIEW) {
      this.props.setContentViewOption({
        id: this.props.content.id,
        option: 'showEditView',
        value: false,
      });
    }
  }

  onComponentContentChange(componentProps, newContent) {
    this.props.setComponentOption({
      option: 'content',
      value: newContent,
      contentId: componentProps.id,
    });
  }

  addImage(evt) {
    if (evt) {
      evt.preventDefault();
    }
    const {
      content: { id: contentId, type: contentType },
    } = this.props;
    this.props.addComponent({ contentId, contentType });
  }

  renderColumn(column, idx) {
    const {
      content: { id: contentId, type: contentType },
      renderTarget,
    } = this.props;
    return (
      <BrowsboxSortableList
        components={column.components}
        onChange={this.onComponentContentChange}
        key={idx}
        id={contentId}
        contentType={contentType}
        renderTarget={renderTarget}
      >
        { renderTarget === DEFAULT_VIEW && this.renderAddImage() }
      </BrowsboxSortableList>
    );
  }

  renderAddImage() {
    const {
      content: { columns },
      maxImageCount,
    } = this.props;
    const currentImageCount = columns[0].components.length;
    const itemStyles = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '157px',
      margin: '0 8px',
    };
    const linkStyles = {
      display: 'block',
      padding: '2px',
      width: '36px',
      height: '36px',
      backgroundColor: 'white',
      borderRadius: '20px',
    };
    // Allow to add up to n images
    if (currentImageCount < maxImageCount) {
      return (
        <div style={itemStyles}>
          <a onClick={this.addImage} className="o-action-link" style={linkStyles}>
            <Icon name="add" />
          </a>
        </div>
      );
    }
    return null;
  }

  renderEditView() {
    const {
      content: { columns },
    } = this.props;
    return columns.map(this.renderColumn);
  }

  renderDefaultView() {
    if (!this.props.content.columns.length) {
      return null;
    }

    const {
      content: { columns: [{ components }] },
    } = this.props;

    return (
      components.map(image => (
        <div key={image.id} onClick={clearAllSelection}>
          <img className="c-image__element" src={image.url || IMAGE_PLACEHOLDER_URL} alt={image.alt} title={image.tooltip} />
        </div>
      ))
    );
  }

  render() {
    const {
      button,
      content: { id, columns },
      subTitle,
      title,
      showEditView,
    } = this.props;
    const containerStyles = {
      overflow: 'hidden',
    };
    const mainClasses = classNames(
      'c-section__main',
      { 'l-columns': (columns.length > 1) },
    );

    return (
      <div className="c-section__wrapper" key={id} style={containerStyles}>
        {!showEditView && (
          <header className="c-section__header">
            { title() }
            { subTitle() }
          </header>
        )}
        <main className={mainClasses}>
          { !showEditView && this.renderDefaultView() }
          { showEditView && this.renderEditView() }
        </main>
        {!showEditView && (
          <footer className="c-section__footer">
            { button() }
          </footer>
        )}
      </div>
    );
  }
}

ContentSectionSlider.propTypes = propTypes;
ContentSectionSlider.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  // Get 'showEditView' from the redux store
  const { options: { content } } = state;
  const { content: { id } } = ownProps;
  const viewOptions = content[id] || {};
  const { showEditView } = viewOptions;
  return {
    showEditView,
  };
};

const mapDispatchToProps = {
  setComponentOption,
  addComponent,
  setContentViewOption,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentSectionSlider);
