import { OPEN_SESSION_EXPIRED_MODAL } from '../actions/session';
import {
  WEBSITE_SETTINGS_FETCH_REQUEST,
  WEBSITE_SETTINGS_FETCH_SUCCESS,
  WEBSITE_SETTINGS_FETCH_FAILURE,
} from '../actions/website';
import { BROWSBOX_LANGUAGES_FETCH_SUCCESS } from '../actions/languages';
import { ADD_TAG, TAGS_FETCH_SUCCESS } from '../actions/tags';

const initialState = {
  isSessionExpiredModalOpen: false,
  enabledModules: [],
  isSettingsPending: false,
  websiteSettings: {
    domain: null,
    maxMenuLevels: 2,
    defaultLanguage: null,
  },
  browsboxLanguages: [],
  tags: [],
};

const global = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SESSION_EXPIRED_MODAL: {
      return {
        ...state,
        isSessionExpiredModalOpen: true,
      };
    }
    case WEBSITE_SETTINGS_FETCH_REQUEST: {
      return {
        ...state,
        isSettingsPending: true,
      };
    }
    case WEBSITE_SETTINGS_FETCH_FAILURE: {
      return {
        ...state,
        isSettingsPending: false,
      };
    }
    case WEBSITE_SETTINGS_FETCH_SUCCESS: {
      return {
        ...state,
        isSettingsPending: false,
        websiteSettings: {
          ...state.websiteSettings,
          ...action.response,
        },
      };
    }
    case BROWSBOX_LANGUAGES_FETCH_SUCCESS: {
      return {
        ...state,
        browsboxLanguages: action.response,
      };
    }
    case TAGS_FETCH_SUCCESS: {
      return {
        ...state,
        tags: action.response,
      };
    }
    case ADD_TAG: {
      return {
        ...state,
        tags: [...state.tags, action.value],
      };
    }
    default:
      return state;
  }
};

export default global;
