export const SET_MEDIA_PICKER_TYPE = 'SET_MEDIA_PICKER_TYPE';
export const setMediaPickerType = value => ({ type: SET_MEDIA_PICKER_TYPE, value });

export const OPEN_MEDIA_PICKER = 'OPEN_MEDIA_PICKER';
export const CLOSE_MEDIA_PICKER = 'CLOSE_MEDIA_PICKER';
export const openMediaPicker = value => ({ type: OPEN_MEDIA_PICKER, value });
export const closeMediaPicker = () => ({ type: CLOSE_MEDIA_PICKER });

export const OPEN_MEDIA_MANAGER = 'OPEN_MEDIA_MANAGER';
export const CLOSE_MEDIA_MANAGER = 'CLOSE_MEDIA_MANAGER';
export const openMediaManager = value => ({ type: OPEN_MEDIA_MANAGER, value });
export const closeMediaManager = value => ({ type: CLOSE_MEDIA_MANAGER, value });
