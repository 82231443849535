import * as constants from './constants';

export const prepareBaseModuleItemFieldsToSubmit = fields => (
  Object.keys(fields).map(key => ({
    internalName: key,
    value: fields[key],
  }))
);

export const convertBaseModuleItemFieldsToForm = fields => (
  fields.reduce((result, field) => (
    { ...result, [field.field.internal_name]: field.value }
  ), {})
);

export const getDefaultValueByFieldType = (type) => {
  let defaultValue = null;
  switch (type) {
    case constants.fieldTypes.boolean:
      defaultValue = false;
      break;
    default:
      break;
  }
  return defaultValue;
};

export const convertBaseModuleItemFieldsToCreateForm = fields => (
  fields.reduce((result, field) => (
    { ...result, [field.internal_name]: getDefaultValueByFieldType(field.type) }
  ), {})
);

export const convertBaseModuleItemCategoriesToForm = categories => (
  categories.map(c => c.id)
);
