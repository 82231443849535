import React from 'react';

export default function useDisableScroll(disable) {
  React.useEffect(() => {
    if (document && disable) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (document) {
        document.body.style.overflow = '';
      }
    };
  }, [disable]);
}
