import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withNamespaces } from 'react-i18next';
import {
  closeBaseModuleView,
  changeBaseModuleLang,
  saveBaseModule,
} from '../../actions/baseModule';
import BaseModuleEditForm from './BaseModuleEditForm';
import { getDefaultLang } from '../../selectors';

class BaseModuleCreate extends Component {
  static propTypes = {
    defaultLang: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    langs: PropTypes.array.isRequired,
    saveBaseModule: PropTypes.func.isRequired,
    closeBaseModuleView: PropTypes.func.isRequired,
    fieldTypes: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
  };

  onCancel = () => {
    this.props.closeBaseModuleView();
  };

  redirectToItems = (name) => {
    // eslint-disable-next-line react/prop-types
    this.props.history.push(`/items/${name}`);
  };

  handleSubmit = async (value) => {
    const treverse = function treverse(categories, lang) {
      return categories.map((category) => {
        let children = [];
        if (category.children && category.children.categories && category.children.categories.length) {
          children = treverse(category.children.categories, lang);
        }

        return {
          title: category.title[lang],
          image: get(category, 'image.id', null),
          image_meta: {
            alt: get(category, 'image.alt', null),
            tooltip: get(category, 'image.tooltip', null),
            hyperlink: get(category, 'image.hyperlink', null),
          },
          children,
        };
      });
    };

    const getLangEntity = function getLangEntity(formData, lang) {
      return {
        label: formData[`label_${lang}`],
        internalName: formData.internalName,
        hasDetailPages: Boolean(formData.hasDetailPages),
        sortingEnabled: Boolean(formData.sortingEnabled),
        language: lang,
        fields: formData.fields && formData.fields.map(field => (
          {
            label: field.label[lang],
            internalName: field.internalName,
            type: field.type,
            options: field.options,
            settings: field.settings,
          }
        )),
        categories: formData.categories && treverse(formData.categories, lang),
        columns: formData.columns,
        templateRouteTitle: formData.templateRouteTitle,
        templatePageTitle: formData.templatePageTitle,
        templateMetaDescription: formData.templateMetaDescription,
      };
    };

    const entities = this.props.langs.map(lng => getLangEntity(value, lng.code));
    const promises = entities.map(entity => this.props.saveBaseModule(entity));

    await Promise.all(promises);

    this.redirectToItems(entities[0].internalName);
  };

  render() {
    return (
      <div className="c-bb-base-module">
        <div className="c-bb-base-module-header" />
        <div className="c-bb-base-module-body">
          <div className="c-bb-form c-bb-form--has-header">
            <BaseModuleEditForm
              onCancel={this.onCancel}
              onSubmit={this.handleSubmit}
              lang={this.props.lang}
              langs={this.props.langs}
              defaultLang={this.props.defaultLang}
              fieldTypes={this.props.fieldTypes}
              initialValues={{
                hasDetailPages: true,
                sortingEnabled: true,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isOpen: state.baseModule.isOpen,
  lang: state.baseModule.lang,
  defaultLang: getDefaultLang(state),
  langs: state.baseModule.langs,
  fieldTypes: state.baseModule.fieldTypes,
});

const mapDispatchToProps = {
  closeBaseModuleView,
  changeBaseModuleLang,
  saveBaseModule,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(BaseModuleCreate));
