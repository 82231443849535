import React, { useState } from 'react';
import classNames from 'classnames';
import { useParams, useHistory } from 'react-router-dom';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { connect, shallowEqual } from 'react-redux';
import ConfirmationModal from '../../../Modals/ConfirmationModal';
import i18n from '../../../../internationalization/i18n';

const BaseModuleItemLanguageTabs = ({ item, t, isDirty }) => {
  const { name, id } = useParams();
  const history = useHistory();
  const [callback, setCallback] = useState(null);

  const tabs = React.useMemo(() => {
    if (!item) {
      return [];
    }

    const items = [
      { id: item.id, language: item.language },
    ];

    (Array.from(item.translations || [])).forEach((translation) => {
      items.push({
        id: translation.id,
        language: translation.language,
      });
    });

    return items;
  }, [item]);

  const navigateToLanguage = (langId) => {
    history.push(`/item/edit/${name}/${langId}`);
  };

  const handleLangLinkClick = (langId) => {
    if (isDirty) {
      setCallback(() => () => navigateToLanguage(langId));
    } else {
      navigateToLanguage(langId);
    }
  };

  return (
    <div className="c-bb-base-module__language-tabs">
      <ul className="c-bb-base-module__language-tabs__tabs">
        {tabs.map(tab => (
          <li
            key={tab.id}
            className={classNames(
              'c-bb-base-module__language-tabs__tabs__item',
              { 'c-bb-base-module__language-tabs__tabs__item--active': +tab.id === +id },
            )}
          >
            <a onClick={+tab.id === +id ? undefined : () => handleLangLinkClick(tab.id)}>
              <span>{t('BASE_MODULE.languageVersion', { lang: tab.language.toUpperCase() })}</span>
            </a>
          </li>
        ))}
      </ul>
      {callback && (
        <ConfirmationModal
          icon="icon-basic_elaboration_document_refresh"
          onClose={() => setCallback(null)}
          onCancel={() => setCallback(null)}
          onOk={callback}
          title={i18n.t('CONTENT.refreshPageModalTitle')}
          submitButtonText={i18n.t('CONTENT.continue')}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: i18n.t('CONTENT.refreshPageModalQuestion') }} />
        </ConfirmationModal>
      )}
    </div>
  );
};

BaseModuleItemLanguageTabs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  t: PropTypes.func.isRequired,
};

BaseModuleItemLanguageTabs.defaultProps = {
  item: null,
};

const mapStateToProps = (state) => {
  const initial = omit(state?.form?.baseModuleItemForm?.initial || {}, ['autoSeoValues']);
  const values = omit(state?.form?.baseModuleItemForm?.values || {}, ['autoSeoValues']);

  return {
    isDirty: !shallowEqual(initial, values),
  };
};
export default withNamespaces()(
  connect(mapStateToProps)(BaseModuleItemLanguageTabs),
);
