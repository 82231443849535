/* eslint-disable react/prop-types */
import React from 'react';
import './tags.scss';

const BBTags = ({ tags }) => (
  <ul className="o-bb-tags">
    {tags.map(tag => (
      <li key={tag.id} className="o-bb-tags__item">{tag.name}</li>
    ))}
  </ul>
);

export default BBTags;
