/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactCropper from 'react-cropper';
import Icon from '../../../Icon/Icon';
import RangeSlider from '../../../FormElements/RangeSlider';
import i18n from '../../../../internationalization/i18n';
import Toolbar from './Toolbar';
import ToolbarItem from './ToolbarItem';
import CircleLoader from '../../../Loader/CircleLoader';
import { findMediaItem } from '../../../../selectors/media';
import MessageModal from '../../../Modals/MessageModal';

const MIN_ZOOM = 0.01;
const MAX_ZOOM = 3;
const ZOOM_STEP = 0.005;
const MAX_IMAGE_DIMENSIONS_PX = 10000;

class Cropper extends React.Component {
  static propTypes = {
    //  eslint-disable-next-line react/no-unused-prop-types
    originalMediaId: PropTypes.number,
    originalMediaItem: PropTypes.shape({
      original: PropTypes.string.isRequired,
    }),
    onCrop: PropTypes.func.isRequired,
    onCropChange: PropTypes.func,
    onCropCancel: PropTypes.func.isRequired,
    cropperData: PropTypes.shape({
      canvasData: PropTypes.object,
      scale: PropTypes.number,
    }),
    isCropPending: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    src: null,
    onCropChange: () => {},
    originalMediaId: null,
    originalMediaItem: null,
    cropperData: null,
  };

  constructor(props) {
    super(props);
    this.cropImage = this.cropImage.bind(this);
    this.fillImage = this.fillImage.bind(this);
    this.fitImage = this.fitImage.bind(this);
    this.calculateZoomStep = this.calculateZoomStep.bind(this);
    this.cancelImageCropping = this.cancelImageCropping.bind(this);
    this.resolveOriginalUrl = this.resolveOriginalUrl.bind(this);
    this.handleZoomChange = this.handleZoomChange.bind(this);
    this.onCropperReady = this.onCropperReady.bind(this);
    this.onDocumentKeydown = this.onDocumentKeydown.bind(this);
    this.onDocumentMouseDown = this.onDocumentMouseDown.bind(this);

    this.state = {
      minZoom: MIN_ZOOM,
      zoom: MIN_ZOOM,
      maxZoom: MAX_ZOOM,
      width: null,
      height: null,
      isCropperReady: false,
      originalMediaError: false,
    };

    const { cropperData } = props;
    if (cropperData && cropperData.scale) {
      this.state.zoom = Number(cropperData.scale);
    }
  }

  assertHasOriginalUrl = async () => {
    const url = this.resolveOriginalUrl();

    if (!url) {
      this.setState({
        originalMediaError: true,
      });
    }

    if (url) {
      fetch(url)
        .then(response => {
          if (response.status !== 200) {
            this.setState({
              originalMediaError: true,
            });
          }
          return response;
        });
    }
  };

  componentDidMount() {
    this.getInitialDimensions();
    document.addEventListener('keydown', this.onDocumentKeydown);
    document.addEventListener('mousedown', this.onDocumentMouseDown);
    document.body.classList.add('--bb-cropper-active');

    this.assertHasOriginalUrl();
  }

  componentDidUpdate(prevProps) {
    this.getInitialDimensions();

    if (prevProps.src !== this.props.src) {
      this.assertHasOriginalUrl();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onDocumentKeydown);
    document.removeEventListener('mousedown', this.onDocumentKeydown);
    document.body.classList.remove('--bb-cropper-active');
  }

  onDocumentMouseDown(event) {
    if (!this.node) {
      return;
    }

    if (this.node.contains(event.target)) {
      return;
    }

    this.cancelImageCropping();
  }

  onDocumentKeydown(event) {
    event.preventDefault();

    switch (event.key) {
      case '+':
        this.increaseZoom();
        break;
      case '-':
        this.decreaseZoom();
        break;
      case 'Enter':
        this.cropImage();
        break;
      case 'Escape':
        this.cancelImageCropping();
        break;
      case 'ArrowLeft':
        this.moveLeft();
        break;
      case 'ArrowRight':
        this.moveRight();
        break;
      case 'ArrowUp':
        this.moveUp();
        break;
      case 'ArrowDown':
        this.moveDown();
        break;
      default:
        break;
    }
  }

  onCropperReady() {
    if (this.props.cropperData) {
      const { canvasData, scale } = this.props.cropperData;
      if (scale) {
        this.cropperRef.zoomTo(scale);
      }

      if (canvasData) {
        this.cropperRef.setCanvasData(canvasData);
      }
    }

    const imageData = this.cropperRef.getImageData();
    const zoom = imageData.width / imageData.naturalWidth;

    this.setState(({ width, height }) => {
      const minZoomX = width / MAX_IMAGE_DIMENSIONS_PX;
      const minZoomY = height / MAX_IMAGE_DIMENSIONS_PX;
      const minZoom = Math.max(minZoomX, minZoomY);

      return {
        minZoom,
        zoom,
        isCropperReady: true,
      };
    }, () => {
      this.handleCropChange();
    });
  }

  getInitialDimensions() {
    const width = this.wrapperRef.clientWidth;
    const height = this.wrapperRef.clientHeight;

    if (width !== this.state.width || height !== this.state.height) {
      this.setState({
        width,
        height,
      });
    }
  }

  cropImage() {
    if (!this.state.isCropperReady) {
      return;
    }

    const { width, height } = this.state;

    const data = {
      originalMedia: this.props.originalMediaItem,
      cropperData: this.collectCropperData(),
      options: {
        width,
        height,
        hasTransparentArea: this.hasTransparentArea(),
      },
    };

    this.props.onCrop(data);
  }

  calculateRatio = () => {
    const { naturalWidth, naturalHeight } = this.cropperRef.img;
    const { width, height } = this.wrapperRef.getBoundingClientRect();

    return [
      width / naturalWidth,
      height / naturalHeight,
    ];
  };

  fillImage() {
    const ratios = this.calculateRatio();
    const zoom = Math.max(...ratios);

    this.setState({
      zoom,
    }, () => {
      const { top, left } = this.calculateCenterCords();
      this.cropperRef.moveTo(left, top);
      this.handleCropChange();
    });
  }

  fitImage() {
    const ratios = this.calculateRatio();
    const zoom = Math.min(...ratios);

    this.setState({
      zoom,
    }, () => {
      const { top, left } = this.calculateCenterCords();
      this.cropperRef.moveTo(left, top);
      this.handleCropChange();
    });
  }

  moveUp() {
    this.cropperRef.move(0, -1);
    this.handleCropChange();
  }

  moveRight() {
    this.cropperRef.move(1, 0);
    this.handleCropChange();
  }

  moveDown() {
    this.cropperRef.move(0, 1);
    this.handleCropChange();
  }

  moveLeft() {
    this.cropperRef.move(-1, 0);
    this.handleCropChange();
  }

  cancelImageCropping() {
    if (this.props.onCropCancel) {
      this.props.onCropCancel();
    }
  }

  calculateCenterCords = () => {
    const { width, height } = this.cropperRef.getCanvasData();
    const { width: frameWidth, height: frameHeight } = this.state;

    const left = (frameWidth - width) * 0.5;
    const top = (frameHeight - height) * 0.5;

    return { top, left };
  };

  hasTransparentArea = () => {
    const {
      width, height, top, left,
    } = this.cropperRef.getCanvasData();
    const { width: frameWidth, height: frameHeight } = this.state;

    switch (true) {
      case top > 0 || left > 0:
        return true;
      case top === 0 && left === 0:
        return frameWidth > width || frameHeight > height;
      case top < 0 || left < 0:
        return (height + top) < frameHeight || (width + left) < frameWidth;
      default:
        return false;
    }
  };

  handleZoomChange(event) {
    this.setState({
      zoom: Number(event.target.value),
    }, () => {
      this.handleCropChange();
    });
  }

  increaseZoom = () => {
    const step = this.calculateZoomStep();

    this.setState((prev) => {
      let { zoom } = prev;

      if (prev.zoom < prev.maxZoom) {
        zoom = prev.zoom + step;
      }

      return {
        zoom,
      };
    }, () => {
      this.handleCropChange();
    });
  };

  decreaseZoom = () => {
    const step = this.calculateZoomStep();

    this.setState((prev) => {
      let { zoom } = prev;

      if (prev.zoom > prev.minZoom) {
        zoom = prev.zoom - step;
      }

      return {
        zoom,
      };
    }, () => {
      this.handleCropChange();
    });
  };

  handleCropChange = () => {
    this.props.onCropChange(this.collectCropperData());
  };

  collectCropperData = () => ({
    canvasData: this.cropperRef.getCanvasData(),
    data: this.cropperRef.getData(true),
    scale: this.state.zoom,
    src: this.resolveOriginalUrl(),
  });

  calculateZoomStep = () => ZOOM_STEP;

  resolveOriginalUrl() {
    const { originalMediaItem } = this.props;

    if (originalMediaItem) {
      return originalMediaItem.original;
    }

    return null;
  }

  shouldShowLoader = () => {
    const { isCropperReady } = this.state;
    const { cropperData, isCropPending } = this.props;

    return isCropPending || (!isCropperReady && cropperData === null);
  };

  syncMoveHandle = () => {
    const {
      width, height, left, top,
    } = this.cropperRef.getCanvasData();
    const w = Math.max(this.state.width, width);
    const h = Math.max(this.state.height, height);

    this.cropperRef.cropper.face.style.width = `${w}px`;
    this.cropperRef.cropper.face.style.height = `${h}px`;

    this.cropperRef.cropper.face.style.transform = `translate(${left}px, ${top}px)`;
  };

  renderCropperToolbar() {
    const {
      minZoom,
      maxZoom,
      zoom,
      isCropperReady,
    } = this.state;

    return (
      <Toolbar>
        <ToolbarItem
          tooltip={i18n.t('CONTENT.fillThePicture')}
        >
          <button type="button" className="button--mini" onClick={this.fillImage}>
            <Icon name="fill-blue" />
          </button>
        </ToolbarItem>
        <ToolbarItem
          tooltip={i18n.t('CONTENT.fitThePicture')}
        >
          <button type="button" className="button--mini" onClick={this.fitImage}>
            <Icon name="fit-blue" />
          </button>
        </ToolbarItem>
        <ToolbarItem>
          <RangeSlider
            value={zoom}
            onChange={this.handleZoomChange}
            min={minZoom}
            max={maxZoom}
            step={this.calculateZoomStep()}
            disabled={!isCropperReady}
          />
        </ToolbarItem>
        <ToolbarItem>
          <button type="button" className="button--mini" onClick={this.cropImage}>
            <Icon name="check" />
          </button>
        </ToolbarItem>
        <ToolbarItem>
          <button type="button" className="button--mini" onClick={this.cancelImageCropping}>
            <Icon name="close" />
          </button>
        </ToolbarItem>
      </Toolbar>
    );
  }

  renderCropperLoader = () => (
    <div className="c-bb-inline-cropper__loader">
      <CircleLoader />
    </div>
  );

  renderCropper() {
    const { width, height, zoom } = this.state;

    if (width === null || height === null) {
      return null;
    }

    return (
      <div ref={(node) => { this.node = node; }}>
        { this.renderCropperToolbar() }
        <ReactCropper
          ref={(node) => { this.cropperRef = node; }}
          src={this.resolveOriginalUrl()}
          style={{ width, height }}
          modal={false}
          center={false}
          guides={false}
          aspectRatio={width / height}
          zoomTo={zoom}
          rotatable={false}
          responsive={false}
          scalable={false}
          dragMode="move"
          zoomOnWheel={false}
          viewMode={0}
          ready={this.onCropperReady}
          cropBoxMovable={false}
          checkOrientation={false}
          toggleDragModeOnDblclick={false}
          cropBoxResizable={false}
          background={false}
          minCropBoxWidth={width}
          minCropBoxHeight={height}
          minContainerWidth={width}
          minContainerHeight={height}
          cropmove={this.handleCropChange}
          crop={this.syncMoveHandle}
        />
      </div>
    );
  }

  render() {
    return (
      <div
        className="c-bb-inline-cropper"
        ref={(node) => { this.wrapperRef = node; }}
      >
        { this.renderCropper() }
        { this.shouldShowLoader() && this.renderCropperLoader() }
        {this.state.originalMediaError && (
          <MessageModal
            onClose={this.props.onCropCancel}
            onOk={this.props.onCropCancel}
            title={i18n.t('MEDIA.couldNotCropImage')}
          >
            {i18n.t('MEDIA.cropperMissingOriginalFile')}
          </MessageModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  originalMediaItem: findMediaItem(state, ownProps.originalMediaId),
});

export default withNamespaces()(connect(mapStateToProps)(Cropper));
