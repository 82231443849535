import { CALL_BB_API } from '../api';
import { MODULE_ARRAY_SCHEMA } from '../api/schema';
import { ROUTE_MODULES } from '../api/routes';

export const MODULES_REQUEST = 'MODULES_REQUEST';
export const MODULES_SUCCESS = 'MODULES_SUCCESS';
export const MODULES_FAILURE = 'MODULES_FAILURE';

// BrowsBox API middleware.
const fetchModules = () => ({
  [CALL_BB_API]: {
    types: [MODULES_REQUEST, MODULES_SUCCESS, MODULES_FAILURE],
    endpoint: ROUTE_MODULES,
    method: 'GET',
    schema: MODULE_ARRAY_SCHEMA,
  },
});

// Redux Thunk middleware.
export const loadModules = () => dispatch => dispatch(fetchModules());

export default loadModules;
