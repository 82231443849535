import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import flatten from 'lodash/flatten';
import {
  prepareBaseModuleItemFieldsToSubmit,
  convertBaseModuleItemFieldsToCreateForm,
} from '../../../../common';
import { setCurrentModule } from '../../../../actions/baseModule';
import {
  saveBaseModuleItem,
  saveNsiProductType,
  saveNsiDetailFilters,
  togglePublishBaseModuleItem,
  getBaseModuleRelatedItemsData,
} from '../../../../actions/baseModuleItems';
import BaseModuleItemForm from './BaseModuleItemForm';
import { loadTags } from '../../../../actions/tags';
import { getModuleByName } from '../../../../selectors';

const propTypes = {
  currentModule: PropTypes.object.isRequired,
  setCurrentModule: PropTypes.func.isRequired,
  saveBaseModuleItem: PropTypes.func.isRequired,
  saveNsiProductType: PropTypes.func.isRequired,
  productTypes: PropTypes.array.isRequired,
  detailFilters: PropTypes.array.isRequired,
  saveNsiDetailFilters: PropTypes.func.isRequired,
  selectableItems: PropTypes.array.isRequired,
  loadTags: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  getBaseModuleRelatedItemsData: PropTypes.func.isRequired,
  allTags: PropTypes.array,
};

const defaultProps = {
  allTags: [],
};

const BaseModuleItemCreate = (props) => {
  React.useEffect(() => {
    props.loadTags();
    props.getBaseModuleRelatedItemsData();
  }, []);

  React.useEffect(() => {
    if (props.currentModule) {
      props.setCurrentModule(props.currentModule);
    }
  }, [props.currentModule.id]);

  const navigateToList = () => {
    const { name } = props.match.params;
    props.history.push(`/items/${name}`);
  };

  const handleReset = () => {
    navigateToList();
  };

  const handleSubmit = (formValues) => {
    const formBody = { ...formValues };
    const fields = prepareBaseModuleItemFieldsToSubmit(formBody.fields);
    const detailFiltersIds = formBody.detailFilters ? flatten(Object.values(formBody.detailFilters)) : [];
    formBody.fields = fields;
    let productCodes = [];
    if (formBody.productCodes) {
      productCodes = Object.keys(formBody.productCodes).map(key => ({
        detailFilterIds: key.split(','),
        code: formBody.productCodes[key],
      }));
    }

    return props.saveBaseModuleItem(formBody, props.currentModule.id)
      .then((res) => {
        const { type } = formBody;

        if (type) props.saveNsiProductType(res.response.id, { type });

        if (detailFiltersIds.length) {
          const detailFilters = {
            baseModuleItemId: res.response.id,
            detailFiltersIds,
            productCodes,
          };
          props.saveNsiDetailFilters(detailFilters);
        }

        return navigateToList();
      });
  };

  const createInitialValues = () => ({
    title: null,
    autoUrl: true,
    categories: [],
    fields: convertBaseModuleItemFieldsToCreateForm(props.currentModule.fields),
    tags: [],
    manualSeo: false,
    autoSeoTitle: true,
    autoSeoDescription: true,
    autoSeoUrl: true,
    autoSeoValues: {},
    seoUrl: '',
    seoTitle: '',
    seoDescription: '',
  });

  const {
    currentModule, productTypes, detailFilters, selectableItems,
  } = props;

  if (!currentModule) {
    return null;
  }

  return (
    <div className="c-bb-base-module c-bb-base-module__item-create">
      <div className="c-bb-base-module-header" />
      <div className="c-bb-base-module-body">
        <div className="c-bb-form c-bb-form--has-header">
          <BaseModuleItemForm
            allowForLangChange
            productTypes={productTypes}
            detailFilters={detailFilters}
            initialValues={createInitialValues()}
            currentModule={currentModule}
            onSubmit={handleSubmit}
            selectable={selectableItems}
            onReset={handleReset}
            allTags={props.allTags}
            routePrefix={currentModule.route}
          />
        </div>
      </div>
    </div>
  );
};

BaseModuleItemCreate.propTypes = propTypes;
BaseModuleItemCreate.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
  const {
    baseModule: {
      isOpen,
      lang,
    },
    global: {
      tags,
    },
  } = state;

  const currentModule = getModuleByName(state, props.match.params.name, lang);
  let productTypes = [];
  let detailFilters = [];

  if (currentModule && currentModule.internal_name === 'product') {
    productTypes = state.baseModuleItems.customProductTypes;
    detailFilters = state.baseModuleItems.customDetailFilters;
  }

  return {
    isOpen,
    currentModule,
    productTypes,
    detailFilters,
    selectableItems: currentModule ? currentModule.categories : [],
    allTags: tags,
  };
};

const mapDispatchToProps = {
  saveBaseModuleItem,
  saveNsiProductType,
  saveNsiDetailFilters,
  togglePublishBaseModuleItem,
  loadTags,
  getBaseModuleRelatedItemsData,
  setCurrentModule,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(BaseModuleItemCreate));
