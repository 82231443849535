export default function isDebug() {
  const isEnvProduction = process.env.NODE_ENV === 'production';

  if (!window) {
    return !isEnvProduction;
  }

  const params = new URLSearchParams(window.location.search);
  let debug = params.get('debug');

  if (debug === '') {
    // enable debug when ?debug is present
    debug = true;
  }

  return !isEnvProduction || debug;
}
